import { Axios } from 'commonUtils';

const endpoint = '/spr/ipRestrictionRules';

export default class IpRestrictionRuleApi {
  save = async (values) => {
    return await Axios.postData(endpoint, values);
  };

  fetchAll = async ({ projectId }) => {
    return await Axios.getData(`${endpoint}`, {
      params: {
        projectId,
      },
    });
  };

  fetchOne = async (restrictionId) => {
    return await Axios.getData(`${endpoint}/${restrictionId}`);
  };

  toggleEnabled = async (restrictionId) => {
    return await Axios.postData(`${endpoint}/${restrictionId}/enabled`);
  };

  delete = async (restrictionId) => {
    return await Axios.delete(`${endpoint}/${restrictionId}`);
  };

  fetchCountries = async () => {
    return await Axios.getData(`${endpoint}/countries`);
  };

  checkAccess = async ({ projectId, platform }) => {
    return await Axios.getData(`${endpoint}/checkAccess`, { params: { projectId, platform } });
  };

  fetchLogs = async ({ projectId }) => {
    return await Axios.getData(`${endpoint}/logs`, { params: { projectId } });
  };
}
