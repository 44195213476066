import React from "react";
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import {Field, Validations, wrapForm} from "components/ui/FormUtils";
import UiMsg from "components/ui/UiMsg";
import Api from "components/Api";
import Button from "components/ui/Button";
import WsBar from "components/ui/in-memory/WsBar";



class PipeRunForm extends React.Component {


    static propTypes = {
        closeModal: PropTypes.func,
        projectId: PropTypes.number,
        inMemoryId: PropTypes.number,
        edit:PropTypes.bool,
        name:PropTypes.string,
        onClose: PropTypes.func,
    };

    static defaultProps = {
        formValues: {},
        projectId:0,
        inMemoryId:0,
        edit:false,
        name:'',
        onClose: _.noop,
    };

    state = {
        tokenIsInvalid: true,
        lockUI: false,
        messagestate: "",
        colorvalidate:'red',
        buttonvalidade:"none",
        namevalidation: "none",
        editdata:[],
        datachange:''
    };



    constructor(props) {
        super(props);
    }

   async componentDidMount() {
        if(this.props.edit){
            const values  = await Api.Piperun.getValuesWS(this.props.inMemoryId);
            this.props.formValues.token = values.token;
            this.props.formValues.name = values.name;
            this.validateToken();
            this.setState({datachange: 'loaded'});
        }
        else {
            this.setState({messagestate: this.props.context.msg.t('pipe.run.disconnected')});
        }
    }


    save = async (pipeRunData) => {
        this.setState({lockUI: true});
        try {
            let result = await Api.Piperun.validateName(pipeRunData);
            if(this.props.namecube === pipeRunData.name){
                result = false;
            }

            if(result == false) {
                this.setState({namevalidation:"none"});
                if(this.props.edit){
                    const save = await Api.Piperun.editOrigin(this.props.inMemoryId,pipeRunData);
                }else{
                    const save = await Api.Piperun.saveOrigin(pipeRunData);
                }
                UiMsg.ok(this.props.context.msg.t('pipe.run.origin.saved'));
                Api.updateJsf();
                this.props.closeModal();
            }else {
                this.setState({namevalidation:"inline"});
            }
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e)
        }
        this.setState({lockUI: false});

    };

    invalidateToken = () => {
        this.setState({tokenIsInvalid: true, colorvalidate: 'red', buttonvalidade:"none", messagestate: this.props.context.msg.t('pipe.run.disconnected')});

    };

    invalidateName = () => {
        this.setState({namevalidation: 'none'});
    };

    validateToken = async () => {
        this.setState({lockUI: true});
        let result;
        try {
            result = await Api.Piperun.validateToken(this.props.formValues.token);
        } catch (e) {
            result = false;
        }

        if(result){
            this.setState({tokenIsInvalid: false, colorvalidate: 'green', messagestate: this.props.context.msg.t('pipe.run.connected'), buttonvalidade: "inline"});
            UiMsg.ok(this.props.context.msg.t('pipe.run.origin.token.isvalid'));

        } else {
            this.invalidateToken();
            UiMsg.error(this.props.context.msg.t('pipe.run.origin.token.invalid'));
        }

        this.setState({lockUI: false});
    };


    render() {
        const {tokenIsInvalid, lockUI, messagestate,colorvalidate, buttonvalidade, namevalidation, values} = this.state;

        const href = this.props.context.msg.t('pipe.run.help.link');
        const icon = Api.baseUrl()+'/images/data/pipe-run-black.png';


        return (

            <WsBar open={this.props.open}
                   title={this.props.context.msg.t('pipe.run.dialog.title')}
                   onClose={() => {
                       this.props.closeModal();
                       this.props.onClose();
                   }}
                   help={this.props.context.msg.t('pipe.run.title.help')}
                   href={href}
                   icon={icon}
                   className={'PipeRunForm'}>

                    <form onSubmit={this.props.handleSubmit(this.save)}>
                        <fieldset className="ws-information-container">
                            <div className="ws-information-container-left">
                                <div>
                                    <Field name="name" type="text"  placeholder={this.props.context.msg.t('pipe.run.name.visualization')} className="fill-w"
                                           data-changed={this.state.datachange}
                                           onChange={this.invalidateName}/>

                                    <span className="nameinvalid" id="nameinvalid" style={{display:namevalidation}}>
                                        {this.props.context.msg.t('pipe.run.origin.name.invalid')}
                                    </span>
                                </div>

                                <div>
                                    <div className="informationtitle" >{this.props.context.msg.t('pipe.run.information.title')}</div>
                                    <div className="information">
                                        <span className="fa fa-address-card"> </span>
                                        {this.props.context.msg.t('pipe.run.information')}
                                    </div>
                                    <div className="ws-information-details">{this.props.context.msg.t('pipe.run.information.description')}</div>
                                </div>

                                <div>
                                    <Field name="token" type="text" placeholder={this.props.context.msg.t('pipe.run.token')} className="fill-w"
                                           data-changed={this.state.datachange}
                                           onChange={this.invalidateToken}/>
                                </div>
                            </div>

                            <div className="validate-token" >
                                <Button className="btn btn-success" id="pipe-btn-ok" style={{display:buttonvalidade} } >
                                    <span className="fa fa-check"></span>
                                </Button>
                                <Button icon="icon-ok" id="btn-validade" onClick={this.validateToken} loading={lockUI} className="pull-left">
                                    {this.props.context.msg.t('pipe.run.validate.token')}
                                </Button>
                            </div>

                            <div className ="is-connected">
                                <span id="circle-validate" className="fa fa-circle-o" style={{color:colorvalidate}}>
                                </span> {messagestate}
                            </div>
                        </fieldset>

                        <div className="spansubmit">
                            <Button id="submit" icon="check" type="submit" loading={lockUI} disabled={tokenIsInvalid} className="pull-right">
                                {this.props.context.msg.t('pipe.run.go')}
                            </Button>
                        </div>
                    </form>
            </WsBar>
        );
    }

}

export default ContextEnhancer(
    wrapForm({
            component: PipeRunForm,
            formName: 'pipeRunForm',
            mapToState: (state) => ({
                initialValues: {...state.modals.current.params}
            })
        }
    )
);