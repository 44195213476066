import './SchemaUpdaterDialog.css';

import React, { useEffect, useMemo, useState } from 'react';
import Button from 'components/ui/Button';
import Dialog from 'components/ui/Dialog';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { TypeOption } from 'components/bng/pages/admin/structures/StructuresPage';
import usePagination from 'components/hooks/usePagination';
import PaginateTable from 'components/ui/common/PaginateTable';
import { BngInput } from 'components/bng/form/BngInput';
import useDebounce from 'components/hooks/useDebounce';
import Utils from 'components/Utils';
import useBimContext from 'components/hooks/useBimContext';

export default function SchemaUpdaterDialog({ projectId, closeModal }) {
  const context = useBimContext();

  const [loading, setLoading] = useState(false);
  const [updatingSchema, setUpdatingSchema] = useState({});

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const originsPagination = usePagination();

  const fetchData = async () => {
    setLoading(true);
    try {
      const { page, size } = originsPagination.pagination;
      const pageInfo = await Api.SchemaUpdater.findOrigins(search, page, size);
      originsPagination.setPageInfo(pageInfo);
    } catch (e) {
      console.error(e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  const isSystemUser = Utils.Users.isSystemUser(context.user);

  useEffect(() => {
    if (isSystemUser) {
      return;
    }
    // Only app admin can access without projectId
    if (!projectId && !context.permissions.isAdmin()) {
      closeModal();
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [originsPagination.pagination, debouncedSearch]);

  const rebuildDatasourcesXml = async () => {
    setLoading(true);
    try {
      await Api.SchemaUpdater.rebuildDatasourcesXml();
      UiMsg.ok(context.msg.t('SchemaUpdaterDialog.rebuildDatasourcesXml.success'));
    } catch (e) {
      console.error(e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  const buildCaption = (origin) => {
    return `${origin.id} - ${origin.caption} (${origin.name})`;
  };

  const updateSchema = async (origin) => {
    setUpdatingSchema(origin);
    const caption = buildCaption(origin);
    try {
      await Api.SchemaUpdater.updateSchema(origin.id);
      UiMsg.ok(context.msg.t('schema.update.success'), caption);
    } catch (e) {
      console.error(e);
      UiMsg.error('error', `${caption} \n ${e.message}`);
    } finally {
      setUpdatingSchema({});
    }
  };

  const updateProjectSchemas = async (projectId) => {
    setLoading(true);
    try {
      await Api.SchemaUpdater.updateProjectSchemas(projectId);
      UiMsg.ok(context.msg.t('schemas.update.success'));
    } catch (e) {
      console.error(e);
      UiMsg.error(null, e.message);
    } finally {
      setLoading(false);
    }
  };

  const removeSchema = async (origin) => {
    setUpdatingSchema(origin);
    const caption = buildCaption(origin);
    try {
      const { removed } = await Api.SchemaUpdater.removeSchema(origin.id);
      UiMsg[removed ? 'ok' : 'warn'](
        context.msg.t(removed ? 'schema.remove.success' : 'schema.remove.notFound'),
        caption
      );
    } catch (e) {
      console.error('Error on removeSchema', origin, e);
      UiMsg.error('error', `${caption} \n ${e.message}`);
    } finally {
      setUpdatingSchema({});
    }
  };

  const updateStructureLanguage = async (origin) => {
    setUpdatingSchema(origin);
    const caption = buildCaption(origin);
    try {
      await Api.SchemaUpdater.updateLanguage(origin.id);
      UiMsg.ok(this.props.context.msg.t('dimensions.update.success'), caption);
    } catch (e) {
      console.error(e);
      UiMsg.error('error', `${caption} \n ${e.message}`);
    } finally {
      setUpdatingSchema({});
    }
  };

  const getSortedOrigins = (origins) => {
    return origins.reduce((acc, origin) => {
      let match = acc.find((a) => a.projectId === origin.projectId);
      if (!match) {
        match = { projectId: origin.projectId, origins: [] };
        acc.push(match);
      }
      match.origins.push(origin);
      return acc;
    }, []);
  };

  const updateAllSchemas = async () => {
    setLoading(true);
    try {
      await Api.SchemaUpdater.updateAllSchemas();
      UiMsg.ok(context.msg.t('schemas.update.success'));
    } catch (e) {
      UiMsg.error(null, e.message);
    } finally {
      setLoading(false);
    }
  };

  const groupedOrigins = useMemo(() => {
    return getSortedOrigins(originsPagination.pageInfo.content);
  }, [originsPagination.pageInfo.content]);

  return (
    <Dialog
      className="SchemaUpdaterDialog large"
      title={context.msg.t('schema.management')}
      onClose={closeModal}
      loading={loading || !_.isEmpty(updatingSchema)}
    >
      {isSystemUser && (
        <div className="row-fluid">
          <div className="span12 text-right btn-fix">
            <Button icon="icon-refresh" className="btn-mini btn-inverse" onClick={rebuildDatasourcesXml}>
              {context.msg.t('SchemaUpdaterDialog.rebuildDatasourcesXmlBtn')}
            </Button>{' '}
            <Button
              icon="icon-refresh"
              className="btn-mini btn-inverse"
              onClick={async () => {
                setLoading(true);
                try {
                  await Api.AppCache.clearOlapCache();
                  UiMsg.ok(context.msg.t('mondrian.cache.cleared.successfully'));
                } catch (e) {
                  console.error(e);
                  UiMsg.ajaxError(null, e);
                } finally {
                  setLoading(false);
                }
              }}
            >
              {context.msg.t('clear.mondrian.cache')}
            </Button>{' '}
            <Button icon="icon-bug" className="btn-mini btn-warning" onClick={updateAllSchemas}>
              {context.msg.t('update.all.schemas')}
            </Button>
          </div>
        </div>
      )}

      <div>
        <BngInput
          placeholder={context.msg.t('search.field.placeholder')}
          field={{
            value: search,
            onChange: (e) => {
              setSearch(e.target.value ?? '');
            },
          }}
        />
      </div>

      <div className="row-fluid">
        <div className="span12">
          <PaginateTable
            handleChangePage={originsPagination.handlePagination}
            handleChangeNumberPerPage={originsPagination.perPageChanged}
            totalItens={originsPagination.pageInfo.totalElements}
            initialPass={originsPagination.pagination.size}
            initialActivePage={originsPagination.pagination.page + 1}
            height={430}
          >
            <table className="table table-hover table-condensed table-bordered mb-0">
              <thead>
                <tr>
                  <th>{context.msg.t('project')}</th>
                  <th colSpan={2}>{context.msg.t('origin')}</th>
                  <th className={'text-center'}>{context.msg.t('actions')}</th>
                </tr>
                <tr>
                  <th></th>
                  <th>{context.msg.t('name')}</th>
                  <th style={{ width: 40 }}>{context.msg.t('type')}</th>
                  <th style={{ width: 80 }} />
                </tr>
              </thead>
              <tbody>
                {groupedOrigins.map(({ origins }) => {
                  return origins.map((origin, idx) => {
                    const isAnalytical = origin.originType === 'ANALYTICAL';
                    return (
                      <tr key={`structure-${origin.id}`} className={origin.id === updatingSchema.id ? 'Running' : ''}>
                        {idx === 0 && (
                          <td
                            rowSpan={origins.length}
                            className={origin.projectId === updatingSchema.projectId ? 'Running' : ''}
                          >
                            <div>
                              {origin.projectId} - {origin.projectCaption} ({origin.projectName})
                            </div>
                            <div className="flex-center-items">
                              <div>
                                <b>{context.msg.t('language')}:</b> {context.msg.t(origin.projectLanguage)}
                              </div>
                              <Button
                                icon="icon-bug"
                                title={context.msg.t('regenerate.project.schemas.button.title')}
                                className="btn-mini btn-warning btn-table ml-auto"
                                onClick={() => updateProjectSchemas(origin.projectId)}
                              />
                            </div>
                          </td>
                        )}
                        <td>{buildCaption(origin)}</td>
                        <td>
                          <TypeOption type={origin.originType} withLabel={false} className="jc-center" />
                        </td>
                        <td>
                          {isAnalytical && (
                            <>
                              <Button
                                icon="icon-bug"
                                title={context.msg.t('regenerate.schema.button.title')}
                                className="btn-mini btn-warning btn-table"
                                onClick={() => updateSchema(origin)}
                              />

                              <Button
                                icon="icon-time"
                                title={context.msg.t('update.structure.language')}
                                className="btn-mini btn-info btn-table"
                                onClick={() => updateStructureLanguage(origin)}
                              />

                              <Button
                                icon="delete"
                                title={context.msg.t('remove.schema.button.title')}
                                className="btn-mini btn-danger btn-table"
                                onClick={() => removeSchema(origin)}
                              />
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  });
                })}
              </tbody>
            </table>
          </PaginateTable>
        </div>
      </div>
    </Dialog>
  );
}
