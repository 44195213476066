import styles from './IpRestrictionBlockAccessDialog.module.css';
import React, { useMemo } from 'react';

import Dialog from 'components/ui/Dialog';
import useTranslation from 'components/hooks/useTranslation';
import Icon from 'components/ui/common/Icon';
import useBimContext from 'components/hooks/useBimContext';
import { Field, Form, Formik } from 'formik';
import BngField from 'components/bng/form/BngField';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';
import BngButton from 'components/bng/ui/BngButton';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import bngYup from 'components/bng/form/yup/BngYup';
import useFetchData from 'components/hooks/useFetchData';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';

const IpRestrictionBlockAccessSchema = bngYup((yup) =>
  yup.object({
    project: yup.object().required(),
  })
);

export default function IpRestrictionBlockAccessDialog() {
  const { t } = useTranslation();
  const context = useBimContext();

  const { data: projectsOpts = [], isLoading } = useFetchData(async () => {
    const projects = await Api.Navbar.projectsMenuDetails();
    return projects
      .filter((project) => project.id !== context.project.id)
      .map((acc) => ({
        label: acc.displayName,
        value: acc,
        render: () => {
          let projectFavIcon = `${context.contextPath}/resources/images/favicon-menu-projects.png`;
          if (checkAddonEnabled('VISUAL_IDENTITY') && !acc.theme.favIcon.endsWith('favicon.ico')) {
            projectFavIcon = `${context.contextPath}/${acc.theme.favIcon}`;
          }
          return (
            <div className={styles.projectOption}>
              <img src={projectFavIcon} alt={t('project.icon.alt', [acc.displayName])} className={styles.projectIcon} />
              <span>{acc.displayName}</span>
            </div>
          );
        },
      }));
  });

  const initialValues = useMemo(() => {
    return { project: projectsOpts?.[0]?.value || '' };
  }, [projectsOpts]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={IpRestrictionBlockAccessSchema}
      enableReinitialize={true}
      onSubmit={async (values) => {
        try {
          const url = await Api.Bng.accessProjectUrl(values.project.name);
          window.location.replace(url);
        } catch (e) {
          console.error(e);
          UiMsg.ajaxError(null, e);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Dialog
          className={`IpRestrictionBlockAccessDialog ${styles.IpRestrictionBlockAccessDialog}`}
          hideClose={true}
          hideHeader={true}
        >
          <div className={styles.headerIcon}>
            <Icon icon={'vpn_lock'} />
            <span className={styles.restrictionTitle}>{t('projectManagementTab.ipRestriction')}</span>
          </div>
          <div className={styles.restrictionBody}>
            <span
              dangerouslySetInnerHTML={{ __html: t('ip.restriction.warning.message', [context.project.displayName]) }}
            ></span>
            <span>
              {t('ip.restriction.contact.message')}
              <a href={t('addon.ip.restriction.link')} target={'_blank'}>
                {t('know.more')}
              </a>
            </span>
          </div>
          <div className={styles.actionsWrapper}>
            {projectsOpts.length > 0 && (
              <>
                <label className={styles.titleSpan}>{t('go.to.another.project')}</label>
                <Form>
                  <div className={styles.projectField}>
                    <Field
                      name="project"
                      component={BngField}
                      inputComponent={BngSelectSearch}
                      showErrors={false}
                      options={projectsOpts}
                      withLabel={false}
                      clearable={false}
                    />
                    <BngButton btnType="submit" disabled={isSubmitting}>
                      {t('project.expired.dialog.open.project')}
                    </BngButton>
                  </div>
                </Form>
              </>
            )}
            <BngButton onClick={Api.User.logout} className={styles.logoutBtn}>
              {t('logout.bimachine')}
            </BngButton>
          </div>
        </Dialog>
      )}
    </Formik>
  );
}
