import React from 'react';
import BaseKpi, {resizeEl} from "components/ui/kpi/BaseKpi";

export class MeterGaugePercent extends BaseKpi {

    constructor(props) {
        super(props);
    }

    initComponent = async () => {
        let kpiId = this.props.kpiId;

        if (this.props.div.id.includes('itemexamplekpi')) {
            kpiId = "Preview"
        }

        j(`#metergauge${kpiId}`).empty();

        let val = this.props.value;

        if (this.props.measureAsTarget && this.props.queryResult?.measureTargetIsPercent) {
            val = this.props.percentage
        }

        let minVal = this.props.minValue;

        let color = [];
        let intervals = [];
        let maxVal = this.props.target;

        if (this.props.bandsInformations.length > 0) {
            let bands = this.props.bandsInformations;
            bands[0].valueNotFormated = 0;
            bands.map(e => {
                color.push(e.bandColor);
                intervals.push(e.valueNotFormated);
                maxVal = e.valueNotFormated;
            });
            val = val > maxVal ? maxVal : val;
        } else {
            color.push(this.state.color);
            intervals.push(this.props.value);
            maxVal = this.props.target < this.props.value ? this.props.value : this.props.target;
        }
        let item = 'metergauge' + kpiId;

        await jQuery(function () {
            try {
                j.jqplot(item, [[val]], {
                    gridPadding: {top: 0, right: 0, bottom: 0, left: 0},
                    grid: {background: "transparent"},
                    seriesDefaults: {
                        renderer: j.jqplot.MeterGaugeRenderer,
                        rendererOptions: {
                            label: null,
                            padding: null,
                            labelPosition: 'bottom',
                            labelHeightAdjust: 0,
                            min: minVal,
                            max: maxVal,
                            showTickLabels: false,
                            intervals: intervals,
                            intervalColors: color
                        }
                    }
                });
            } catch (err) {
                console.error(err)
            }
        });
        j(".jqplot-meterGauge-label").css("display", "none");
    };

    customResizeKpi = ({height}) => {
        resizeEl(this.$kpiTitleEl, {
            fontSize: {minValue: 12, multiplier: 0.1, refSize: height},
            lineHeight: {minValue: 14, multiplier: 0.11, refSize: height},
        });

        if (this.$kpiTitleEl.offsetWidth >= this.props.div.offsetWidth) {
            let newFontSize = height * (this.props.div.offsetWidth / this.$kpiTitleEl.offsetWidth);

            resizeEl(this.$kpiTitleEl, {
                fontSize: {minValue: 12, multiplier: 0.1, refSize: newFontSize},
                lineHeight: {minValue: 14, multiplier: 0.11, refSize: newFontSize},
            });
        }

        resizeEl(this.$kpiMeterGaugeEl, {
            height: {minValue: 50, multiplier: 0.5, refSize: height},
            width: {minValue: 60, multiplier: 1.5, refSize: height},
        });

        resizeEl(this.$percentEl, {
            fontSize: {minValue: 12, multiplier: 0.07, refSize: height},
            lineHeight: {minValue: 12, multiplier: 0.07, refSize: height},
        });

        resizeEl(this.$kpiDescriptionEl, {
            fontSize: {minValue: 8, multiplier: 0.05, refSize: height},
            lineHeight: {minValue: 10, multiplier: 0.1, refSize: height},
        });

        if (this.$kpiDescriptionEl.offsetWidth >= this.props.div.offsetWidth) {
            const newFontSize = height * (this.props.div.offsetWidth / this.$kpiDescriptionEl.offsetWidth);

            resizeEl(this.$kpiDescriptionEl, {
                fontSize: {minValue: 8, multiplier: 0.05, refSize: newFontSize},
                lineHeight: {minValue: 10, multiplier: 0.1, refSize: newFontSize},
            });
        }
    };

    render() {

        let kpiId = this.props.kpiId;

        if (this.props.div.id.includes('itemexamplekpi')) {
            kpiId = "Preview"
        }

        return (
            <table>
                <tbody>
                <tr className="title-kpi-percent">
                    <td>
                        <div className="kpititle" style={{textAlign: 'center'}}>
                            <span ref={el => this.$kpiTitleEl = el}>{this.props.title}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign: 'center'}}>
                        <div ref={el => this.$kpiMeterGaugeEl = el} id={`metergauge${kpiId}`}/>
                    </td>
                </tr>
                <tr className="title-kpi-percent">
                    <td>
                        <div title={this.state.value} className="kpi-percent" ref={el => this.$percentEl = el}
                             style={{textAlign: 'center'}}>
                            {this.state.value}
                        </div>
                    </td>
                </tr>
                <tr className="title-kpi-description">
                    <td>
                        <div style={{textAlign: 'center'}}>
                                <span className="kpidescription"
                                      ref={el => this.$kpiDescriptionEl = el}>{this.props.label}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <center>
                            <div ref={el => this.$containerBandsEl = el}>
                                {this.useBands({container: this.$containerBandsEl})}
                            </div>
                        </center>
                    </td>
                </tr>
                </tbody>
            </table>

        )
    }

}

