import styles from './AddAdditionalDialog.module.css';

import React, { useState } from 'react';
import { animated, useSpring } from '@react-spring/web';

import Dialog from 'components/ui/Dialog';
import AddonStars from 'components/ui/navbar/addons/AddonStars';
import Icon from 'components/ui/common/Icon';
import BngButton, { Variant } from 'components/bng/ui/BngButton';
import useBimContext from 'components/hooks/useBimContext';
import useFetchData from 'components/hooks/useFetchData';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { proposalUtils } from 'components/service/bng/AccountApi';
import Utils from 'components/Utils';
import { DEFAULT_REQUEST_QUOTA, MAX_QUOTA_REQUESTS } from 'components/ui/project/BngQuotaViewer';
import useTranslation from 'components/hooks/useTranslation';

export const ADDITIONALS = {
  USERS: { name: 'user', key: 'USER' },
  STRUCTURES: { name: 'structure', key: 'STRUCTURE' },
  QUOTA: { name: 'quota', key: 'QUOTA' },
};

export function separateDecimals(num) {
  if (Number.isInteger(num)) {
    return [num, 0];
  }

  return num.toString().split('.');
}

function FreeQuotaDropdown({ setLoading, closeModal }) {
  const context = useBimContext();
  const { t } = useTranslation();
  const [temporaryQuotaOpen, setTemporaryQuotaOpen] = useState(false);

  const freeQuotaStyles = useSpring({
    opacity: temporaryQuotaOpen ? 1 : 0,
    transform: temporaryQuotaOpen ? 'translateY(0px)' : 'translateY(-40px)',
    height: temporaryQuotaOpen ? 140 : 0,
    overflow: 'hidden',
  });

  const { data: quotaRequests } = useFetchData(async () => {
    try {
      return await Api.ProjectQuotaRequest.findRequests({
        projectId: context.project.id,
        limit: MAX_QUOTA_REQUESTS,
        onlyCurrentMonth: true,
      });
    } catch (e) {
      console.error('Error on function fetchDailyQuotaUsage()', e);
      UiMsg.error(t('error.fetching.data', [t('free.quota')]));
      throw e;
    }
  }, []);

  return (
    <div className={styles.temporaryQuotaWrapper}>
      <a
        onClick={() => {
          setTemporaryQuotaOpen(!temporaryQuotaOpen);
        }}
        className={styles.detailsBtn}
      >
        <Icon icon={temporaryQuotaOpen ? 'arrow_drop_up' : 'arrow_drop_down'} />
        {t('free.quota.dropdown')}
      </a>
      <animated.div className={styles.hiddenTemporaryQuota} style={freeQuotaStyles}>
        <span
          dangerouslySetInnerHTML={{
            __html: t('free.quota.usage', [quotaRequests?.length || 0, MAX_QUOTA_REQUESTS]),
          }}
        ></span>
        <span>{t('free.quota.confirmation', [Utils.Date.formatDate(Date.now())])}</span>
        <div
          className={styles.freeQuotaButtons}
          title={quotaRequests?.length === MAX_QUOTA_REQUESTS ? t('free.quota.limit.reached') : null}
        >
          <BngButton onClick={closeModal} variant={Variant.outline}>
            {t('no')}
          </BngButton>
          <BngButton
            disabled={quotaRequests?.length === MAX_QUOTA_REQUESTS}
            onClick={async () => {
              try {
                setLoading(true);
                await Api.ProjectQuotaRequest.requestQuota(context.project.id);
                UiMsg.ok(t('request.quota.notification.message', [DEFAULT_REQUEST_QUOTA]));
                closeModal();
                window.location.reload();
              } catch (e) {
                console.error('Error on requestQuota()', e);
                UiMsg.ajaxError(null, e);
              } finally {
                setLoading(false);
              }
            }}
          >
            {t('yes')}
          </BngButton>
        </div>
      </animated.div>
    </div>
  );
}

export default function AddAdditionalDialog({
  featureKey,
  closeModal = _.noop,
  openKnowledgeBase = false,
  isConsumptionAdditional = false,
  onActivate = _.noop,
}) {
  const context = useBimContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const $additionalInfo = useFetchData(async () => {
    try {
      const accountId = context.accountId;
      return await Api.Account.fetchAdditionalInfo(accountId, featureKey, isConsumptionAdditional);
    } catch (e) {
      console.error('Error on function fetchInfo()', e);
      UiMsg.ajaxError(null, e);
    }
  });

  const activateAdditional = async () => {
    setLoading(true);
    try {
      await Api.Account.requestAdditional({
        accountId: context.accountId,
        additional: additionalInfo,
        projectId: context.project.id,
        enable: activateWithoutRequest,
        origin: proposalUtils.PROPOSAL_ORIGIN.BIM_ADD_ADDITIONAL_DIALOG,
      });
      UiMsg.ok(t(activateWithoutRequest ? 'additional.added.successfully' : 'additional.requested.successfully'));
      if (activateWithoutRequest) {
        if (additionalInfo.marketplaceInfo.openTicket) {
          try {
            const title = `Adicional contratado através da dialog que necessita de Suporte`;
            await Api.Support.newTicketForMarketplace({
              title,
              items: [additionalInfo],
              user: context.user,
              project: context.project,
              account: { id: context.accountId },
            });

            UiMsg.ok(t('marketplace.item.ticket.opened'));
          } catch (e) {
            console.error('Error opening ticket in function activateAdditional()', e);
            UiMsg.error(t('marketplace.item.ticket.open.error'));
          }
        }

        await Utils.sleep(750);
        onActivate();
      }
      closeModal();
    } catch (e) {
      console.error('Error on function activateAdditional()', e);
      UiMsg.error(t('additional.request.error'));
      closeModal();
    } finally {
      setLoading(false);
    }
  };

  const redirectToMarketplace = () => {
    const redirectUrl = Api.buildUrl('/spr/bng/marketplace', {
      currentTab: 'item',
      itemId: additionalInfo.id,
      type: 'ADDITIONAL',
    });
    window.location.replace(redirectUrl);
  };

  const redirectToKnowledgeBase = () => {
    const knowledgeBaseLink =
      additionalInfo &&
      proposalUtils.findTranslation(additionalInfo?.marketplaceInfo.translatedProps ?? marketplaceInfo.translatedProps)
        .link;
    window.open(knowledgeBaseLink, '_blank').focus();
  };

  const activateWithoutRequest = context.canActivateAddonWithoutRequest;
  const additionalInfo = $additionalInfo.data;
  const classification = additionalInfo?.feature.classification;
  const quantity =
    classification === 'ADDON' || parseInt(additionalInfo?.featureValue.value) < 1
      ? 1
      : additionalInfo?.featureValue.value;
  const pricing = proposalUtils.getDefaultPricing(additionalInfo?.pricings);
  const pricingValue = separateDecimals((pricing?.pricing / quantity).toFixed(2));
  const addonTranslations = additionalInfo ? proposalUtils.translationsForSales(additionalInfo) : {};
  const pricingDesc = additionalInfo
    ? proposalUtils.translationsForSales(
        additionalInfo.marketplaceInfo.pageContent.find(
          (page) => page.type === 'SINGLE_PRICE' || page.type === 'PLAN_PRICE',
        ),
      )
    : {};

  return (
    <Dialog
      className={`${styles.AddAdditionalDialog} AddAdditionalDialog`}
      loading={$additionalInfo.isLoading || loading}
      onClose={closeModal}
      titleComponent={() => {
        return (
          <div className={styles.AddAdditionalDialogHeader}>
            <AddonStars fill={additionalInfo?.marketplaceInfo.color} />
            <Icon
              icon={additionalInfo?.marketplaceInfo.icon}
              className={styles.headerIcon}
              style={{ color: additionalInfo?.marketplaceInfo.color }}
            />
          </div>
        );
      }}
      contentFullWidth
    >
      {additionalInfo && (
        <>
          <div className={styles.contentWrapper}>
            <span className={styles.additionalTitle}>{addonTranslations.name}</span>
            <span className={styles.additionalDesc}>{addonTranslations.description}</span>
            <div className={styles.additionalPricing}>
              <div className={styles.currencySymbol}>{pricing.currency.symbol}</div>
              <div className={styles.fullPricing}>{pricingValue[0]}</div>
              <div className={styles.centsWrapper}>
                <div className={styles.pricingCents}>{pricingValue[1]}</div>
                <div className={styles.byMonth}>{t('byMonth')}</div>
              </div>
            </div>
            <span className={styles.additionalPricingInfo}>
              {additionalInfo.key === 'DEFAULT_STRUCTURE'
                ? t('STRUCTURE_PACKAGE.single_price.info')
                : additionalInfo.key === 'DEFAULT_USER'
                  ? t('USER_PACKAGE.single_price.info')
                  : pricingDesc.title}
            </span>
          </div>
          <div className={styles.buttonWrappers}>
            <BngButton
              className={`${styles.additionalButtons} AdditionalKnowMoreButton`}
              variant={Variant.textButton}
              onClick={openKnowledgeBase ? redirectToKnowledgeBase : redirectToMarketplace}
            >
              {t('know.more')}
            </BngButton>
            <BngButton
              className={`${styles.additionalButtons} AdditionalActivateButton`}
              onClick={activateAdditional}
              loading={loading}
            >
              {t(activateWithoutRequest ? 'additional.hire' : 'request.activation')}
            </BngButton>
          </div>
          {isConsumptionAdditional && featureKey === ADDITIONALS.QUOTA.key && (
            <FreeQuotaDropdown setLoading={setLoading} closeModal={closeModal} />
          )}
        </>
      )}
    </Dialog>
  );
}
