import React from 'react';

import MktDefaultTab from 'components/bng/pages/marketplace/tabs/MktDefaultTab';
import Api from 'components/Api';
import GroupKeys from 'components/bng/pages/marketplace/tabs/GroupKeys';

export const HOME_GROUPS = [
  {
    title: 'marketplaceTab.additionals',
    desc: 'additionals.desc',
    key: GroupKeys.ADDITIONALS,
    knowMore: true,
  },
  {
    title: 'marketplaceTab.addons',
    desc: 'addons.desc',
    key: GroupKeys.ADDONS,
    knowMore: true,
  },
  {
    title: 'marketplace.banner.expand',
    desc: 'marketplace.banner.expand.desc',
    link: '/marketplace?currentTab=plans',
    color: '#FF7B4D',
    image: `${Api.baseUrl()}/resources/images/marketplace/IMG_extensions.jpg`,
    isBanner: true,
  },
  {
    title: 'marketplaceTab.services',
    desc: 'services.desc',
    key: GroupKeys.SERVICES,
    knowMore: true,
  },
  {
    title: 'marketplace.banner.university',
    desc: 'marketplace.banner.university.desc',
    link: '/marketplace?currentTab=educational',
    color: '#2d2d2d',
    image: `${Api.baseUrl()}/resources/images/marketplace/IMG_university.jpg`,
    isBanner: true,
  },
  {
    title: 'marketplaceTab.templates',
    desc: 'templates.desc',
    key: GroupKeys.TEMPLATES,
    knowMore: true,
  },
  {
    title: 'marketplace.banner.templates',
    desc: 'marketplace.banner.templates.desc',
    link: '/marketplace?currentTab=templates',
    color: '#0184fa',
    image: `${Api.baseUrl()}/resources/images/marketplace/IMG_templates.jpg`,
    isBanner: true,
  },
  {
    title: 'marketplaceTab.educational',
    desc: 'educational.desc',
    key: GroupKeys.EDUCATIONAL,
    knowMore: true,
  },
];

export default function MktHomeTab({ ...props }) {
  return (
    <MktDefaultTab className={'MktHomeTab'} groupsToRender={HOME_GROUPS} {...props} groupProps={{ singleLine: true }} />
  );
}
