import './DeleteFileDialog.css';
import React, { useEffect, useMemo, useState } from 'react';

import Dialog from 'components/ui/Dialog';
import Button from 'components/ui/Button';
import Api from 'components/Api';
import Icon from 'components/ui/common/Icon';
import Utils from 'components/Utils';
import { BngTable } from 'components/bng/ui/BngTable';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import useBimContext from 'components/hooks/useBimContext';

export default function DeleteFileDialog({
  closeModal = _.noop,
  nodesToDelete = [],
  onConfirm = _.noop,
  showWarning = true,
  showConfirmCheckbox = false,
  confirmCheckboxLabel = '',
}) {
  const context = useBimContext();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState({
    countRelatedDashboards: 0,
    countRelatedCockpits: 0,
  });
  const [confirmCheckboxValue, setConfirmCheckboxValue] = useState(false);

  const fetchRelatedFilesInfo = async () => {
    setLoading(true);
    try {
      const values = nodesToDelete.map((val) => val.value);
      const files = _.noop;
      if (showWarning) {
        await Api.ManageFile.findRelatedObjectsCount(values);
      }
      setFiles({
        ...files,
        toBeDeletedFiles: nodesToDelete,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRelatedFilesInfo();
  }, []);

  const deleteFiles = async () => {
    setLoading(true);
    try {
      delete window.__OBJECT_DIRTY;
      await onConfirm();
      closeModal();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const tableColumns = useMemo(() => buildTableColumns({ context }), []);

  const buildWarningMessage = () => {
    const { countRelatedDashboards: relatedDashes, countRelatedCockpits: relatedCockpits } = files;
    const dashboardMessage =
      relatedDashes === 1
        ? context.msg.t('one.object.refer.dashboard')
        : relatedDashes + ' ' + context.msg.t('objects.refer.dashboard');
    const cockpitMessage =
      relatedCockpits === 1
        ? context.msg.t('one.object.refer.cockpits')
        : relatedCockpits + ' ' + context.msg.t('objects.refer.cockpits');
    return `${
      showWarning && (relatedDashes > 0 || relatedCockpits > 0)
        ? `${context.msg.t('objects.refer.from.files.to.be.deleted')} 
            ${relatedDashes > 0 ? dashboardMessage : ''} 
            ${relatedDashes > 0 && relatedCockpits > 0 ? context.msg.t('and') : ''} 
            ${relatedCockpits > 0 ? cockpitMessage : ''}.`
        : ''
    }
        ${context.msg.t('object.deletion.is.permanent')}`;
  };

  const toggleConfirmCheckbox = () => {
    setConfirmCheckboxValue(!confirmCheckboxValue);
  };

  return (
    <Dialog
      className="DeleteFileDialog large"
      loading={loading}
      onClose={closeModal}
      contentFullWidth={true}
      titleComponent={() => (
        <>
          <h5>{`${context.msg.t('attention')}: ${context.msg.t('deleteConfirm_title_label')}`}</h5>
          <div className="removeWarn">
            <span>{`${buildWarningMessage()}`}</span>
          </div>
        </>
      )}
    >
      <Dialog.Body>
        <BngTable rows={files.toBeDeletedFiles} cols={tableColumns} stickyHeader />
      </Dialog.Body>

      <Dialog.Footer>
        <div className={'conection-dialog-division bottom'} />
        <div className="FooterButtons">
          {showConfirmCheckbox && (
            <BngCheckbox
              label={confirmCheckboxLabel}
              disabled={loading}
              className={`deleteFileDialogCheckbox`}
              field={{
                onChange: toggleConfirmCheckbox,
                value: confirmCheckboxValue,
              }}
            />
          )}
          <Button className={`bng-button cancel`} loading={loading} disabled={loading} onClick={closeModal}>
            {context.msg.t('cancel')}
          </Button>
          <Button
            className={`bng-button removeButton`}
            loading={loading}
            disabled={showConfirmCheckbox && !confirmCheckboxValue}
            onClick={deleteFiles}
          >
            {context.msg.t('delete_button')}
          </Button>
        </div>
      </Dialog.Footer>
    </Dialog>
  );
}

const buildTableColumns = ({ context }) => {
  return [
    {
      key: 'name',
      label: context.msg.t('name'),
      render: (row) => (
        <div
          className="nameTableRow"
          style={
            row.level > 1
              ? {
                  borderLeft: '1px dashed #BBBBBB',
                  marginLeft: `${row.level * 18}px`,
                }
              : { marginLeft: '15px' }
          }
        >
          <Icon icon={row.icon} size={'xl'} className={`fileIcon ${!row.leaf ? 'material-icons-outlined' : ''}`} />
          <span>{row.text}</span>
        </div>
      ),
    },
    {
      key: 'type',
      label: context.msg.t('type'),
      size: 170,
      render: (row) => (
        <div>
          <span>{context.msg.t(Utils.Object.getObjectType(row.value))}</span>
        </div>
      ),
    },
  ];
};
