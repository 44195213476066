import styles from './ContainerEditMenu.module.css';

import React, { useMemo } from 'react';
import { Field, Formik } from 'formik';

import AccordionList from 'components/ui/AccordionList';
import BngForm from 'components/bng/form/BngForm';
import bngYup from 'components/bng/form/yup/BngYup';
import BngButton, { Type, Variant } from 'components/bng/ui/BngButton';
import { RIGHT_MENU_CLOSE_SUBMENU } from 'components/ui/right-menu/RightMenu';
import useTranslation from 'components/hooks/useTranslation';
import Accordion from 'components/ui/Accordion';
import BngField from 'components/bng/form/BngField';
import BngInputIconSelector from 'components/bng/form/BngInputIconSelector';
import { enumAlignOptions } from 'components/bng/pages/bigTable/constants';
import ButtonGroup from 'components/ui/button-group/ButtonGroup';
import BngMarkdownEditor from 'components/bng/form/markdown/BngMarkdownEditor';
import BngSwitch from 'components/bng/form/BngSwitch';
import { ContainerStyleAccordion } from 'components/ui/dashboard/DashLayoutAccordion';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import { processContainerValuesToPersist } from 'components/ui/dashboard/components/ContainerCreator';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';

export const CONTAINER_EDIT_MENU_EVENT = 'CONTAINER_EDIT_MENU_EVENT';

export const ContainerStyleSchema = bngYup((yup) => {
  const colorSchema = yup
    .object({
      id: yup.number().default(0),
      color: yup.string().default('#ffffff'),
      opacity: yup.number().default(1),
      position: yup.number().default(0),
    })
    .default({
      id: 0,
      color: '#ffffff',
      opacity: 1,
      position: 0,
    });

  const gradientColorSchema = yup
    .object({
      colors: yup.array(colorSchema),
      rotation: yup.number().default(0),
    })
    .default({
      colors: [colorSchema.default()],
      rotation: 0,
    });

  return yup.object({
    fontSize: yup.number().default(10),
    textFormat: yup.array(yup.string()).default([]),
    fontColor: yup.string().default('transparent'),
    textAlign: yup.string().default('left'),
    headerStyle: yup.string().default('DEFAULT').nullable(),
    size: yup.string().default('DEFAULT'),
    headerColor: gradientColorSchema,
    backgroundColor: gradientColorSchema,
  });
});

const ContainerSchema = bngYup((yup) => {
  return yup.object({
    title: yup.string().default(''),
    description: yup.string().default(''),
    showTitle: yup.boolean().default(true),
    iconAlign: yup.string().default('left'),
    icon: yup
      .object({
        name: yup.string().default(''),
        type: yup.number().default(0),
      })
      .default({ name: '', type: 0 }),
    customStyle: yup.boolean().default(false),
    style: ContainerStyleSchema,
  });
});

export default function ContainerEditMenu({ id, container }) {
  const { t } = useTranslation();

  const initialValues = useMemo(() => {
    return _.mergeWith({}, ContainerSchema.default(), container, (a, b) => b ?? a);
  }, [id]);

  return (
    <Formik
      validationSchema={ContainerSchema}
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          await useDashboardPageCtx.getState().addChange({
            type: 'CONTAINER',
            data: processContainerValuesToPersist({
              id: id,
              ...values,
            }),
          });
        } catch (e) {
          console.error('Error while saving container title', e);
          UiMsg.ajaxError(null, e);
        }
      }}
      enableReinitialize
    >
      {({ isSubmitting, setFieldValue, submitForm, values, errors }) => {
        return (
          <BngForm className={'ContainerEditMenu'}>
            <AccordionList
              className={'ObjectRightMenuAccordion'}
              actionButton={
                <div className={styles.ContainerEditActionButtons}>
                  <BngButton
                    className={styles.actionButton}
                    variant={Variant.textButton}
                    onClick={() => bimEventBus.emit(RIGHT_MENU_CLOSE_SUBMENU)}
                  >
                    {t('close')}
                  </BngButton>

                  <BngButton
                    className={styles.actionButton}
                    variant={Variant.default}
                    type={Type.primary}
                    onClick={() => submitForm()}
                  >
                    {t('apply')}
                  </BngButton>
                </div>
              }
              loading={isSubmitting}
            >
              <Accordion id="ContainerEditMenuData" title={t('tab_data')} startOpen={true}>
                <Field component={BngField} name="title" maxLength={128} />

                <Field
                  component={BngSwitch}
                  title={t('show')}
                  name="showTitle"
                  className={styles.showTitleToggle}
                  verticalTitle={true}
                />

                <div className={styles.iconConfigWrapper}>
                  <Field
                    component={BngField}
                    inputComponent={BngInputIconSelector}
                    label={t('icon')}
                    name="icon.name"
                    showErrors={false}
                    round
                  />

                  <ButtonGroup
                    items={enumAlignOptions.filter((a) => a.value !== 'CENTER')}
                    value={values.iconAlign}
                    onChange={(val) => setFieldValue('iconAlign', val)}
                  />
                </div>

                <Field
                  label={t('dataFeeds_label_description')}
                  component={BngField}
                  inputComponent={BngMarkdownEditor}
                  name="description"
                  maxLength={1000}
                  height={`150px`}
                  previewStyle={'none'}
                />
              </Accordion>

              <ContainerStyleAccordion
                className={styles.ContainerStyleAccordion}
                disabled={!values.customStyle}
                title={
                  <div className={styles.customStyleAccordion}>
                    <span>{t('custom.style')}</span>
                    <BngSwitch
                      verticalTitle={false}
                      checked={values.customStyle}
                      onChange={() => {
                        setFieldValue('customStyle', !values.customStyle);
                      }}
                    />
                  </div>
                }
                startOpen={values.customStyle}
                setFieldValue={setFieldValue}
                containerStyle={values.style}
                name={'style'}
              />
              {!values.customStyle && (
                <div className={styles.warnMessage}>
                  <Icon icon={'info'} className={styles.warnMessageIcon} />
                  <span>{t('style.container.info')}</span>
                </div>
              )}
            </AccordionList>
          </BngForm>
        );
      }}
    </Formik>
  );
}
