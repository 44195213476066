import './BngFolderField.css';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, useField, useFormikContext } from 'formik';
import { MODALS } from 'components/ui/redux/Actions';
import Button from 'components/ui/Button';
import { NewFolderDialog } from 'components/bng/folder/NewFolderDialog';
import BngTreeDropdown from 'components/bng/form/BngTreeDropdown';
import useBimContext from 'components/hooks/useBimContext';
import BngField from 'components/bng/form/BngField';

const BngFolderField = ({
  dispatch = _.noop,
  className = '',
  name = 'folder',
  folders = [],
  foldersToIgnore = [],
  required = false,
  disabled = false,
  ...props
}) => {
  const formik = useFormikContext();

  const context = useBimContext();
  const [meta] = useField(name);
  const { value } = meta;
  const error = formik.errors[name];

  return (
    <div
      className={`FolderContainer ${className} ${required ? 'Required' : ''} ${error ? 'HasErrors' : ''}`}
      {...props}
    >
      <div style={{ marginBottom: '18px', maxWidth: 'inherit' }}>
        <label className={`folderTitleLabel`}>{context.msg.t('folder')}</label>
        <div style={{ display: 'inline-flex', width: '100%' }}>
          <Field
            name={name}
            component={BngField}
            inputComponent={BngTreeDropdown}
            rootClassName="folder-root-field"
            className="folder-field"
            dataTree={folders}
            withLabel={false}
            initialValue={value ?? null}
            foldersToIgnore={foldersToIgnore}
            required
            selectFolders
          />
          <Button
            className={`button-create-folder ${disabled ? 'disabled' : ''}`}
            icon="add"
            title={`${context.msg.t('button.create.icon.title')}`}
            disabled={_.isEmpty(folders)}
            onClick={() =>
              dispatch(
                MODALS.open(NewFolderDialog, {
                  parentFolder: _.isEmpty(value) ? `/${context.project.name}` : value,
                  onCreateFolder: (folderData) => {
                    formik.setFieldValue(name, folderData.path);
                  },
                })
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

BngFolderField.propTypes = {
  setFieldValue: PropTypes.func,
  className: PropTypes.string,
  values: PropTypes.object,
};

export default connect((state) => {
  return {
    folders: state.menu.roots?.filter((folder) => folder.canWrite !== false),
  };
})(BngFolderField);
