import React, { useEffect, useRef, useState } from 'react';

const AnalysisTitleWrapper = ({ analysis, className = '' }) => {
  if (!analysis) return null;

  return (
    <div
      className={`analysis-dashboard-title ${className}`}
      style={{ textAlign: analysis.dashboardTextPosition ?? 'left' }}
    >
      <AnalysisTitle analysis={analysis} />
    </div>
  );
};

const AnalysisTitle = ({ analysis }) => {
  const fontStyle = {
    fontWeight: 'normal',
    margin: '5px 0 2px 0',
    color: analysis.dashboardTextFontColor,
    textAlign: analysis.dashboardTextPosition,
  };

  switch (analysis.analysisTitleDashboardFontStyle) {
    case 1: {
      fontStyle.fontWeight = 'bold';
      break;
    }
    case 2: {
      fontStyle.fontStyle = 'italic';
      break;
    }
    case 3: {
      fontStyle.fontWeight = 'bold';
      fontStyle.fontStyle = 'italic';
      break;
    }
  }

  return (
    <>
      {analysis.showTitleDashboard && (
        <div
          className="analysis-title-first-div"
          style={{
            fontSize: `${analysis.analysisTitleDashboardFontSize || 0}px`,
            ...fontStyle,
          }}
        >
          {analysis.analysisTitleDashboard || ''}
        </div>
      )}
      {analysis.showDescriptionDashboard && (
        <div
          style={{
            fontSize: `${analysis.analysisTitleDashboardFontSize - 4 || 0}px`,
            ...fontStyle,
          }}
        >
          {analysis.information.description || ''}
        </div>
      )}

      {analysis.showLineBreakDashboard && <hr style={{ margin: `2px` }} />}
    </>
  );
};

let ID_GEN = 0;

// ATTENTION: no async-await here, shared with mobile
export function BngAnalysisTable({ analysis, html = '', onInteraction = _.noop }) {
  const [uniqueSelector, setUniqueSelector] = useState('');
  const $AnalysisDynamicTableRender = useRef();

  let rootclassName = '';
  if (analysis) {
    rootclassName = `Table-overlay table-theme-${analysis.analysisThemeTable} ${analysis.rowSeparatorBorderTemplate} table-row-size-${analysis.tableRowLineSize} publisher-or-mobile`;
  }

  useEffect(() => {
    ID_GEN++;
    setUniqueSelector(`BngAnalysisTable-${ID_GEN}`);
  }, []);

  return (
    <div className={rootclassName}>
      {analysis && (
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: `
        .${uniqueSelector} .mdx-table tr:nth-child(odd), 
        .${uniqueSelector} .mdx-table tr:nth-child(odd) th, 
        .${uniqueSelector} .mdx-table tr:nth-child(odd) td,
        .${uniqueSelector} .mdx-table tr:nth-child(even), 
        .${uniqueSelector} .mdx-table tr:nth-child(even) th, 
        .${uniqueSelector} .mdx-table tr:nth-child(even) td {
          font-size: ${analysis.fontSizeTable}px;
        }`,
          }}
        />
      )}
      <AnalysisTitleWrapper analysis={analysis} />
      <div
        className={`mdx-table-container AnalysisDynamicTableRender h-100 ${uniqueSelector}`}
        ref={(ref) => {
          if (!ref && $AnalysisDynamicTableRender.current) {
            $AnalysisDynamicTableRender.current.off('click');
            return;
          }

          const $el = j(ref);
          $AnalysisDynamicTableRender.current = $el;

          $el.html(html);
          $el.find('input').removeAttr('onclick');
          $el.on('click', 'input', (event) => {
            event.preventDefault();
            event.stopPropagation();
            $el.append(
              `<div class="overlay-container"><div class="overlay"></div><i class="fa fa-refresh fa-spin"></i></div>`
            );
            const formData = new FormData();
            formData.append(event.target.name, '');
            onInteraction(formData).finally(() => {
              $el.find('.overlay-container').remove();
            });
          });
        }}
      />
    </div>
  );
}
