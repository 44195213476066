import './BngFolderFieldInput.css';

import React from 'react';
import { connect } from 'react-redux';

import { MODALS } from 'components/ui/redux/Actions';
import Button from 'components/ui/Button';
import { NewFolderDialog } from 'components/bng/folder/NewFolderDialog';
import BngTreeDropdown from 'components/bng/form/BngTreeDropdown';
import useBimContext from 'components/hooks/useBimContext';

const BngFolderFieldInput = ({
  dispatch = _.noop,
  className = '',
  folders = [],
  foldersToIgnore = [],
  required = false,
  disabled = false,
  field,
  form,
  initialValue,
  addButton = true,
  ...props
}) => {
  const context = useBimContext();
  return (
    <div className={`BngFolderFieldInput flex-center-items`} {...props}>
      <BngTreeDropdown
        className={`folder-field ${className}`}
        dataTree={folders}
        selectFolders={true}
        initialValue={initialValue}
        field={field}
        form={form}
        foldersToIgnore={foldersToIgnore}
      />
      {addButton && (
        <Button
          className={`button-create-folder ${disabled ? 'disabled' : ''}`}
          icon="add"
          title={`${context.msg.t('button.create.icon.title')}`}
          onClick={() =>
            dispatch(
              MODALS.open(NewFolderDialog, {
                parentFolder: _.isEmpty(field.value) ? `/${context.project.name}` : field.value,
                onCreateFolder: (folderData) => {
                  form.setFieldValue(field.name, folderData.path);
                },
              })
            )
          }
        />
      )}
    </div>
  );
};

const ReduxBngFolderFieldInput = connect((state) => {
  return {
    folders: state.menu.roots,
  };
})(BngFolderFieldInput);

export default ReduxBngFolderFieldInput;
