import styles from './WhatsAppInfoDialog.module.css';

import React, { useEffect, useState } from 'react';

import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Utils from 'components/Utils';
import BngButton, { Type } from 'components/bng/ui/BngButton';
import useTranslation from 'components/hooks/useTranslation';

export default function WhatsAppInfoDialog() {
  const { t } = useTranslation();
  const [qrCodeInfo, setQrCodeInfo] = useState();
  const [whatsStatus, setWhatsStatus] = useState();
  const [error, setError] = useState();
  const [invalidatingConnection, setInvalidatingConnection] = useState(false);

  const updateStatus = async () => {
    setError(null);
    try {
      const statusResult = await Api.MobileMessageRequest.findStatus();
      setWhatsStatus(statusResult);
      if (statusResult.whats?.state !== 'CONNECTED') {
        const qrCodeResult = await Api.MobileMessageRequest.findWhatsQrCode();
        setQrCodeInfo(qrCodeResult);
      }
    } catch (e) {
      console.error('Error on findWhatsQrCode()', e);
      UiMsg.ajaxError(null, e);
      setError(e);
    }
  };

  useEffect(() => {
    updateStatus();
  }, []);

  return (
    <div className={styles.WhatsAppInfoDialog}>
      {error && <div className="mb-2">{error}</div>}

      {whatsStatus && (
        <div>
          {`WhatsApp current state: ${whatsStatus.whats?.state || 'NOT CONNECTED'}`}

          <br />

          {whatsStatus.whats?.state !== 'CONNECTED' && qrCodeInfo?.qrCode && (
            <div>
              <img
                src={`data:image/png;base64,${qrCodeInfo.qrCode}`}
                alt="QR Code"
                style={{ filter: 'grayscale(1)' }}
              />
              <div>Last updated: {Utils.Date.formatDateTime(qrCodeInfo.lastUpdated)}</div>
            </div>
          )}

          <div className="mt-2">
            <BngButton
              variant={Type.warning}
              onClick={async () => {
                setInvalidatingConnection(true);
                try {
                  await Api.MobileMessageRequest.invalidateConnection();
                  UiMsg.ok(t('invalidate.connection.success'));
                } catch (e) {
                  console.error('Error on invalidateConnection()', e);
                  UiMsg.ajaxError(null, e);
                } finally {
                  setInvalidatingConnection(false);
                  updateStatus();
                }
              }}
              loading={invalidatingConnection}
            >
              {t('invalidate.connection')}
            </BngButton>
          </div>
        </div>
      )}
    </div>
  );
}
