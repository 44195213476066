import { Axios } from 'commonUtils';

class ProjectAppApi {
  async findInstalls(projectId) {
    const { data } = await Axios.get(`/spr/projectAppUpdate/${projectId}`);
    [data.installs, data.installedOn].forEach((a) => {
      a.forEach((i) => {
        i.install.createdAt = moment(i.install.createdAt);
        i.install.updatedAt = moment(i.install.updatedAt);
      });
      a.sort((i, i2) => {
        const result = i.install.updatedAt.diff(i2.install.updatedAt);
        if (result !== 0) return result;
        return i.app.caption.localeCompare(i2.app.caption);
      });
    });
    return data;
  }

  async findDiff(projectId, appId, updateSchedulings = false) {
    const { data } = await Axios.get(`/spr/projectAppUpdate/${projectId}/apps/${appId}/diff`, {
      params: {
        updateSchedulings,
      },
    });
    data.objects.sort((o1, o2) => {
      const c1 = o1.sourceCaption || o1.targetCaption;
      const c2 = o2.sourceCaption || o2.targetCaption;
      return c1.localeCompare(c2);
    });
    return data;
  }

  async updateApp(projectId, appId, updateData) {
    const { data } = await Axios.post(`/spr/projectAppUpdate/${projectId}/apps/${appId}/update`, updateData);
    return data;
  }

  async removeAppLink(appId) {
    const { data } = await Axios.delete(`/spr/projectApps/${appId}`);
    return data;
  }
}

export default ProjectAppApi;
