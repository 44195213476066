import styles from './BngListField.module.css';
import React from 'react';
import { useFormikContext } from 'formik';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngButton, { Variant } from 'components/bng/ui/BngButton';
import useTranslation from 'components/hooks/useTranslation';

export default function BngListField({
  label = '',
  name = '',
  items = [],
  btnLabel,
  itemRender = _.noop,
  onNewItem,
  className = '',
}) {
  const { t } = useTranslation();
  const { values, setFieldValue, errors, submitCount } = useFormikContext();
  const errorMessage = _.get(errors, name);
  const hasError = submitCount > 0 && errorMessage;

  return (
    <div className={`BngListField ${styles.BngListField} ${hasError ? styles.hasError : ''} ${className}`}>
      <span className={styles.fieldLabel}>{label}</span>
      <div className={styles.itemsWrapper}>
        {items.map((item, idx) => {
          const fieldName = `${name}[${idx}]`;
          return (
            <div className={styles.fieldWrapper} key={fieldName}>
              {itemRender({ item, name: fieldName, idx })}
              <BngIconButton
                icon={'close'}
                onClick={async () => {
                  const newArray = (_.get(values, name) ?? []).slice();
                  newArray.splice(idx, 1);
                  await setFieldValue(name, newArray);
                }}
              />
            </div>
          );
        })}
      </div>
      <BngButton
        variant={Variant.textButton}
        className={styles.addItemButton}
        icon={'add_circle'}
        onClick={async () => {
          const newValue = (_.get(values, name) ?? []).slice();
          const newItem = onNewItem?.(newValue.length) ?? {};
          newValue.push(newItem);
          await setFieldValue(name, newValue);
        }}
      >
        {btnLabel ? btnLabel : t('add')}
      </BngButton>
      {hasError && <span className={styles.errorMessage}>{errorMessage}</span>}
    </div>
  );
}
