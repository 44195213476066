import { Outlet, useSearchParams } from 'react-router-dom';
import { useState } from 'react';

import BngSelectionOverlay from 'components/bng/ui/BngSelectionOverlay';
import useTranslation from 'components/hooks/useTranslation';
import { localStore, SESSION_DATA } from 'components/Storage';
import { proposalUtils } from 'components/service/bng/AccountApi';
import useBimNavigate from 'components/hooks/useBimNavigate';
import { TAB_QUERY_PARAM } from 'components/ui/accounts/NavPanel';
import { MKT_ACC_QUERY_PARAM } from 'components/bng/pages/marketplace/MarketplacePage';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import GroupKeys from 'components/bng/pages/marketplace/tabs/GroupKeys';

const STORAGE_KEY = 'MarketplaceLayout:MARKETPLACE_SELECTED_ITEMS';

export const mapCardItemToSelectedItem = (
  cardItem,
  groupKey,
  quantity = 1,
  pricingId = null,
  canSelectQuantity = false,
) => {
  const defaultPricing = pricingId
    ? cardItem.pricings.find((p) => p.id === pricingId)
    : proposalUtils.getDefaultPricing(cardItem.pricings);
  return {
    id: cardItem.id,
    groupKey,
    key: cardItem.key,
    quantity,
    name: proposalUtils.translationsForSales(cardItem).name,
    pricing: defaultPricing,
    featureValue: cardItem.featureValue,
    pricings: cardItem.pricings,
    canSelectQuantity: canSelectQuantity,
    openTicket: cardItem.marketplaceInfo?.openTicket,
    templateStructures: cardItem.templateStructures,
    templateProjectHours: cardItem.templateProjectHours,
    templateProjectId: cardItem.templateProjectId,
  };
};

export const guessGroupKey = (cardItem) => {
  if (cardItem.feature) {
    if (cardItem.feature.classification === 'ADDON') {
      return cardItem.key === 'BIM_UNIVERSITY' ? GroupKeys.EDUCATIONAL : GroupKeys.ADDONS;
    } else {
      return GroupKeys.ADDITIONALS;
    }
  } else if (cardItem.hasOwnProperty('isTemplate')) {
    return GroupKeys.SERVICES;
  } else if (cardItem.planFeatures) {
    return GroupKeys.PLANS;
  }
};

const useSessionStorageState = ({ initialValue, key }) => {
  const [data, setData] = useState((localStore.get(SESSION_DATA) ?? {})[key] ?? initialValue);

  return [
    data,
    (val) => {
      const sessionData = localStore.get(SESSION_DATA) || {};
      sessionData[key] = val;
      localStore.put(SESSION_DATA, sessionData);
      setData(val);
    },
  ];
};

export default function MarketplaceLayout() {
  const { t } = useTranslation();
  const context = useBimContext();
  const navigate = useBimNavigate();
  const [searchParams] = useSearchParams();

  const [selectedItems, setSelectedItems] = useSessionStorageState({
    initialValue: [],
    key: STORAGE_KEY,
  });

  const redirectToCart = () => {
    const accountId = searchParams.get(MKT_ACC_QUERY_PARAM) ?? context.accountId;
    const url = Api.buildUrl('/spr/bng/marketplace', {
      currentTab: 'checkout',
      currentAccount: accountId,
    });
    navigate(url);
  };

  const selectCard = ({ cardItem, groupKey, quantity = 1, clearAll, pricingId = null, canSelectQuantity = false }) => {
    if (!groupKey) {
      groupKey = guessGroupKey(cardItem);
    }

    const isPlanItem = groupKey === GroupKeys.PLANS;
    const itemToRemove = selectedItems.find(
      (value) =>
        (value.id === cardItem.id && value.groupKey === groupKey) ||
        (isPlanItem && value.groupKey === GroupKeys.PLANS),
    );
    if (itemToRemove) {
      const alteredItems = selectedItems.filter((value) => value.id !== itemToRemove.id);
      if ((pricingId && itemToRemove.pricing.id !== pricingId) || (isPlanItem && cardItem.id !== itemToRemove.id)) {
        alteredItems.push(mapCardItemToSelectedItem(cardItem, groupKey, quantity, pricingId));
      }
      setSelectedItems([...alteredItems]);
    } else {
      if (clearAll) {
        setSelectedItems([mapCardItemToSelectedItem(cardItem, groupKey, quantity, pricingId, canSelectQuantity)]);
      } else {
        selectedItems.push(mapCardItemToSelectedItem(cardItem, groupKey, quantity, pricingId, canSelectQuantity));
        setSelectedItems([...selectedItems]);
      }
    }
  };

  const currentTab = searchParams.get(TAB_QUERY_PARAM) ?? '';
  return (
    <BngSelectionOverlay
      icon="add_shopping_cart"
      btnAction={redirectToCart}
      btnLabel={t('click.for.checkout')}
      selectedItems={selectedItems}
      onChange={setSelectedItems}
      showPopup={currentTab !== 'checkout'}
    >
      <Outlet
        context={{
          selectedItems,
          selectCard,
          updateSelectedItems: setSelectedItems,
          onClearSelection: () => setSelectedItems([]),
        }}
      />
    </BngSelectionOverlay>
  );
}
