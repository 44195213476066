import styles from './IpRestrictionTab.module.css';
import React, { useEffect, useMemo, useState } from 'react';

import useTranslation from 'components/hooks/useTranslation';
import Icon from 'components/ui/common/Icon';
import BngTable from 'components/bng/ui/BngTable';
import BngButton, { Variant } from 'components/bng/ui/BngButton';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';
import CreateIpRestrictionDialog from 'components/bng/pages/project/management/tabs/ipRestriction/CreateIpRestrictionDialog';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import UiBlocker from 'components/bng/ui/UiBlocker';
import BngTag from 'components/bng/ui/BngTag';
import LinkedObjects from 'components/ui/LinkedObjects';
import Utils from 'components/Utils';
import Avatar from 'components/ui/Avatar';
import BngDropdown from 'components/bng/ui/BngDropdown';
import OpConfirmation from 'components/ui/OpConfirmation';
import IpRestrictionDetailsDialog from 'components/bng/pages/project/management/tabs/ipRestriction/IpRestrictionDetailsDialog';
import BngIconButton from 'components/bng/ui/BngIconButton';
import IpRestrictionLogsDialog from 'components/bng/pages/project/management/tabs/ipRestriction/IpRestrictionLogsDialog';
import { buildRuleOpts } from 'components/bng/pages/project/management/tabs/AccessTimeRestrictionTab';

const buildIpRestrictionColumn = ({ t, setLoading, updateFunc = _.noop, dispatch, userLanguage }) => {
  const ruleOpts = buildRuleOpts(t);
  return [
    {
      key: 'icon',
      label: '',
      size: 20,
      sortable: false,
      render: (row) => {
        return <Icon icon={row.active ? 'lock' : 'lock_open'} />;
      },
    },
    {
      key: 'allowedIps',
      label: t('allowed.ips'),
      size: 450,
      sortable: false,
      render: (row) => {
        const ipList = row.props.restrictedIps.join('; ');
        return (
          <div className={styles.ipList} title={ipList}>
            {ipList}
          </div>
        );
      },
    },
    {
      key: 'country',
      label: t('country.region'),
      sortable: false,
      render: (row) => {
        const languageTag = userLanguage.split('_')[0];
        const regionNames = new Intl.DisplayNames([languageTag], { type: 'region' });

        const countriesList = row.props.countries.map((country) => regionNames.of(country)).join(', ');
        return <div>{countriesList}</div>;
      },
    },
    {
      key: 'users',
      label: t('user.and.groups'),
      sortable: false,
      render: (row) => {
        return (
          <div className={styles.userCol}>
            <LinkedObjects
              itemWidth={35}
              items={row.restrictedUsers?.filter((restrictedUser) => restrictedUser.type === 'DENY') || []}
              itemNumber={6}
              render={({ user, userGroup, rule }) => {
                const isUser = !!user;
                const isRule = !!rule;
                const member = isUser ? user : isRule ? ruleOpts.find((r) => r.id === rule) : userGroup;

                return (
                  <div
                    key={`${isUser ? 'USER' : isRule ? 'RULE' : 'GROUP'}-${member.id}`}
                    className={`${styles.userAvatarOuter} ${!isUser ? styles.groupIcon : ''}`}
                    title={isRule ? member.name : Utils.Users.displayName(member)}
                  >
                    {isUser ? (
                      <Avatar userId={member.id} className={styles.userAvatar} />
                    ) : (
                      <Icon icon={isRule ? member.icon : 'group'} style={{ fontSize: '25px' }} />
                    )}
                  </div>
                );
              }}
            />
          </div>
        );
      },
    },
    {
      key: 'status',
      label: t('status'),
      sortable: true,
      render: (row) => {
        return (
          <div>
            <BngTag
              description={t(row.active ? 'active' : 'inactive')}
              className={row.active ? styles.activeTag : styles.inactiveTag}
            />
          </div>
        );
      },
    },
    {
      key: 'actions',
      label: t('actions'),
      sortable: false,
      render: (row) => {
        const isEnabled = row.active;
        return (
          <BngDropdown
            icon={'more_vert'}
            popperClassName={styles.actionsDropdown}
            options={[
              {
                label: t(isEnabled ? 'ip.restriction.disable' : 'ip.restriction.enable'),
                icon: isEnabled ? 'lock_open' : 'lock',
                onClick: async () => {
                  setLoading(true);
                  try {
                    await Api.IpRestrictionRule.toggleEnabled(row.id);
                    await updateFunc();
                  } catch (e) {
                    console.error('Error on function toggleRestrictionEnabled()', e);
                    UiMsg.error(t('ip.restriction.enable.toggle.error'));
                  } finally {
                    setLoading(false);
                  }
                },
              },
              {
                label: t('details'),
                icon: 'info',
                onClick: async () => {
                  dispatch(MODALS.open(IpRestrictionDetailsDialog, { restrictionId: row.id }));
                },
              },
              {
                label: t('edit'),
                icon: 'edit',
                onClick: async () => {
                  dispatch(MODALS.open(CreateIpRestrictionDialog, { afterSubmit: updateFunc, id: row.id }));
                },
              },
              {
                isSeparator: true,
              },
              {
                label: t('remove'),
                icon: 'delete',
                onClick: async () => {
                  OpConfirmation({
                    title: t('attention'),
                    message: t('ip.restriction.delete.confirmation'),
                    onConfirm: async () => {
                      setLoading(true);
                      try {
                        await Api.IpRestrictionRule.delete(row.id);
                        UiMsg.ok(t('ip.restriction.delete.success'));
                        await updateFunc();
                      } catch (e) {
                        console.error('Error on function deleteIpRestriction()', e);
                        UiMsg.error(t('ip.restriction.delete.error'));
                      } finally {
                        setLoading(false);
                      }
                    },
                  });
                },
              },
            ]}
          />
        );
      },
    },
  ];
};

export default function IpRestrictionTab({}) {
  const { t } = useTranslation();
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const [loading, setLoading] = useState(false);
  const [restrictions, setRestrictions] = useState([]);

  useEffect(() => {
    fetchIpRestrictionRules();
  }, []);

  const fetchIpRestrictionRules = async () => {
    setLoading(true);
    try {
      const fetchedIpRestrictionRules = await Api.IpRestrictionRule.fetchAll({ projectId: context.project.id });
      setRestrictions(fetchedIpRestrictionRules.sort((a, b) => a.id > b.id));
    } catch (e) {
      console.error('Error on function fetchIpRestrictionRules()', e);
      UiMsg.error(t('ip.restriction.fetch.error'));
    } finally {
      setLoading(false);
    }
  };

  const cols = useMemo(
    () =>
      buildIpRestrictionColumn({
        t,
        setLoading,
        updateFunc: fetchIpRestrictionRules,
        dispatch,
        userLanguage: context.user.preferredLanguage,
      }),
    [restrictions]
  );

  return (
    <div className={`IpRestrictionTab ${styles.IpRestrictionTab}`}>
      <div className={styles.ipRestrictionWarn}>
        <Icon icon={'warning'} className={styles.warnIcon} />
        <span>{t('ipRestriction.master.warn')}</span>
      </div>
      <section className={styles.ipRestrictionSection}>
        <UiBlocker block={loading}>
          <h5 className={styles.sectionTitle}>{t('liberation.rules')}</h5>
          <div className={styles.ipRestrictionTable}>
            <div className={styles.tableHeader}>
              <BngButton
                icon={'add_circle'}
                variant={Variant.textButton}
                className={styles.createButton}
                onClick={() => {
                  dispatch(MODALS.open(CreateIpRestrictionDialog, { afterSubmit: fetchIpRestrictionRules }));
                }}
              >
                {t('create.restriction.rule')}
              </BngButton>
              <BngIconButton
                icon={'description'}
                className={styles.logsButton}
                onClick={() => {
                  dispatch(MODALS.open(IpRestrictionLogsDialog, {}));
                }}
              />
            </div>
            <BngTable
              cols={cols}
              rows={restrictions}
              stickyHeader={true}
              showEmptyAlert={true}
              emptyAlertProps={{ message: t('no.structures') }}
            />
          </div>
        </UiBlocker>
      </section>
    </div>
  );
}
