import 'script-loader!chroma-js';

import React, {PureComponent} from 'react';
import ReactDOMServer from 'react-dom/server'
import PropTypes from 'prop-types';
import LeafletMap from "./LeafletMap";
import ReadySource from "./ReadySource";
import {OPERATORS} from './editor/LegendItems'
import DashboardItemInformation from "components/ui/dashboard/components/DashboardItemInformation";

export const FieldType = {
    Georeference: 'Georeference',
    Measure: 'Measure',
    TimeDimension: 'TimeDimension',
    Regular: 'Regular',
    Null: 'Null',
};

export const LegendType = {
    Regular: 'Regular',
    Measure: 'Measure'
};

export const LegendClass = {
    Average: 'Average',
    Bands: 'Bands'
};

export const randomColor = () => {
    let c = chroma.random();
    if (c.luminance() > 0.75) {
        c = c.darken(0.5)
    }
    return c.hex();
};

export const DEFAULT_COLORS = {
    RED: '#b74635',
    GREEN: '#2E8965',
    YELLOW: '#EA8900'
};


export const TooltipFormat = {
    UL: (items) => `<ul>${items.map(e => `<li><i>${e.caption}</i> ${e.value ? `: ${e.formattedValue}` : ''}</li>`).join('')}</ul>`,
    OL: (items) => `<ol>${items.map(e => `<li>${e.caption}</li>`).join('')}</ol>`,
    SEPARATOR: (items, sep = ', ') => items.map(e => e.caption).join(sep),
    FIRST_MEMBER: (items) => _.head(items).caption,
    LAST_MEMBER: (items) => _.last(items).caption,
    COUNT: (items) => items.length,
    DISTINCT_COUNT: (items) => {
        const obj = {};
        items.forEach(i => obj[i.uniqueName] = 0);
        return Object.keys(obj).length;
    }
};

export class NewMapSource extends PureComponent {

    static propTypes = {
        path: PropTypes.string,
        geoJSON: PropTypes.object,
        query: PropTypes.shape({
            legendField: PropTypes.string
        }),
        style: PropTypes.object,
        onMapRef: PropTypes.func,
        onResultProcessed: PropTypes.func,
        updateSelectedLegends: PropTypes.func,
        location: PropTypes.string,
        exportView: PropTypes.bool
    };

    static defaultProps = {
        geoJSON: LeafletMap.emptyGeoJson(),
        query: {},
        style: {},
        onMapRef: _.noop,
        onResultProcessed: _.noop,
        updateSelectedLegends: _.noop,
        location: '',
        exportView: false
    };

    state = {
        geoJSON: LeafletMap.emptyGeoJson(),
        mapLegend: []
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const result = this.reprocess(this.props.query, this.props.style, this.props.geoJSON);
        this.setState(result);
        this.props.onResultProcessed({...result});
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        if (this.props.geoJSON !== prevProps.geoJSON
            || this.props.style !== prevProps.style
            || (prevState.mapLegend?.length === 0 && this.state.mapLegend?.length !== 0)
        ) {
            this.componentDidMount();
        }
    }

    render() {
        const {path, style} = this.props;
        const {geoJSON, mapLegend} = this.state;

        if(geoJSON.error?.status === 'NO_CONTENT') {
            return (
                <DashboardItemInformation path={path ?? 'newmap.newmap'}/>
            );
        }

        let center = undefined;
        let zoom = undefined;

        if (style) {
            if (style.center) {
                center = [style.center.lat, style.center.lng];
            }
            if (style.zoom !== null) {
                zoom = style.zoom;
            }
        }
        return (
                <LeafletMap ref={this.props.onMapRef}
                            path={path}
                            center={center}
                            zoom={zoom}
                            geoJSON={geoJSON}
                            legend={mapLegend}
                            useCircles={true}
                            enableHeatMap={_.get(this.props, 'style.heatMap.enabled', false)}
                            heatMapBlur={_.get(this.props, 'style.heatMap.blur', 15)}
                            heatMapRadius={_.get(this.props, 'style.heatMap.radius', 25)}
                            heatMapMinOpacity={_.get(this.props, 'style.heatMap.minOpacity', 50)}
                            showMarkers={_.get(this.props, 'style.markerProps.enabled', true)}
                            showCaption={_.get(this.props, 'style.captionProps.enabled', true)}
                            mobile={this.props.mobile}
                            isPresentation={this.props.isPresentation}
                            location={this.props.location}
                            updateSelectedLegends={this.props.updateSelectedLegends}
                            legendField={this.props.query.legendField}
                            exportView={this.props.exportView}
                            width={this.props.width ?? 0}
                            height={this.props.height ?? 0}
                />
        );
    }

    reprocess(query, style, geoJSON) {
        const colors = {};
        const isMeasure = this.legendIsMeasure(style);
        const legendStats = isMeasure ? this.calculateStats(geoJSON) : null;

        geoJSON = this.processGeoJSON(geoJSON, colors, query, style, legendStats);
        let mapLegend = [];
        const colorToLegend = (obj) => {
            return {
                icon: obj.color,
                description: obj.member.caption || obj.member.uniqueName,
                uniqueName: obj.member.uniqueName || obj.member.caption,
                count: obj.count || 0,
                selected: obj.member.selected ?? true,
            };
        };
        let members = null;
        if (!isMeasure) {
            members = Object.keys(colors).map(key => {
                const obj = colors[key];
                mapLegend.push(colorToLegend(obj));
                return {
                    color: obj.color,
                    member: obj.member.uniqueName,
                    caption: obj.member.caption || obj.member.uniqueName,
                    count: obj.count,
                    selected: obj.member.selected ?? true,
                }
            });
            mapLegend = _.sortBy(mapLegend, ['description']);
            members = _.sortBy(members, ['caption']);
        } else {
            Object.keys(colors).map(key => {
                const obj = colors[key];
                mapLegend.push(colorToLegend(obj))
            });
        }
        return {geoJSON, legendStats, mapLegend, members};
    }

    processGeoJSON(geoJSON, colors = {}, query, style, stats) {
        const isExport = application.utils.isExporter();
        if (_.isEmpty(query) || _.isEmpty(style)) {
            return geoJSON;
        }

        let DEFAULT_ICON = {
            color: DEFAULT_COLORS.GREEN,
            count: 0
        };

        geoJSON = {...geoJSON, features: geoJSON.features.slice()};

        let bands = {};
        if (style.legend.type === LegendType.Measure) {
            bands = style.legend.measure.bands;
            if (style.legend.measure.type === LegendClass.Average) {
                bands = this.generateBandRulesForAverage(stats, style.legend.measure.bands);
            }
            for (const band of bands) {
                colors[band.color] = {
                    member: {
                        caption: band.description,
                        selected: band.selected
                    },
                    color: band.color
                }
            }

        }

        const emptyTitleFields = _.isEmpty(query.titleFields);
        const titleIdx = query.titleFields.map(field => ({name: `[${field.name}]`, idx: 0}));
        let initTitleIdx = true;

        const needReplication = [];

        const cols = geoJSON.cols;
        geoJSON.features.forEach(f => {
            const props = f.properties;
            delete props.marker;
            delete props.title;
            delete props.icon;
            const {title, legend} = props.data;

            if (isExport || emptyTitleFields) {
                props.title = '';
            } else {
                if (initTitleIdx) {
                    cols.forEach((col, idx) => {
                        const tIdx = titleIdx.find(t => _.includes(col.uniqueName, t.name));
                        if (tIdx) {
                            tIdx.idx = idx;
                        }
                    });
                    initTitleIdx = false;
                }

                const titleComponent = (
                    <div className={'LeafletMapTitleContainer'}>
                        <table className="table table-bordered table-condensed">
                            <thead>
                            <tr>
                                {titleIdx.map(({idx}) => {
                                    return (
                                        <td key={idx} style={{textAlign: (cols[idx] || {}).isMeasure ? 'right' : 'left'}}>{(cols[idx] || {}).caption || '-'}</td>
                                    );
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            {title.map((rowData, rowDataIdx) =>
                                <tr key={rowDataIdx}>
                                    {titleIdx.map(({idx}) => {
                                        const row = rowData[idx];

                                        const legendNamePart = this.state.mapLegend
                                            ? this.state.mapLegend[0]?.uniqueName.substring(1, this.state.mapLegend[0]?.uniqueName.indexOf(']'))
                                            : undefined;

                                        const legendRowIdx = _.findIndex(rowData,
                                            (r) => r.uniqueName?.substring(1, r.uniqueName.indexOf(']')) === legendNamePart);
                                        const isSelected = legendRowIdx !== -1
                                            ? this.state.mapLegend?.find(legend => legend.uniqueName === rowData[legendRowIdx]?.uniqueName)?.selected
                                            : true;

                                        let caption = null;
                                        if (row) {
                                            if (row.hasOwnProperty('value')) {
                                                if (idx !== 0 && !isSelected) {
                                                    return false;
                                                }
                                                caption = row.value ? row.formattedValue : null;
                                            } else {
                                                if (!isSelected && this.state.mapLegend !== []) {
                                                    return false;
                                                }
                                                caption = row.caption;
                                            }
                                        }
                                        return (
                                            <td key={idx} style={{textAlign: row.isMeasure ? 'right' : 'left'}}>
                                                {caption || '-'}
                                            </td>
                                        );
                                    })}
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                );

                props.title = ReactDOMServer.renderToString(titleComponent);
            }


            let color = DEFAULT_ICON;

            if (query.legendField) {
                // Legend by dimension
                const memberColors = {};
                style.legend.memberColors.forEach(mc => memberColors[mc.member] = mc);

                if (_.isArray(legend)) {
                    if (legend.length > 1) {
                        needReplication.push(f);
                    }
                    for (const member of legend.reverse()) {
                        color = colors[member.uniqueName];
                        member.selected = memberColors[member.uniqueName]?.selected;
                        if (!color) {
                            color = {
                                member,
                                color: (memberColors.hasOwnProperty(member.uniqueName) ? memberColors[member.uniqueName].color || null : null) || randomColor(),
                                count: 0,
                            };
                            colors[member.uniqueName] = color;
                        }
                        color.count++;
                    }
                } else {
                    const bandValues = Object.values(bands);
                    for (const item of bandValues) {
                        try {
                            const operator = OPERATORS[item.operator];
                            if (operator && operator.handler(Number(legend.value) || 0, Number(item.value) || 0)) {
                                color = item;
                                break;
                            }
                        } catch (e) {
                            console.error("Error evaluating [%s]", e.message, item, legend);
                        }
                        color.count++;
                    }

                }
            }
            props.icon = color;
        });

        needReplication.forEach(feature => {
            const l = feature.properties.data.legend;
            for (let i = 0; i < (l.length - 1); i++) {
                const copy = {
                    ...feature,
                    properties: {
                        ...feature.properties,
                        data: {...feature.properties.data}
                    }
                };
                copy.properties.data.legend = l.slice(i);
                copy.properties.icon = colors[_.get(copy, 'properties.data.legend[0].uniqueName', '')];
                geoJSON.features.push(copy);
            }
        });

        return geoJSON;
    }

    calculateStats(geoJSON) {
        let stats = {
            format: 'INTEGER',
            max: Number.MIN_VALUE,
            maxFormatted: '0',
            min: Number.MAX_VALUE,
            minFormatted: '0',
            avg: 0,
            avgFormatted: '0'
        };
        let count = 0;
        for (let feature of geoJSON.features) {
            if (feature.properties.data.legend && feature.properties.data.legend.value) {
                const legend = feature.properties.data.legend;
                if (legend.value && _.isFinite(legend.value)) {
                    stats.max = Math.max(stats.max, legend.value);
                    if (stats.max === legend.value) stats.maxFormatted = legend.formattedValue;

                    stats.min = Math.min(stats.min, legend.value);
                    if (stats.min === legend.value) stats.minFormatted = legend.formattedValue;
                    stats.avg += legend.value;
                }
            }
            count++;
        }

        if (stats.max === Number.MIN_VALUE) stats.max = 0;
        if (stats.min === Number.MAX_VALUE) stats.min = 0;

        const type = application.mdxTable.detectValueFormat(stats.maxFormatted);

        stats.avg = count > 0 ? stats.avg / count : 0;
        stats.avgFormatted = application.mdxTable.formatValue(stats.avg, type);
        stats.count = count;
        stats.countFormatted = application.mdxTable.formatValue(count, 'INTEGER');

        stats.format = type || stats.format;

        return stats;
    }

    generateBandRulesForAverage(stats, bands) {
        return [
            {
                color: bands[0].color,
                value: stats.avg * (1 + (bands[0].value / 100)),
                operator: '>',
                description: bands[0].description,
                selected: bands[0].selected ?? true,
            },
            {
                color: bands[1].color,
                value: stats.avg * (1 - (bands[2].value / 100)),
                operator: '>',
                description: bands[1].description,
                selected: bands[1].selected ?? true,
            },
            {
                color: bands[2].color,
                value: Number.MIN_VALUE,
                operator: '!==',
                description: bands[2].description,
                selected: bands[2].selected ?? true,
            }
        ]
    }

    legendIsMeasure(style) {
        return !_.isEmpty(style) && style.legend.type === LegendType.Measure;
    }
}


/*const SIMPLE_TABLE = ({rowHeaders, colHeaders, rowData}) => {
    return (
        <table className="table table-condensed table-striped">
            <thead>
            <tr>
                {rowHeaders.map(c => <th key={c.uniqueName}>{c.caption}</th>)}
                {_.flatMap(colHeaders, 'members').map(c => <th key={c.uniqueName}>{c.caption}</th>)}
            </tr>
            </thead>
            <tbody>
            {rowData.map((c, idx) => (
                <tr key={idx}>
                    {c.rows.map((r, idx) => <td key={idx}>{r.caption}</td>)}
                    {c.cells.map((c, idx) => <td key={idx} style={{textAlign: 'right'}}>{c.formattedValue}</td>)}
                </tr>
            ))}
            </tbody>
        </table>
    )
};*/