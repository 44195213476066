import styles from './JsfBngSelectSearch.module.css';
import { useMemo, useState } from 'react';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';

export default function JsfBngSelectSearch({ jsfInput, ...props }) {
  const options = useMemo(() => {
    let opts = [];

    jsfInput.querySelectorAll('option').forEach((option) => {
      opts.push({
        value: option.value,
        label: option.label,
      });
    });

    return opts;
  }, []);

  const [value, setValue] = useState(jsfInput.value ?? '');

  return (
    <BngSelectSearch
      className={styles.main}
      options={options}
      field={{ value }}
      form={{
        setFieldValue: (name, val) => {
          setValue(val);
          jsfInput.value = val;
        },
      }}
      {...props}
    />
  );
}
