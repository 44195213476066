import styles from './BimQueryPage.module.css';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';

import useBimContext from 'components/hooks/useBimContext';
import BimQueryRightMenu from 'components/bng/pages/admin/structures/bimQuery/BimQueryRightMenu';
import { bngYup } from 'components/bng/form/yup/BngYup';
import BimQuerySqlEditor from 'components/bng/pages/admin/structures/bimQuery/BimQuerySqlEditor';
import UiBlocker from 'components/bng/ui/UiBlocker';
import { Tab, TabSet } from 'components/ui/TabSet';
import AssistedMode from 'components/bng/pages/admin/structures/bimQuery/AssistedMode';
import BngDropdown from 'components/bng/ui/BngDropdown';
import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import QueryResultPreview from 'components/bng/pages/admin/structures/bimQuery/menuTabs/QueryResultPreview';
import ColumnsConfiguration from 'components/bng/pages/admin/structures/bimQuery/menuTabs/ColumnsConfiguration';
import LogPreview from 'components/bng/pages/admin/structures/bimQuery/menuTabs/LogPreview';
import bimEventBus from 'BimEventBus';
import BngIconButton from 'components/bng/ui/BngIconButton';
import useTranslation from 'components/hooks/useTranslation';
import Utils from 'components/Utils';
import useAsyncEffect from 'components/hooks/useAsyncEffect';
import useBimQueryPageCtx from 'components/bng/pages/admin/structures/bimQuery/useBimQueryPageCtx';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';
import OpConfirmation from 'components/ui/OpConfirmation';
import useAdaAiChatStore from 'components/bng/adaAi/useAdaAiChatStore';

const buildSchema = (structureNameValidator, validateFieldsConfiguration, t) =>
  bngYup((yup) =>
    yup.object({
      // First step
      structureName: yup.string().max(35).default(''),
      displayName: yup
        .string()
        .test({
          async test(value) {
            if (_.isEmpty(value)) {
              return false;
            }
            const isInUse = await structureNameValidator(value);
            return isInUse ? this.createError({ message: t('inMemory_nameAlreadyInUse') }) : true;
          },
        })
        .required()
        .max(35)
        .default(''),
      structureIcon: yup.string().required().default('cloud'),
      structureDescription: yup.string().required().default(''),
      structureType: yup.string().default('ANALYTICAL'),
      fullLoads: yup.boolean().default(false),
      structureTag: yup.number().default(0),
      // Second step
      connection: yup
        .mixed()
        .test({
          test(value) {
            return value === 'BIMWarehouse' || value > 0;
          },
        })
        .required()
        .default(0),
      fieldConfigs: yup
        .array(yup.object())
        .test({
          test(value) {
            return validateFieldsConfiguration(value);
          },
        })
        .required()
        .default([]),
      sql: yup.string().required().default(''),
      // Third step
      dataRecyclingEnabled: yup
        .boolean()
        .test({
          test(value) {
            return value ? this.parent.dataRecyclingFieldName !== '' : true;
          },
        })
        .default(false),
      dataRecyclingFieldName: yup.string().default(''),
      dataRecyclingPeriod: yup.number().default(2),
      dataRecyclingType: yup.string().default('YEAR'),
      dimensions: yup.array(yup.object()).default([]),
    })
  );

export const normalizeFieldDescription = (value = '') => {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export default function BimQueryPage() {
  const { msg, project, labs, user } = useBimContext();
  const $pageCtx = useBimQueryPageCtx();
  const $adaAiChatStore = useAdaAiChatStore();
  const { t } = useTranslation();
  const { id: structureId } = useParams();

  const $runningTimeRef = useRef();
  const $rightMenuFormikRef = useRef();
  const $resizer = useRef();

  const [currentAccordion, setCurrentAccordion] = useState();
  const [queryResult, setQueryResult] = useState([]);
  const [resultLimit, setResultLimit] = useState(20);
  const [loading, setLoading] = useState(false);
  const [runningTime, setRunningTime] = useState('00:00');
  const [queryExecutionLog, setQueryExecutionLog] = useState(null);
  const [structureToEdit, setStructureToEdit] = useState({});
  const [queryExecuted, setQueryExecuted] = useState(false);
  const [tabsHeight, setTabsHeight] = useState({
    top: 0,
    bottom: 0,
  });
  const [blockEdit, setBlockEdit] = useState(structureId !== undefined);

  useAsyncEffect({
    onMount: async () => {
      if (!labs.features.some((feature) => feature === 'BIM_QUERY')) {
        window.location.replace(Api.buildUrl('/pages/errors/403.iface'));
        return;
      }

      try {
        await $pageCtx.fetchConnections(project.id);
      } catch (e) {
        console.error('Error while fetching connections', e);
        UiMsg.ajaxError(null, e);
      }

      if (structureId) {
        try {
          setLoading(true);
          const structure = await Api.BimQuery.fetchStructure(structureId);
          setStructureToEdit(structure);
        } catch (e) {
          console.error('Error on fetchStructure()', e);
          UiMsg.ajaxError(t('bim.query.fetching.structure.infos.error'), e);
        } finally {
          setLoading(false);
        }
      }

      return () => {
        clearInterval($runningTimeRef.current);
        $runningTimeRef.current = undefined;
        $rightMenuFormikRef.current = undefined;
        $resizer.current = undefined;
      };
    },
  });

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      let clientHeight = document.body.clientHeight;
      let multiplier = 0;
      if (clientHeight > 800 && clientHeight < 850) {
        multiplier = 0.04;
      } else if (clientHeight >= 850 && clientHeight < 880) {
        multiplier = 0.08;
      } else if (clientHeight >= 880 && clientHeight < 930) {
        multiplier = 0.12;
      } else if (clientHeight >= 930) {
        multiplier = 0.1474;
      }
      setTabsHeight({
        top: clientHeight * 0.376,
        bottom: clientHeight * 0.3 + clientHeight * 0.3 * multiplier,
      });
    });

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if ($resizer.current === undefined) {
      return;
    }

    const resizer = (e) => {
      const clientHeight = document.body.clientHeight;
      if (e.buttons === 1 && e.clientY >= clientHeight * 0.2 && e.clientY <= clientHeight * 0.85) {
        let multiplier = 0.1474;
        if (clientHeight >= 930) {
          multiplier = 0.13;
        }
        setTabsHeight({
          top: e.clientY - 138,
          bottom: clientHeight - e.clientY - clientHeight * multiplier,
        });
      }
    };

    $resizer.current.addEventListener('mousedown', function () {
      window.addEventListener('mousemove', resizer);
    });

    window.addEventListener('mouseup', function () {
      window.removeEventListener('mousemove', resizer);
    });
  }, [$resizer]);

  const startRunningTimer = () => {
    let time = 0;
    const executionTimer = () => {
      time += 1;
      const minutes = '' + Math.floor(time / 60);
      const seconds = '' + (time - minutes * 60);
      setRunningTime(`${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`);
    };
    executionTimer();
    $runningTimeRef.current = setInterval(executionTimer, 1000);
  };

  const stopRunningTimer = () => {
    clearInterval($runningTimeRef.current);
  };

  const validateFieldsConfiguration = useCallback((fieldsConfigs) => {
    return fieldsConfigs.find((f) => f.dimensionType === 'Measure');
  }, []);

  const formSchema = useMemo(() => {
    const structureNameValidator = async (structureName) => {
      try {
        if (structureId && structureToEdit.displayName === structureName) {
          return false;
        }
        if (structureName !== '') {
          const { alreadyUsed } = await Api.BimQuery.validateName({
            name: structureName,
            projectId: project.id,
          });
          return alreadyUsed;
        }
      } catch (e) {
        console.error('Error on BimQueryPAge.structureNameValidator()', { e });
        UiMsg.ajaxError(null, e);
        return true;
      }
    };
    return buildSchema(structureNameValidator, validateFieldsConfiguration, t);
  }, [structureToEdit, structureId]);

  const initialValues = useMemo(() => {
    const schemaDefaults = formSchema.default();
    if (!_.isEmpty(structureToEdit)) {
      schemaDefaults.sql = structureToEdit.sql;
      schemaDefaults.fieldConfigs = structureToEdit.fields;
      schemaDefaults.connection = structureToEdit.connectionId ?? 'BIMWarehouse';
      schemaDefaults.structureName = structureToEdit.structureName;
      schemaDefaults.displayName = structureToEdit.displayName;
      schemaDefaults.structureDescription = structureToEdit.structureDescription;
      schemaDefaults.structureIcon = structureToEdit.icon;
      schemaDefaults.structureType = structureToEdit.structureType;
      schemaDefaults.fullLoads = structureToEdit.fullLoads;
      schemaDefaults.structureTag = structureToEdit.groupId || 0;
      schemaDefaults.dataRecyclingEnabled = structureToEdit.dataRecyclingEnabled;
      schemaDefaults.dataRecyclingFieldName = structureToEdit.dataRecyclingFieldName || '';
      schemaDefaults.dataRecyclingPeriod = structureToEdit.dataRecyclingPeriod || 2;
      schemaDefaults.dataRecyclingType = structureToEdit.dataRecyclingType;
      schemaDefaults.dimensions = structureToEdit.dimensions;
    }
    return schemaDefaults;
  }, [formSchema, structureToEdit]);
  return (
    <div className={`BimQueryPage ${styles.BimQueryPage}`}>
      <Formik
        enableReinitialize={true}
        validationSchema={formSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {(formikProps) => {
          const { values, setFieldValue, isValid, setValues } = formikProps;
          const [bottomTabIdx, setBottomTabIdx] = useState(0);

          useMemo(() => {
            if (_.isEmpty(validateFieldsConfiguration(values.fieldConfigs)) && values.fieldConfigs.length > 0) {
              UiMsg.error(t('inMemory_needAMeasure'));
            }
          }, [values.fieldConfigs]);

          const hasError = !_.isEmpty(queryExecutionLog);

          useEffect(() => {
            setBottomTabIdx(hasError ? 2 : 0);
          }, [hasError]);

          const unlockSideMenu = (_.isNil(queryExecutionLog) && values.fieldConfigs.length > 0) || structureId;
          const showFieldConfigs = !_.isEmpty(values.sql) && !_.isEmpty(values.fieldConfigs) && queryExecuted;

          const queryProps = {
            connectionId: values.connection === 'BIMWarehouse' ? 0 : values.connection,
            sql: values.sql,
            fromScheduler: false,
            projectId: project.id,
          };

          const validateQuery = async () => {
            if (_.isEmpty(values.sql)) {
              return;
            }

            try {
              setLoading(true);

              await Api.BimQuery.validateQuery(queryProps);
              UiMsg.ok(t('inmemory.sql.syntax.valid'));
              setQueryExecutionLog(null);
            } catch (e) {
              console.error('Error on validateQuery()', { e });
              setQueryExecutionLog(e.response.data.message);
              return false;
            } finally {
              setLoading(false);
            }
            return true;
          };

          const executeQuery = async () => {
            if (!(await validateQuery())) {
              return;
            }

            setQueryExecuted(false);
            startRunningTimer();
            try {
              setLoading(true);
              const response = await Api.BimQuery.executeQuery({
                ...queryProps,
                pageSize: resultLimit,
              });

              setQueryResult(response.result);
              const normalizedFields = response.fields.map((f) => {
                f.description = normalizeFieldDescription(f.description);
                return f;
              });

              const updatedExistingFields = values.fieldConfigs.map((field) => ({
                ...field,
                visibleOnUi: false,
              }));

              const updatedFields = [
                ...updatedExistingFields.map((existingField) => {
                  const matchingNormalizedField = normalizedFields.find(
                    (normalizedField) => normalizedField.description === existingField.description
                  );

                  if (matchingNormalizedField) {
                    return {
                      ...existingField,
                      visibleOnUi: true,
                    };
                  }

                  return existingField;
                }),
                ...normalizedFields
                  .filter(
                    (normalizedField) =>
                      !updatedExistingFields.some(
                        (existingField) => existingField.description === normalizedField.description
                      )
                  )
                  .map((newField) => ({
                    ...newField,
                    visibleOnUi: true,
                  })),
              ];

              setFieldValue('fieldConfigs', updatedFields);
              setQueryExecutionLog(null);
              setQueryExecuted(true);
            } catch (e) {
              console.error('Error on executeQuery()', e);
              setQueryExecutionLog(e.response.data.message);
            } finally {
              setLoading(false);
              stopRunningTimer();
            }
          };

          const openAdaChat = (messageLogPreview) => {
            if (!checkAddonEnabled(AddonType.ADA_AI.key, false)) {
              UiMsg.warn(t('addon.adaai.not.hired'));
              return;
            }

            const chatData = {
              assistantKey: 'Assistant-BIMQuery-SQL',
              messageLogPreview,
              dashboardPath: '',
              itemPath: '',
              item: '',
              metadataObjectBimQuery: {
                dashboardItems: [{ id: 'Assistant-BIMQuery-SQL', name: 'BIMQuery' }],
              },
            };

            $adaAiChatStore.openChat(chatData);
          };

          return (
            <UiBlocker block={loading} className={`${currentAccordion ? 'menu-opened' : 'menu-closed'}`}>
              <Form
                className={`${styles.FormWrapperStyle} ${currentAccordion ? 'menu-opened' : 'menu-closed'} ${
                  loading ? 'is-loading' : ''
                }`}
              >
                <div className={`${styles.FloatActionButton} ${currentAccordion ? 'menu-opened' : 'menu-closed'}`}>
                  <BngIconButton
                    icon={<img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-icon.png')} alt="Ada AI" />}
                    className="ada-ai"
                    text={msg.t('sql.ada.ia.query.assistant.button')}
                    onClick={() => openAdaChat()}
                  />
                </div>
                <BngIconButton
                  text={t('bim.query.tab.clear.sql.button.label')}
                  className={`${styles.ClearSQLEditor}`}
                  icon="cleaning_services"
                  disabled={!values.sql || blockEdit}
                  onClick={() => {
                    setValues({
                      ...values,
                      sql: '',
                      fieldConfigs: [],
                    });
                    setQueryResult([]);
                    setQueryExecuted(false);
                  }}
                />
                <TabSet internal={true} tabToOpenIndex={1} className={`${styles.TopTabsWrapper}`}>
                  <Tab icon="outbound" label={t('bim.query.tab.title.assisted.mode')}>
                    <AssistedMode />
                  </Tab>
                  <Tab icon="code" label={t('bim.query.tab.title.sql.mode')} className={styles.SqlModeTab}>
                    {blockEdit && (
                      <div className={`${styles.AreaBlocker}`}>
                        <BngIconButton
                          icon="edit"
                          text={t('bim.query.enable.sql.edit')}
                          className={`${styles.EnableEditButton}`}
                          onClick={() => {
                            OpConfirmation({
                              title: t('attention'),
                              html: t('in_memory_dirty_confirmation'),
                              onConfirm: () => setBlockEdit(false),
                            });
                          }}
                        />
                      </div>
                    )}
                    <BimQuerySqlEditor style={{ height: `${tabsHeight.top}px` }} structureId={structureId} />
                    <Icon className={`${styles.resizeIcon}`} icon="drag_handle" ref={$resizer} />
                  </Tab>
                </TabSet>
                <div className={`${styles.CenterButtonsWrapper}`}>
                  <div className={`${styles.ActionsButtons} ${currentAccordion ? 'menu-opened' : 'menu-closed'}`}>
                    <BngDropdown
                      popperClassName={`${styles.QueryResultLinesAmountPopper} `}
                      customButton={({ openDropdown }) => (
                        <div className={`${styles.ExecuteQueryButton}`}>
                          <div onClick={executeQuery}>
                            <Icon icon="play_circle" />
                            {t('execute')}
                          </div>
                          <Icon
                            icon="arrow_drop_down"
                            onClick={(event) => {
                              openDropdown(event);
                            }}
                          />
                        </div>
                      )}
                      options={[20, 50, 100, 1000].map((limit) => ({
                        className: styles.queryResultLinesOption,
                        label: `${limit} ${t('bim.query.dropdown.line.selector.option')}`,
                        icon: `${resultLimit === limit ? 'done' : ''}`,
                        onClick: () => setResultLimit(limit),
                      }))}
                    />
                    <div className={`${styles.Timer}`}>{runningTime}</div>
                  </div>
                  <div className={`${styles.OptionsLeftSide}`}>
                    {bottomTabIdx === 1 && values.fieldConfigs.length > 0 && (
                      <div
                        className={`${styles.ExportViewButton}`}
                        onClick={() => {
                          bimEventBus.emit(QueryResultPreview.EXPORT_CSV_EVENT);
                        }}
                      >
                        <div className={`${styles.csvIconStyle}`}>csv</div>
                        {t('bim.query.export.preview.button.label')}
                      </div>
                    )}
                  </div>
                </div>
                <TabSet
                  internal={true}
                  tabToOpenIndex={0}
                  selectedTab={bottomTabIdx}
                  handleTabChange={(tabIdx) => setBottomTabIdx(tabIdx)}
                  className={`${styles.TabSetWrapper}`}
                >
                  <Tab
                    icon="settings"
                    label={t('configuration')}
                    className={`${styles.ColumnsConfigurationTab}`}
                    style={{ height: tabsHeight.bottom }}
                  >
                    {showFieldConfigs ? (
                      <ColumnsConfiguration />
                    ) : (
                      <div className={`${styles.EmptyConfigurationTabLabel}`}>
                        {t('bim.query.configurations.empty')}
                      </div>
                    )}
                  </Tab>
                  <Tab
                    icon="visibility"
                    label={t('preview')}
                    disabled={_.isNil(queryResult)}
                    className={`${styles.QueryResultPreviewTab}`}
                    style={{ height: tabsHeight.bottom }}
                  >
                    {_.isEmpty(queryResult) ? (
                      <div className={`${styles.EmptyConfigurationTabLabel}`}>{t('bim.query.preview.empty')}</div>
                    ) : (
                      <QueryResultPreview queryResult={queryResult} />
                    )}
                  </Tab>
                  <Tab
                    icon="description"
                    label={t('log')}
                    disabled={!hasError}
                    className={`${styles.LogPreviewTab}`}
                    props={{ alert: hasError }}
                    tabClassName={`${styles.LogPreviewTabButton}`}
                    style={{ height: tabsHeight.bottom }}
                  >
                    {hasError && (
                      <LogPreview
                        messageLog={queryExecutionLog}
                        openAdaChat={() => {
                          const connection = $pageCtx.connections.find((c) => c.id === values.connection);
                          const connectionName = connection?.type?.name || 'PostgreSQL';
                          openAdaChat({
                            message: queryExecutionLog,
                            messageGpt: `${queryExecutionLog} ${connectionName} ${values.sql}`,
                            user: {
                              displayName: Utils.Users.displayName(user),
                              avatar: user.avatarLink,
                            },
                          });
                        }}
                      />
                    )}
                  </Tab>
                </TabSet>
                <div className={`BimQueryRightMenuWrapper ${styles.BimQueryRightMenuWrapper}`}>
                  <BimQueryRightMenu
                    structureId={structureId}
                    formikProps={formikProps}
                    onAccordionChange={({ accordionKey }) => {
                      setCurrentAccordion(accordionKey);
                    }}
                    onFormikRef={$rightMenuFormikRef}
                    queryExecutionLog={queryExecutionLog}
                    isEditing={!!structureId}
                    unlockSideMenu={unlockSideMenu}
                    saveDisabled={unlockSideMenu ? !isValid : true}
                    blockEdit={blockEdit}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </div>
              </Form>
            </UiBlocker>
          );
        }}
      </Formik>
    </div>
  );
}
