import styles from './MktAddonsTab.module.css';

import React, { useState } from 'react';

import MktDefaultTab from 'components/bng/pages/marketplace/tabs/MktDefaultTab';
import { MARKETPLACE_GROUPS } from 'components/bng/pages/marketplace/MarketplacePage';
import useTranslation from 'components/hooks/useTranslation';
import GroupKeys from 'components/bng/pages/marketplace/tabs/GroupKeys';

const ADDONS_GROUPS = [
  {
    title: 'addons.brand',
    desc: 'addons.brand.desc',
    key: GroupKeys.ADDONS,
    filterFunc: (item) => item.marketplaceInfo.groupKey === MARKETPLACE_GROUPS.BRAND,
    anchorKey: `MarketplacePage-BRAND`,
  },
  {
    title: 'addons.security',
    desc: 'addons.security.desc',
    key: GroupKeys.ADDONS,
    filterFunc: (item) => item.marketplaceInfo.groupKey === MARKETPLACE_GROUPS.SECURITY,
    anchorKey: `MarketplacePage-SECURITY`,
  },
  {
    title: 'addons.manage',
    desc: 'addons.manage.desc',
    key: GroupKeys.ADDONS,
    filterFunc: (item) => item.marketplaceInfo.groupKey === MARKETPLACE_GROUPS.MANAGEMENT,
    anchorKey: `MarketplacePage-MANAGEMENT`,
  },
];

export default function MktAddonsTab(props) {
  const { t } = useTranslation();
  const [marketplaceFilter, setMarketplaceFilter] = useState('all');

  return (
    <MktDefaultTab
      className={'MktAddonsTab'}
      groupsToRender={ADDONS_GROUPS}
      findItemsOnGroup={({ marketplaceItems, group }) => {
        const filteredAddons = marketplaceItems.addons.filter(
          (addon) =>
            !Object.values(marketplaceItems?.educational).some((eduAddon) => eduAddon.feature.key === addon.feature.key) &&
            addon.marketplaceInfo?.groupKey !== 'TEMPLATE'
        );
        if (marketplaceFilter === 'active') {
          return filteredAddons.filter((addon) =>
            marketplaceItems.activeAddons?.some((activeAddon) => activeAddon.feature.key === addon.feature.key)
          );
        } else if (marketplaceFilter === 'inactive') {
          return filteredAddons.filter(
            (addon) => !marketplaceItems.activeAddons?.some((activeAddon) => activeAddon.feature.key === addon.feature.key)
          );
        }
        return filteredAddons;
      }}
      afterSearchSlot={() => (
        <section>
          <div className={styles.marketplaceFilter}>
            <span>{t('filter.by')}</span>
            <div className={styles.marketplaceFilterOptions}>
              {[
                { key: 'all', label: t('all.addons') },
                { key: 'active', label: t('active.addons') },
                { key: 'inactive', label: t('inactive.addons') },
              ].map((filterOption, index) => (
                <React.Fragment key={filterOption.key}>
                  <span
                    className={marketplaceFilter === filterOption.key ? styles.activeFilter : ''}
                    onClick={() => setMarketplaceFilter(filterOption.key)}
                  >
                    {filterOption.label}
                  </span>
                  {index < 2 && <div>•</div>}
                </React.Fragment>
              ))}
            </div>
          </div>
        </section>
      )}
      {...props}
    />
  );
}
