import React from 'react';
import useTranslation from 'components/hooks/useTranslation';
import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import Api from 'components/Api';
import useAdaAiChatStore from 'components/bng/adaAi/useAdaAiChatStore';

export default function DashboardItemMenuAdaAiInsights({ item, dashboard, assistantKey, closeDropdown }) {
  const { t } = useTranslation();
  const $adaAiChatStore = useAdaAiChatStore();

  return (
    <li
      className="DashboardItemMenuAdaAiInsights"
      onClick={() => {
        const chatData = {
          assistantKey,
          dashboardPath: dashboard || '',
          itemPath: item?.path || '',
          dashboardFilters: '',
          itemFilters: item?.filters || '',
          item,
        };

        $adaAiChatStore.openChat(chatData);

        closeDropdown();
      }}
    >
      <DashboardItemMenuTitle
        title={t('ada.ai.dashboard.item.menu.title')}
        icon={<img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-icon-insisghts.png')} alt="Ada AI" />}
        more={false}
      />
    </li>
  );
}
