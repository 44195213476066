import './RenameDialog.css';

import React, { Component, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/ui/Dialog';
import { Field, Formik } from 'formik';

import ContextEnhancer from 'components/ContextEnhancer';
import { validateAndConfirmPath } from 'components/ui/common/SaveAsDialog';
import { BngForm } from 'components/bng/form/BngForm';
import { bngYup } from 'components/bng/form/yup/BngYup';
import { BngField } from 'components/bng/form/BngField';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import Icon from 'components/ui/common/Icon';
import { BngTable } from 'components/bng/ui/BngTable';
import LoadingSvg from 'components/ui/loading/LoadingSvg';
import LoadingBox from 'components/ui/loading/LoadingBox';
import Button from 'components/ui/Button';
import ProgressBar from 'components/ui/ProgressBar';
import Utils from 'components/Utils';
import BngFolderField from 'components/bng/form/BngFolderField';

const RenameObjectSchema = bngYup(yup => {
    return yup.object().shape({
        name: yup.string().min(0).max(100).trim().default('').when(['isFolder', 'isMoveOnly'], (isFolder, isMoveOnly, schema) => {
            return isMoveOnly ? _.noop() : isFolder ? schema.validFolderName() : schema.validObjectName();
        }),
        isFolder: yup.boolean().default(false),
        isMoveOnly: yup.boolean().default(false),
        folder: yup.string()
          .when('isFolder', {
              is: false,
              then: (schema) => schema.required().typeError('no.dir.object.error'),
              otherwise: (schema) => schema.nullable().default(''),
          }),
    });
});

class RenameDialog extends Component {

    static propTypes = {
        path: PropTypes.string,
        objArray: PropTypes.array,
        isMoveOnly: PropTypes.bool,
        folder: PropTypes.string.isRequired,
        name: PropTypes.string,
        isFolder: PropTypes.bool,
        loadAfterSave: PropTypes.bool,
        onRename: PropTypes.func,
        icon: PropTypes.string,
        foldersToIgnore: PropTypes.array,
    };

    static defaultProps = {
        path: '',
        folder: '',
        name: '',
        isFolder: false,
        loadAfterSave: true,
        onRename: _.noop,
        folders: [],
        foldersToIgnore: [],
    };

    state = {
        availableFolders: [],
        folderChildren: null,
        renamingFolder: false,
        renamingFolderDone: true,
        cancelRenameFolder: false,
    };

    initialValues = RenameObjectSchema.default();

    constructor(props, context) {
        super(props, context);
        this.initialValues = _.pick(props, ['folder', 'name', 'isFolder', 'isMoveOnly']);
    }

    renameFolderAction = async (values, overwrite) => {
        this.setState({
            renamingFolder: true,
            renamingFolderDone: false,
            cancelRenameFolder: false
        });

        try {
            if (!overwrite) {
                try {
                    await Api.Project.createFolder({
                        ...values,
                        parentFolder: values.folder,
                        icon: this.props.icon,
                        oldPath: values.path,
                    });
                } catch (e) {
                    // ignore error case target folder already exists
                }
            }

            const folderChildren = await Api.Project.folderChildObjects(values.projectId, this.props.path);
            const moveActionParams = {
                ...values,
                folder: `${values.folder}/${values.name}`,
                objArray: folderChildren.children
            }
            await this.moveObjectsAction(moveActionParams, overwrite);
        } finally {
            this.setState({
                renamingFolderDone: true,
                cancelRenameFolder: false
            });
        }
    }

    renameObjectAction = async (values, actions) => {
        try {
            const {newPath} = await Api.Project.renameObject(values);
            if (this.props.loadAfterSave) {
                window.location = Api.loadObjectUrl({content: newPath});
            } else {
                await this.props.onRename(newPath, this.state.cancelRenameFolder);
                await Api.updateJsf();
                this.props.closeModal();
            }
        } catch (e) {
            console.error('Error on RenameDialog.renameObjectAction', {values, actions}, e);
            UiMsg.ajaxError(null, e);
            actions.setSubmitting(false);
        }
    };

    renameAction = async (values, actions) => {
        if (this.props.name === values.name && this.props.folder === values.folder) {
            UiMsg.ok(this.props.context.msg.t('nothing.was.changed'));
            await this.renameFolderCloseAction();
            return;
        }

        values = {
            projectId: this.props.context.project.id,
            path: this.props.path,
            ...values,
            name: values.name.trim(),
        };

        const {proceed, overwrite} = await validateAndConfirmPath({values, props: this.props, actions});

        if (!proceed) {
            actions.setSubmitting(false);
            return;
        }

        if (this.props.isFolder) {
            await this.renameFolderAction(values, overwrite);
        } else {
            await this.renameObjectAction(values, actions);
        }
    }

    moveAction = async (values, actions) => {
        values = {
            projectId: this.props.context.project.id,
            objArray: _.cloneDeep(this.props.objArray),
            ...values
        };

        let result = {proceed: false, overwrite: false};

        for (const node of values.objArray) {
            const nodeValues = {
                ...values,
                name: node.text || node.attributes.name,
                path: node.value
            };
            result = await validateAndConfirmPath({
                values: nodeValues,
                props: this.props,
                actions
            });
            node.name = node.attributes.name;
            node.namePart = node.attributes.name.split('.')[0];
            node.parentPath = Utils.Object.parentPath(node.value);
            if (!result.proceed) {
                actions.setSubmitting(false);
                return;
            }
        }

        await this.moveObjectsAction(values, result.overwrite);
    }

    moveObjectsAction = async (values, overwrite) => {
        const objectLevels = {};
        this.setState({folderChildren: values.objArray});
        try {
            for (let obj of values.objArray) {

                let newPath = values.folder;

                if (this.state.cancelRenameFolder) {
                    await this.rollbackProcess(values, overwrite);
                    break;
                }

                obj.loading = true;

                this.setState({
                    folderChildren: _.sortBy(values.objArray, [c => {
                        if (c.loading) return 1;
                        if (c.error) return 2;
                        if (c.done) return 3;
                        return 4;
                    }])
                });

                try {

                    if (objectLevels[obj.level - 1] && this.getParentName(objectLevels[obj.level - 1]) === this.getParentName(obj.parentPath)) {
                        newPath = `${newPath}/${objectLevels[obj.level - 1]}`;

                        objectLevels[obj.level] = `${objectLevels[obj.level - 1]}/${obj.namePart}`;
                    } else {
                        objectLevels[obj.level] = obj.namePart;
                    }
                    obj.newPath = newPath;
                    if (!obj.leaf) {
                        try {
                            await Api.Project.createFolder({
                                projectId: values.projectId,
                                parentFolder: newPath,
                                name: obj.name,
                                icon: obj.icon,
                                oldPath: obj.path,
                            });
                        } catch (e) {
                            obj.conflict = true;
                        }
                    } else {
                        const renameOpts = {
                            projectId: values.projectId,
                            path: obj.value || obj.path,
                            folder: newPath,
                            name: obj.text || obj.name,
                            description: obj.description
                        };
                        await Api.Project.renameObject(renameOpts);
                    }

                    obj.done = true;
                } catch (e) {
                    console.error('Error on RenameDialog.moveObjectsAction', {obj, values, overwrite}, e);
                    const message = e.response?.data?.message || e.message || e.stack;
                    UiMsg.error(this.props.context.msg.t('object.error.canceling', obj.name), message);
                    if (e.response?.status === 400) {
                        obj.done = true;
                    }
                    this.setState({cancelRenameFolder: true});
                    await this.rollbackProcess(values, overwrite);
                    break;
                } finally {
                    obj.loading = false;
                }
            }
        } finally {
            this.setState({
                renamingFolder: true,
                renamingFolderDone: false,
            });
        }

        await this.props.onRename(values.folder, this.state.cancelRenameFolder);
        UiMsg.ok(this.props.context.msg.t(this.state.cancelRenameFolder ? 'cancel.move.success' : 'object.move.success'));
        await this.renameFolderCloseAction();

    }

    onSubmit = (values, actions) => {

        if (values.folder === null) {
            values.folder = `/${this.props.context.project.name}`
        }

        if (this.props.isMoveOnly) {
            this.moveAction(values, actions)
        } else {
            this.renameAction(values, actions);
        }
    }

    getParentName = (fullPath) => {
        return fullPath.slice(fullPath.lastIndexOf("/") + 1, fullPath.length)
    }

    renameFolderCloseAction = async () => {
        this.props.closeModal();
        await Api.updateJsf();
    }

    cancelFolderRename = () => {
        this.setState({cancelRenameFolder: true});
    }

    rollbackProcess = async (values, overwrite) => {

        for (let obj of values.objArray) {

            if (obj.done && obj.leaf) {
                    const renameOpts = {
                        projectId: values.projectId,
                        path: `${obj.newPath}${obj.path.substring(obj.path.lastIndexOf('/'))}`,
                        folder: obj.folder,
                        name: obj.text || obj.name,
                        description: obj.description
                    };
                    await Api.Project.renameObject(renameOpts);
            }
        }

        if (!overwrite) {
            await Api.ManageFile.deletePaths([values.folder]);
        } else {
            for (let obj of values.objArray) {
                if (obj.done && !obj.leaf && !obj.conflict) {
                    await Api.ManageFile.deletePaths([`${obj.newPath}/${obj.namePart}`]);
                }
            }
        }

    }

    render() {
        return (
            <Formik initialValues={this.initialValues}
                    validationSchema={RenameObjectSchema}
                    onSubmit={this.onSubmit}>
                {({values, isSubmitting}) => {
                    return (
                        <Dialog
                            className={`RenameDialog`}
                            title={this.props.context.msg.t(this.props.isFolder ? 'rename.folder' : this.props.objArray ? 'move.objects' : 'rename.object')}
                            onClose={this.state.renamingFolder ? undefined : this.props.closeModal}
                            newDialogLayout
                            loading={!this.props.isFolder && isSubmitting}
                        >
                            {!this.state.renamingFolder &&
                                <BngForm>
                                    <Dialog.Body>

                                        <div className="row-fluid">
                                            <div className="span12">
                                                <BngFolderField className="SaveAsFolderField"
                                                                name="folder"
                                                                foldersToIgnore={this.props.foldersToIgnore}
                                                                required
                                                />
                                            </div>
                                        </div>

                                        {!this.props.isMoveOnly &&
                                            <div className="row-fluid">
                                                <div className="span12">
                                                    <Field name="name"
                                                           autoComplete="off"
                                                           component={BngField}
                                                           label={this.props.context.msg.t('name')}
                                                           maxLength={100}
                                                           required={!this.props.objArray}
                                                           style={{height: '38px'}}
                                                    />
                                                </div>
                                            </div>
                                        }

                                        {this.props.isFolder &&
                                            <div className="alert FolderWarning">
                                                <strong
                                                    className="d-r-m">{this.props.context.msg.t('attention')}!</strong>
                                                {this.props.context.msg.t('rename.folder.warning.message')}
                                            </div>
                                        }


                                    </Dialog.Body>

                                    <Dialog.Footer>
                                        <DefaultDialogActions
                                            {...this.props}
                                        />
                                    </Dialog.Footer>
                                </BngForm>
                            }

                            {this.state.renamingFolder &&
                                <FolderRenameProgressView  {...this.state}
                                                           onCancel={this.cancelFolderRename}
                                                           onFinish={this.renameFolderCloseAction}
                                />
                            }
                        </Dialog>
                    );
                }}
            </Formik>
        );
    }

}


const FolderRenameProgressView = ContextEnhancer(({
                                                      onCancel,
                                                      onFinish,
                                                      folderChildren,
                                                      renamingFolderDone,
                                                      cancelRenameFolder,
                                                      context
                                                  }) => {
    const [showDetails, setShowDetails] = useState(false);
    const tableColumns = useMemo(() => {
        return [
            {
                label: context.msg.t('name'),
                render: (row) => (
                    <div style={{display: 'flex'}}>
                        <Icon icon={row.icon} className='loadingIcon'/>
                        <span className="d-l-m">
                                {row.name || row.caption}
                            </span>
                    </div>
                )

            },
            {
                render: row => {
                    return (
                        <div className="text-center">
                            {row.loading &&
                                <LoadingSvg/>
                            }
                            {row.done &&
                                <Icon icon="done"
                                      className="DoneIcon"
                                />
                            }
                            {row.error &&
                                <Icon icon="error"
                                      className="ErrorIcon"
                                      title={context.msg.t('show_details')}
                                      onClick={() => {
                                          return Swal.fire('', row.error);
                                      }}
                                />
                            }
                        </div>
                    )
                }
            }
        ];
    }, []);

    const childs = folderChildren || [];
    const totalChildren = childs.length;
    const processedChildren = childs?.filter(c => c.loading || c.done || c.error).length;
    const progress = totalChildren ? Math.floor((childs.filter(c => c.done || c.error).length / totalChildren) * 100) : 0;
    const numberOfErrors = childs.filter(c => c.error).length;
    return (
        <>
            <Dialog.Body>
                {!folderChildren &&
                    <LoadingBox/>
                }

                {folderChildren &&
                    <>
                        <ProgressBar progress={progress}>
                            <div className="row-fluid">
                                <div className="span3">
                                    <Button className="btn-link DetailsBtn"
                                            onClick={() => setShowDetails(!showDetails)}>
                                        {context.msg.t(showDetails ? 'hide.details' : 'see.details')}
                                    </Button>
                                </div>
                                <div className="span9">
                                    <div className="text-right">
                                        <div>
                                            <small>
                                                {context.msg.t('renaming.progress.info', [
                                                    processedChildren,
                                                    totalChildren
                                                ])}
                                            </small>
                                        </div>
                                        {numberOfErrors > 0 &&
                                            <div>
                                                <small className="text-error">
                                                    Erros: {numberOfErrors}
                                                </small>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </ProgressBar>
                        {showDetails &&
                            <>
                                <hr/>
                                <div className={`ChildrenView`}>
                                    <BngTable
                                        rowKeyBuilder={(row) => row.path}
                                        rows={folderChildren}
                                        cols={tableColumns}/>
                                </div>
                            </>
                        }
                    </>
                }
            </Dialog.Body>
            <Dialog.Footer>
                <div className="row-fluid">
                    <div className="span12 text-right btn-fix">
                        {!renamingFolderDone &&
                            <Button className={`bng-button cancel`}
                                    onClick={onCancel}
                                    loading={cancelRenameFolder || processedChildren === totalChildren}>
                                {context.msg.t('cancel')}
                            </Button>
                        }
                        <Button className={`bng-button save d-l-m`}
                                onClick={onFinish}
                                disabled={!renamingFolderDone}>
                            {context.msg.t('close')}
                        </Button>
                    </div>
                </div>
            </Dialog.Footer>
        </>
    );
});
export default ContextEnhancer(RenameDialog);
