import React, { useEffect, useMemo } from 'react';

import ObjectRightMenu from 'components/ui/right-menu/ObjectRightMenu';
import useBimContext from 'components/hooks/useBimContext';
import BimQueryConfigurationMenuItem from 'components/bng/pages/admin/structures/bimQuery/menuItems/BimQueryConfigurationMenuItem';
import BimQueryConnectionsMenuItem from 'components/bng/pages/admin/structures/bimQuery/menuItems/BimQueryConnectionsMenuItem';
import BimQueryKeyRecyclingHierarchyMenuItem from 'components/bng/pages/admin/structures/bimQuery/menuItems/BimQueryKeyRecyclingHierarchyMenuItem';
import SaveMenuItem from 'components/ui/right-menu/items/SaveMenuItem';
import RemoveMenuItem from 'components/ui/right-menu/items/RemoveMenuItem';
import Api from 'components/Api';
import BngPulsateLoading from 'components/bng/ui/BngPulsateLoading/BngPulsateLoading';
import UiMsg from 'components/ui/UiMsg';
import ConfigurationTab from 'components/bng/pages/admin/structures/bimQuery/menuTabs/ConfigurationTab';
import ConnectionsTab from 'components/bng/pages/admin/structures/bimQuery/menuTabs/ConnectionsTab';
import KeyRecyclingHierarchyTab from 'components/bng/pages/admin/structures/bimQuery/menuTabs/KeyRecyclingHierarchyTab';
import useTranslation from 'components/hooks/useTranslation';
import bimEventBus from 'BimEventBus';
import Utils from 'components/Utils';

const availableAccordions = (unlockSideMenu, saveDisabled) => [
  {
    key: BimQueryConnectionsMenuItem.KEY,
    Component: BimQueryConnectionsMenuItem,
    component: ConnectionsTab,
    icon: 'cloud_upload',
    disabled: !unlockSideMenu,
  },
  {
    key: BimQueryConfigurationMenuItem.KEY,
    Component: BimQueryConfigurationMenuItem,
    component: ConfigurationTab,
    icon: 'menu_open',
    disabled: !unlockSideMenu,
  },
  {
    key: BimQueryKeyRecyclingHierarchyMenuItem.KEY,
    Component: BimQueryKeyRecyclingHierarchyMenuItem,
    component: KeyRecyclingHierarchyTab,
    icon: 'settings',
    disabled: !unlockSideMenu,
  },
  {
    key: 'SaveMenuItem',
    Component: SaveMenuItem,
    fixed: true,
    disabled: saveDisabled,
  },
  {
    key: 'RemoveMenuItem',
    Component: RemoveMenuItem,
    fixed: true,
  },
];

export const BIM_QUERY_RIGHT_MENU_SAVE = 'BIM_QUERY_RIGHT_MENU_SAVE';

export default function BimQueryRightMenu({
  onFormikRef,
  isEditing = true,
  saveDisabled,
  structureId,
  unlockSideMenu,
  blockEdit = false,
  loading = false,
  setLoading = _.noop,
  ...props
}) {
  const context = useBimContext();
  const { t } = useTranslation();

  useEffect(() => {
    bimEventBus.on(BIM_QUERY_RIGHT_MENU_SAVE, handleSave);
  }, []);

  const accordion = useMemo(() => {
    const tempAcc = availableAccordions().find((acc) => acc.key === BimQueryConnectionsMenuItem.KEY);
    return tempAcc || {};
  }, []);

  const handleSave = async (data) => {
    setLoading(true);
    const values = data.hasOwnProperty('dimensions') ? data : props.formikProps.values;
    try {
      const bodyReq = {
        structureId,
        projectId: context.project.id,
        connectionId: values.connection === 'BIMWarehouse' ? 0 : values.connection,
        sql: values.sql,
        structureName: values.structureName,
        displayName: values.displayName,
        structureDescription: values.structureDescription,
        icon: values.structureIcon,
        fullLoads: values.fullLoads,
        structureType: values.structureType,
        groupId: values.structureTag,
        dataRecyclingEnabled: values.dataRecyclingEnabled,
        dataRecyclingFieldName: values.dataRecyclingFieldName,
        dataRecyclingPeriod: values.dataRecyclingPeriod,
        dataRecyclingType: values.dataRecyclingType,
        fields: values.fieldConfigs,
        dimensions: values.dimensions,
        disableScheds: !blockEdit
      };
      await Api.BimQuery.save(bodyReq);
      UiMsg.ok(t('inMemory_successfullyCreated', [values.displayName]));
      await Utils.sleep(3000);
      window.location.replace(`${Api.baseUrl()}/pages/project-selection/dataconfig.iface`);
    } catch (e) {
      console.error('Error on BimQueryRightMenu.handleSave()', { e });
      e.response.data.message = t(e.response.data.message);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ObjectRightMenu
        {...props}
        context={context}
        open={true}
        onFormikRef={onFormikRef}
        save={handleSave}
        options={availableAccordions(unlockSideMenu, saveDisabled)}
        showAccordion={accordion}
        isEditing={isEditing}
      />
      {loading && <BngPulsateLoading />}
    </>
  );
}
