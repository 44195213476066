import styles from './BngStepTutorialDialog.module.css';

import React, { useEffect, useState } from 'react';
import { animated, useChain, useSpringRef, useTransition } from '@react-spring/web';

import Dialog from 'components/ui/Dialog';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import usePrevious from 'components/hooks/usePrevious';

export default function BngStepTutorialDialog({
  className = '',
  beforeDotPaginationChange = _.noop,
  enableDotNavigation = true,
  onClose,
  onModeChanged,
  defaultMode,
  renderDefaultFooter = true,
  onApi = _.noop,
  backdropClassName,
  bodyRef= null,
  steps = [
    {
      title: '',
      hideClose: true,
      render: _.noop,
      renderFooter: _.noop,
      stepDisabled: false,
      nextStepDisabled: false,
      backStepDisabled: false,
    },
  ],
  ...props
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const previousStep = usePrevious(currentStep);
  const [enableNextStep, setEnableNextStep] = useState(true);

  const slideApi = useSpringRef();
  const [slideTransition] = useTransition(currentStep, () => ({
    ref: slideApi,
    keys: null,
    initial: null,
    trail: 100,
    from: { opacity: 1, transform: previousStep > currentStep ? 'translate3d(-100%,0,0)' : 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: {
      opacity: 0,
      transform: previousStep > currentStep ? 'translate3d(100%,0,0)' : 'translate3d(-100%,0,0)',
    },
  }));

  const footerApi = useSpringRef();
  const [footerTransition] = useTransition(currentStep, () => ({
    ref: footerApi,
    keys: null,
    initial: null,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 200,
    },
  }));

  const dotApi = useSpringRef();
  const [dotTransition] = useTransition(currentStep, () => ({
    ref: dotApi,
    keys: null,
    initial: null,
    from: { width: 0, height: 0, opacity: 0 },
    enter: { width: 12, height: 12, opacity: 1 },
    leave: { width: 0, height: 0, opacity: 0 },
    config: {
      duration: 200,
    },
  }));

  const changeToPreviousStep = (skipValidation = false) => {
    changeStep(currentStep - 1, skipValidation);
  };

  const changeToNextStep = (skipValidation = false) => {
    changeStep(currentStep + 1, skipValidation);
  };

  const step = steps[currentStep];

  const isBackEnabled = !step.backStepDisabled && currentStep > 0 && !steps[currentStep - 1]?.stepDisabled;
  const isForwardEnabled =
    !step.nextStepDisabled && currentStep + 1 < steps.length && !steps[currentStep + 1]?.stepDisabled && enableNextStep;

  const hideClose = step?.hasOwnProperty('hideClose') ? step.hideClose : currentStep !== steps.length - 1;

  const handleDotPaginationOnClick = (nextStep) => {
    if (!enableDotNavigation) {
      return;
    }
    beforeDotPaginationChange(nextStep, currentStep);
    changeStep(nextStep);
  };

  const changeStep = (stepPosition, skipValidation = false) => {
    if (steps[stepPosition].stepDisabled) {
      return;
    }
    if (stepPosition >= 0 && stepPosition <= steps.length - 1) {
      if (
        ((skipValidation || isForwardEnabled) && stepPosition > currentStep) ||
        ((skipValidation || isBackEnabled) && stepPosition < currentStep)
      ) {
        setEnableNextStep(true);
        setCurrentStep(stepPosition);
      }
    }
  };

  const componentApi = {
    changeToPreviousStep,
    changeToNextStep,
    changeStep,
    setEnableNextStep,
  };

  useEffect(() => {
    onApi(componentApi);
  }, []);

  useChain([slideApi, footerApi, dotApi], [0]);

  return (
    <Dialog
      className={`BngStepTutorialDialog ${styles.tutorialWrapper} ${className}`}
      onClose={onClose}
      onModeChanged={onModeChanged}
      defaultMode={defaultMode}
      title={_.isFunction(step.title) ? step.title(componentApi) : step.title ?? ''}
      hideClose={hideClose}
      backdropClassName={backdropClassName}
      {...props}
    >
      <div ref={bodyRef} className={`animationWrapper ${styles.animationWrapper} ${step?.title ? styles.withTitle : ''}`}>
        {slideTransition((style, item) => {
          return (
            <animated.div style={{ ...style }} className={`stepContainer ${styles.stepContainer}`}>
              {steps[item].render({
                changeToPreviousStep,
                changeToNextStep,
                changeStep,
                setEnableNextStep,
              })}
            </animated.div>
          );
        })}
      </div>
      {renderDefaultFooter && (
        <div className={`${styles.paginateContainer}`}>
          <BngIconButton
            className={`${styles.paginateBackArrow} ${
              isBackEnabled ? '' : currentStep === 0 ? styles.arrowDisabled : styles.arrowNotAllowed
            }`}
            icon={'arrow_back_ios_new'}
            onClick={changeToPreviousStep}
            disabled={!isBackEnabled}
          />
          <div className={`${styles.dotPaginationContainer}`}>
            {steps.map((step, idx) => {
              return (
                <div key={idx} className={`${styles.dotContainer}`}>
                  <div className={`${styles.dotPagination}`} onClick={() => handleDotPaginationOnClick(idx)} />
                  {dotTransition((style, item) => (
                    <React.Fragment>
                      {item === idx && <animated.div className={`${styles.dotPosition}`} style={{ ...style }} />}
                    </React.Fragment>
                  ))}
                </div>
              );
            })}
          </div>
          <BngIconButton
            className={`${styles.paginateForwardArrow} ${
              isForwardEnabled ? '' : steps.length - 1 === currentStep ? styles.arrowDisabled : styles.arrowNotAllowed
            }`}
            icon={'arrow_forward_ios'}
            onClick={changeToNextStep}
            disabled={!isForwardEnabled}
          />
        </div>
      )}

      {footerTransition((style, item) => {
        return (
          _.isFunction(steps[item].renderFooter) && (
            <animated.div className={`${styles.footerComponent}`} style={{ ...style }}>
              {steps[item].renderFooter()}
            </animated.div>
          )
        );
      })}
    </Dialog>
  );
}
