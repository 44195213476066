import PropTypes from 'prop-types';
import Utils from 'components/Utils';

const OpTextConfirmation = ({
  title = '',
  message = '',
  nameToDelete = '',
  onConfirm,
  msg = {},
  level = 'error',
  html = null,
  confirmText = msg.t('yes'),
  confirmColor,
  cancelText = msg.t('no'),
  validationMessage = '',
  showCheckbox = false,
  checkboxLabel = '',
  disableInput = false,
  target,
  customClass = '',
}) => {
  let checkboxId = '';
  let checkboxComponent = '';
  if (showCheckbox) {
    checkboxId = Utils.randomId();
    checkboxComponent = `
       <div class="mt-2">
        <input type="checkbox" id="${checkboxId}" >
        <label for="${checkboxId}">${checkboxLabel}</label>
       </div>`;
  }

  Swal.fire({
    title: title,
    text: message,
    input: onConfirm && !disableInput ? 'text' : undefined,
    inputAttributes: {
      autocapitalize: 'off',
    },
    icon: level ? level : '',
    html: `
              ${html}
              ${checkboxComponent}
            `,
    showCancelButton: !!onConfirm,
    confirmButtonColor: confirmColor || (level === 'error' ? '#DD6B55' : level === 'warning' ? '#FFA500' : ''),
    confirmButtonText: confirmText,
    cancelButtonText: cancelText,
    preConfirm: onConfirm && !disableInput
      ? (inputForDelete) => {
          if (inputForDelete !== nameToDelete) {
            Swal.showValidationMessage(validationMessage);
          }
        }
      : undefined,
    target,
    customClass,
  }).then((result) => {
    if (!onConfirm) {
      return;
    }
    if (result.value) {
      const checkboxValue = showCheckbox ? document.getElementById(checkboxId).checked : undefined;
      onConfirm({ checkboxValue });
    }
  });
};

OpTextConfirmation.propTypes = PropTypes.shape({
  msg: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string,
  level: PropTypes.string,
  onConfirm: PropTypes.func,
  showCheckbox: PropTypes.bool,
  checkboxLabel: PropTypes.string,
});

export default OpTextConfirmation;
