import styles from 'components/bng/exportScheduling/BngExportRecipientsTab.module.css';

import React, { useEffect, useMemo, useState } from 'react';

import { Field } from 'formik';
import BngField from 'components/bng/form/BngField';
import BngUserItemSelect from 'components/bng/form/itemSelect/BngUserItemSelect';
import BngObjectItemSelect from 'components/bng/form/itemSelect/BngObjectItemSelect';
import UiMsg from 'components/ui/UiMsg';
import useBimContext from 'components/hooks/useBimContext';
import bngYup from 'components/bng/form/yup/BngYup';
import Utils from 'components/Utils';
import BngTextArea from 'components/bng/form/BngTextArea';
import { BngAvatar } from 'components/bng/ui/BngAvatar';
import { BngCheckboxPure } from 'components/bng/form/BngCheckbox';
import Api from 'components/Api';

export const BngExportRecipientsTabSchema = bngYup((yup) => {
  return yup.object().shape({
    sendTo: yup
      .array()
      .of(
        yup.object({
          allowMobileMessaging: yup.boolean().default(null).nullable(),
          deliveryType: yup.string(),
          email: yup.string().email(),
          id: yup.string().required(),
          name: yup.string(),
          effectiveName: yup.string().default(''),
          phone: yup.string().nullable(),
          type: yup.string(),
          userId: yup.number(),
        })
      )
      .required('Share.errors.users')
      .min(1, '')
      .default([]),
    resources: yup.object({
      type: yup.string().default(Utils.Scheduling.TYPE.OBJECT),
      cockpit: yup
        .object({
          id: yup.number(),
          name: yup.string(),
        })
        .nullable()
        .when('type', {
          is: Utils.Scheduling.TYPE.COCKPIT,
          then: (schema) => schema.required('Share.errors.objects'),
        })
        .default({}),
      contents: yup
        .array()
        .of(
          yup.object({
            name: yup.string(),
            path: yup.string(),
            exportType: yup.string().default(Utils.Scheduling.EXPORT_TYPE.PDF),
          })
        )
        .when('type', {
          is: Utils.Scheduling.TYPE.OBJECT,
          then: (schema) => schema.required('Share.errors.objects').min(1, '').max(10, 'Share.errors.objects.max'),
        })
        .default([]),
    }),
    title: yup.string().when(['sendTo', 'messageType', 'schedulingType'], {
      is: (sendTo, messageType, schedulingType) => {
        if (schedulingType === Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED) {
          return false;
        } else if (sendTo && sendTo.length >= 1) {
          return sendTo.some((user) => user.deliveryType === Utils.Scheduling.DELIVERY_TYPE.EMAIL);
        } else {
          return messageType === Utils.Scheduling.DELIVERY_TYPE.EMAIL;
        }
      },
      then: (schema) => schema.required('Share.errors.title'),
      otherwise: (schema) => schema.nullable(),
    }),
    message: yup.string().when(['schedulingType'], {
      is: (schedulingType) => {
        return schedulingType === Utils.Scheduling.SCHEDULING_TYPE.IMMEDIATE;
      },
      then: (schema) => schema.required('Share.errors.description'),
      otherwise: (schema) => schema.nullable(),
    }),
  });
});

export default function BngExportRecipientsTab({ values, shareMode = false }) {
  const context = useBimContext();
  const [availableMembers, setAvailableMembers] = useState([]);

  const [titlePlaceholder, setTitlePlaceholder] = useState('');
  const [messagePlaceholder, setMessagePlaceholder] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const fetchedMembers = await fetchMembersForOptions({
          projectId: context.project.id,
          msg: context.msg,
        });
        setAvailableMembers(fetchedMembers);
      } catch (e) {
        console.error('Error on while fetching users and groups', e);
        UiMsg.ajaxError(null, e);
      }
    })();
  }, []);

  useEffect(() => {
    const isWhatsapp = values.messageType === Utils.Scheduling.DELIVERY_TYPE.WHATSAPP;

    if (isWhatsapp) {
      setTitlePlaceholder(context.msg.t('Share.title.whatsapp.placeholder'));
    }
    if (values.identifier && values.schedulingType === Utils.Scheduling.SCHEDULING_TYPE.SCHEDULED) {
      if (!isWhatsapp) setTitlePlaceholder(values.identifier);
      setMessagePlaceholder(
        context.msg.t('Share.export.title.placeholder', [context.msg.t(values.type).toLowerCase(), values.identifier])
      );
    } else {
      if (!isWhatsapp) setTitlePlaceholder(context.msg.t('Share.title'));
      setMessagePlaceholder(context.msg.t('Share.description'));
    }
  }, [values.identifier, values.schedulingType, values.messageType]);

  const isSharingMobile = values.messageType !== Utils.Scheduling.DELIVERY_TYPE.EMAIL;
  const sendTo = values.sendTo;

  const membersOptions = useMemo(() => {
    const invites = sendTo.filter((s) => !s.type);
    const options = buildSortedMemberOptions({
      members: availableMembers.concat(invites),
      isSharingMobile,
      sendTo,
      msg: context.msg,
    });

    return options.map((i) => {
      return {
        ...i,
        value: {
          ...i.value,
          deliveryType: values.messageType,
        },
      };
    });
  }, [sendTo, availableMembers, values.messageType]);

  return (
    <div className={`${styles.sendTabContainer}`}>
      <Field
        name={`sendTo`}
        label={context.msg.t('send.to.users')}
        labelClassName={`${styles.bngFieldLabel}`}
        component={BngField}
        showErrors={false}
        inputComponent={BngUserItemSelect}
        options={membersOptions}
      />
      <Field
        name={`resources`}
        label={context.msg.t('analytical.objects')}
        labelClassName={`${styles.bngFieldLabel}`}
        component={BngField}
        showErrors={false}
        inputComponent={BngObjectItemSelect}
        readOnly={shareMode}
      />
      <span className={`${styles.bngFieldLabel} ${styles.titleDescLabel}`}>{context.msg.t('title_description')}</span>
      <div className={`${styles.titleDescContainer}`}>
        <Field
          name={`title`}
          className={`${styles.titleField}`}
          rootClassName={`${styles.titleFieldRoot}`}
          placeholder={titlePlaceholder}
          component={BngField}
          disabled={
            values.sendTo && values.sendTo.length >= 1
              ? !values.sendTo.some((user) => user.deliveryType === Utils.Scheduling.DELIVERY_TYPE.EMAIL)
              : values.messageType !== Utils.Scheduling.DELIVERY_TYPE.EMAIL
          }
          maxLength={95}
          showErrors={false}
          withLabel={false}
        />
        <Field
          name={`message`}
          className={`${styles.descriptionField}`}
          rootClassName={`${styles.descriptionFieldRoot}`}
          placeholder={messagePlaceholder}
          component={BngField}
          inputComponent={BngTextArea}
          maxLength={512}
          showErrors={false}
          withLabel={false}
        />
      </div>
    </div>
  );
}

export async function fetchMembersForOptions({ projectId, msg }) {
  const { groups, projectUsers } = await Api.Project.findProjectUsersAndGroups(projectId);
  return createMembersForOptions({ msg, groups, projectUsers });
}

export function createMembersForOptions({ msg, groups = [], projectUsers = [] }) {
  return [
    ...groups.map((group) => ({
      id: `GROUP-${group.id}`,
      name: group.name.startsWith('GROUP') ? `${msg.t('group')} ${group.name}` : group.name,
      type: 'GROUP',
      effectiveName: group.name,
      groupId: group.id,
      icon: group.icon ?? 'group',
    })),
    ...projectUsers.map((projectUser) => ({
      id: `USER-${projectUser.user.id}`,
      userId: projectUser.user.id,
      name: projectUser.user.displayName ?? projectUser.user.name,
      type: 'USER',
      email: projectUser.user.email,
      phone: projectUser.user.phone,
      allowMobileMessaging: projectUser.user.allowMobileMessaging !== false,
      deliveryType: null,
      role: projectUser.role?.name,
    })),
  ];
}

function buildMemberOption({ member, isSharingMobile, sendTo, msg }) {
  const label = Utils.Users.displayName(member);
  const disabledMobileMessaging = isSharingMobile && !member.allowMobileMessaging && !(member.type === 'GROUP');
  const title = disabledMobileMessaging
    ? !member.allowMobileMessaging
      ? msg.t('share.whatsapp.user.disabled')
      : msg.t('user.invalid.number')
    : member.type === 'GROUP' && isSharingMobile
    ? msg.t('share.whatsapp.group.warning')
    : '';
  return {
    value: member,
    disabled: disabledMobileMessaging,
    title: title,
    label,
    member: member,
    render() {
      return (
        <div className={`w-100 flex-center-items ${styles.optionWrapper}`}>
          <BngAvatar
            userId={member.userId}
            isGroup={member.type === 'GROUP'}
            icon={member.icon ? member.icon : !member.type ? 'mail' : undefined}
          />
          <div
            className="ml-2 mr-2 text-overflow-ellipsis flex-grow-1"
            title={
              disabledMobileMessaging || member.type === 'GROUP'
                ? ''
                : `${label}<br>${isSharingMobile ? member.phone : (member.email || '')}`
            }
          >
            <div className="d-flex text-overflow-ellipsis flex-direction-column">
              <span className="text-overflow-ellipsis">{label}</span>
              <span className="base-font-size text-overflow-ellipsis color-blue">
                {isSharingMobile ? member.phone : member.email}
              </span>
            </div>
          </div>
          <div className="ml-auto">
            <BngCheckboxPure
              className={`${styles.memberCheckboxOption}`}
              checked={sendTo.some((s) => member.id === s.id && member.type === s.type)}
              onChange={_.noop}
            />
          </div>
        </div>
      );
    },
  };
}

export function buildSortedMemberOptions({ members, isSharingMobile, sendTo, msg, removeGroups = false }) {
  const filteredMembers = removeGroups ? members.filter((m) => m.type !== 'GROUP') : members;
  const firstSixMembers = filteredMembers.slice(0, 6);

  const remainingMembers = _.orderBy(
    filteredMembers.slice(6), // Pegue todos os elementos a partir do 7º em diante
    ['enable', 'type', (o) => (o.name ?? 'ZZZ' + o.email).toLowerCase()],
    ['desc', 'asc', 'asc']
  );

  return [...firstSixMembers, ...remainingMembers].map((m) => {
    return buildMemberOption({
      member: m,
      isSharingMobile,
      sendTo,
      msg,
    });
  });
}
