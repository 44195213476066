import './FoldersPage.css';

import React, { useEffect, useMemo, useState } from 'react';

import Api from 'components/Api';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import Icon from 'components/ui/common/Icon';
import { MODALS } from 'components/ui/redux/Actions';
import ChangeFolderIconDialog from 'components/bng/folder/ChangeFolderIconDialog';
import Utils from 'components/Utils';
import NewFolderDialog from 'components/bng/folder/NewFolderDialog';
import { MultipleObjectsPermissionDialog, PathPermissionDialog } from 'components/bng/permission/PermissionDialog';
import RenameDialogContainer from 'components/ui/common/RenameDialogContainer';
import DeleteFileDialog from 'components/bng/pages/admin/folders/DeleteFileDialog';
import UiMsg from 'components/ui/UiMsg';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import Tooltip from 'components/ui/Tooltip';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import CrudPageLayout from 'components/bng/pages/common/layout/CrudPageLayout';
import BimEventBus from 'BimEventBus';

export default function FoldersPage({ embedded = false }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [treeTable, setTreeTable] = useState({});
  const [tableSortMode, setTableSortMode] = useState({ name: 'ASC' });
  const [expanded, setExpanded] = useState({});
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(true);

  const expand = Object.keys(expanded).length === 0;

  const fetchData = async () => {
    setLoading(true);
    try {
      const treeTableResult = await Api.ManageFile.findTreeTable(context.project.name, context.user.id);
      setTreeTable(treeTableResult);
    } catch (e) {
      console.error('Error on fetchData()', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const doDrillDown = (row, expand) => {
    if (row.leaf) return;

    expand ? (expanded[row.value] = row) : delete expanded[row.value];

    setExpanded({ ...expanded });
  };

  const drillDownAll = (expand) => {
    if (expand) {
      treeTable.linearizedTree.forEach((treeNode) => {
        drillDownAllFolders(treeNode);
      });
    } else {
      setExpanded({});
    }
  };

  const drillDownAllFolders = (treeNode) => {
    if (treeNode.leaf) return;
    doDrillDown(treeNode, true);
    const children = treeNode.children;
    if (children.length > 0) {
      children.forEach((treeNode) => drillDownAllFolders(treeNode));
    }
  };

  const createNewFolder = () => {
    dispatch(
      MODALS.open(NewFolderDialog, {
        parentFolder: `/${context.project.name}`,
        onCreateFolder: () => fetchData(),
      })
    );
  };

  const onDeleteNode = async (paths, showMessage = true) => {
    try {
      await Api.ManageFile.deletePaths(paths);
      await fetchData();
      setSelected({});
      await Api.updateJsf();
      BimEventBus.emit(Api.Project.FOLDER_CREATED_EVENT);
      if (showMessage) {
        UiMsg.ok(context.msg.t('FILE_DELETE_SUCCESS'));
      }
    } catch (e) {
      console.error(e);
      UiMsg.error(null, e);
    }
  };

  const deleteSelected = () => {
    dispatch(
      MODALS.open(DeleteFileDialog, {
        onConfirm: async () => await onDeleteNode(Object.keys(selected)),
        nodesToDelete: Object.values(selected),
        showConfirmCheckbox: true,
        confirmCheckboxLabel: context.msg.t('delete.files.checkbox.label'),
      })
    );
  };

  const moveSelected = () => {
    const parts = Object.keys(selected)[0].split('/');
    parts.pop();
    const folder = parts.join('/');
    try {
      dispatch(
        MODALS.open(RenameDialogContainer, {
          objArray: Object.values(selected),
          folder,
          isMoveOnly: true,
          loadAfterSave: false,
          foldersToIgnore: Object.keys(selected),
          onRename: (newFolder, canceled) => {
            Object.values(selected).forEach((object) => {
              if (folder !== newFolder && !object.leaf && !canceled) {
                onDeleteNode([object.value], false);
              } else {
                fetchData();
              }
            });
            setSelected({});
          },
        })
      );
    } catch (e) {
      console.error('Error on moveSelected()', e);
    }
  };

  const changeSelectedPermission = () => {
    const containPrivateUserFolder = Object.keys(selected).some((obj) =>
      obj.startsWith(`/${context.project.name}/${context.user.id}`)
    );
    if (containPrivateUserFolder) {
      UiMsg.warn(context.msg.t('cannot.change.permission'));
    } else {
      dispatch(
        MODALS.open(MultipleObjectsPermissionDialog, {
          objects: Object.values(selected),
          projectId: context.project.id,
          afterSave: () => fetchData(),
          pathMessage: buildSelectedTooltip(),
          lockCurrentUser: embedded,
        })
      );
    }
  };

  const tableColumns = useMemo(
    () =>
      buildTableColumns({
        dispatch,
        context,
        project: context.project,
        fetchData,
        doDrillDown,
        expanded,
        selected,
        setSelected,
        onDeleteNode,
        embedded,
      }),
    [expanded, selected]
  );

  const buildSelectedTooltip = () => {
    let selectedFolders = 0;
    let selectedObjects = 0;
    Object.keys(selected).forEach((path) => {
      if (Utils.Object.isFolder(path)) {
        selectedFolders++;
      } else {
        selectedObjects++;
      }
    });

    return `${selectedFolders} ${context.msg.t(selectedFolders <= 1 ? 'folder' : 'folders')} ${context.msg.t(
      'and'
    )} ${context.msg.t('objects.selected', selectedObjects.toString())}`;
  };

  const buildExpandedNodes = (nodeArray) => {
    if (!nodeArray) return [];

    const showOnSearch = [];

    for (let i = 0; i < nodeArray.length; i++) {
      let children = [];
      let nodeItem = nodeArray[i];
      if (nodeItem.value in expanded && nodeItem.level === nodeArray[0].level) {
        children = buildExpandedNodes(nodeItem.children);
        nodeArray.splice(i + 1, 0, ...children);
      }
      if (
        (nodeItem.text.toLowerCase().includes(searchTerm.toLowerCase()) && nodeItem.level === nodeArray[0].level) ||
        children.length > 0
      ) {
        showOnSearch.push(nodeItem);
      }
      showOnSearch.push(...children);
    }

    if (searchTerm) {
      return showOnSearch;
    } else {
      return nodeArray;
    }
  };

  const expandedNodes = useMemo(() => {
    const linearizedTreeClone = _.cloneDeep(treeTable.linearizedTree);
    return buildExpandedNodes(linearizedTreeClone);
  }, [treeTable, expanded, searchTerm]);

  const headerButtons = () => {
    return (
      <div className="flex-center-items">
        <BngIconButton
          icon={expand ? 'folder' : 'folder_open'}
          onClick={() => drillDownAll(expand)}
          title={context.msg.t(expand ? 'expandall_link' : 'collapseall_link')}
        />

        {embedded && !context.permissions.isExplorer() && (
          <BngIconButton
            icon={'create_new_folder'}
            onClick={createNewFolder}
            disabled={loading}
            title={context.msg.t('create_folder')}
          />
        )}
      </div>
    );
  };

  const optionalButtons =
    Object.keys(selected).length === 0
      ? null
      : () => {
          return (
            <div className="flex-center-items">
              <BngIconButton
                icon="remove_done"
                onClick={() => setSelected({})}
                title={context.msg.t('uncheck.selected.objects')}
              />
              <BngIconButton
                icon="zoom_out_map"
                className="action-move"
                onClick={moveSelected}
                title={context.msg.t('move.selected.objects')}
              />
              <BngIconButton
                icon="lock"
                onClick={changeSelectedPermission}
                title={context.msg.t('change.permission.selected.objects')}
              />
              <BngIconButton icon="delete" onClick={deleteSelected} title={context.msg.t('remove.selected')} />
            </div>
          );
        };

  const onChangeSearch = (value) => {
    if (searchTerm === '' || value === '') {
      drillDownAll(value !== '');
    }
    setSearchTerm(value);
  };

  return (
    <div className={`FoldersPage`}>
      <CrudPageLayout
        fetchData={fetchData}
        filters={searchTerm}
        tableRows={expandedNodes}
        loading={loading}
        emptyAlertMessage={context.msg.t('currently.no.folders.available')}
        pageTitle={context.msg.t('folder.and.permissions')}
        tableSortMode={tableSortMode}
        setFilters={setSearchTerm}
        tableColumns={tableColumns}
        selectedRows={selected}
        headerButtons={headerButtons}
        showItemsCount={true}
        optionalButtons={optionalButtons}
        setTableSortMode={setTableSortMode}
        embedded={embedded}
        open={open}
        setOpen={setOpen}
        onSearchChanged={onChangeSearch}
        pageButton={() => <FloatButton embedded={embedded} createNewFolder={createNewFolder} />}
      />
    </div>
  );
}

const FloatButton = ({ embedded, createNewFolder }) => {
  const context = useBimContext();
  return (
    <>
      {!embedded && (
        <BngIconButton
          className={`BngIconButton info FloatCreateButton`}
          icon={'add'}
          onClick={createNewFolder}
          title={context.msg.t('create_folder')}
        />
      )}
    </>
  );
};

const buildTableColumns = ({
  dispatch,
  context,
  project,
  fetchData,
  doDrillDown,
  expanded,
  selected,
  setSelected,
  onDeleteNode,
  embedded,
}) => {
  const toggleSelected = (row) => {
    if (row.value in selected) {
      toggleSelectedChildren(row, false);
      toggleSelectedParents(row, false);
    } else {
      toggleSelectedChildren(row, true);
    }
  };

  const toggleSelectedChildren = (row, select) => {
    if (selected[row.value]) {
      delete selected[row.value];
    }

    if (select) {
      selected[row.value] = row;
    }

    if (!row.leaf) {
      const children = row.children;
      if (children.length > 0) {
        children.forEach((childRow) => toggleSelectedChildren(childRow, select));
      }
    }

    setSelected({ ...selected });
  };

  const toggleSelectedParents = (row, select) => {
    select ? (selected[row.value] = row) : delete selected[row.value];

    const parentPath = Utils.Object.parentPath(row.value);
    if (parentPath in selected) {
      toggleSelectedParents(selected[parentPath]);
    }

    setSelected({ ...selected });
  };

  const personalFolderPath = `/${context.project.name}/${context.user.id}`;
  const insidePersonalFolder = (row) => row.value.startsWith(personalFolderPath);
  const explorerUser = (row) => !(context.permissions.isExplorer() && !insidePersonalFolder(row));

  //Verify if the current user can edit an object or visualize it only
  const userHasObjectPermission = (row) => {
    let canAccessRow = true;
    const permissions = row.attributes.permissions;
    if (permissions.length > 0 && context.permissions.roleLevel <= 2) {
      canAccessRow = false;
      for (const permission of permissions) {
        if (permission.id === context.user.id && permission.write === true) {
          canAccessRow = true;
          break;
        }
      }
    }
    return canAccessRow;
  };

  return [
    {
      key: 'checkbox',
      label: '',
      size: 4,
      render: (row) => (
        <div>
          {row.value !== personalFolderPath && explorerUser(row) && userHasObjectPermission(row) && (
            <BngCheckbox
              field={{
                onChange: (event) => {
                  toggleSelected(row);
                },
                value: row.value in selected,
              }}
              style={{
                transform: 'scale(1.2)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingLeft: '8px',
              }}
            />
          )}
        </div>
      ),
    },
    {
      key: 'text',
      label: context.msg.t('name'),
      render: (row) => {
        const hasChildren = row.children?.length > 0;
        const tooltipProps = {
          onShow: () => {
            return !hasChildren && !row.leaf;
          },
          followCursor: 'horizontal',
          hideOnClick: false,
        };
        return (
          <Tooltip title={context.msg.t('empty_folder')} delay={[300, 0]} {...tooltipProps}>
            <div
              className={`FileNameWrapper ${!row.leaf && hasChildren ? 'pointer' : ''}`}
              onClick={() => hasChildren && doDrillDown(row, !(row.value in expanded))}
              style={
                row.level > 1
                  ? {
                      marginLeft: `${(row.level - 1) * 18}px`,
                      borderLeft: '1px dashed #BBBBBB',
                    }
                  : {}
              }
            >
              <BngIconButton
                icon={row.value in expanded || !hasChildren ? 'remove' : 'add'}
                className={`expandIconButton ${row.leaf ? 'disabled' : ''}`}
                disabled={row.leaf || !hasChildren}
                style={{ marginRight: '5px' }}
              />
              <div className={`borderedTableRow`}>
                <Icon
                  icon={row.icon}
                  size={'xl'}
                  outlined={row.value in expanded || (!row.leaf && !hasChildren)}
                  className={`fileIcon`}
                />
                <span className="fileName">{row.text}</span>
              </div>
            </div>
          </Tooltip>
        );
      },
    },
    {
      key: 'type',
      label: context.msg.t('type'),
      size: 100,
      render: (row) => (
        <div>
          <span>{context.msg.t(Utils.Object.getObjectType(row.value))}</span>
        </div>
      ),
    },
    {
      key: 'permissions',
      label: context.msg.t('permissions'),
      size: 500,
      render: (row) => {
        const permittedMembers = row.attributes.permissions || [context.user];
        const missingPermissions =
          permittedMembers.length === 0 && row.attributes.isPublic === false && !insidePersonalFolder(row);
        const isPublicLevelOne = row.attributes.isPublic && row.level === 1;
        return (
          <div className="MemberPermissionWrapper">
            {permittedMembers.length > 0 &&
              permittedMembers.map((member) => (
                <MemberPermission key={`${row.text}-member-perm-${member.id}`} member={member} />
              ))}
            {missingPermissions && (
              <span className="missingPermissions">{context.msg.t('folder.permission.not.members')}</span>
            )}
            {isPublicLevelOne && <PublicPermission />}
          </div>
        );
      },
    },
    {
      key: 'modifiedDate',
      label: context.msg.t('updated'),
      size: 150,
      render: (row) => {
        return (
          <div className={`flex-center-items`}>
            <div
              className={`d-flex align-items-flex-end cursor-help`}
              title={moment(row.attributes.modifiedDate).format('DD/MM/YYYY HH:mm')}
            >
              <Icon icon={'access_time'} className={'mr-1 TimeIcon'} />
              <span className={'mr-2 no-wrap'}>
                {row.attributes.modifiedDate
                  ? moment(row.attributes.modifiedDate).fromNow()
                  : context.msg.t('no.activity.register')}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      key: 'actions',
      label: context.msg.t('actions'),
      size: 70,
      render: (row) => {
        return (
          <RenderActions
            row={row}
            project={project}
            fetchData={fetchData}
            dispatch={dispatch}
            context={context}
            setSelected={setSelected}
            explorerUser={explorerUser(row)}
            userHasObjectPermission={userHasObjectPermission(row)}
            insidePersonalFolder={insidePersonalFolder(row)}
            onDeleteNode={onDeleteNode}
            embedded={embedded}
          />
        );
      },
    },
  ];
};

const RenderActions = ({
  row,
  project,
  fetchData,
  dispatch,
  context,
  setSelected,
  explorerUser,
  userHasObjectPermission,
  insidePersonalFolder,
  onDeleteNode,
  embedded,
}) => {
  const openRenameDialog = () => {
    const parts = row.value.split('/');
    parts.pop();
    const folder = parts.join('/');
    try {
      dispatch(
        MODALS.open(RenameDialogContainer, {
          path: row.value,
          folder,
          name: row.text,
          isFolder: !row.leaf,
          loadAfterSave: false,
          foldersToIgnore: [row.value],
          onRename: (newPath, canceled) => {
            if (row.value !== newPath && !row.leaf && !canceled) {
              onDeleteNode([row.value], false);
            } else {
              fetchData();
              setSelected({});
              BimEventBus.emit(Api.Project.FOLDER_CREATED_EVENT);
            }
          },
          icon: row.icon,
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  const getNodesToDelete = (row) => {
    const nodesToDelete = [];
    nodesToDelete.push(row);

    if (!row.leaf) {
      const children = row.children;
      if (children.length > 0) {
        children.forEach((childRow) => {
          if (children[0].level === childRow.level) {
            nodesToDelete.push(...getNodesToDelete(childRow));
          }
        });
      }
    }
    return nodesToDelete;
  };

  const isRootFolder = row.value === `/${context.project.name}/${context.user.id}`;

  return (
    <div
      className="ActionsWrapper"
      style={isRootFolder || userHasObjectPermission || row.leaf ? { justifyContent: 'flex-start' } : {}}
    >
      {explorerUser && (userHasObjectPermission || row.leaf) && (
        <BngIconButton
          icon={!row.leaf ? `create_new_folder` : `exit_to_app`}
          size={'xl'}
          onClick={() => {
            !row.leaf
              ? dispatch(
                  MODALS.open(NewFolderDialog, {
                    parentFolder: row.value,
                    onCreateFolder: async () => {
                      await fetchData();
                      setSelected({});
                    },
                  })
                )
              : Utils.Object.openObject(row.value);
          }}
          title={context.msg.t(!row.leaf ? 'create_subfolder' : 'access.object')}
        />
      )}
      {!isRootFolder && explorerUser && userHasObjectPermission && (
        <BngDropdown
          popperOpts={{ placement: 'bottom-end' }}
          popperClassName="FolderMenuPopper"
          options={[
            [
              {
                label: context.msg.t('change.icon'),
                icon: 'edit',
                visible: row.level === 1,
                onClick: () => {
                  dispatch(
                    MODALS.open(ChangeFolderIconDialog, {
                      folder: row.value,
                      icon: row.icon,
                      afterSave: () => fetchData(),
                    })
                  );
                },
              },
              {
                label: context.msg.t('move.and.rename'),
                icon: 'zoom_out_map',
                className: 'action-move',
                onClick: () => {
                  openRenameDialog();
                },
              },
              {
                label: context.msg.t('permissions'),
                icon: 'lock',
                visible: !insidePersonalFolder && context.permissions.roleLevel > 2,
                onClick: () => {
                  dispatch(
                    MODALS.open(PathPermissionDialog, {
                      path: row.value,
                      projectId: project.id,
                      useParentPermission: true,
                      afterSave: () => fetchData(),
                      lockCurrentUser: embedded,
                    })
                  );
                },
              },
            ],
            {
              label: context.msg.t('delete'),
              icon: 'delete_outline',
              onClick: () => {
                dispatch(
                  MODALS.open(DeleteFileDialog, {
                    onConfirm: async () => await onDeleteNode([row.value]),
                    nodesToDelete: getNodesToDelete(row),
                    showConfirmCheckbox: true,
                    confirmCheckboxLabel: context.msg.t('delete.files.checkbox.label'),
                  })
                );
              },
            },
          ]}
        />
      )}
    </div>
  );
};

// TODO: Após entrada da #4818 substituir esse componente pelo uso do BngTag atualizada
const MemberPermission = ({ member }) => {
  return (
    <div className="MemberPermission">
      <span className="permissionMember" title={member.displayName ?? member.name}>
        {member.displayName ?? member.name}
      </span>
      <Icon icon={member.write ? 'edit' : 'visibility'} className="permissionIcon" />
    </div>
  );
};

const PublicPermission = () => {
  const context = useBimContext();
  return (
    <div className="PublicPermission">
      <span className="permissionPublic">{context.msg.t('public')}</span>
      <Icon icon={'lock_open'} className="permissionIcon" />
    </div>
  );
};
