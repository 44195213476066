import style from './ProjectUpdateAppsDialog.module.css';

import React, { useEffect, useRef, useState } from 'react';

import { Form, Formik } from 'formik';
import WizardDialog from 'components/bng/ui/WizardDialog';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import InfoWizardStep from 'components/bng/project/updateApps/InfoWizardStep';
import ValidateMultipleUpdatesStep from 'components/bng/project/updateApps/ValidateMultipleUpdatesStep';
import AddObjectsWizardStep from 'components/bng/project/updateApps/AddObjectsWizardStep';
import EditObjectsWizardStep from 'components/bng/project/updateApps/EditObjectsWizardStep';
import RemoveObjectsWizardStep from 'components/bng/project/updateApps/RemoveObjectsWizardStep';
import AppSelectStep from 'components/bng/project/updateApps/AppSelectStep';
import { ACTION, countActions, MODE, STATUS } from 'components/bng/project/updateApps/projectUpdateAppsCommons';
import OpConfirmation from 'components/ui/OpConfirmation';
import useBimContext from 'components/hooks/useBimContext';

const StatusToActionMap = {
  NEW: ACTION.CREATE,
  UPDATED: ACTION.UPDATE,
  REMOVED: ACTION.REMOVE,
};

const DEFAULT_SERVICE = {
  findInstalls: Api.ProjectApp.findInstalls,
  findDiff: Api.ProjectApp.findDiff,
  updateApp: Api.ProjectApp.updateApp,
  removeAppLink: Api.ProjectApp.removeAppLink,
};

const createKeys = (effectiveProjectId, appInstall, isCustomersMode) => {
  const projectToUpdateId = isCustomersMode ? appInstall.app.id : effectiveProjectId;

  const appProjectId = isCustomersMode ? effectiveProjectId : appInstall.install.appId;

  const diffResultKey = appInstall.install.id;

  return { projectToUpdateId, appProjectId, diffResultKey };
};

function ProjectUpdateAppsDialog({ projectId, closeModal, className = '', apiService = DEFAULT_SERVICE }) {
  const { msg, project } = useBimContext();

  const [projectData, setProjectData] = useState(null);
  const [appsToUpdate, setAppsToUpdate] = useState([]);
  const [diffResults, setDiffResults] = useState({});
  const [appBeingUpdated, setAppBeingUpdated] = useState();
  const [appsUpdateStatus, setAppsUpdateStatus] = useState({});
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(MODE.PROJECT);
  const [confirmAndUpdate, setConfirmAndUpdate] = useState(false);

  const [wizardState, setWizardState] = useState({
    keepData: false,
    showAdded: true,
    showUpdated: true,
    showRemoved: true,
    updateSql: false,
    updateCockpitOrder: false,
    updateSchedulings: false,
  });
  const $formikRef = useRef();

  const effectiveProjectId = projectId || project.id;

  const isCustomersMode = mode === MODE.CUSTOMERS;

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const projectData = await apiService.findInstalls(effectiveProjectId);
        setProjectData(projectData);

        if (_.isEmpty(projectData.installs) && _.isEmpty(projectData.installedOn)) {
          UiMsg.warn('', msg.t('project.update.app.dialog.no.data.alert'));
          closeModal();
          return;
        }

        if (_.isEmpty(projectData.installs) && !_.isEmpty(projectData.installedOn)) {
          setMode(MODE.CUSTOMERS);
        }
      } catch (e) {
        console.error(e);
        UiMsg.ajaxError(null, e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const onSelectApp = (appInstall) => {
    if (wizardState.keepData) {
      setAppsToUpdate([appInstall]);
    } else {
      const appInstalls = !Array.isArray(appInstall) ? [appInstall] : appInstall;
      const val = appsToUpdate.slice();
      for (const appInst of appInstalls) {
        const id = appInst.install.id;
        const idx = val.findIndex((a) => a.install.id === id);
        if (idx === -1) {
          val.push(appInst);
        } else {
          val.splice(idx, 1);
        }
      }
      setAppsToUpdate(val);
    }
  };

  const processAppInstall = async (appInstall) => {
    const { projectToUpdateId, appProjectId, diffResultKey } = createKeys(
      effectiveProjectId,
      appInstall,
      isCustomersMode
    );

    if (diffResults.hasOwnProperty(diffResultKey)) {
      const { actions, dataSources } = diffResults[diffResultKey];
      $formikRef.current.setValues({ actions, dataSources });
      return;
    }

    const diffResult = await apiService.findDiff(projectToUpdateId, appProjectId, wizardState.updateSchedulings);

    const actions = diffResult.objects.reduce((acc, el) => {
      acc[el.sourcePath || el.targetPath] = {
        object: el,
        action: StatusToActionMap[el.status],
      };
      return acc;
    }, {});

    const dataSources = diffResult.dataSources.map((ds) => ds.name);
    const diffResultData = {
      diffResult,
      actions,
      dataSources,
    };

    if (wizardState.keepData) {
      setDiffResults({
        [diffResultKey]: diffResultData,
      });
    } else {
      diffResults[diffResultKey] = diffResultData;
      setDiffResults(diffResults);
    }

    $formikRef.current.setValues({ actions, dataSources });
  };

  const runAppInstall = async (appInstall, formValues) => {
    if (appInstall.canceled) return;

    const { projectToUpdateId, appProjectId, diffResultKey } = createKeys(
      effectiveProjectId,
      appInstall,
      isCustomersMode
    );

    if (appsUpdateStatus.hasOwnProperty(diffResultKey)) {
      const { finished, error } = appsUpdateStatus[diffResultKey];
      if (finished && !error) {
        return;
      }
    }

    setAppBeingUpdated(appInstall);
    appsUpdateStatus[diffResultKey] = {
      finished: false,
      error: null,
    };
    setAppsUpdateStatus({ ...appsUpdateStatus });
    try {
      const { actions, dataSources } = formValues ? formValues : diffResults[diffResultKey];

      const requestData = buildRequestData(actions, dataSources);
      requestData.updateSql = wizardState.updateSql;
      requestData.updateCockpitOrder = wizardState.updateCockpitOrder;
      requestData.updateSchedulings = wizardState.updateSchedulings;
      await apiService.updateApp(projectToUpdateId, appProjectId, requestData);
    } catch (e) {
      console.error(e);
      appsUpdateStatus[diffResultKey].error = e;
    } finally {
      setAppBeingUpdated(null);
      appsUpdateStatus[diffResultKey].finished = true;
      setAppsUpdateStatus({ ...appsUpdateStatus });
    }
  };

  const isUnlinkProjectMode = mode === MODE.UNLINK_PROJECT;

  const save = async (values, actions) => {
    try {
      if (isUnlinkProjectMode) {
        for (const appToUnlink of appsToUpdate) {
          try {
            await apiService.removeAppLink(appToUnlink.install.id);
          } catch (e) {
            console.error(`Error on apiService.removeAppLink`, { appToUnlink }, e);
            UiMsg.ajaxError(msg.t('remove.app.link.error', [appToUnlink.app.caption]), e);
          }
        }
        UiMsg.ok(msg.t('remove.app.links.process.finished'));
        await Api.executeExp(`#{projectSelectionMB.reloadCurrentProject()}`);
        closeModal();
      } else {
        if (wizardState.keepData) {
          const appInstall = appsToUpdate[0];
          await runAppInstall(appInstall, values);
        } else {
          for (const appInstall of appsToUpdate) {
            await runAppInstall(appInstall);
          }
        }
        UiMsg.ok(msg.t('project.update.process.finished'), msg.t('project.update.process.finished.description'));
      }
    } catch (e) {
      console.error(e);
      UiMsg.ajaxError(null, e);
    } finally {
      actions.setSubmitting(false);
      await Api.updateJsf();
    }
  };

  return (
    <Formik initialValues={{ actions: {}, dataSources: [] }} onSubmit={save} innerRef={$formikRef}>
      {({ values, submitForm, isSubmitting, setFieldValue }) => {
        const wizardSteps = [];

        if (_.isNull(projectData)) {
          wizardSteps.push({
            infoOnly: true,
            dialogTitle: msg.t('projectUpdate'),
            disableNext: true,
            dialogClassName: style.dialogUpdatingApps,
            render: () => {
              return <></>;
            },
          });
        } else {
          const availableInstalls = isCustomersMode ? projectData.installedOn : projectData.installs;

          wizardSteps.push({
            infoOnly: true,
            dialogTitle: msg.t('projectUpdate'),
            dialogClassName: style.onInfoWizardStep,
            render: () => (
              <InfoWizardStep
                keepData={wizardState.keepData}
                projectData={projectData}
                appsToUpdate={appsToUpdate}
                mode={mode}
                onModeChange={(mode) => {
                  setMode(mode);
                  setAppsToUpdate([]);
                }}
                keepDataChanged={() => {
                  setWizardState({
                    ...wizardState,
                    keepData: !wizardState.keepData,
                  });
                  setAppsToUpdate([]);
                }}
                updateSql={wizardState.updateSql}
                onUpdateSqlChanged={() => {
                  setWizardState({
                    ...wizardState,
                    updateSql: !wizardState.updateSql,
                  });
                }}
                updateCockpitOrder={wizardState.updateCockpitOrder}
                onUpdateCockpitOrderChanged={() => {
                  setWizardState({
                    ...wizardState,
                    updateCockpitOrder: !wizardState.updateCockpitOrder,
                  });
                }}
                updateSchedulings={wizardState.updateSchedulings}
                onUpdateSchedulingsChanged={() => {
                  setWizardState({
                    ...wizardState,
                    updateSchedulings: !wizardState.updateSchedulings,
                  });
                }}
              />
            ),
            onNextStep: async () => {
              if (appsToUpdate.length > 0) return;
              onSelectApp(availableInstalls[0]);
            },
          });

          {
            const dialogTitle = isCustomersMode
              ? wizardState.keepData
                ? 'select.the.customer'
                : 'select.the.customers'
              : wizardState.keepData
              ? 'select.the.app'
              : 'select.the.apps';
            const title = isCustomersMode
              ? wizardState.keepData
                ? 'customer'
                : 'customers'
              : wizardState.keepData
              ? 'app'
              : 'apps';
            wizardSteps.push({
              dialogTitle: msg.t(dialogTitle),
              title: msg.t(title),
              render: () => {
                return (
                  <AppSelectStep
                    availableInstalls={availableInstalls}
                    appsToUpdate={appsToUpdate}
                    onSelect={(a) => onSelectApp(a)}
                    keepData={wizardState.keepData}
                  />
                );
              },
              disableNext: _.isEmpty(appsToUpdate),
              onNextStep: async () => {
                if (isUnlinkProjectMode) {
                  return;
                }

                setLoading(true);
                try {
                  for (const appToUpdate of appsToUpdate) {
                    await processAppInstall(appToUpdate);
                  }
                } finally {
                  setLoading(false);
                }
              },
            });
          }

          if (!isUnlinkProjectMode && wizardState.keepData) {
            const objects = Object.values(diffResults)[0]?.diffResult?.objects;
            if (wizardState.showAdded) {
              wizardSteps.push({
                dialogTitle: msg.t('objectAddedInVersion'),
                title: msg.t('added.plural'),
                render: () => {
                  const rows = objects.filter((o) => o.status === STATUS.NEW);
                  return (
                    <AddObjectsWizardStep
                      rows={rows}
                      onChange={(actions) => {
                        setFieldValue('actions', actions);
                      }}
                      actions={values.actions}
                    />
                  );
                },
              });
            }

            if (wizardState.showUpdated) {
              wizardSteps.push({
                dialogTitle: msg.t('modifiedObjectsInTheVersion'),
                title: msg.t('modified.plural'),
                dialogClassName: style.updatedStep,
                render: () => {
                  const rows = objects.filter((o) => o.status === STATUS.UPDATED);
                  return (
                    <EditObjectsWizardStep
                      rows={rows}
                      values={values}
                      onChange={(actions) => setFieldValue('actions', actions)}
                    />
                  );
                },
              });
            }

            if (wizardState.showRemoved) {
              wizardSteps.push({
                dialogTitle: msg.t('objectsRemovedInTheVersion'),
                title: msg.t('removed.plural'),
                render: () => {
                  const rows = objects.filter((o) => o.status === STATUS.REMOVED);
                  return (
                    <RemoveObjectsWizardStep
                      rows={rows}
                      onChange={(actions) => {
                        setFieldValue('actions', actions);
                      }}
                      actions={values.actions}
                    />
                  );
                },
              });
            }
          }

          const allFinished = isUnlinkProjectMode
            ? false
            : appsToUpdate.every((appInstall) => {
                if (appInstall.canceled) return true;
                const appInstallId = appInstall.install.id;
                const { actions, dataSources } = (wizardState.keepData ? values : diffResults[appInstallId]) ?? {};
                return (
                  actions &&
                  Object.values(countActions(actions)).every((v) => v === 0) &&
                  dataSources &&
                  dataSources.length === 0
                );
              });

          wizardSteps.push({
            dialogTitle: msg.t(confirmAndUpdate ? 'ProjectUpdateAppsDialog.update' : 'validation'),
            title: msg.t('validation'),
            nextButtonLabel: msg.t(isUnlinkProjectMode ? 'unlink.apps' : allFinished ? 'close' : 'confirm.and.update'),
            infoOnly: confirmAndUpdate,
            preventClose: confirmAndUpdate && (!!appBeingUpdated || isSubmitting),
            dialogClassName: confirmAndUpdate ? style.dialogUpdatingApps : '',
            actionBtnClassName: isUnlinkProjectMode ? 'BngWarnButton' : undefined,
            render: () => {
              return (
                <ValidateMultipleUpdatesStep
                  appsToUpdate={appsToUpdate}
                  diffResults={diffResults}
                  appBeingUpdated={appBeingUpdated}
                  appsUpdateStatus={appsUpdateStatus}
                  onCancel={(appInstall) => {
                    appInstall.canceled = true;
                    setAppsToUpdate(appsToUpdate.slice());
                  }}
                  runAppInstall={runAppInstall}
                  keepData={wizardState.keepData}
                  formActions={values}
                  mode={mode}
                />
              );
            },
            onNextStep: async () => {
              if (isUnlinkProjectMode) {
                const proceed = await new Promise((resolve) => {
                  OpConfirmation({
                    msg,
                    title: msg.t('attention'),
                    message: msg.t('unlink.apps.message'),
                    onResult: resolve,
                  });
                });

                if (!proceed) {
                  return false;
                }
              }

              if (allFinished) {
                closeModal();
              } else {
                setConfirmAndUpdate(true);
                await submitForm();
              }
            },
          });
        }

        return (
          <Form>
            <WizardDialog
              className={`${style.main} ProjectUpdateAppsDialog large ${className}`}
              closeModal={closeModal}
              loading={loading}
              dialogProps={{
                newDialogLayout: false,
              }}
              steps={wizardSteps}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

const buildRequestData = (actionsObject, dataSources = []) => {
  const actions = Object.values(actionsObject)
    .filter((value) => value.action !== ACTION.KEEP)
    .map((value) => {
      return {
        sourcePath: value.object.sourcePath,
        targetPath: value.object.targetPath,
        type: value.object.type,
        action: value.action,
      };
    });
  return {
    actions,
    dataSources,
  };
};

export default ProjectUpdateAppsDialog;
