import styles from './MarketplaceCard.module.css';

import React from 'react';
import { useSearchParams } from 'react-router-dom';

import Icon from 'components/ui/common/Icon';
import useBimContext from 'components/hooks/useBimContext';
import { separateDecimals } from 'components/bng/accounts/additionals/AddAdditionalDialog';
import Utils from 'components/Utils';
import Api from 'components/Api';
import useBimNavigate from 'components/hooks/useBimNavigate';
import { MARKETPLACE_GROUPS } from 'components/bng/pages/marketplace/MarketplacePage';
import BngButton from 'components/bng/ui/BngButton';
import UiMsg from 'components/ui/UiMsg';

export const RECURRING_TYPES = {
  MONTHLY: {
    key: 'MONTHLY',
    label: 'byMonth',
  },
  BIMONTHLY: {
    key: 'BIMONTHLY',
    label: 'byTrimester',
  },
  QUARTERLY: {
    key: 'QUARTERLY',
    label: 'byBimonthly',
  },
  BIANNUALLY: {
    key: 'BIANNUALLY',
    label: 'bySemester',
  },
  YEARLY: {
    key: 'YEARLY',
    label: 'byYear',
  },
  ONCE: {
    key: 'ONCE',
    label: '',
  },
};

export default function MarketplaceCard({
  itemKey,
  title,
  description,
  thumbnail = null,
  icon = 'extension',
  color,
  pricing,
  itemId,
  itemType,
  recurringType = RECURRING_TYPES.MONTHLY,
  isChecked = false,
  selectCard,
  isContracted = false,
  soonTag = false,
  cardImagePath = null,
  translatedProps = {},
  groupKey,
  templateInfo,
  account,
  marketplaceItem,
  activateWithoutRequest
}) {
  const context = useBimContext();
  const navigate = useBimNavigate();
  const [searchParams] = useSearchParams();
  const isConsultant = Utils.Users.isConsultant(context.user);
  const isViewUser = !isConsultant && context.permissions.isViewer() && !activateWithoutRequest;
  const pricingValue = separateDecimals((pricing?.pricing).toFixed(2));
  const isTemplate = groupKey === MARKETPLACE_GROUPS.TEMPLATE;
  const userLanguage = context.user.preferredLanguage.replace('_', '-');

  const redirectToItemPage = async () => {
    if (isTemplate) {
      searchParams.set('currentTab', 'templateItem');
      searchParams.set('templateName', translatedProps.langs[userLanguage]?.link.split('/').slice(-2, -1)[0]);
    } else {
      searchParams.set('currentTab', 'item');
      searchParams.set('itemId', itemId);
      searchParams.set('type', itemType);
    }

    const url = Api.buildUrl('/spr/bng/marketplace', searchParams);
    await navigate(url);
  };

  const imageUrl = cardImagePath ? Api.Marketplace.marketplaceImageUrl(cardImagePath) : null;

  return (
    <div
      className={`MarketplaceCard-${itemId} ${styles.MarketplaceCard} ${isChecked ? styles.selectedCard : ''}`}
      onClick={redirectToItemPage}
    >
      <div className={styles.imageWrapper}>
        {cardImagePath ? (
          <img key={`${itemId}-card-image`} className={styles.cardImage} src={imageUrl} />
        ) : thumbnail ? (
          <img src={thumbnail} />
        ) : (
          <div className={styles.iconBackground} style={{ background: color }}>
            <Icon icon={icon} className={styles.cardIcon} />
          </div>
        )}
        <div className={styles.imageGradient}></div>
        {soonTag && (
          <div className={styles.soonTag}>
            <span>{context.msg.t('coming.soon')}</span>
          </div>
        )}
      </div>
      <div className={styles.detailsWrapper}>
        <div className={styles.textWrapper}>
          <div className={styles.cardTitleRow}>
            <span className={styles.cardTitle}>{title}</span>
            {templateInfo && (
              <Icon
                icon={'info'}
                title={`${
                  templateInfo.templateStructures
                    ? `${context.msg.t('template.structures', [templateInfo.templateStructures])} <br>`
                    : ''
                }
                 ${
                   templateInfo.templateProjectHours
                     ? context.msg.t('template.hours', [templateInfo.templateProjectHours])
                     : ''
                 }`}
                className={styles.cardTemplateInfo}
              />
            )}
            {isConsultant && !isContracted && !soonTag && (
              <Icon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  selectCard();
                }}
                icon={isChecked ? 'check_box' : 'check_box_outline_blank'}
                outlined={!isChecked}
                className={`${styles.cardCheckbox}`}
              />
            )}
          </div>
          <span className={styles.cardDescription}>{description}</span>
        </div>
        <div className={styles.cardFooter}>
          {isContracted ? (
            <div className={styles.activeLabel}>{context.msg.t('enabled')}</div>
          ) : (
            <div className={styles.pricingFooter}>
              {isViewUser && !soonTag ? (
                <BngButton
                  className={styles.footerButton}
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const currentObjType = itemType || new URLSearchParams(window.location.search).get('type');
                    try {
                      await Api.Marketplace.changeItemStatus(itemKey, currentObjType, context.project.id, false, null, {
                        id: itemId,
                        type: itemType,
                        pricings: [pricing],
                        key: itemKey,
                        billingPricingId: pricing.id,
                        agreedPricing: pricing.pricing,
                        quantity: 1,
                        marketplaceInfo: marketplaceItem.marketplaceInfo,
                      });

                      if (marketplaceItem.marketplaceInfo.openTicket) {
                        const title = `Solicitação de ${marketplaceItem.name} através do Marketplace`;
                        await Api.Support.newTicketForMarketplace({
                          title,
                          items: [marketplaceItem],
                          user: context.user,
                          project: context.project,
                          account,
                        });
                        UiMsg.ok(context.msg.t('marketplace.item.support.request.success'));
                      }
                      UiMsg.ok(context.msg.t('marketplace.item.requested.successfully'));
                    } catch (e) {
                      console.error('Error while requesting item', e);
                      UiMsg.ajaxError(context.msg.t('marketplace.request.error'), e);
                    }
                  }}
                >
                  {context.msg.t('request.activation')}
                </BngButton>
              ) : (
                !soonTag && (
                  <>
                    <span className={styles.footerDescription}>{context.msg.t('marketplace.starting.from')}</span>
                    <div className={styles.pricingWrapper}>
                      <span className={styles.currency}>{pricing.currency.symbol}</span>
                      <strong className={styles.fullPricing}>{pricingValue[0]}</strong>
                      <div className={styles.decimalsWrapper}>
                        <strong>{pricingValue[1]}</strong>
                        <div>{context.msg.t(recurringType.label)}</div>
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
