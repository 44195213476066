import styles from './AccAdaAiTab.module.css';

import React, { useMemo, useState } from 'react';

import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { BngPrimaryButton } from 'components/ui/Button';
import Icon from 'components/ui/common/Icon';
import { Field, Formik } from 'formik';
import useFetchData from 'components/hooks/useFetchData';
import UiBlocker from 'components/bng/ui/UiBlocker';
import useTranslation from 'components/hooks/useTranslation';
import BngForm from 'components/bng/form/BngForm';
import BngField from 'components/bng/form/BngField';
import bngYup from 'components/bng/form/yup/BngYup';
import useBimContext from 'components/hooks/useBimContext';

const validateChatGptKey = (key) => {
  const regex = /^sk-proj-/;
  return regex.test(key);
};

const Schema = bngYup((yup) =>
  yup.object({
    chatGptKey: yup.string(),
  })
);

export default function AccAdaAiTab() {
  const context = useBimContext();
  const { t } = useTranslation();

  const [isIconHovered, setIsIconHovered] = useState(false);

  const { data: adaAiConfig, isLoading, reload } = useFetchData(async () => {
    try {
      return await Api.Account.findAdaAiConfig(context.accountId);
    } catch (e) {
      UiMsg.ajaxError(null, e);
      throw e;
    }
  }, [context.accountId]);

  const isApiKeyLoaded = !_.isEmpty(adaAiConfig?.chatGptKey);
  const initialFormValues = useMemo(() => Object.assign({}, Schema.default(), _.cloneDeep(adaAiConfig ?? {})), [
    adaAiConfig,
  ]);

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={async (values, formikHelpers) => {
        try {
          await Api.Account.saveAdaAiConfig(context.accountId, values);
          setIsIconHovered(false);
          UiMsg.ok(t('account.management.acc.ada.ai.button.save.valid'));
          reload();
        } catch (e) {
          console.error('Error on saveAdaAiConfig()', e);
          UiMsg.ajaxError(t('account.management.acc.ada.ai.input.invalid'), e);
        }
      }}
      enableReinitialize
    >
      {({ values, isSubmitting, resetForm, setFieldValue }) => {
        const isValid = validateChatGptKey(values.chatGptKey);
        return (
          <UiBlocker className={`AccAdaAiTab ${styles.AccAdaAi}`} block={isLoading || isSubmitting}>
            <BngForm>
              <img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-acc.png')} alt="Ada Ai" />
              <h1>
                <span>
                  {isApiKeyLoaded
                    ? t('account.management.acc.ada.ai.apiKeyLoaded')
                    : t('account.management.acc.ada.ai.title')}
                </span>{' '}
                <img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-icon.png')} alt="Icon" />
              </h1>

              <div className={`inputContainer ${styles.inputContainer}`}>
                <div className={`inputWrapper ${styles.inputWrapper}`}>
                  <Field
                    name="chatGptKey"
                    component={BngField}
                    placeholder={t('account.management.acc.ada.ai.input')}
                  />
                  {isApiKeyLoaded && (
                    <div className={`inputWrapperIcon ${styles.inputWrapperIcon}`}>
                      <Icon
                        className={`iconDone ${styles.iconDone}`}
                        onMouseEnter={() => setIsIconHovered(true)}
                        onMouseLeave={() => setIsIconHovered(false)}
                        onClick={async () => {
                          try {
                            await Api.Account.saveAdaAiConfig(context.accountId, {
                              chatGptKey: null,
                            });
                            resetForm({
                              values: Schema.default(),
                            });
                            setFieldValue('chatGptKey', '');
                            UiMsg.ok(t('account.management.acc.ada.ai.delete.api.key.success'));
                            reload();
                          } catch (error) {
                            console.error('Erro ao excluir a API KEY', error);
                            UiMsg.error('account.management.acc.ada.ai.delete.api.key.error', error);
                          }
                        }}
                        icon={!isIconHovered ? 'done' : 'close'}
                      />
                    </div>
                  )}
                  <div className={`inputWrapperErrorMsg ${styles.inputWrapperErrorMsg}`}>
                    {!isValid && (
                      <div className={`errorMsg ${styles.errorMsg}`}>
                        {t('account.management.acc.ada.ai.input.invalid')}
                      </div>
                    )}
                  </div>
                </div>
                <BngPrimaryButton type="submit" className={`saveButton ${styles.saveButton}`} disabled={!isValid}>
                  {t('account.management.acc.ada.ai.button.save')}
                </BngPrimaryButton>
              </div>
            </BngForm>
          </UiBlocker>
        );
      }}
    </Formik>
  );
}
