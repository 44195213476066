import styles from './ConnectionsTab.module.css';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import AccordionList from 'components/ui/AccordionList';
import Accordion from 'components/ui/Accordion';
import useBimContext from 'components/hooks/useBimContext';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';
import BngDropdown from 'components/bng/ui/BngDropdown';
import { MODALS } from 'components/ui/redux/Actions';
import ConnectionsDialog from 'components/ui/in-memory/ConnectionsDialog';
import Tooltip from 'components/ui/Tooltip';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import useTranslation from 'components/hooks/useTranslation';
import BngSearch from 'components/bng/ui/BngSearch';
import BngLogDialog from 'components/bng/logs/BngLogDialog';
import Utils from 'components/Utils';
import BngButton from 'components/bng/ui/BngButton';
import useBimQueryPageCtx from 'components/bng/pages/admin/structures/bimQuery/useBimQueryPageCtx';
import BimQueryConfigurationMenuItem from 'components/bng/pages/admin/structures/bimQuery/menuItems/BimQueryConfigurationMenuItem';
import { RIGHT_MENU_TOGGLE_SUBMENU } from 'components/ui/right-menu/RightMenu';
import bimEventBus from 'BimEventBus';
import LoadingSvg from 'components/ui/loading/LoadingSvg';

const SCHEMA = 'SCHEMA';
const TABLE = 'TABLE';
const COLUMN = 'COLUMN';
const VIEW = 'VIEW';

const columnIconSelector = (columnType) => {
  switch (columnType) {
    case 'NUMERIC':
      return '123';
    case 'DATE':
      return 'schedule';
    case 'BOOL':
      return 'memory';
    case 'JSON':
      return '{JSON}';
    default:
      return 'abc';
  }
};

function Selector({
  list = [],
  starOpen = false,
  isSomeSelected = false,
  loading = false,
  optionToRender = _.noop,
  onClear = _.noop,
  placeHolder = _.noop,
}) {
  const [opened, setOpened] = useState(false);

  return (
    <BngDropdown
      startOpened={starOpen}
      customButton={({ openDropdown }) => (
        <div className={styles.SelectorWrapper}>
          <div
            className={`${styles.Selector} opened-${opened}`}
            onClick={(event) => {
              setOpened((prev) => !prev);
              openDropdown(event);
            }}
          >
            {placeHolder()}
            <Icon icon="arrow_drop_down" />
          </div>
          {isSomeSelected && <Icon icon="close" onClick={onClear} />}
        </div>
      )}
      popperOpts={{
        placement: 'bottom-start',
      }}
      className={`Selector ${styles.SelectorButton}`}
      onClose={() => setOpened((prev) => !prev)}
      popperClassName={`SelectorListOptionPopper ${styles.SelectorListOptionPopper} `}
      customOptions={({ closeDropdown }) => (
        <div className={styles.SelectorOptionsDropdown}>
          {list.map((item, idx) => optionToRender(item, idx, closeDropdown))}
          {loading && (
            <div className={`${styles.SelectorListOption} loading-selected`}>
              <LoadingSvg />
            </div>
          )}
        </div>
      )}
    />
  );
}

function RenderList({
  list = [],
  fetchChildList = _.noop,
  childProp = 'columns',
  parent = {},
  handleSelection = _.noop,
  schemaName = '',
  className = styles.listWrapper,
  filter = '',
}) {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    if (filter) {
      const expandedSchemas = list
        .map((item, idx) => (item.hasOwnProperty('tables') || item.hasOwnProperty('views') ? idx : null))
        .filter((idx) => idx !== null);
      setExpanded(expandedSchemas);
    } else {
      setExpanded([]);
    }
  }, [filter, list]);

  const handleExpand = (idx, tableName, isTable) => {
    if (isTable) {
      fetchChildList(schemaName, tableName, childProp);
    }
    const expandedClone = expanded.slice();
    const indexOf = expandedClone.indexOf(idx);
    if (indexOf < 0) {
      expandedClone.push(idx);
    } else {
      expandedClone.splice(indexOf, 1);
    }
    setExpanded(expandedClone);
  };

  return (
    <>
      {list.map((item, idx) => {
        const isExpanded = expanded.indexOf(idx) >= 0;
        const children = item.tables || item.views || item[childProp];
        const shouldNotRender = item.hasOwnProperty('tables') || item.hasOwnProperty('views');
        const hasChild = children?.length > 0;
        const isTable = item.hasOwnProperty('columns');
        const isColumn = item.hasOwnProperty('key');
        const itemName = item.name;

        let itemProps = {};

        if (!children) {
          itemProps.title = (
            <>
              <b>{itemName}</b>
              <div>
                {t('bim.query.menu.item.tooltip.from.table')}: <b>{item.key?.table}</b>
              </div>
              <div>
                {t('bim.query.menu.item.tooltip.column.name.from.table')}: <b>{item.key?.name}</b>
              </div>
              <div>
                {t('bim.query.menu.item.tooltip.column.type')}: <b>{item.type}</b>
              </div>
            </>
          );
        }

        const Tip = !children ? Tooltip : 'div';

        return (
          <Tip {...itemProps} key={idx}>
            <div
              className={`${className} ${isExpanded ? 'open' : 'closed'}`}
              draggable={!shouldNotRender}
              onDragStart={(event) => {
                event.persist();
                handleSelection(
                  item,
                  childProp,
                  parent,
                  event.dataTransfer,
                  schemaName,
                  isTable ? itemName : null,
                  false,
                  false,
                  isColumn
                );
                event.stopPropagation();
              }}
              onDragEnd={(event) => {
                event.persist();
                handleSelection(
                  item,
                  childProp,
                  parent,
                  event.dataTransfer,
                  schemaName,
                  isTable ? itemName : null,
                  false,
                  false,
                  isColumn
                );
                event.stopPropagation();
              }}
            >
              <div
                key={`${parent.id || 0}${idx}`}
                className={`${styles.schemaName}`}
                onClick={() => {
                  if (!isColumn) {
                    handleExpand(idx, itemName, isTable);
                  }
                }}
              >
                {!isColumn && (
                  <Icon
                    className={`${styles.schemaNameArrowIcon} ${isExpanded ? 'open' : 'closed'}`}
                    icon="arrow_right"
                  />
                )}
                {!shouldNotRender && (
                  <Icon
                    className={`${styles.listItemIcon} ${item.type} `}
                    icon={(isTable && 'table_view') || columnIconSelector(item.type)}
                  />
                )}
                <div className={`${styles.itemName} ${!children ? 'column' : ''}`}>{itemName}</div>
                {['FOREIGN', 'PRIMARY'].includes(item?.key?.type) && (
                  <Icon className={`${styles.keyIcon} ${item.key.type}`} icon="key" />
                )}
              </div>
              <div className={`${styles.subListWrapper} ${isExpanded ? 'open' : 'closed'}`}>
                {isExpanded && (
                  <RenderList
                    list={children}
                    parent={item}
                    fetchChildList={fetchChildList}
                    handleSelection={handleSelection}
                    schemaName={schemaName}
                    className={isTable ? styles.columnList : styles.subList}
                    filter={filter}
                  />
                )}
              </div>
              {!shouldNotRender && (
                <Icon
                  className={`${styles.itemAddIcon}`}
                  icon={children ? 'add' : ''}
                  onClick={() =>
                    handleSelection(
                      item,
                      childProp,
                      parent,
                      { dropEffect: 'copy' },
                      schemaName,
                      isTable ? itemName : null,
                      true,
                      false
                    )
                  }
                />
              )}
            </div>
          </Tip>
        );
      })}
    </>
  );
}

export default function ConnectionsTab({ formikProps, isEditing, options }) {
  const { t } = useTranslation();
  const { project, msg } = useBimContext();
  const dispatch = useReduxDispatch();
  const $bimQueryPageCtx = useBimQueryPageCtx();

  const { values, setFieldValue, errors, setValues } = formikProps;

  const $intervalRef = useRef();

  const [connections, setConnections] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState([]);
  const [loading, setLoading] = useState(false);
  const [connectionsLoading, setConnectionsLoading] = useState(false);
  const [dbSchemas, setDbSchemas] = useState(null);
  const [filter, setFilter] = useState('');
  const [syncing, setSyncing] = useState({ running: false, errorMessage: '', jobId: 0 });

  const selectedConnection = values.connection;

  const fieldsHasError = errors.hasOwnProperty('connection') || errors.hasOwnProperty('sql');

  const fieldsAreEmpty = values.connection !== 0 && values.sql !== '' && values.fieldConfigs?.length > 0;

  const stepIsValid = fieldsAreEmpty && !fieldsHasError;

  const connection = useMemo(() => {
    let temp = connections.find((con) => con.id === selectedConnection);
    if (temp?.id === 'BIMWarehouse') {
      temp = _.cloneDeep(temp);
      temp.databaseStructureUpdatedAt = moment().format();
    }
    return temp;
  }, [connections, selectedConnection]);

  const filteredTables = useMemo(() => {
    if (selectedSchema == null) {
      return [];
    }
    if (!filter) {
      if (!_.isEmpty(selectedSchema.tables)) {
        const clone = selectedSchema.tables.slice();
        return [
          {
            name: t('bim.query.tables.list.title'),
            tables: clone.filter((c) => c.type === TABLE),
          },
          {
            name: t('bim.query.views.list.title'),
            views: clone.filter((c) => c.type === VIEW),
          },
        ];
      }
      return selectedSchema.tables;
    }

    let clone = selectedSchema.tables.slice();
    clone = clone.filter((table) => table.name.toLowerCase().includes(filter.toLowerCase()));
    const tables = clone.filter((c) => c.type === TABLE);
    const views = clone.filter((c) => c.type === VIEW);

    return [
      {
        name: t('bim.query.tables.list.title'),
        tables,
      },
      {
        name: t('bim.query.views.list.title'),
        views,
      },
    ];
  }, [selectedSchema, filter]);

  useEffect(() => {
    fetchConnections();

    return () => {
      clearInterval($intervalRef.current);
    };
  }, []);

  useEffect(() => {
    clearInterval($intervalRef.current);

    setDbSchemas(null);
    const newSyncing = { ...syncing };
    setFilter('');
    if (selectedConnection !== 0) {
      if (selectedConnection !== 'BIMWarehouse') {
        newSyncing.running = true;
        syncDatabase(true);
      }
      fetchDataBase();
    } else {
      newSyncing.running = false;
    }
    setSyncing(newSyncing);
  }, [selectedConnection]);

  const fetchConnections = async () => {
    try {
      setConnectionsLoading(true);
      const data = await $bimQueryPageCtx.fetchConnections(project.id);
      const connectionList = data
        .filter((con) => con.type !== null)
        .map((connection) => ({
          ...connection,
          database: connections.type?.name,
        }));
      connectionList.push({
        id: 'BIMWarehouse',
        name: t('bim.query.export.my.data.lake.connection.title'),
        type: { name: 'BIMWarehouse' },
      });
      setConnections(connectionList);
      const connection = connectionList.find((connection) => connection.id === values.connection);
      if (connection) {
        setFieldValue('connection', connection.id);
      }
    } catch (e) {
      console.error('Error on function fetchConnections', e);
      UiMsg.ajaxError(t('bim.query.fetch.connections.error'), { e });
    } finally {
      setConnectionsLoading(false);
    }
  };

  const fetchDataBase = async () => {
    try {
      setLoading(true);
      let data;
      if (selectedConnection === 'BIMWarehouse') {
        data = await Api.Connection.fetchProjectDwSchema(project.id);
      } else if (selectedConnection) {
        data = await Api.Connection.fetchSchema({ connectionId: selectedConnection, inspectionType: SCHEMA });
      }
      setDbSchemas(data?.schemas || []);
    } catch (e) {
      console.error('Error on function fetchDataBase', e);
      UiMsg.ajaxError(t('bim.query.fetch.database.error'), { e });
    } finally {
      setLoading(false);
    }
  };

  const syncDatabase = async (createInterval = false) => {
    if (selectedConnection <= 0) {
      return;
    }

    if (createInterval) {
      $intervalRef.current = setInterval(syncDatabase, 30000);
    }

    const newSync = { ...syncing };

    try {
      const { job } = await Api.Connection.findSchemaInspectionJob(selectedConnection);
      if (job) {
        clearInterval($intervalRef.current);
        newSync.errorMessage = job.props.errorMessage || '';
        newSync.jobId = job.id;
      }
      setSyncing({ ...newSync, running: true });
      if (_.isEmpty(job)) {
        clearInterval($intervalRef.current);
        const resp = await Api.Connection.fetchSchema({
          connectionId: selectedConnection,
          inspectionType: 'PERSISTED',
        });

        if (resp.schemas !== null) {
          await fetchConnections();
          setDbSchemas(resp.schemas);
        }
        setSyncing({ ...newSync, running: false });
        clearInterval($intervalRef.current);
      }
    } catch (e) {
      setSyncing({ ...newSync, running: false });
      console.error('Error on fetch FULL_SCHEMA', { e });
      UiMsg.ajaxError(t('bim.query.fetch.database.error'), { e });
      clearInterval($intervalRef.current);
    }
  };

  const createJobToSyncDataBase = async () => {
    try {
      setSyncing({ ...syncing, running: true });
      if (selectedConnection === 'BIMWarehouse') {
        const data = await Api.Connection.fetchProjectDwSchema(project.id);
        setDbSchemas(data.schemas);
        setSyncing({ ...syncing, running: false });
        connection.databaseStructureUpdatedAt = moment().format();
      } else if (selectedConnection > 0) {
        await Api.Connection.createSchemaInspectionJob(selectedConnection, project.id);
        $intervalRef.current = setInterval(syncDatabase(true), 30000);
      }
    } catch (e) {
      console.error('Error syncing database', { e });
      UiMsg.ajaxError('', { e });
      setSyncing({ ...syncing, running: false });
      clearInterval($intervalRef.current);
    }
  };

  const fetchChildList = async (schemaName, tableName, childProp, lockUI = false) => {
    try {
      if (!lockUI) {
        setLoading(true);
      }
      const clonedSchemas = dbSchemas.slice();
      let temp = clonedSchemas.find((schema) => schema.name === schemaName);

      if (tableName) {
        temp = temp.tables.find((table) => table.name === tableName);
      }

      if (temp[childProp]?.length === 0) {
        const data = await Api.Connection.fetchSchema({
          connectionId: selectedConnection,
          inspectionType: tableName ? COLUMN : TABLE,
          schemaName: schemaName,
          tableName: tableName,
        });

        if (tableName === null) {
          temp[childProp] = data.schemas.find((s) => s.name === schemaName).tables;
        } else {
          temp[childProp] = data.schemas
            .find((s) => s.name === schemaName)
            .tables.find((t) => t.name === tableName).columns;
        }

        setDbSchemas(clonedSchemas);
      }
    } catch (e) {
      console.error('Error on fetchChildList()', { e });
      UiMsg.ajaxError(t('bim.query.fetch.database.error'), { e });
    } finally {
      if (!lockUI) {
        setLoading(false);
      }
    }
  };

  const handleSelection = async (
    item,
    child,
    parent,
    dataTransfer = {},
    schemaName,
    tableName,
    fromAddButton = false,
    lockUI = false,
    isColumn = false
  ) => {
    if (dataTransfer?.dropEffect === 'none' && !isColumn) {
      return;
    }
    const hasChildProp = Object.hasOwn(item, child);
    const isTable = item.hasOwnProperty('columns');
    if (hasChildProp && selectedConnection !== 'BIMWarehouse' && isTable) {
      await fetchChildList(schemaName, tableName, 'columns', lockUI);
    }

    const isBIMWarehouse = selectedConnection === 'BIMWarehouse';
    let sqlSelect = '';
    if (!isTable) {
      sqlSelect = `${parent.name}.${isBIMWarehouse ? '"' : ''}${item.name}${isBIMWarehouse ? '"' : ''}`;
      dataTransfer.setData('text/plain', sqlSelect);
    } else if (dataTransfer.dropEffect === 'copy') {
      const table = hasChildProp ? item : parent;
      sqlSelect = '\nSELECT';

      if (connection.type.jdbcDriver === 'oracle.jdbc.driver.OracleDriver') {
        (hasChildProp ? table[child] : [item]).forEach((v, idx) => {
          sqlSelect += `\n${idx > 0 ? ',' : ' '} ${table.name}.${v.name}`;
        });
        sqlSelect += `\nFROM\n${schemaName.toLowerCase()}.${table.name} ${table.name}`;
      } else {
        (hasChildProp ? table[child] : [item]).forEach((v, idx) => {
          sqlSelect += `\n${idx > 0 ? ',' : ' '} ${table.name}."${v.name}"`;
        });
        if (selectedConnection === 'BIMWarehouse') {
          sqlSelect += `\nFROM\n"${schemaName}"."${table.name}" as ${table.name}`;
        } else {
          sqlSelect += `\nFROM\n"${schemaName}".${table.name} as ${table.name}`;
        }
      }

      setFieldValue('sql', values.sql + sqlSelect);
    }
  };

  const clearSyncingError = async () => {
    try {
      await Api.Connection.clearSchemaInspectionJob(selectedConnection, syncing.jobId);
      setSyncing({
        running: false,
        errorMessage: '',
        jobId: 0,
      });
    } catch (e) {
      console.error('Error on clearSyncingError()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  const lastSync = connection?.databaseStructureUpdatedAt ?? 0;
  const disableButtons = _.isEmpty(selectedSchema) ? 'disabled' : '';
  const isConnectionSelected = selectedConnection > 0 || selectedConnection === 'BIMWarehouse';

  return (
    <>
      <AccordionList className={`${styles.ConnectionsTabAccordionWrapper} ObjectRightMenuAccordion`} loading={loading}>
        <Accordion title={t('connection')}>
          <Selector
            list={connections}
            starOpen={selectedConnection === 0 && !isEditing}
            isSomeSelected={isConnectionSelected}
            loading={connectionsLoading}
            selected={{
              id: connection?.id,
              name: connection?.name,
            }}
            placeHolder={() => (
              <div className={styles.selectionNameWrapper}>
                {!_.isEmpty(connection?.name) ? (
                  <>
                    <img
                      src={
                        connection.id === 'BIMWarehouse'
                          ? Api.buildUrl('/images/data/logo.png')
                          : Api.buildUrl('/images/data/db.png')
                      }
                      alt={connection.name}
                      className={styles.connectionIcon}
                    />
                    {connection.name}
                  </>
                ) : (
                  msg.t('select.one.connection')
                )}
              </div>
            )}
            onClear={() => {
              setValues({
                ...values,
                connection: 0,
                fieldConfigs: [],
                sql: '',
                dimensions: [],
                dataRecyclingFieldName: '',
              });
              setSelectedSchema([]);
              setDbSchemas(null);
            }}
            optionToRender={(item, idx, closeDropdown) => (
              <>
                <div
                  key={idx}
                  className={`${styles.SelectorListOption} ${idx === 0 ? 'FirstConnection' : ''}`}
                  onClick={() => {
                    setValues({
                      ...values,
                      connection: item.id,
                      fieldConfigs: [],
                      sql: '',
                      dimensions: [],
                    });
                    setSelectedSchema([]);
                    setDbSchemas(null);
                    closeDropdown();
                  }}
                >
                  {item.id === 'BIMWarehouse' ? (
                    <>
                      <img
                        className="ConnectionTab-ConnectionListOption-db-logo"
                        src={Api.buildUrl('/images/data/logo.png')}
                        alt="Icon"
                      />
                      <div>
                        <div>{`${msg.t('bim.query.export.my.data.lake.connection.title')}`}</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        className="ConnectionTab-ConnectionListOption-db-logo"
                        src={Api.buildUrl('/images/data/db.png')}
                        alt="Icon"
                      />
                      <div>
                        <div className={styles.selectionNameWrapper}>{`${msg.t('name')}: ${item.name}`}</div>
                        <div>{item.type?.name || ''}</div>
                      </div>
                    </>
                  )}
                </div>
                {connections.length - 1 === idx && (
                  <div
                    className={`${styles.SelectorListOption} new-database-connection`}
                    onClick={() => {
                      dispatch(
                        MODALS.open(ConnectionsDialog, {
                          projectId: project.id,
                          onClose: fetchConnections,
                        })
                      );
                      closeDropdown();
                    }}
                  >
                    <Icon icon="add_circle" />
                    <div className={styles.AddConnectionLabel}>{msg.t('manage.connections')}</div>
                  </div>
                )}
              </>
            )}
          />
        </Accordion>
        <Accordion title={t('bim.query.menu.item.title.schemas')}>
          <Selector
            list={dbSchemas || []}
            placeHolder={() => (
              <div className={styles.selectionNameWrapper}>
                {!_.isEmpty(selectedSchema) ? (
                  <div className={styles.SelectedSchemaPlaceholder}>
                    <Icon icon="schema" />
                    <div>{selectedSchema?.name}</div>
                  </div>
                ) : (
                  msg.t('select.one.schema')
                )}
              </div>
            )}
            optionToRender={(item, idx, closeDropdown) => {
              return (
                <div
                  key={idx}
                  className={`${styles.SelectorListOption} ${idx === 0 ? 'FirstSchema' : ''}`}
                  onClick={async () => {
                    if (item.tables.length === 0) {
                      await fetchChildList(item.name, null, 'tables', true);
                    }
                    setSelectedSchema({ ...item, id: idx });
                    closeDropdown();
                  }}
                >
                  <Icon icon="schema" />
                  {item.name}
                </div>
              );
            }}
          />
        </Accordion>
        <Accordion title={t('bim.query.menu.item.title.tables')} className={styles.TablesViewsAccordionWrapper}>
          <div className={`${styles.TopButtons} ${disableButtons}`}>
            <div className={`${styles.syncingIndicatorWrapper}`}>
              <div
                className={`${styles.SyncButton} ${disableButtons}`}
                onClick={disableButtons ? undefined : () => createJobToSyncDataBase()}
                title={lastSync !== 0 ? `${t('bim.query.last.sync')}: ${Utils.Date.formatDateTime(lastSync)}` : ''}
              >
                <Icon className={`${styles.SyncIcon} ${syncing.running ? 'syncing' : ''}`} icon="sync" />
                {t(syncing.running ? 'syncing' : 'sync')}{' '}
                {lastSync > 0 && selectedConnection !== 'BIMWarehouse' ? (
                  syncing.errorMessage ? (
                    <Icon
                      title={`${t('bim.query.last.sync')}: ${Utils.Date.formatDateTime(lastSync)}`}
                      className={`${styles.lastSyncDateIcon}`}
                      icon="calendar_month"
                    />
                  ) : (
                    Utils.Date.formatDateTime(lastSync)
                  )
                ) : (
                  ''
                )}
              </div>
              {syncing.errorMessage && (
                <>
                  <Icon title={syncing.errorMessage} className={styles.syncingInfoIcon} icon="info" />
                  <Icon
                    title={t('bim.query.database.syncing.clean.error')}
                    className={styles.syncingClearIcon}
                    icon="close"
                    onClick={clearSyncingError}
                  />
                </>
              )}
            </div>
            {selectedConnection !== 0 && (
              <BngSearch className={`${styles.SearchTable}`} onChange={(value) => setFilter(value)} />
            )}
            <div
              className={`${styles.ToJsonButton} ${disableButtons}`}
              onClick={
                disableButtons
                  ? undefined
                  : () => {
                      dispatch(
                        MODALS.open(BngLogDialog, {
                          log: JSON.stringify(dbSchemas, null, 2),
                          title: 'JSON',
                          footer: (
                            <div className={`${styles.LogDialogFooter}`}>
                              <BngButton
                                className={`${styles.SaveJsonButton}`}
                                onClick={() => {
                                  const exportName = connections.find((c) => c.id === selectedConnection);
                                  const dataStr =
                                    'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(dbSchemas));
                                  const el = document.createElement('a');
                                  el.setAttribute('href', dataStr);
                                  el.setAttribute('download', exportName + '.json');
                                  document.body.appendChild(el); // required for firefox
                                  el.click();
                                  el.remove();
                                }}
                              >
                                {t('save')}
                              </BngButton>
                            </div>
                          ),
                        })
                      );
                    }
              }
            >
              {'{JSON}'}
            </div>
          </div>
          <div className={`${styles.schemasWrapper}`}>
            {!_.isEmpty(selectedSchema) && (
              <RenderList
                list={filteredTables}
                fetchChildList={fetchChildList}
                handleSelection={handleSelection}
                filter={filter}
                schemaName={selectedSchema.name}
                parent={selectedSchema}
              />
            )}
          </div>
        </Accordion>
        {!isEditing && (
          <>
            <BngButton
              className={`${styles.NextStepButton}`}
              disabled={!stepIsValid}
              onClick={() => {
                if (stepIsValid) {
                  bimEventBus.emit(
                    RIGHT_MENU_TOGGLE_SUBMENU,
                    options.find((o) => o.key === BimQueryConfigurationMenuItem.KEY)
                  );
                }
              }}
            >
              {t('bim.query.connection.configuration.next.step')}
            </BngButton>
          </>
        )}
      </AccordionList>
    </>
  );
}
