import { Axios } from 'commonUtils';

class MobileMessageRequestApi {
  findResourceUsage = async (accountId, startDate, endDate) => {
    return await Axios.get(`/spr/mobileMessageRequests/usage`, {
      params: {
        accountId,
        startDate: startDate.toISOString(true),
        endDate: endDate.toISOString(true),
      },
    });
  };

  findRequests = async (accountId, page, size) => {
    const { data } = await Axios.get(`/spr/mobileMessageRequests`, {
      params: {
        accountId,
        page,
        size,
      },
    });

    return data;
  };

  exportToCsv = (accountId, startDate, endDate) => {
    const link = Axios.getUri({
      url: '/spr/mobileMessageRequests/usage/csv',
      params: {
        accountId,
        startDate: startDate.toISOString(true),
        endDate: endDate.toISOString(true),
      },
    });
    window.open(link, '_blank');
  };

  findWhatsQrCode = async () => {
    const { data } = await Axios.get(`/spr/mobileMessageRequests/whats/qrCode`);
    data.lastUpdated = moment(data.lastUpdated);
    return data;
  };

  findStatus = async () => {
    const { data } = await Axios.get(`/spr/mobileMessageRequests/status`);
    return data;
  };

  invalidateConnection = async () => {
    return await Axios.postData(`/spr/mobileMessageRequests/whats/invalidate`);
  };
}

export default MobileMessageRequestApi;
