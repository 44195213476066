import styles from './AdaAiChat.module.css';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FastField, Form, Formik } from 'formik';

import useTranslation from 'components/hooks/useTranslation';
import useBimContext from 'components/hooks/useBimContext';
import BngIconButton from 'components/bng/ui/BngIconButton';
import Icon from 'components/ui/common/Icon';
import { BngPanelFooter } from 'components/bng/ui/BngPanel';
import BngStepTutorialDialog from 'components/bng/ui/BngStepTutorial/BngStepTutorialDialog';
import { bngYup } from 'components/bng/form/yup/BngYup';
import CodeBlock from 'components/bng/adaAi/CodeBlock';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Utils from 'components/Utils';
import useAsyncEffect from 'components/hooks/useAsyncEffect';
import UiBlocker from 'components/bng/ui/UiBlocker';
import { sessionStore } from 'components/Storage';
import { BetaTag } from 'components/bng/ui/BngTag';
import OpTextConfirmation from 'components/ui/OpTextConfirmation';
import BngDropdown from 'components/bng/ui/BngDropdown';
import useAdaAiChatStore from 'components/bng/adaAi/useAdaAiChatStore';

const STORE_KEY = 'AdaAiChat';
const STORE_CHAT_HISTORY_KEY = `${STORE_KEY}:chatHistory`;
const STORE_CHAT_IDS_KEY = `${STORE_KEY}:chatIds`;

const USER_TYPE = {
  ASSISTANT: 'assistant',
  USER: 'user',
};

const WITH_METADATA = ['Assistant-Insights-Objects', 'Assistant-Insights-Dashboard'];
const ASSISTANT_BIM_QUERY = 'Assistant-BIMQuery-SQL';

const $ongoingChatRef = { current: null };

const MsgFormSchema = bngYup((yup) => {
  return yup.object({
    message: yup.string().required().min(1).max(4096).trim().default(''),
  });
});

function TypingDots() {
  const { t } = useTranslation();
  return (
    <div className={`TypingDots ${styles.TypingDots}`}>
      {t('ada.ai.chat.assistant.typing')}
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

function AdaAiChatMessage({
  invert = false,
  avatar = '',
  name = '',
  message = '',
  typing = false,
  isMaximized,
  previousMessage = '',
  metadata = {},
  isLatestMessage = false,
}) {
  const { t } = useTranslation();
  const context = useBimContext();
  const $feedbackId = useRef();
  const [clickedButton, setClickedButton] = useState();

  const handleFeedback = async (usefulAnswer) => {
    try {
      const feedback = await Api.AdaAi.sendFeedback({
        id: $feedbackId.current,
        question: previousMessage,
        answer: message,
        usefulAnswer,
        projectId: context.project.id,
        userId: context.user.id,
        objectName: metadata.dashboardTitle,
        props: {
          metadata,
        },
      });
      $feedbackId.current = feedback.id;
    } catch (e) {
      console.error('Error on handleFeedback()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  const feedbackActions = [
    {
      key: 'yes',
      label: t('yes'),
      icon: 'thumb_up',
      iconPosition: 'after',
      action: async () => {
        await handleFeedback(true);
      },
    },
    {
      key: 'no',
      label: t('no'),
      icon: 'thumb_down',
      iconPosition: 'before',
      action: async () => {
        await handleFeedback(false);
      },
    },
  ];

  const isCodeMessage = name === USER_TYPE.ASSISTANT;

  return (
    <div className={`AdaAiChatMessageWrapper ${styles.AdaAiChatMessageWrapper}`}>
      <div className={`AdaAiChatMessage ${invert ? styles.AdaAiChatMessageInverter : styles.AdaAiChatMessage}`}>
        <div className={`AdaAiChatMessageArrow ${styles.AdaAiChatMessageArrow}`}></div>
        <div className={`AdaAiChatMessageAvatar ${styles.AdaAiChatMessageAvatar}`}>
          <img src={avatar} alt="Avatar" />
        </div>
        <div
          className={`AdaAiChatMessageMessage ${
            isMaximized ? styles.AdaAiChatMessageMessage : styles.AdaAiChatMessageNotMaximized
          }`}
        >
          {typing ? (
            <TypingDots />
          ) : isCodeMessage ? (
            <CodeBlock message={message} />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: message }} />
          )}
        </div>
      </div>
      {!typing && !invert && isLatestMessage && (
        <div className={`AdaAiChatMessageFeedback ${styles.AdaAiChatMessageFeedback}`}>
          <span>{t('ada.ai.chat.feedback')}</span>
          <div style={{ display: 'flex' }}>
            {feedbackActions.map(({ label, icon, iconPosition, key, action }, idx) => (
              <button
                key={key}
                className={styles.FeedbackButtons}
                disabled={clickedButton === key}
                onClick={async () => {
                  setClickedButton(key);
                  await action();
                }}
              >
                {iconPosition === 'before' && <Icon icon={icon} />}
                {label}
                {iconPosition === 'after' && <Icon icon={icon} />}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

const findTranslated = (context, translatedItem = {}) => {
  const DEFAULT_LOCALE = 'pt_BR';

  const translations = translatedItem?.translations ?? {};
  return (
    translations[context?.user?.preferredLanguage] ?? translations[DEFAULT_LOCALE] ?? Object.values(translations)[0]
  );
};

function groupMessageInSequence(messages, buildUserDetails) {
  const groupedMessages = [];
  let currentUser = null;
  let currentMessageGroup = [];

  messages.forEach((message) => {
    if (_.isEmpty(message.user) || _.isEmpty(message.message)) {
      return;
    }

    let processedMessage = message.message;
    const regex = /([\s\S]*?)PostgreSQL\s+SELECT/;
    const match = processedMessage.match(regex);

    if (message.user === 'user' && match) {
      processedMessage = match[1].trim();
    }

    if (currentUser === null || message.user !== currentUser) {
      if (currentMessageGroup.length > 0) {
        groupedMessages.push({
          user: buildUserDetails(currentUser),
          message: [...new Set(currentMessageGroup)].join('\n\n'),
        });
      }

      currentUser = message.user;
      currentMessageGroup = [processedMessage];
    } else {
      if (!currentMessageGroup.includes(processedMessage)) {
        currentMessageGroup.push(processedMessage);
      }
    }
  });

  if (currentMessageGroup.length > 0) {
    groupedMessages.push({
      user: buildUserDetails(currentUser),
      message: [...new Set(currentMessageGroup)].join('\n\n'),
    });
  }

  return groupedMessages;
}

//  TODO vou deixar este todo aqui para pensar uma forma de integrar ao useAdaAiChatStore
const saveChatHistory = ({
  assistantData,
  metadataObject,
  assistantId,
  threadId,
  fileId,
  ongoingChats,
  dashboardPath,
}) => {
  let adaAiChatHistory = sessionStore.get(STORE_CHAT_HISTORY_KEY) || [];
  const path = metadataObject?.path || ongoingChats?.path || dashboardPath || '';
  const metadataObjectItemsIds = metadataObject?.dashboardItems
    ? metadataObject?.dashboardItems?.map((item) => item.id)
    : ongoingChats?.id
    ? ongoingChats?.id
    : [`${metadataObject?.id}`];
  const newItem = {
    assistantData: assistantData,
    path: path,
    id: metadataObjectItemsIds || [],
    assistantId: assistantId,
    threadId: threadId,
    fileId: fileId,
  };

  const duplicateIndex = adaAiChatHistory.findIndex(
    (item) =>
      item.assistantData.assistantKey === newItem.assistantData.assistantKey &&
      item.path === newItem.path &&
      _.isEqual(item.id, newItem.id)
  );

  if (duplicateIndex !== -1) {
    const existingItem = adaAiChatHistory[duplicateIndex];
    if (
      existingItem.assistantId !== newItem.assistantId ||
      existingItem.threadId !== newItem.threadId ||
      existingItem.fileId !== newItem.fileId
    ) {
      adaAiChatHistory[duplicateIndex] = newItem;
    }
  } else {
    adaAiChatHistory.push(newItem);
  }
  sessionStore.put(STORE_CHAT_HISTORY_KEY, adaAiChatHistory);
};

// TODO vou deixar este todo aqui para pensar uma forma de integrar ao useAdaAiChatStore
const retrieveChatHistory = async ({
  initialAssistantKey,
  metadataObject,
  dashboardPath,
  accountId,
  isClearChat = false,
}) => {
  const historyMessages = sessionStore.get(STORE_CHAT_HISTORY_KEY);
  if (_.isEmpty(historyMessages)) {
    return null;
  }

  let matchedItem = null;
  let matchedIndex = -1;

  if (metadataObject?.dashboardItems || metadataObject?.queryResult) {
    for (let i = 0; i < historyMessages.length; i++) {
      const historyMessage = historyMessages[i];
      const matchPath = historyMessage.path === (metadataObject.path || dashboardPath);
      const dashboardItemIds = metadataObject?.dashboardItems?.length
        ? metadataObject?.dashboardItems.map((item) => item.id)
        : [`${metadataObject?.id}`];
      const matchIds =
        historyMessage.id.length === dashboardItemIds.length &&
        dashboardItemIds.every((id) => historyMessage.id.includes(id));

      if (matchPath && matchIds) {
        matchedItem = historyMessage;
        matchedIndex = i;
        break;
      }
    }
  } else {
    for (let i = 0; i < historyMessages.length; i++) {
      const historyMessage = historyMessages[i];
      const isBimQuery =
        initialAssistantKey === ASSISTANT_BIM_QUERY &&
        historyMessage.assistantData.assistantKey === ASSISTANT_BIM_QUERY;
      const isDashboardMatch =
        historyMessage.assistantData.assistantKey === initialAssistantKey &&
        dashboardPath &&
        historyMessage.path === dashboardPath;

      if (isBimQuery || isDashboardMatch) {
        matchedItem = historyMessage;
        matchedIndex = i;
        break;
      }
    }
  }

  if (isClearChat) {
    return matchedIndex;
  }


  if (matchedItem) {
    const messageRecovered = await Api.AdaAi.fetchMessages({
      accountId,
      threadId: matchedItem.threadId,
    });

    return {
      messages: messageRecovered.messages.reverse(),
      assistantId: matchedItem.assistantId,
      threadId: matchedItem.threadId,
    };
  }

  return null;
};

// TODO vou deixar este todo aqui para pensar uma forma de integrar ao useAdaAiChatStore
const saveChatId = ({ metadataObject, values, metadataObjectBimQuery, dashboardPath }) => {
  const chatIds = sessionStore.get(STORE_CHAT_IDS_KEY) || [];
  const newChatIds =
    metadataObject?.dashboardItems?.map((item) => item.id) ||
    metadataObjectBimQuery?.dashboardItems?.map((item) => item.id) ||
    [`${metadataObject?.id}`];

  const newChat = {
    id: newChatIds,
    path: metadataObject?.path || dashboardPath || '',
    values: {
      message: values.message,
      user: USER_TYPE.USER,
    },
  };

  const duplicateIndex = chatIds.findIndex((item) => item.id === newChat.id);

  if (duplicateIndex !== -1) {
    chatIds[duplicateIndex] = newChat;
  } else {
    chatIds.push(newChat);
  }

  sessionStore.put(STORE_CHAT_IDS_KEY, chatIds);
};

// TODO vou deixar este todo aqui para pensar uma forma de integrar ao useAdaAiChatStore
const retrieveChatIds = ({ metadataObject, metadataObjectBimQuery }) => {
  const historyChatIds = sessionStore.get(STORE_CHAT_IDS_KEY);
  if (_.isEmpty(historyChatIds)) {
    return null;
  }

  const metadataObjectId = [`${metadataObject?.id}`];
  const metadataChats = metadataObject?.dashboardItems || metadataObjectBimQuery?.dashboardItems || metadataObject?.queryResult;
  if (metadataChats) {
    for (const historyChatId of historyChatIds) {
      let matchIds = false;
      if (!_.isEmpty(metadataObject?.dashboardItems) || !_.isEmpty(metadataObjectBimQuery?.dashboardItems)) {
        matchIds = metadataChats.some((dashboardItem) => historyChatId.id.includes(dashboardItem.id));
      }

      if (!_.isEmpty(metadataObject?.queryResult)) {
        matchIds = _.isEqual(historyChatId.id, metadataObjectId);
      }

      if (matchIds) {
        return historyChatId;
      }
    }
  }

  return null;
};

const assistantConfig = {
  titleSuffix: (metadataObject) => {
    if (!metadataObject) return '';

    return metadataObject?.dashboardItems?.length === 1
      ? ` - ${metadataObject.dashboardItems?.[0]?.name}`
      : metadataObject?.dashboardTitle
      ? ` - ${metadataObject.dashboardTitle}`
      : metadataObject?.caption
      ? ` - ${metadataObject.caption}`
      : '';
  },
};

function AdaAiChatTitleText({ metadataObject, isMinimized }) {
  const { t } = useTranslation();
  const $adaAiChatStore = useAdaAiChatStore();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const titleSuffix = assistantConfig.titleSuffix(metadataObject) || '';
  const fullTitle = `${t('ada.ai.dialog.title')}${titleSuffix}`;

  const showMinimizedChats = $adaAiChatStore.chats.length > 1 && isMinimized;
  return (
    <div className={`AdaAiChatTitleText ${styles.AdaAiChatTitleText}`} title={fullTitle}>
      {showMinimizedChats && (
        <BngDropdown
          popperClassName={`${styles.AdaAiChatDropdownPopper}`}
          options={$adaAiChatStore.chats.map((chat) => {
            const chatPath = chat.itemPath || chat.dashboardPath;
            return {
              className: `AdaAiChatDropdownOptions ${styles.AdaAiChatDropdownOptions}`,
              key: chat.id,
              label: (
                <div className={`AdaAiChatDropdownOptionsLabel ${styles.AdaAiChatDropdownOptionsLabel}`}>
                  <Icon icon={Utils.Object.getObjectIcon(chatPath)} />
                  <span className={`AdaAiChatDropdownOptionsLabelSpan ${styles.AdaAiChatDropdownOptionsLabelSpan}`}>
                    {chat.name}
                  </span>
                </div>
              ),
              onClick: () => $adaAiChatStore.restoreChat(chat),
            };
          })}
          className={`AdaAiChatDropdown ${styles.AdaAiChatDropdown}`}
          onOpen={() => setDropdownOpen(true)}
          onClose={() => setDropdownOpen(false)}
          customButton={({ openDropdown, closeDropdown }) => (
            <BngIconButton
              className={`AdaAiChatDropdownCustomButton ${styles.AdaAiChatDropdownCustomButton}`}
              icon=""
              onClick={(e) => {
                dropdownOpen ? closeDropdown(e) : openDropdown(e);
              }}
            />
          )}
          disabled={!showMinimizedChats}
        />
      )}

      {showMinimizedChats && (
        <span
          className={`AdaAiChatTitleTextCounterNumberOfOpenChats ${styles.AdaAiChatTitleTextCounterNumberOfOpenChats}`}
        >
          {$adaAiChatStore.chats.length}
        </span>
      )}
      <img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-icon.png')} alt="Ada AI Icon" />
      {fullTitle}
    </div>
  );
}

const deleteChatHistoryAtIndex = (index) => {
  let adaAiChatHistory = sessionStore.get(STORE_CHAT_HISTORY_KEY) || [];

  if (index < 0 || index > adaAiChatHistory.length) {
    return;
  }

  adaAiChatHistory.splice(index, 1);

  if (_.isEmpty(adaAiChatHistory)) {
    sessionStore.remove(STORE_CHAT_HISTORY_KEY);
  } else {
    sessionStore.put(STORE_CHAT_HISTORY_KEY, adaAiChatHistory);
  }
};

export const initializeAssistant = async (assistantKey, t) => {
  try {
    const assistants = await Api.AdaAi.findAll();
    return assistants.find((assistant) => assistant.assistantKey === assistantKey);
  } catch (e) {
    console.error('Error while trying to find assistantKey', e);
    UiMsg.ajaxError(t('ada.ai.chat.trying.to.find.assistance.error'), e);
  }
};

export default function AdaAiChat({
  messageLogPreview,
  assistantKey,
  metadataObjectBimQuery,
  dashboardPath,
  item,
  dashboardFilters,
  onClose,
  onModeChanged,
  dialogMode,
}) {
  const { t } = useTranslation();
  const context = useBimContext();
  const $adaAiChatStore = useAdaAiChatStore();

  const $formikRef = useRef();
  const $scrollToTheEndOfTheChatRef = useRef();
  const $tutorialApi = useRef();
  const $chatContainerForMessageRef = useRef();
  const $hasHandleMessageLogPreviewRef = useRef();

  const [messages, setMessages] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [isAdaTyping, setIsAdaTyping] = useState(false);
  const [assistantId, setAssistantId] = useState('');
  const [threadId, setThreadId] = useState('');
  const [assistantData, setAssistantData] = useState({});
  const [fileId, setFileId] = useState('');

  const isMinimized = dialogMode === 'minimized';
  const isMaximized = dialogMode === 'maximized';

  const [metadataObject, setMetadataObject] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const buildUserDetails = useCallback((user) => {
    if (user === USER_TYPE.ASSISTANT) {
      return {
        displayName: USER_TYPE.ASSISTANT,
        avatar: Api.buildUrl('/resources/images/ada-ai/ada.png'),
      };
    } else if (user === USER_TYPE.USER) {
      return context.user;
    }
    return user;
  }, []);

  const getTitleSuffix = (metadata, metadataObject, metadataObjectBimQuery) => {
    return (
      assistantConfig.titleSuffix(metadata) ||
      assistantConfig.titleSuffix(metadataObject) ||
      assistantConfig.titleSuffix(metadataObjectBimQuery) ||
      ''
    );
  };

  const buildChatData = useCallback(({ assistant, dashboardPath, item, metadata }) => {
    return {
      assistantKey: assistant?.assistantKey || assistantData?.assistantKey || assistant,
      dashboardPath: dashboardPath || '',
      itemPath: item?.path || '',
      name: getTitleSuffix(metadata, metadataObject, metadataObjectBimQuery),
    };
  }, []);

  const fetchHistoryChat = async (assistantKey, metadata) => {
    try {
      const history = await retrieveChatHistory({
        initialAssistantKey: assistantKey,
        metadataObject: metadata,
        dashboardPath,
        accountId: context.accountId,
      });

      if (history) {
        setThreadId(history.threadId);
        setAssistantId(history.assistantId);
        setFileId(history.fileId);

        const groupedMessages = groupMessageInSequence(history.messages, buildUserDetails);
        registerMessage(groupedMessages);
        return history;
      }
    } catch (e) {
      console.error('Error on retrieveChatHistory()', e);
      UiMsg.ajaxError(t('ada.ai.get.the.retrieve.messages.error'), e);
    }
  };

  useAsyncEffect({
    onMount: async () => {
      const assistant = await initializeAssistant(assistantKey, t);
      setAssistantData(assistant);
      let metadata = {};

      if (WITH_METADATA.includes(assistant?.assistantKey) && !_.isEmpty(dashboardPath)) {
        const fetchMetadata = async (assistant, dashboardPath, item, dashboardFilters) => {
          try {
            setIsLoading(true);
            return await Api.Metadata.exportMetadata({
              content: dashboardPath,
              filters: item?.filters || dashboardFilters,
              itemId: item?.id,
              queryResultLimit: assistant.props.queryResultLimit,
              params: {},
            });
          } catch (e) {
            console.error('Error while trying to get the metadata', e);
            UiMsg.ajaxError(t('ada.ai.get.the.metadata.error'), e);
            return null;
          } finally {
            setIsLoading(false);
          }
        };
        metadata = await fetchMetadata(assistant, dashboardPath, item, dashboardFilters);
        setMetadataObject(metadata);
      } else if (metadataObjectBimQuery) {
        setMetadataObject(metadataObjectBimQuery);
      }

      const history = await fetchHistoryChat(assistantKey, metadata);

      const chatData = buildChatData({ assistant, dashboardPath, item, metadata });

      await $adaAiChatStore.updateChat(chatData);

      const fetchOngoingChats = async (metadata, metadataObjectBimQuery) => {
        try {
          const ongoingChats = retrieveChatIds({ metadataObject: metadata, metadataObjectBimQuery });
          if (ongoingChats) {
            setCurrentStep(1);
            setIsAdaTyping(true);

            await onSendMessage(ongoingChats.values, assistant, history, ongoingChats);
          }
        } catch (e) {
          console.error('Error on AdaAiChat.retrieveChat()', e);
          UiMsg.ajaxError(t('ada.ai.retrieve.chats.ongoing.error'), e);
        }
      };

      if ($ongoingChatRef.current) {
        await fetchOngoingChats(metadata, metadataObjectBimQuery);
      }
    },
  });

  useEffect(() => {
    const handleMessageLogPreview = async () => {
      if ($hasHandleMessageLogPreviewRef.current) return;

      if ($adaAiChatStore.chats.length > 0 && messageLogPreview && !isMinimized) {
        $hasHandleMessageLogPreviewRef.current = true;
        const assistant = await initializeAssistant(assistantKey, t);
        await onSendMessage(messageLogPreview, assistant);
        $adaAiChatStore.clearMessageLogPreview(
          buildChatData({
            assistant: assistant?.assistantKey || assistantData?.assistantKey,
            dashboardPath: '',
            item: '',
            metadata: metadataObject,
          })
        );
      }
    };

    handleMessageLogPreview();
  }, [$adaAiChatStore.chats, messageLogPreview, isMinimized]);

  useEffect(() => {
    const handleScroll = async () => {
      await Utils.sleep(1000);
      $scrollToTheEndOfTheChatRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    handleScroll();
  }, [messageLogPreview, currentStep, messages, isAdaTyping, dialogMode]);

  useEffect(() => {
    $tutorialApi.current?.changeStep(currentStep);
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === 0 && (!_.isEmpty(messages) || !_.isEmpty(messageLogPreview))) {
      setCurrentStep(1);
    }
  }, [messages, messageLogPreview, currentStep]);

  useEffect(() => {
    const handleDashboardChange = async () => {
      if (isMinimized || !metadataObject) return;

      if ($adaAiChatStore.currentObjectPath !== dashboardPath && !_.isEmpty(dashboardPath)) {
        OpTextConfirmation({
          level: '',
          html: t('ada.ai.chat.button.dashboard.chat.op.text.confirmation.html'),
          confirmText: `
            <div>
              <img src="${Api.buildUrl('/resources/images/ada-ai/chat/open_new_chat.svg')}" alt="Chat Add" />
              ${t('ada.ai.chat.button.clear.chat.op.text.confirmation.confirmText')}
            </div>
          `,
          cancelText: `
            <div>
              <img src="${Api.buildUrl('/resources/images/ada-ai/chat/chat.svg')}" alt="Chat Add" />
              ${t('ada.ai.chat.button.clear.chat.op.text.confirmation.cancelText')}
            </div>
          `,
          onConfirm: async () => {
            onClose();

            // TODO isso aqui não é muito bom, não tinha sido implementado um evento no patch?
            //  enfim, nada grave também, da pra manter por enquanto
            //  Sim, tinha um evento, mas ele não estava funcionando corretamente, não estava pegando o path do dashboard atual,
            //  estava sempre pegando o path do dashboard que o chat estava aberto, por isso alterei
            const adaAiButton = document.getElementById('ada-ai-button');
            if (adaAiButton) {
              adaAiButton.click();
            }
          },
          target: $chatContainerForMessageRef.current,
          disableInput: true,
          customClass: {
            cancelButton: `${styles.OpTextConfirmation}`,
          },
        });
      }
    };

    handleDashboardChange();
  }, [$adaAiChatStore.chats, $adaAiChatStore.currentObjectPath, isMinimized]);

  const registerMessage = (groupedMessages) => {
    setMessages((messages) => [
      ...messages,
      ...groupedMessages.map((group) => ({
        message: group.message,
        user: group.user,
      })),
    ]);
  };

  const onSendMessage = async (values, assistant, history, ongoingChats) => {
    if (_.isEmpty(values.message)) {
      return;
    }

    // TODO vou deixar este todo aqui para pensar uma forma de integrar ao useAdaAiChatStore
    if (!ongoingChats) {
      saveChatId({ metadataObject, values, metadataObjectBimQuery, dashboardPath });
    }

    if (!history || messageLogPreview) {
      registerMessage([
        {
          message: values.message,
          user: buildUserDetails(USER_TYPE.USER),
        },
      ]);
    }

    $formikRef.current?.resetForm();

    try {
      setIsAdaTyping(true);

      const sendMessageToAssistant = async (values, assistant, history) => {
        try {
          const responseMessage = await Api.AdaAi.sendMessage({
            message: values.messageGpt || values.message,
            metadata: metadataObject,
            accountId: context.accountId,
            assistantId: assistantId || history?.assistantId,
            threadId: threadId || history?.threadId,
            userId: context.user.id,
            name: assistantData?.name || assistant?.name,
            description: assistantData?.description || assistant?.description,
            instructions: assistantData?.instruction || assistant?.instruction,
            model: assistantData?.model || assistant?.model,
            type: assistantData?.type || assistant?.type,
            temperature: assistantData?.temperature || assistant?.temperature,
            fileId: fileId,
          });


          const fetchAssistantMessageResult = async (responseMessage) => {
            try {
              let messageResult;
              for (let i = 0; i < 60; i++) {
                messageResult = await Api.AdaAi.fetchMessageResult({
                  accountId: context.accountId,
                  threadId: responseMessage.threadId,
                  runAssistantId: responseMessage.runAssistantId,
                });

                if (messageResult.finished) {
                  return messageResult.data;
                }
                await Utils.sleep(1000);
              }
              return null;
            } catch (e) {
              console.error('Error fetching assistant message result', e);
              throw e;
            }
          };

          const messageResult = await fetchAssistantMessageResult(responseMessage);
          if (!messageResult) {
            UiMsg.warn(t('ada.ai.chat.response.timeout.message.warning'));
            registerMessage([
              {
                message: t('ada.ai.chat.response.timeout.message'),
                user: buildUserDetails(USER_TYPE.ASSISTANT),
              },
            ]);
            return null;
          }

          return {
            responseMessage,
            messageResult,
          };
        } catch (e) {
          console.error('Error sending messages to assistant', e);
          throw e;
        }
      };

      // TODO vou deixar este todo aqui para pensar uma forma de integrar ao useAdaAiChatStore
      if (!ongoingChats) {
        $ongoingChatRef.current = sendMessageToAssistant(values, assistant, history);
      }

      const result = await $ongoingChatRef.current;
      if (result) {
        const groupedMessageResult = groupMessageInSequence([...result?.messageResult].reverse(),buildUserDetails);
        registerMessage(groupedMessageResult);
        setAssistantId(result?.responseMessage.assistantId);
        setThreadId(result?.responseMessage.threadId);
        setFileId(result?.responseMessage.fileId);

        saveChatHistory({
          assistantData: assistant || assistantData,
          metadataObject: metadataObject || metadataObjectBimQuery,
          assistantId: result?.responseMessage.assistantId,
          threadId: result?.responseMessage.threadId,
          fileId: result?.responseMessage.fileId,
          ongoingChats: ongoingChats,
          dashboardPath: dashboardPath,
        });
        $hasHandleMessageLogPreviewRef.current = null;
      }
    } catch (e) {
      console.error('Error on onSendMessage()', e);
      UiMsg.ajaxError(t('ada.ai.chat.send.message.to.ada.ai.service.error'), e);
      registerMessage([
        {
          message: t('ada.ai.chat.response.timeout.message'),
          user: buildUserDetails(USER_TYPE.ASSISTANT),
        },
      ]);
    } finally {
      setIsAdaTyping(false);
      sessionStore.remove(STORE_CHAT_IDS_KEY);
      $ongoingChatRef.current = null;
    }
  };

  const steps = useMemo(() => {
    const VALIDATION_ASSISTANT =
      assistantData?.assistantKey !== ASSISTANT_BIM_QUERY && (_.isEmpty(dashboardPath) || _.isNil(metadataObject));
    return [
      {
        title: () => (
          <div className={`AdaAiChat ${styles.AdaAiChat}`}>
            <AdaAiChatTitleText metadataObject={metadataObject} isMinimized={isMinimized} />
          </div>
        ),
        hideClose: false,
        render: ({ changeToNextStep }) => {
          return (
            <UiBlocker block={isLoading}>
              <div className={`StepOneWrapper ${styles.StepOneWrapper}`}>
                {assistantData?.props?.phrase?.map((translatedPhrase, index) => {
                  const phraseData = findTranslated(context, translatedPhrase);
                  return (
                    <div key={index}>
                      <img
                        src={Api.buildUrl('/resources/images/ada-ai/ada-ai-acc.png')}
                        alt="Ada Ai Dialog"
                        className={`AdaAiChatBodyImg ${styles.AdaAiChatBodyImg}`}
                      />
                      <h1>{t('ada.ai.dialog.body.title')}</h1>
                      <h2>{phraseData.introductory}</h2>
                      <h3>{phraseData.benefits}</h3>
                      {VALIDATION_ASSISTANT ? (
                        <div className={`AdaAiChatMessageInfo ${styles.AdaAiChatMessageInfo}`}>
                          <Icon icon="info" />
                          <h3 className={`AdaAiChatMessageInfoH3 ${styles.AdaAiChatMessageInfoH3}`}>
                            {context.msg.t('ada.ai.chat.when.is.no.there.metadata.in.the.dashboard')}
                          </h3>
                        </div>
                      ) : (
                        <h3 className={`Question ${styles.Question}`}>{phraseData.question}</h3>
                      )}
                    </div>
                  );
                })}
                <div className={`AdaAiChatMessageButton ${styles.AdaAiChatMessageButton}`}>
                  {assistantData?.props?.questions?.map((translatedQuestion, index) => {
                    if (VALIDATION_ASSISTANT) {
                      return null;
                    }
                    const questionData = findTranslated(context, translatedQuestion);
                    return (
                      <BngIconButton
                        key={index}
                        icon={<img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-idea.png')} alt="Ada Ai" />}
                        className={`AdaAiChatBodyButton ${styles.AdaAiChatBodyButton}`}
                        text={questionData.caption}
                        disabled={isAdaTyping}
                        onClick={() => {
                          changeToNextStep();
                          if (currentStep !== 0) {
                            return;
                          }
                          setCurrentStep(1);
                          onSendMessage({
                            message: questionData.question,
                            user: {
                              displayName: context.user.displayName,
                              avatar: Api.buildUrl(context.user.avatarLink),
                            },
                          });
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </UiBlocker>
          );
        },
        stepDisabled: false,
        nextStepDisabled: false,
        backStepDisabled: false,
      },
      {
        title: () => (
          <div className={`AdaAiChatTitle ${styles.AdaAiChatTitle}`}>
            <AdaAiChatTitleText metadataObject={metadataObject} isMinimized={isMinimized} />
          </div>
        ),
        hideClose: false,
        render: ({ changeToPreviousStep }) => {
          if (isMinimized) return;

          return (
            <div className={`AdaAiChatWrapper ${styles.AdaAiChatWrapper}`}>
              <div className={`AdaAiChatInfoWrapper ${styles.AdaAiChatInfoWrapper}`}>
                <BetaTag />
                <span className={`AdaAiChatInfoWrapperBetaTagSpan ${styles.AdaAiChatInfoWrapperBetaTagSpan}`}>
                  {t('ada.ai.chat.info.beta.tag')}
                </span>
              </div>
              <div className={`AdaAiChatHistoryWrapper ${styles.AdaAiChatHistoryWrapper}`}>
                {messages.map((message, idx) => {
                  return (
                    <AdaAiChatMessage
                      key={`Message-${idx}`}
                      name={message.user.displayName}
                      avatar={
                        message.user.avatarLink
                          ? Api.buildUrl(message.user.avatarLink)
                          : Api.buildUrl('/resources/images/ada-ai/ada.png')
                      }
                      invert={message.user.displayName !== USER_TYPE.ASSISTANT}
                      message={message.messageGpt || message.message}
                      isMaximized={isMaximized}
                      previousMessage={messages[idx - 1]?.messageGpt || messages[idx - 1]?.message}
                      metadata={metadataObject}
                      isLatestMessage={idx === messages.length - 1}
                    />
                  );
                })}
                {isAdaTyping && (
                  <AdaAiChatMessage
                    name={t('sql.ada.ai.query.assistant.displayName')}
                    avatar={Api.buildUrl('/resources/images/ada-ai/ada.png')}
                    invert={false}
                    typing={true}
                  />
                )}
                <div ref={$scrollToTheEndOfTheChatRef} />
              </div>
              <BngPanelFooter className={`BngPanelFooter ${styles.BngPanelFooter}`}>
                <Formik
                  initialValues={MsgFormSchema.default()}
                  onSubmit={onSendMessage}
                  innerRef={$formikRef}
                  validationSchema={MsgFormSchema}
                >
                  {({ values, setFieldValue }) => {
                    return (
                      <Form
                        className={isAdaTyping ? `FooterAdaAiChat ${styles.FooterAdaAiChat}` : ''}
                        autoComplete="off"
                        style={{ display: 'flex' }}
                      >
                        <FastField
                          className={`MessageInput ${styles.MessageInput}`}
                          name="message"
                          placeholder={t('your.answer')}
                          maxLength={4096}
                          style={{ maxHeight: 60, overflowY: 'hidden' }}
                          onKeyDown={async (e) => {
                            if (e.keyCode !== 13) {
                              return;
                            }

                            if (e.altKey) {
                              setFieldValue('message', values.message + '\r\n');
                            } else {
                              onSendMessage(values);
                              e.target.blur();
                            }
                          }}
                        />
                        <BngIconButton
                          icon={
                            <img src={Api.buildUrl('/resources/images/ada-ai/chat/clear_chat.svg')} alt="Chat Add" />
                          }
                          onClick={async () => {
                            OpTextConfirmation({
                              level: '',
                              html: t('ada.ai.chat.button.clear.chat.op.text.confirmation.html'),
                              confirmText: `
                                <div>
                                  <img src="${Api.buildUrl('/resources/images/ada-ai/chat/open_new_chat.svg')}" 
                                  alt="Chat Add" 
                                  />
                                  ${t('ada.ai.chat.button.clear.chat.op.text.confirmation.confirmText')}
                                </div>
                                `,
                              cancelText: `
                                <div>
                                  <img src="${Api.buildUrl('/resources/images/ada-ai/chat/chat.svg')}" alt="Chat Add" />
                                  ${t('ada.ai.chat.button.clear.chat.op.text.confirmation.cancelText')}
                                </div>
                                `,
                              onConfirm: async () => {
                                try {
                                  const chatIndex = await retrieveChatHistory({
                                    initialAssistantKey: assistantKey,
                                    metadataObject,
                                    dashboardPath,
                                    accountId: context.accountId,
                                    isClearChat: true,
                                  });

                                  deleteChatHistoryAtIndex(chatIndex);
                                  changeToPreviousStep();
                                  setMessages([]);
                                  setCurrentStep(0);
                                } catch (error) {
                                  console.error('Error deleting chat history by index:', error);
                                }
                              },
                              target: $chatContainerForMessageRef.current,
                              disableInput: true,
                              customClass: {
                                cancelButton: `${styles.OpTextConfirmation}`,
                              },
                            });
                          }}
                          title={t('ada.ai.chat.button.clear.chat')}
                        />
                        <BngIconButton className="Send" type="submit" icon="send" title={t('send.comment')} />
                      </Form>
                    );
                  }}
                </Formik>
              </BngPanelFooter>
            </div>
          );
        },
        stepDisabled: false,
        nextStepDisabled: false,
        backStepDisabled: false,
      },
    ];
  }, [messages, isAdaTyping, currentStep, assistantData, messageLogPreview, dialogMode, metadataObject, isLoading]);

  return (
    <BngStepTutorialDialog
      enableDotNavigation={false}
      renderDefaultFooter={false}
      steps={steps}
      newDialogLayout={true}
      className={styles.AdaAiChat}
      onClose={onClose}
      onApi={(api) => ($tutorialApi.current = api)}
      showMaximize={true}
      showMinimize={true}
      bodyRef={$chatContainerForMessageRef}
      onModeChanged={(mode) => onModeChanged(mode)}
      defaultMode={dialogMode}
      backdropClassName={`AdaAiModalBackdrop ${styles.AdaAiModalBackdrop}`}
      draggable={false}
    />
  );
}
