import useAdaAiChatStore from 'components/bng/adaAi/useAdaAiChatStore';
import AdaAiChat from 'components/bng/adaAi/AdaAiChat';

export default function AdaAiChatContainer() {
  const $store = useAdaAiChatStore();

  return (
    <>
      {$store.chats.map((chat) => (
        <AdaAiChat
          key={chat.id}
          {...chat}
          dialogMode={chat.dialogMode}
          onRestore={(chatToRestore) => $store.restoreChat(chatToRestore)}
          onClose={() => $store.closeChat(chat)}
          onModeChanged={(mode) => $store.setDialogMode(chat, mode)}
        />
      ))}
    </>
  );
}
