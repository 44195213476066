import './NotificationMessageDialog.css'

import React from "react";
import PropTypes from 'prop-types';

import ContextEnhancer from "components/ContextEnhancer";
import Dialog from "components/ui/Dialog";

class NotificationMessageDialog extends React.Component {

    static propTypes = {
        closeModal: PropTypes.func,
        notificationId: PropTypes.number
    };

    static defaultProps = {};

    state = {
        notificationmessage: '',
        notificationtitle: ''
    };


    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.findNotificationMessage(this.props.notificationId);
    }

    findNotificationMessage = async (id) => {
        const notmessage = await jQuery.getJSON(`/spr/notifications/${id}`);
        var val = notmessage.message.replace('<pre>', '')
        val = val.replace('</pre>', '');
        this.setState({notificationmessage: val});
        this.setState({notificationtitle: notmessage.title});
    }


    render() {
        return (
            <Dialog style={{width: 900}} onClose={this.props.closeModal} open={this.props.open} className="xlarge"
                    title={this.state.notificationtitle}>
                <div className="icePnlGrp" style={{
                    width: 972,
                    border: '2px solid #CCC',
                    height: 440,
                    overflow: 'auto',
                    backgroundColor: '#f5f5f5',
                    whiteSpace: 'pre-line'
                }}>
                    <pre>
                        {this.state.notificationmessage}
                    </pre>
                </div>
            </Dialog>
        );
    }


}

export default ContextEnhancer(NotificationMessageDialog);