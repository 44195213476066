import styles from './IpRestrictionLogsDialog.module.css';
import React, { useMemo, useState } from 'react';

import useTranslation from 'components/hooks/useTranslation';
import BngSearch from 'components/bng/ui/BngSearch';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngLogsDialog from 'components/bng/logs/BngLogsDialog';
import useFetchData from 'components/hooks/useFetchData';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Avatar from 'components/ui/Avatar';
import Utils from 'components/Utils';
import useBimContext from 'components/hooks/useBimContext';
import {
  filterLogRows,
  LogsFilterDropdown,
} from 'components/bng/pages/project/management/tabs/accessTimeRestriction/AccessTimeRestrictionLogsDialog';

export default function IpRestrictionLogsDialog({ closeModal = _.noop }) {
  const context = useBimContext();
  const { t } = useTranslation();
  const [filters, setFilters] = useState({ searchTerm: '', filterButton: {} });

  const {
    data: ipRestrictionLogs = [],
    isLoading,
    reload,
  } = useFetchData(async () => {
    try {
      return await Api.IpRestrictionRule.fetchLogs({ projectId: context.project.id });
    } catch (e) {
      UiMsg.ajaxError(null, e);
      throw e;
    }
  });

  const tableColumns = useMemo(() => {
    return [
      {
        label: t('user'),
        render: (row) => {
          const user = row.user;
          return (
            <div className={styles.avatarAndName}>
              <Avatar userId={row.user.id} className={styles.userAvatar} />
              <label>{Utils.Users.displayName(user)}</label>
            </div>
          );
        },
      },
      {
        label: t('platform.PLATFORM'),
        render: (row) => {
          return <label>{t(`platform.${row.platform}`)}</label>;
        },
      },
      {
        label: t('date'),
        render: (row) => {
          const [datePart, ...timePart] = Utils.Date.formatDateTime(row.date).split(' ');
          return <div>{`${datePart} ${t('atTime')} ${timePart.join(' ')}`}</div>;
        },
      },
    ];
  }, [ipRestrictionLogs]);

  // Set is used to get only unique results
  const users = useMemo(() => [...new Set(ipRestrictionLogs.map((log) => log.user))], [ipRestrictionLogs]);
  const filteredRows = useMemo(
    () => filterLogRows(ipRestrictionLogs, filters, users, true),
    [ipRestrictionLogs, filters],
  );

  return (
    <BngLogsDialog
      className={`IpRestrictionLogsDialog ${styles.IpRestrictionLogsDialog}`}
      closeModal={closeModal}
      title={t('logs')}
      isEmpty={_.isEmpty(ipRestrictionLogs)}
      loading={isLoading}
      rows={filteredRows}
      columns={tableColumns}
      headerButtons={
        <React.Fragment>
          <BngSearch
            className={`${styles.logsSearch}`}
            value={filters.searchTerm}
            onChange={(val) => {
              setFilters({ ...filters, searchTerm: val });
            }}
            title={t('search.object.name')}
            alwaysOpen
            inline
          />
          <BngIconButton icon={'refresh'} onClick={reload} />
          <LogsFilterDropdown
            alwaysOpen={false}
            users={users}
            onChange={(values) => setFilters({ ...filters, filterButton: values })}
            initialValues={filters.filterButton}
            showActions={false}
          />
        </React.Fragment>
      }
    />
  );
}
