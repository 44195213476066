import styles from './MetadataConfigurationDialog.module.css';

import React, { useMemo } from 'react';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';

import Dialog from 'components/ui/Dialog';
import useTranslation from 'components/hooks/useTranslation';
import BngRadio from 'components/bng/form/BngRadio';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';
import BngTextArea from 'components/bng/form/BngTextArea';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngButton from 'components/bng/ui/BngButton';
import bngYup from 'components/bng/form/yup/BngYup';

const ValidationSchema = {
  Regular: bngYup((yup) =>
    yup.object({
      functionality: yup.array(yup.string()).required().default([]),
      primary: yup.string().required(),
      description: yup.string().default(),
    })
  ),
  Measure: bngYup((yup) =>
    yup.object({
      relevance: yup.string().required().default(''),
      selectedLinks: yup
        .array(yup.string())
        .when('relevance', {
          is: (val) => ['primary', 'secondary'].some((r) => r === val),
          than: (schema) => schema.notRequired(),
          otherwise: (schema) => schema.required(),
        })
        .test({
          test(value) {
            const relevance = this.parent.relevance;
            return ['primary', 'secondary'].some((r) => r === relevance) ? true : !_.isEmpty(value);
          },
        })
        .default([]),
      description: yup.string().default(),
    })
  ),
  TimeDimension: bngYup((yup) =>
    yup.object({
      relevance: yup.string().required().default(''),
      description: yup.string().default(),
    })
  ),
  Url: bngYup((yup) =>
    yup.object({
      selectedRegular: yup.string().required().default(''),
      description: yup.string().default(),
    })
  ),
};

ValidationSchema.Georeference = ValidationSchema.Url;

function RegularDimensionContent() {
  const { t } = useTranslation();
  const { setFieldValue, values, setValues } = useFormikContext();

  const primaryFunctionality = useMemo(
    () =>
      ['filter', 'detailing', 'complement'].map((value) => ({
        label: t(`metadata.configuration.dialog.regular.primary.functionality.options.${value}`),
        value,
      })),
    []
  );

  return (
    <div className="RegularDimensionContent">
      <div className={`${styles.RegularFunctionalityTitle}`}>
        {t('metadata.configuration.dialog.regular.functionality')}
      </div>
      <div className={`${styles.RegularFunctionalityDescription}`}>
        {t('metadata.configuration.dialog.regular.functionality.description')}
      </div>
      <div className={`${styles.RegularPrimaryFunctionsOptionsWrapper}`}>
        {primaryFunctionality.map((option, idx) => {
          const checked = values.functionality?.some((sr) => sr === option.value) || false;
          let disabled = false;
          if (values.functionality) {
            disabled = values.functionality.length === 2 && !checked;
          }

          return (
            <div
              key={idx}
              className={`${styles.PrimaryFunctionalityOptionWrapper} ${!checked ? 'function-not-selected' : ''}`}
            >
              <BngCheckbox
                className={`${styles.PrimaryFunctionalityOption}`}
                label={option.label}
                onChange={() => {
                  const functionality = (values.functionality ?? []).slice();
                  const idx = functionality.indexOf(option.value);

                  let primary;
                  if (idx === -1) {
                    primary = functionality.length === 0 ? option.value : values.primary;
                    functionality.push(option.value);
                  } else {
                    functionality.splice(idx, 1);
                    primary =
                      values.primary === option.value
                        ? functionality.length === 1
                          ? functionality[0]
                          : ''
                        : values.primary;
                  }

                  setValues({
                    ...values,
                    functionality,
                    primary,
                  });
                }}
                checked={checked}
                field={{ value: checked }}
                disabled={disabled}
              />
              {checked && (
                <BngIconButton
                  className={`${styles.Star} ${values.primary === option.value ? 'primary' : ''}`}
                  onClick={() => {
                    setFieldValue('primary', option.value);
                  }}
                  icon="stars"
                />
              )}
            </div>
          );
        })}
        <ErrorMessage name="functionality">
          {(msg) => <div className={`${styles.ErrorMessage}`}>{msg}</div>}
        </ErrorMessage>
      </div>
    </div>
  );
}

function TimeDimensionContent() {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext();

  const relevanceOptions = useMemo(
    () =>
      ['primary', 'secondary'].map((value) => ({
        label: t(`metadata.configuration.dialog.measure.relevance.option.label.${value}`),
        value,
      })),
    []
  );

  return (
    <div className="TimeDimensionContent">
      <div className={`${styles.RelevanceTitle}`}>{t('metadata.configuration.dialog.measure.relevance')}</div>
      <div className={`${styles.RelevanceDescription}`}>
        {t('metadata.configuration.dialog.measure.relevance.description')}
      </div>
      <div className={`${styles.RelevanceOptionsWrapper}`}>
        {relevanceOptions.map((option, idx) => {
          const checked = values.relevance === option.value || false;
          return (
            <div key={idx} className={`${styles.OptionWrapper}`}>
              <BngRadio
                className={`${styles.RelevanceOptionsRadioButton}`}
                field={{ value: checked }}
                checked={checked}
                label={option.label}
                onChange={() => {
                  setFieldValue('relevance', option.value);
                }}
              />
            </div>
          );
        })}
      </div>
      <ErrorMessage name="relevance">{(msg) => <div className={`${styles.ErrorMessage}`}>{msg}</div>}</ErrorMessage>
    </div>
  );
}

function MeasureDimensionContent({ measuresOptions = [] }) {
  const { t } = useTranslation();
  const { setFieldValue, values, setValues } = useFormikContext();

  const relevanceOptions = useMemo(
    () =>
      ['key', 'primary', 'secondary', 'meta'].map((value) => ({
        label: t(`metadata.configuration.dialog.measure.relevance.option.label.${value}`),
        value,
      })),
    []
  );

  return (
    <div className="MeasureDimensionContent">
      <div className={`${styles.RelevanceTitle}`}>{t('metadata.configuration.dialog.measure.relevance')}</div>
      <div className={`${styles.RelevanceDescription}`}>
        {t('metadata.configuration.dialog.measure.relevance.description')}
      </div>
      <div className={`${styles.RelevanceOptionsWrapper}`}>
        {relevanceOptions.map((option, idx) => {
          const checked = values.relevance === option.value || false;
          return (
            <div key={idx} className={`${styles.OptionWrapper}`}>
              <BngRadio
                className={`${styles.RelevanceOptionsRadioButton}`}
                field={{ value: checked }}
                checked={checked}
                label={option.label}
                onChange={() => {
                  setValues({
                    ...values,
                    selectedLinks: [],
                    relevance: option.value,
                  });
                }}
              />
            </div>
          );
        })}
      </div>
      <ErrorMessage name="relevance">{(msg) => <div className={`${styles.ErrorMessage}`}>{msg}</div>}</ErrorMessage>
      {['key', 'meta']?.some((sr) => sr === values.relevance) && (
        <div className={`${styles.LinkWrapper}`}>
          <div className={`${styles.LinkTitle}`}>{t('metadata.configuration.dialog.measure.link')}</div>
          <div className={`${styles.LinkDescription}`}>
            {t('metadata.configuration.dialog.measure.link.description')}
          </div>
          <Field
            name="selectedLinks"
            component={BngSelectSearch}
            options={measuresOptions}
            field={{ value: values.selectedLinks }}
            multiple={values.relevance === 'key'}
            onChange={(value) => {
              if (value && !Array.isArray(value)) {
                value = [value];
              }
              return setFieldValue('selectedLinks', value);
            }}
          />
          <ErrorMessage name="selectedLinks">
            {(msg) => <div className={`${styles.ErrorMessage}`}>{msg}</div>}
          </ErrorMessage>
        </div>
      )}
    </div>
  );
}

function UrlGeoDimensionContent({ regularsOptions = [] }) {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext();
  return (
    <>
      <div className={`${styles.LinkTitle}`}>{t('metadata.configuration.dialog.measure.link')}</div>
      <div className={`${styles.LinkDescription}`}>{t('metadata.configuration.dialog.geo.link.description')}</div>
      <Field
        name="selectedRegular"
        component={BngSelectSearch}
        options={regularsOptions}
        field={{ value: values.selectedRegular }}
        onChange={(event) => {
          setFieldValue('selectedRegular', event);
        }}
      />
      <ErrorMessage name="selectedRegular">
        {(msg) => <div className={`${styles.ErrorMessage}`}>{msg}</div>}
      </ErrorMessage>
    </>
  );
}

const DimensionTypeComponent = {
  Regular: RegularDimensionContent,
  TimeDimension: TimeDimensionContent,
  Measure: MeasureDimensionContent,
  Url: UrlGeoDimensionContent,
  Georeference: UrlGeoDimensionContent,
};

export default function MetadataConfigurationDialog({
  closeModal = _.noop,
  onSave = _.noop,
  dimensionType = '',
  caption = '',
  measures = [],
  regulars = [],
  metadata = _.noop,
}) {
  const { t } = useTranslation();

  const validationSchema = ValidationSchema[dimensionType];

  const initialValues = useMemo(() => {
    const value = metadata();
    if (_.isEmpty(value) && validationSchema.hasOwnProperty('default')) {
      return validationSchema.default();
    }
    return value;
  }, []);

  const measuresOptions = useMemo(() => measures.map((m) => ({ value: m.fieldName, label: m.caption })), [measures]);
  const regularsOptions = useMemo(() => regulars.map((m) => ({ value: m.fieldName, label: m.caption })), [regulars]);

  const DimensionTypeContent = DimensionTypeComponent[dimensionType];

  return (
    <Dialog
      className={`${styles.Dialog}`}
      onClose={closeModal}
      title={`${t('metadata.configuration.dialog.title')} - ${caption}`}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSave(values);
          closeModal();
        }}
      >
        {({ submitForm }) => {
          return (
            <Form>
              <Dialog.Body className={`${styles.DialogBody}`}>
                <DimensionTypeContent
                  dimensionType={dimensionType}
                  measuresOptions={measuresOptions}
                  regularsOptions={regularsOptions}
                />
                <div className={`${styles.DescriptionWrapper}`}>
                  <div className={`${styles.DescriptionTitle}`}>
                    {t('metadata.configuration.dialog.measure.description.title')}
                  </div>
                  <Field name="description" component={BngTextArea} className={`${styles.DescriptionTextArea}`} />
                </div>
              </Dialog.Body>
              <Dialog.Footer className={`${styles.DialogFooter}`}>
                <BngButton className={`${styles.CancelButton}`} onClick={closeModal}>
                  {t('cancel')}
                </BngButton>
                <BngButton onClick={submitForm}>{t('save')}</BngButton>
              </Dialog.Footer>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
