import styles from './ProposalActivationInfoDialog.module.css';

import React from 'react';

import Dialog from 'components/ui/Dialog';
import Utils from 'components/Utils';
import { proposalUtils } from 'components/service/bng/AccountApi';
import { PROPOSAL_STATUS } from 'components/ui/accounts/tabs/AccProposalsTab';
import useTranslation from 'components/hooks/useTranslation';
import { getAppliedItemFromVoucher, Voucher } from 'components/service/bng/MarketplaceApi';

const ACTIVATION_PROPS = ['userName', 'userEmail', 'role', 'phone', 'ipAddress', 'date'];

const ProposalPropsList = ({ propsList = [], title = '' }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.infoDetails}`}>
      <span className={styles.infoTitle}>{`${title}:`}</span>
      {ACTIVATION_PROPS.map((prop, idx) => {
        let value = propsList[prop];
        if (prop === 'date') {
          value = Utils.Date.formatDateTime(value);
        }
        return (
          <div key={idx}>
            <span className={`mr-1`}>{t(`activationProps.${prop}`)}:</span>
            <b>{value ?? '-'}</b>
          </div>
        );
      })}
    </div>
  );
};

const VoucherInfo = ({ proposalPricing, voucher }) => {
  const { t } = useTranslation();

  const pricingType = proposalUtils.getProposalPricingType(proposalPricing);
  const voucherAppliedItems = getAppliedItemFromVoucher(voucher, pricingType, {
    calculatedPricing: proposalPricing.calculatedPricing,
    pricingId: proposalPricing.pricing.id,
    name: proposalUtils.translationsForProposalPricing(proposalPricing).name,
  });
  if (voucherAppliedItems.length === 0) return;
  return (
    <section className={styles.voucherSection}>
      <b className={styles.voucherTitle}>{t('voucher')}</b>
      {voucherAppliedItems.map((voucherItem, idx) => {
        const voucherPricing = proposalUtils.formatPricing(
          proposalPricing.calculatedPricing + voucherItem.applyOnValue(proposalPricing.calculatedPricing),
          proposalPricing.pricing.currency.symbol
        );
        return (
          <div className={styles.generalInfo} key={`voucher-item-${idx}`}>
            <div className={styles.infoItem}>
              <div className={`${styles.blueBall}`} />
              {t(`voucher.${voucherItem.type}`)}
              <b>{voucherItem.type === 'DISCOUNT' ? `: ${voucherItem.formattedValue}` : ''}</b>
            </div>
            <div className={styles.infoItem}>
              <div className={`${styles.blueBall}`} />
              {t('voucher.pricing')}:<b>{voucherPricing}</b>
            </div>
            <div className={styles.infoItem}>
              <div className={`${styles.blueBall}`} />
              {t('duration')}:<b>{t(voucherItem.duration)}</b>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default function ProposalActivationInfoDialog({
  requestProps = null,
  activationProps = null,
  removalProps = null,
  proposalPricing,
  account,
  closeModal = _.noop,
}) {
  const { t } = useTranslation();

  const itemName = proposalUtils.translationsForProposalPricing(proposalPricing).name;
  const status =
    proposalPricing.proposalStatus === 'ACCEPTED'
      ? PROPOSAL_STATUS[proposalPricing.status]
      : PROPOSAL_STATUS[proposalPricing.proposalStatus];
  const agreedPricing = proposalUtils.formatPricing(
    proposalPricing.calculatedPricing,
    proposalPricing.pricing.currency.symbol
  );
  const voucher = proposalPricing.proposalVoucher && new Voucher(proposalPricing.proposalVoucher);

  return (
    <Dialog className={`ProposalActivationInfoDialog large`} title={t('proposal.details')} onClose={closeModal}>
      <div className={styles.detailsWrapper}>
        {requestProps && requestProps.userName && (
          <ProposalPropsList propsList={requestProps} title={t('requested.by')} />
        )}

        {activationProps && activationProps.userName && (
          <ProposalPropsList
            propsList={activationProps}
            title={t(activationProps.type === 'ACCEPTED' ? 'activated.by' : 'rejected.by')}
          />
        )}

        {removalProps && removalProps.userName && (
          <ProposalPropsList propsList={removalProps} title={t('removed.by')} />
        )}
      </div>

      <div className={styles.generalInfo}>
        <div className={styles.infoItem}>
          <div className={`${styles.blueBall}`} />
          {`${t(`name`)}:`}
          <b>{itemName}</b>
        </div>
        <div className={styles.infoItem}>
          <div className={`${styles.blueBall}`} />
          {`${t(`agreed.pricing`)}:`}
          <b>{agreedPricing}</b>
        </div>
        <div className={styles.infoItem}>
          <div className={`${styles.blueBall}`} />
          {`${t(`account.name`)}:`}
          <b>{account.name}</b>
        </div>
        <div className={styles.infoItem}>
          <div className={`${styles.blueBall}`} />
          {`${t(`status`)}:`}
          <b>{t(status)}</b>
        </div>
      </div>
      {voucher && <VoucherInfo proposalPricing={proposalPricing} voucher={voucher} />}
    </Dialog>
  );
}
