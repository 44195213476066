import styles from './UserGroupListDialog.module.css';

import React, { useEffect, useState } from 'react';
import Dialog from 'components/ui/Dialog';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { BngTable } from 'components/bng/ui/BngTable';
import BngSearch from 'components/bng/ui/BngSearch';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import Icon from 'components/ui/common/Icon';
import OpConfirmation from 'components/ui/OpConfirmation';
import { MODALS } from 'components/ui/redux/Actions';
import UserGroupsDialog from 'components/bng/pages/admin/users/UserGroupsDialog';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import Utils from 'components/Utils';
import Avatar from 'components/ui/Avatar';
import LinkedObjects from 'components/ui/LinkedObjects';

export default function UserGroupListDialog({ closeModal = _.noop, beforeClose = _.noop }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [userGroups, setUserGroups] = useState([]);

  const visibleUserGroups = searchTerm
    ? userGroups.filter(
        (usrGrp) =>
          usrGrp.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          usrGrp.displayName?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : userGroups;

  const fetchData = async () => {
    setLoading(true);
    try {
      const groups = await Api.UserGroup.findAllUserGroups(context.project.id);
      groups.sort((o1, o2) => Utils.Strings.compareIgnoreCase(o1.name, o2.name));
      groups.forEach((group) => {
        group.users.sort((u1, u2) => Utils.Strings.compareIgnoreCase(Utils.Users.displayName(u1), Utils.Users.displayName(u2)));
      });
      setUserGroups(groups);
    } catch (e) {
      console.error('Error on UserGroupListDialog.fetchData', e);
      UiMsg.error('Error', context.msg.t('user.group.fetch.error'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const userGroupsColumns = [
    {
      label: context.msg.t('name'),
      colClassName: styles.nameColumn,
      rowClassName: styles.nameRow,
      render: (row) => row.name,
    },
    {
      label: context.msg.t('user.groups.display.users'),
      colClassName: styles.columnHeaderAccessPermissions,
      rowClassName: styles.accessPermissions,
      render: (item) => (
        <div className="div-information-group w-100">
          <span className="group-item-slide-name w-100">
            <LinkedObjects
              itemWidth={50}
              items={item.users || []}
              render={(user) => {
                return (
                  <div key={`USER-${user.id}`} className={styles.userAvatarOuter} title={Utils.Users.displayName(user)}>
                    <Avatar userId={user.id} className={`${styles.userAvatar}`} />
                  </div>
                );
              }}
            />
          </span>
        </div>
      ),
    },
    {
      label: context.msg.t('actions'),
      colClassName: styles.actionsColumn,
      render: (userGroup) => (
        <>
          <BngIconButton
            icon={'edit'}
            className="icon-filter-edit"
            title={context.msg.t('edit')}
            onClick={() => onEdit(userGroup)}
          />
          <BngIconButton
            icon="delete"
            className="icon-filter-remove"
            title={context.msg.t('remove')}
            onClick={() => {
              OpConfirmation({
                title: context.msg.t('attention'),
                message: context.msg.t('group.remove.confirmation'),
                onConfirm: () => onDelete(userGroup),
                msg: context.msg,
              });
            }}
          />
        </>
      ),
    },
  ];

  const onCreateUserGroup = async () => {
    dispatch(MODALS.open(UserGroupsDialog, { afterSubmit: fetchData }));
  };

  const onEdit = (row) => {
    dispatch(MODALS.open(UserGroupsDialog, { groupId: row.id, isEditing: true, afterSubmit: fetchData }));
  };

  const onDelete = async (row) => {
    setLoading(true);
    try {
      await Api.UserGroup.deleteUserGroup(row.id);
      UiMsg.ok(context.msg.t(`remove_success`, context.msg.t('group')));
      await fetchData();
    } catch (e) {
      console.error('Error on UserGroupListDialog.onDelete', e);
      UiMsg.error(context.msg.t('group.delete.error'), e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      id="UserGroupListDialog"
      className={`UserGroupListDialog ${styles.UserGroupListDialog} xlarge`}
      loading={loading}
      title={context.msg.t('user.groups')}
      onClose={async () => {
        closeModal();
        await beforeClose();
      }}
      contentFullWidth={true}
    >
      <div className={styles.headerDivider} />

      <div className={styles.dialogHeader}>
        <div className={`CreateButton ${styles.createButton}`} onClick={onCreateUserGroup}>
          <Icon icon="add_circle" />
          <div style={{ padding: '0 5px' }}>{context.msg.t('new.group')}</div>
        </div>
        <BngSearch alwaysOpen={false} className={`UserGroupSearchBar ${styles.searchBar}`} onChange={setSearchTerm} />
      </div>

      <div className={styles.userGroupTableWrapper}>
        <div className={styles.userGroupScroll}>
          <BngTable
            cols={userGroupsColumns}
            rows={visibleUserGroups}
            stickyHeader={true}
            showEmptyAlert={true}
            emptyAlertProps={{ message: context.msg.t('currently.no.user.group.available') }}
          />
        </div>
      </div>
    </Dialog>
  );
}