import React, {Component} from "react";
import PropTypes from 'prop-types';
import ContextEnhancer from "components/ContextEnhancer";
import {FastField, Field, Form, Formik} from "formik";
import Api from "components/Api";
import {BngField} from "components/bng/form/BngField";
import {bngYup} from "components/bng/form/yup/BngYup";
import UiMsg from "components/ui/UiMsg";
import WsBar from "components/ui/in-memory/WsBar";
import {DefaultDialogActions} from "components/ui/FormUtils";
import {connect} from "react-redux";
import 'components/ui/in-memory/GitHubForm.css'
import Button from "components/ui/Button";

const NewGitHubOriginSchema = bngYup(yup => {
    return yup.object().shape({
        name: yup.string().required().min(3).max(20).trim().default(''),
        token: yup.string().required().trim().default(''),
        owner: yup.string().required().default(''),
        repository: yup.string().required().default(''),
    });
});


class GitHubForm extends Component {


    static propTypes = {
        closeModal: PropTypes.func,
        projectId: PropTypes.number,
        inMemoryId: PropTypes.number,
        edit:PropTypes.bool,
        nameCube:PropTypes.string,
        onClose: PropTypes.func,
    };

    static defaultProps = {
        formValues: {},
        projectId:0,
        inMemoryId:0,
        edit:false,
        nameCube:'',
        onClose: _.noop,
    };

    state = {
        lockUI:false,
        tokenIsInvalid:true,
        colorValidate:'red',
        messageState: this.props.context.msg.t('git.hub.disconnected')
    }

    initialFormValues = NewGitHubOriginSchema.default();


   async componentDidMount() {
        if(this.props.edit){
            const gitHubValues = await Api.GitHub.getValuesWS(this.props.inMemoryId)
            this.buildConfigData(gitHubValues);
            this.validateParams(gitHubValues);
        }
    }


    buildConfigData = (gitHubValues) => {
        this.initialFormValues.name = gitHubValues.name;
        this.initialFormValues.token = gitHubValues.token;
        this.initialFormValues.owner = gitHubValues.owner;
        this.initialFormValues.repository = gitHubValues.repository;
    };


    save = async (values, actions) => {

        values.projectId = this.props.projectId;

        try {
            let isValid;
            if(this.props.nameCube === values.name){
                isValid = true;
            } else {
                isValid = await Api.GitHub.validateName(values);
            }
            if(isValid) {
                if(this.props.edit){
                     await Api.GitHub.editOrigin(this.props.inMemoryId,values);
                }else{
                     await Api.GitHub.saveOrigin(values);
                }
                UiMsg.ok(this.props.context.msg.t('git.hub.origin.saved'));
                Api.updateJsf();
                this.props.closeModal();
            }else {
                UiMsg.error(this.props.context.msg.t('git.hub.cube.name.already.in.use'));
            }
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
        }
    };

    validateParams = async (values) =>{
        this.setState({lockUI: true});
        let isValid;
        try {
            isValid = await Api.GitHub.validateToken(values.token,values.owner,values.repository);
        } catch (e) {
            isValid = false;
        }

        if(isValid){
            this.setState({tokenIsInvalid: false, colorValidate: 'green', messageState: this.props.context.msg.t('git.hub.connected')});
            UiMsg.ok(this.props.context.msg.t('git.hub.valid.params'));

        } else {
            this.invalidateParams();
            UiMsg.error(this.props.context.msg.t('git.hub.invalid.params'));
        }

        this.setState({lockUI: false});

    }

    invalidateParams= () => {
        this.setState({tokenIsInvalid: true, colorValidate: 'red',messageState: this.props.context.msg.t('git.hub.disconnected')});
    };


    render() {
        const icon = Api.baseUrl()+'/images/data/github-white.png';

        return (
            <Formik initialValues={this.initialFormValues}
                    validationSchema={NewGitHubOriginSchema}
                    onSubmit={this.save}>
                {({values}) => {
                    return (
                        <WsBar open={this.props.open}
                               title={this.props.context.msg.t('git.hub.dialog.title')}
                               onClose={() => {
                                   this.props.closeModal();
                                   this.props.onClose();
                               }}
                               help={this.props.context.msg.t('pipe.drive.title.help')}
                               href={this.props.context.msg.t('git.hub.documentation.link')}
                               icon={icon}
                               className={'GitHubForm'}>

                            <Form>
                                <div className="git-hub-painel">
                                    <div className="row-fluid painel">
                                        <div className="git-hub-field-container">
                                            <Field name="name"
                                                   placeholder={this.props.context.msg.t('git.hub.name')}
                                                   component={BngField}
                                                   required={true}/>
                                        </div>


                                        <div className="git-hub-field-container">
                                            <Field name="token"
                                                   placeholder={this.props.context.msg.t('git.hub.token')}
                                                   component={BngField}
                                                   customChange={this.invalidateParams}
                                                   required={true}/>

                                        </div>

                                        <div className="git-hub-field-container">
                                            <Field name="owner"
                                                   placeholder={this.props.context.msg.t('git.hub.owner')}
                                                   component={BngField}
                                                   customChange={this.invalidateParams}
                                                   required={true}/>
                                        </div>

                                        <div className="git-hub-field-container">
                                            <Field name="repository"
                                                   placeholder={this.props.context.msg.t('git.hub.repository')}
                                                   component={BngField}
                                                   customChange={this.invalidateParams}
                                                   required={true}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="git-hub-validate-params">
                                    <Button icon="icon-ok" id="btn-validade" onClick={() =>{this.validateParams(values)}} loading={this.state.lockUI} className="pull-left">
                                        {this.props.context.msg.t('git.hub.validate.params')}
                                    </Button>
                                    <div className ="is-connected">
                                        <span id="circle-validate" className="fa fa-circle-o" style={{color:this.state.colorValidate}}>
                                         </span> {this.state.messageState}
                                    </div>
                                </div>



                                <div className="git-hub-button-container">
                                    <DefaultDialogActions disabled={this.state.tokenIsInvalid} contentFullWidth={true} {...this.props}/>
                                </div>

                            </Form>

                        </WsBar>

                    );
                }}
            </Formik>
        );

    }

}

export default ContextEnhancer(connect() (GitHubForm));