import ReactDOM from 'react-dom/client';

export const renderReactComponent = (component, container) => {
  const reactRoot = container.__renderReactComponentRoot ?? ReactDOM.createRoot(container);
  container.__renderReactComponentRoot = reactRoot;

  const root = {
    unmount() {
      reactRoot.unmount();
      delete container.__renderReactComponentRoot;
    },
  };

  if (_.isFunction(component)) {
    component = component({ root, container });
  }

  reactRoot.render(component);

  return root;
};