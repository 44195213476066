import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

import Api from 'components/Api';
import Utils from 'components/Utils';
import ContextEnhancer from 'components/ContextEnhancer';
import Text from 'components/ui/dashboard/components/Text';
import IconText from 'components/ui/dashboard/components/IconText';
import BiSource from 'components/ui/map/BiSource';
import Kpi from 'components/ui/kpi/Kpi';
import BigTableRenderer from 'components/bng/pages/bigTable/BigTableRenderer';
import LoadingPlaceholder from 'components/ui/loading/LoadingPlaceholder';
import DashboardItemInformation from 'components/ui/dashboard/components/DashboardItemInformation';
import FilterService from 'components/filter/FilterService';
import HtmlComponent from 'components/ui/dashboard/components/HtmlComponent';
import BngErrorBoundary from 'components/bng/ui/BngErrorBoundary';
import BngAnalysisDrillDownBar, { extractMemberLastPart } from 'components/bng/analysis/BngAnalysisDrillDownBar';
import DashGridContext from 'components/ui/dashboard/components/DashGridContext';
import FilterUtils from 'components/filter/FilterUtils';
import UiMsg from 'components/ui/UiMsg';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import AnalysisECharts from 'components/bng/analysis/AnalysisECharts';
import BimEventBus from 'BimEventBus';
import { PUBLISHER_FULL_INTERACTION_EVENT } from 'components/service/bng/PublisherApi';
import { ObjectErrorPopup } from 'components/ui/dashboard/components/ObjectErrorPopup';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import { DashSkipOnResizeComponent } from 'components/ui/dashboard/DashShared';
import DangerouslySetHtmlContent from 'components/bng/ui/DangerouslySetHtmlContent';

window.RENDERABLE_PRELOAD_CACHE = {};

function RenderablePreloadLoadingPlaceholder({ path, viewType, width, height, ...props }) {
  return (
    <div className="RenderablePreloadLoadingPlaceholder free-style-first-dashboard-load fill-w fill-h">
      <LoadingPlaceholder path={path} viewType={viewType} width={width} height={height} {...props} />
    </div>
  );
}

const DYNAMIC_ALREADY_ALERTED = [];

const RENDER_FINISHED_EVENT = 'RenderablePreload:RENDER_FINISHED_EVENT';
export const RENDERABLE_PRELOAD_UPDATE_SIZE = 'RENDERABLE_PRELOAD_UPDATE_SIZE';

const buildNoContentPreloadResult = () => {
  return {
    html: '',
    preload: {
      status: 'NO_CONTENT',
    },
  };
};

const buildOkPreloadResult = () => {
  return {
    html: '',
    preload: {
      status: 'OK',
    },
  };
};

class RenderablePreload extends DashSkipOnResizeComponent {
  static propTypes = {
    id: PropTypes.string,
    path: PropTypes.string,
    viewType: PropTypes.string,
    filters: PropTypes.any,
    dynamic: PropTypes.bool,
    order: PropTypes.number,
    additionalProps: PropTypes.object,
    resizeContent: PropTypes.bool,
    align: PropTypes.string,
    objectLastUpdate: PropTypes.any,
    renderQueue: PropTypes.any,
    renderCallback: PropTypes.func,
    borderType: PropTypes.string,
    style: PropTypes.object,
    inContainer: PropTypes.bool,
    position: PropTypes.object,
    changes: PropTypes.array,
    containerStyles: PropTypes.object,
  };

  static defaultProps = {
    path: '',
    viewType: '',
    filters: [],
    order: 1,
    dynamic: false,
    additionalProps: {},
    resizeContent: false,
    mobile: false,
    dashboardPath: '',
    renderCallback: _.noop,
    borderType: 'SIMPLE',
    style: {
      allowMargin: false,
      allowTheme: false,
      allowItemTransparency: false,
    },
    inContainer: false,
    changes: [],
  };

  state = {
    reloadRequestTime: 0,
    visible: false,
    result: null,
    loading: true,
    invalidFilter: false,
  };

  componentDidMount() {
    this.verifyInvalidFilters(true);
    this.__eventListenersToUnsubscribe = [
      BimEventBus.on(RENDERABLE_PRELOAD_UPDATE_SIZE, ({ id }) => {
        if (this.props.id !== id) {
          return;
        }
        this.renderElement();
      }),
      BimEventBus.on(RENDER_FINISHED_EVENT, ({ renderItem, result }) => {
        if (renderItem.id !== this.props.id) {
          return;
        }
        this.updatePreloadResult(result);
      }),
    ];
  }

  componentWillUnmount() {
    this.__eventListenersToUnsubscribe?.forEach((el) => el());
  }

  verifyInvalidFilters(alert = false) {
    if (!this.props.viewType || this.props.isText) {
      return;
    }

    const currentDate = new Date();

    const filtersIsArray = _.isArray(this.props.filters);
    const containSomeInvalidDateFilter = filtersIsArray
      ? this.props.filters.some((f) => f.members.some((m) => !FilterUtils.isPeriodicityValidOnDate(m, currentDate)))
      : false;
    const dynamicTimeFilterConflictFilterId = this.props.additionalProps?.dynamicTimeFilterConflictFilterId;
    let invalidPeriodicityIsOverridden = false;
    if (filtersIsArray && dynamicTimeFilterConflictFilterId) {
      const match = this.props.filters.find((f) => f.id === dynamicTimeFilterConflictFilterId);
      invalidPeriodicityIsOverridden = match && match.members.length > 0;
    }

    const periodicity = this.props.additionalProps?.dynamicTimeFilter?.periodicity;
    const invalidPeriodicity =
      !FilterUtils.isPeriodicityValidOnDate(periodicity, currentDate) && !invalidPeriodicityIsOverridden;

    if (
      alert &&
      !containSomeInvalidDateFilter &&
      invalidPeriodicity &&
      !DYNAMIC_ALREADY_ALERTED.includes(this.props.path)
    ) {
      DYNAMIC_ALREADY_ALERTED.push(this.props.path);
      UiMsg.warn(
        `${this.props.context.msg.t('attention')}!`,
        this.props.context.msg.t('invalid.date.for.periodicity.alert', [
          this.props.context.msg.t(periodicity),
          this.props.caption,
        ])
      );
      setTimeout(() => {
        const idx = DYNAMIC_ALREADY_ALERTED.indexOf(this.props.path);
        if (idx !== -1) {
          DYNAMIC_ALREADY_ALERTED.splice(idx, 1);
        }
      }, 1000);
    }

    const invalidFilter = containSomeInvalidDateFilter || invalidPeriodicity;

    if (this.state.invalidFilter !== invalidFilter) {
      this.setState({
        invalidFilter,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let renderElement = false;
    if (!_.isEqual(prevProps, this.props)) {
      if (!_.isEqual(this.props.filters, prevProps.filters)) {
        this.verifyInvalidFilters(true);
        renderElement = true;
      }
      renderElement =
        renderElement ||
        this.props.path !== prevProps.path ||
        this.props.dynamic !== prevProps.dynamic ||
        this.props.resizeContent !== prevProps.resizeContent ||
        this.props.viewType !== prevProps.viewType ||
        !_.isEqual(this.props.additionalProps, prevProps.additionalProps);
    }

    if (
      !renderElement &&
      this.state.visible &&
      (this.state.loading ||
        !this.state.result ||
        (!this.state.invalidFilter && this.state.invalidFilter !== prevState.invalidFilter))
    ) {
      renderElement = true;
    }

    if (!renderElement) {
      return;
    }

    this.renderElement();
  }

  renderElement = (reload = false) => {
    const { visible, reloadRequestTime, invalidFilter } = this.state;
    const { width, height } = this.props.position;

    if (!width || !height || !visible) {
      return;
    }

    this.setState({
      result: null,
      loading: true,
      reloadRequestTime: reload ? Date.now() : reloadRequestTime,
    });

    const { id, path = '', viewType, isText } = this.props;

    if (!id || !viewType) {
      const result = {
        html: '',
        preload: {
          status: 'NOT_FOUND',
        },
      };
      this.updatePreloadResult(result);
      return;
    }

    if (!isText && invalidFilter) {
      this.updatePreloadResult(buildNoContentPreloadResult());
      return;
    }

    if (
      isText ||
      Utils.Object.isKpi(path) ||
      Utils.Object.isNewMap(path) ||
      Utils.Object.isBigTable(path) ||
      (Utils.Object.isAnalysis(path) && viewType !== 'html')
    ) {
      this.updatePreloadResult(buildOkPreloadResult());
      return;
    }

    return this.requestRenderForItem({ width, height, reload });
  };

  requestRenderForItem = (
    { width, height, reload, drillState, changes } = {
      width: 100,
      height: 100,
      reload: false,
      drillState: { drills: [] },
    }
  ) => {
    return new Promise((resolvePromise) => {
      const {
        id,
        filters,
        dynamic,
        viewType,
        resizeContent,
        objectLastUpdate,
        align,
        dashboardPath,
        highlight,
        style,
        path,
        originalPath,
        containerStyles,
      } = this.props;

      changes = (_.isNil(changes) ? this.props.changes : changes) ?? [];

      const ignoreSize = viewType === 'html' && Utils.Object.isAnalysis(path);

      const cacheInfo = {
        path,
        width: ignoreSize ? 100 : width,
        height: ignoreSize ? 100 : height,
        filters,
        dynamic,
        viewType,
        resizeContent,
        objectLastUpdate,
        align,
        highlight,
        restrictFilters: this.props.restrictFilters,
        allowTheme: style.allowTheme,
        allowMargin: style.allowMargin,
        allowContainerMargin: style.allowContainerMargin,
        margin: style.margin,
        containerMargin: style.containerMargin,
        backgroundTheme: style.backgroundTheme,
        highlightColor: style.highlightBoxColor,
        containerBackgroundColor: containerStyles?.customStyle
          ? containerStyles.style.backgroundColor
          : style.containerStyle.backgroundColor,
        drillState,
        changes: ignoreSize ? changes.filter((c) => c.type !== 'LAYOUT') : changes,
        dashKey: useDashboardPageCtx.getState().dashKey,
      };

      const renderItem = {
        id,
        date: new Date(),
        finished: false,
        cacheInfo,
      };

      renderItem.render = async () => {
        let result = window.RENDERABLE_PRELOAD_CACHE[id];

        if (!result || !_.isEqual(result.cacheInfo, cacheInfo)) {
          try {
            result = await Api.Dash.renderItemMobile(id, {
              width,
              height,
              dashboardPath,
              ...FilterService.createFilterParam(filters || [], true),
              drillState,
              sourceType: viewType,
              itemToRenderPath: path !== originalPath ? path : null,
              changes,
              dashKey: cacheInfo.dashKey,
              currentDashBreakpoint: containerStyles?.breakpoint,
            });
          } catch (e) {
            console.error('error render dashboard element...', e);
            result = { preload: { status: 'ERROR', message: e.message } };
          }
          renderItem.finished = true;
          if (result.preload.status === 'OK') {
            result.cacheInfo = cacheInfo;
            window.RENDERABLE_PRELOAD_CACHE[id] = result;
          }
        }

        BimEventBus.emit(RENDER_FINISHED_EVENT, {
          renderItem,
          result,
        });
        resolvePromise(result);
      };

      this.props.renderQueue.add(renderItem);
    });
  };

  debouncedRequestRenderForItem = _.debounce(this.requestRenderForItem, 300);

  updatePreloadResult = (result) => {
    window.RENDERABLE_PRELOAD_CACHE[this.props.id] = result;
    const mdx = result?.mdx;
    useDashboardPageCtx.getState().updateMdx(this.props.id, mdx ? { mdx } : null);
    this.setState({ result, loading: false }, () => {
      this.props.renderCallback({ result });
    });
  };

  showError = () => {
    ObjectErrorPopup({ message: this.state.result.preload.message });
  };

  toggleVisibility = (visible) => {
    if (this.state.visible) {
      return;
    }

    if (!visible) {
      this.props.renderCallback();
      return;
    }

    const sizes = this.props.position;

    const isVisible = !isNaN(sizes.width) && !isNaN(sizes.height);
    this.setState({ visible: isVisible });
  };

  getDashMargin = () => {
    const { style } = this.props;
    if (style.allowTheme && style.allowMargin) {
      return style.margin;
    }
    return 0;
  };

  render() {
    const {
      id,
      path,
      viewType,
      order,
      dynamic,
      isText,
      itemProps,
      additionalProps,
      resizeContent,
      filters,
      style,
      highlight,
      align,
      dashItemId,
      mobile,
      isPresentation,
      dashboardPath,
      inContainer,
      containerStyles,
    } = this.props;

    const { result, loading, visible, reloadRequestTime, invalidFilter } = this.state;

    let { width, height } = this.props.position;

    const itemMargin = this.getDashMargin() * 2;
    width -= itemMargin;
    height -= itemMargin;

    let content = '';
    if (loading || (result && result.cancelRequest)) {
      content = <RenderablePreloadLoadingPlaceholder path={path} viewType={viewType} width={width} height={height} />;
    } else if (invalidFilter || result?.preload?.status === 'NO_CONTENT') {
      content = <DashboardItemInformation path={path} viewType={viewType} width={width} height={height} />;
    } else if (result) {
      switch (result.preload.status) {
        case 'OK':
          const Comp = this.findComponent(path, viewType, dynamic, isText);
          content = (
            <div style={{ height: height, width: width }}>
              <BngErrorBoundary
                path={path}
                height={height}
                message={this.props.context.msg.t('dashboard.item.error.message')}
                width={width}
                errorComponent={DashboardItemInformation}
              >
                <Comp
                  key={`${id}-${path}`}
                  id={id}
                  path={path}
                  filters={filters}
                  order={order}
                  height={height}
                  width={width}
                  html={result.html}
                  dashboardStyle={style}
                  highlight={highlight}
                  align={align}
                  itemProps={itemProps}
                  additionalProps={additionalProps}
                  resizeContent={resizeContent}
                  dashboardPath={dashboardPath}
                  showErrorDialog={this.showError}
                  updatePreloadResult={this.updatePreloadResult}
                  dashItemId={dashItemId}
                  mobile={mobile}
                  isPresentation={isPresentation}
                  inContainer={inContainer}
                  backgroundTheme={style.backgroundTheme}
                  requestRenderForItem={this.debouncedRequestRenderForItem}
                  reloadRequestTime={reloadRequestTime}
                  result={result}
                  viewType={viewType}
                  containerStyles={containerStyles}
                />
              </BngErrorBoundary>
            </div>
          );
          break;
        case 'BAD_QUERY':
          content = (
            <DashboardItemInformation
              path={path}
              viewType={viewType}
              width={width}
              height={height}
              message={this.props.context.msg.t('xmla_query_error')}
            />
          );
          break;
        case 'NOT_FOUND':
          const containMsg = !_.isEmpty(this.state.result?.preload?.message);
          content = (
            <DashboardItemInformation
              path={path}
              viewType={viewType}
              width={width}
              height={height}
              showErrorDialog={containMsg ? this.showError : undefined}
              reload={containMsg ? () => this.renderElement(true) : undefined}
              message={this.props.context.msg.t(containMsg ? 'dashboard.item.error.message' : 'object.not.found')}
            />
          );
          break;
        case 'MISS_DASHBOARD':
          content = (
            <RenderablePreloadLoadingPlaceholder path={path} viewType={viewType} width={width} height={height} />
          );
          break;
        case 'STRUCTURE_NOT_LOADED':
          content = (
            <DashboardItemInformation
              path={path}
              message={this.props.context.msg.t('dashboard.item.not.loaded.message')}
              showErrorDialog={null}
              width={width}
              height={height}
              snackbarType="not-loaded"
              snackbarIcon="cached"
            />
          );
          break;
      }
    }

    if (!content) {
      content = (
        <div style={{ height: '100%' }}>
          <div className="fill-w fill-h free-style-first-dashboard-load">
            {result && result.preload.status !== 'OK' && !result.cancelRequest && (
              <DashboardItemInformation
                path={path}
                viewType={viewType}
                width={width}
                height={height}
                showErrorDialog={this.showError}
                reload={() => this.renderElement(true)}
                message={this.props.context.msg.t(
                  result.preload.status === 'ERROR' ? 'dashboard.item.error.message' : 'dashboard.item.timeout.message'
                )}
                errorTrace={result.preload.message}
              />
            )}
          </div>
        </div>
      );
    }

    return (
      <VisibilitySensor
        onChange={this.toggleVisibility}
        offset={{ top: 10, bottom: 10 }}
        active={!visible}
        partialVisibility
        delayedCall
      >
        <div
          id={`dashbox-${id}`}
          className={`RenderablePreload ${visible ? 'ExportAsync' : ''} ${
            visible && !loading && result ? 'ExportAsync-ready' : ''
          }`}
          style={{ width, height }}
        >
          {content}
        </div>
      </VisibilitySensor>
    );
  }

  findComponent(path, viewType, dynamic = false, isText = false) {
    if (isText) {
      switch (viewType) {
        case 'label': {
          return LabelRender;
        }
        case 'icon': {
          return IconRender;
        }
        case 'imageContent': {
          return ImageRender;
        }
        case 'text':
        case 'textNew': {
          return HtmlRender;
        }
      }
    }
    const extension = Utils.Object.getObjectType(path);
    switch (extension) {
      case 'analysis': {
        if (viewType === 'html') {
          if (dynamic) {
            return AnalysisDynamicTableRender;
          }
          return AnalysisTableRender;
        }
        return AnalysisChartRender;
      }
      case 'newmap': {
        return MapRender;
      }
      case 'kpi': {
        return NewKpiRender;
      }
      case 'bigtable': {
        return BigTableRender;
      }
    }
    return ComponentNotFound;
  }
}

const ComponentNotFound = (props) => {
  useEffect(() => {
    console.log('Component not found for props:', props);
  }, []);
  return <></>;
};

const AnalysisHeader = ({
  showTitle = false,
  title = '',
  showDescription = false,
  description = '',
  showLineBreak = false,
  titleFontSize = 11,
  titleTextAlign = 'center',
  titleColor = '#333333',
  titleFontStyle = '',
}) => {
  const style = {
    margin: '5px 0 2px 0',
    fontSize: `${titleFontSize}px`,
    textAlign: titleTextAlign,
    color: titleColor,
  };

  if (titleFontStyle === 'font-weight: bold;') {
    style.fontWeight = 'bold';
  } else if (titleFontStyle === 'font-style: italic;') {
    style.fontStyle = 'italic';
  } else if (titleFontStyle === 'font-weight: bold; font-style: italic;') {
    style.fontWeight = 'bold';
    style.fontStyle = 'italic';
  }

  const descriptionStyle = { ...style, fontSize: `${titleFontSize - 4}px` };
  return (
    <div className="titleItemDash">
      {showTitle && <div style={style}>{title}</div>}

      {showDescription && <div style={descriptionStyle}>{description}</div>}
      {showLineBreak && <hr style={{ margin: '2px' }} />}
    </div>
  );
};

const AnalysisDynamicTableRender = ({
  id,
  order,
  html,
  updatePreloadResult,
  additionalProps,
  height,
  resizeContent,
  path,
  viewType,
  width,
}) => {
  const { editMode } = useContext(DashGridContext);
  const hugeHtml = isHugeHtml(editMode, html);

  let updating = false;
  return (
    <ContentSubWrapper
      height={height}
      resizeContent={resizeContent}
      className="AnalysisDynamicTableRender"
      childRenderClassName={additionalProps.fixedHeader ? '' : 'analysisScrollHorizontalFix'}
      slowElementPlaceholder={
        hugeHtml ? (
          <RenderablePreloadLoadingPlaceholder path={path} viewType={viewType} width={width} height={height} />
        ) : undefined
      }
    >
      <AnalysisHeader {...additionalProps} />
      <AnalysisWrapper id={id} order={order} {...additionalProps}>
        <div
          className="mdx-table-container AnalysisDynamicTableRender"
          ref={(ref) => {
            if (!ref) return;

            const $el = j(ref);
            $el.html(html);
            $el.find('input').removeAttr('onclick');

            const findScrollDiv = () => ref.querySelector('.clusterize-scroll');
            const restoreScroll = !!findScrollDiv();
            let scrollYPosition = 0;

            if (ref.__updateTable) {
              return;
            }

            ref.__updateTable = async (event) => {
              event.preventDefault();
              event.stopPropagation();

              if (updating) return;

              const $container = $el.parents('.item-content-container');
              $container.append(`<div class='overlay-container'><div class='overlay'></div> <LoadingPulse/> </div>`);

              try {
                updating = true;
                if (restoreScroll) {
                  scrollYPosition = findScrollDiv().scrollTop;
                }
                const result = await Api.Dash.updateTable(id, { [event.target.name]: '', content: path });
                $el.off('click', 'input', ref.__updateTable);
                delete ref.__updateTable;
                updatePreloadResult(result);

                BimEventBus.emit(PUBLISHER_FULL_INTERACTION_EVENT, {
                  source: 'AnalysisDynamicTable',
                });
              } finally {
                $container.find('.overlay-container').remove();
                if (restoreScroll) {
                  findScrollDiv().scrollTop += scrollYPosition;
                }
                updating = false;
              }
            };
            $el.on('click', 'input', ref.__updateTable);
          }}
        ></div>
      </AnalysisWrapper>
    </ContentSubWrapper>
  );
};

function AnalysisTableRender({
  id,
  order,
  html,
  height,
  additionalProps,
  resizeContent,
  path,
  viewType = 'html',
  width,
}) {
  const { editMode } = useContext(DashGridContext);
  const hugeHtml = isHugeHtml(editMode, html);
  return (
    <ContentSubWrapper
      height={height}
      resizeContent={resizeContent}
      className="AnalysisTableRender"
      childRenderClassName={additionalProps.fixedHeader ? '' : 'analysisScrollHorizontalFix'}
      slowElementPlaceholder={
        hugeHtml ? (
          <RenderablePreloadLoadingPlaceholder path={path} viewType={viewType} width={width} height={height} />
        ) : undefined
      }
    >
      <AnalysisHeader {...additionalProps} />
      <AnalysisWrapper id={id} order={order} {...additionalProps}>
        <DangerouslySetHtmlContent className="mdx-table-container AnalysisTableRender" html={html} />
      </AnalysisWrapper>
    </ContentSubWrapper>
  );
}

const isHugeHtml = (editMode = false, html = '') => {
  return editMode && (html?.length ?? 0) > 32768;
};

const AnalysisChartRender = ({
  id,
  path,
  viewType,
  filters,
  width,
  height,
  inContainer,
  backgroundTheme,
  additionalProps,
  requestRenderForItem,
  reloadRequestTime,
  highlight,
  dashboardStyle,
  result,
  dashboardPath,
  containerStyles,
  updatePreloadResult,
}) => {
  const dashGridCtx = useContext(DashGridContext);
  const $dashPageCtx = useDashboardPageCtx.cached((state) =>
    _.pick(state, ['drillStates', 'updateDrillState', 'filterChangeHandler', 'updateMdx', 'updateDrillResponse'])
  );
  const [loading, setLoading] = useState(false);
  const [chartAreaRef, setChartAreaRef] = useState();
  const [currentDrillResponse, setCurrentDrillResponse] = useState();

  const htmlIsEmpty = !result?.html;

  const drillState =
    $dashPageCtx.drillStates[id]?.drillState ??
    result?.additionalProps?.drillState ??
    additionalProps.drillData?.drillState;

  const drillResponse =
    currentDrillResponse ?? additionalProps.drillData?.drillResponse ?? result?.additionalProps?.drillResponse;

  const chartWidth = Math.min(chartAreaRef?.clientWidth ?? 99999, width);
  const chartHeight = Math.min(chartAreaRef?.clientHeight ?? 99999, height);
  const highlightColor = highlight ? dashboardStyle.highlightBoxColor : null;

  const $prevRefreshState = useRef();
  useEffect(() => {
    if (!chartAreaRef || additionalProps.isEchartsModel || width < 1 || height < 1) {
      return;
    }

    const nextRefreshState = {
      inContainer,
      width,
      height,
      reloadRequestTime,
      filters,
      drillState,
      backgroundTheme,
      highlightColor,
      containerBackground: containerStyles?.customStyle
        ? containerStyles.style.backgroundColor
        : dashboardStyle.containerStyle.backgroundColor,
    };

    if (_.isEqual(nextRefreshState, $prevRefreshState.current) && !htmlIsEmpty) {
      return;
    }
    $prevRefreshState.current = nextRefreshState;

    let drillStateCopy = drillState;
    if (drillStateCopy) {
      drillStateCopy = _.cloneDeep(drillStateCopy);
      drillStateCopy.drills?.forEach((drill) => {
        if (filters instanceof Array && filters.length !== 0) {
          const filter = filters.find((filter) => drill.filterId === filter.id);
          if (filter && _.isEmpty(filter.members)) {
            drill.member = '';
          }
        }
      });
    }

    window.requestAnimationFrame(() => {
      const width = chartAreaRef.clientWidth;
      const height = chartAreaRef.clientHeight;
      if (width < 1 || height < 1) {
        return;
      }
      requestRenderForItem({
        width: chartAreaRef.clientWidth,
        height: chartAreaRef.clientHeight,
        reload: false,
        drillState: drillStateCopy,
      });
    });
  }, [
    chartAreaRef,
    inContainer,
    width,
    height,
    reloadRequestTime,
    htmlIsEmpty,
    filters,
    drillState,
    backgroundTheme,
    highlightColor,
    dashboardStyle.containerStyle.backgroundColor,
    containerStyles,
  ]);

  const marginAdjust = inContainer ? (dashboardStyle.allowContainerMargin ? dashboardStyle.containerMargin : 10) : 24; // margin applied on .zoom-target-disable

  const showDrillButtons =
    additionalProps.isDrillableChartModel && (!dashGridCtx.isFromPublisher || dashGridCtx.privateVisibility);
  const dashCtxFiltersCopy = showDrillButtons ? _.cloneDeep(dashGridCtx?.filters ?? []) : null;
  const buttonContainer = showDrillButtons
    ? document.querySelector(`.dash-item-newmenu[data-item-id="${id}"] .DrillButtonsContainer`)
    : additionalProps.isEchartsModel
    ? document.querySelector(`.dash-item-newmenu[data-item-id="${id}"] .EChartsButtonsContainer`)
    : undefined;

  return (
    <ContentSubWrapper
      className={`AnalysisChartRender ${showDrillButtons ? 'BngAnalysisDrillDownContainer' : ''} ${
        additionalProps.isEchartsModel ? 'EChartModelAnalysisContainer' : ''
      }`}
    >
      <UiBlocker
        className={`AnalysisChartRenderWrapper`}
        style={{ height: `${height - marginAdjust}px` }}
        block={loading}
      >
        <AnalysisHeader {...additionalProps} />
        <div
          className={`AnalysisChartRenderChart ${showDrillButtons ? 'mt-5' : ''}`}
          ref={(ref) => setChartAreaRef(ref)}
        >
          {!chartAreaRef ? null : additionalProps.isEchartsModel ? (
            <AnalysisECharts
              path={path}
              filters={filters}
              backgroundTheme={backgroundTheme}
              toolbarContainer={buttonContainer}
              highlightColor={highlightColor}
              dashboardPath={dashboardPath}
              disableNavigation={dashGridCtx.editMode}
              itemId={id}
              drillState={drillState}
              assistedData={additionalProps.assistedData}
              onQueryResult={(queryResult) => {
                updatePreloadResult({
                  ...(_.isEmpty(queryResult.result?.data?.cells)
                    ? buildNoContentPreloadResult()
                    : buildOkPreloadResult()),
                  mdx: queryResult.mdx,
                });

                $dashPageCtx.updateMdx(id, { mdx: queryResult.mdx });
                $dashPageCtx.updateDrillResponse(id, queryResult.drillResponse);

                if (_.isEqual(drillResponse, queryResult.drillResponse)) {
                  return;
                }

                setCurrentDrillResponse(queryResult.drillResponse);
              }}
            />
          ) : htmlIsEmpty ? (
            <RenderablePreloadLoadingPlaceholder
              path={path}
              viewType={viewType}
              width={chartHeight}
              height={chartWidth}
            />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: result.html }} />
          )}
        </div>
      </UiBlocker>
      {showDrillButtons && (
        <BngAnalysisDrillDownBar
          drillButtonsContainer={buttonContainer}
          assisted={additionalProps.assistedData}
          drillHandler={async (drills, prevDrills, { fromFilterEvent }) => {
            setLoading(true);
            try {
              const filterStateUpdate = [];
              if (drills.length > prevDrills.length) {
                // Drill down
                for (const drill of drills) {
                  if (!drill.filterId || !drill.member) continue;
                  let match = dashCtxFiltersCopy.find((f) => f.id === drill.filterId);
                  if (!match) {
                    match = {
                      id: drill.filterId,
                      members: [],
                      restrictionType: 'SHOW_SELECTED',
                    };
                    dashCtxFiltersCopy.push(match);
                  } else {
                    // Clear all members if filter already applied
                    // so that the only member applied is the selected from the graph
                    match.members = [];
                  }

                  // Drill contain full member name, ex: [Aonde].[activity.where.project]
                  // so remove to [activity.where.project]
                  const memberName = extractMemberLastPart(drill.member);
                  if (!match.members.includes(memberName)) {
                    if (dimensionApplied(drills, drill)) {
                      match.members = [];
                      match.members.push(memberName);
                      filterStateUpdate.push(match);
                    } else {
                      match.members.push(memberName);
                      filterStateUpdate.push(match);
                    }
                  }
                }
              } else if (prevDrills.length === drills.length) {
                const newFilterMember = drills[drills.length - 1];
                const match = dashCtxFiltersCopy.find((f) => f.id === newFilterMember?.filterId);
                if (match) {
                  try {
                    const member = extractMemberLastPart(newFilterMember.member);
                    if (dimensionApplied(prevDrills, newFilterMember)) {
                      match.members[0] = member;
                    } else {
                      match.members = [member];
                    }

                    filterStateUpdate.push(match);
                  } catch (e) {
                    console.error(
                      'Error on filter apply',
                      {
                        newFilterMember,
                        match,
                        dashCtxFiltersCopy,
                      },
                      e
                    );
                  }
                }
              } else {
                // Drill up
                const drillsToIterate = prevDrills.slice().reverse();
                const drillsDiff = prevDrills.length - drills.length;
                for (let i = 0; i < drillsDiff; i++) {
                  const lastDrill = drillsToIterate[i];
                  if (lastDrill && lastDrill.filterId && lastDrill.member) {
                    const match = dashCtxFiltersCopy.find((f) => f.id === lastDrill.filterId);
                    if (match) {
                      const memberName = extractMemberLastPart(lastDrill.member);
                      const idx = match.members.indexOf(memberName);
                      if (idx !== -1) {
                        match.members.splice(idx, 1);
                        if (dimensionApplied(prevDrills, lastDrill) && drills.length !== 0) {
                          const newLastDrill = drills[drills.length - 1];
                          if (match.id === newLastDrill.filterId) {
                            const memberLastPart = extractMemberLastPart(newLastDrill.member);
                            match.members.push(memberLastPart);
                          }
                        }
                        filterStateUpdate.push(match);
                      }
                    }
                  }
                }
              }

              $dashPageCtx.updateDrillState(id, { drills });
              if (!fromFilterEvent) {
                $dashPageCtx.filterChangeHandler(filterStateUpdate, false, true);
              }
            } finally {
              setLoading(false);
            }
          }}
          drillState={drillState}
          drillResponse={drillResponse}
          chartClickEventFilterRef={chartAreaRef}
          itemId={id}
        />
      )}
    </ContentSubWrapper>
  );
};

const dimensionApplied = (drillArray, drillToCompare) => {
  return drillArray.some((compDrill, idx) => {
    const indexOfTimeSelector = compDrill.dimension.indexOf('.(');
    if (indexOfTimeSelector === -1) {
      return false;
    }

    const drillIdx = drillArray.findIndex((el) => el === drillToCompare);
    if (idx >= drillIdx && drillToCompare.member !== compDrill.member && idx === drillIdx) {
      return false;
    }

    const dimPrefix = compDrill.dimension.substring(0, indexOfTimeSelector);
    return drillToCompare.dimension.startsWith(dimPrefix);
  });
};

const AnalysisWrapper = ({
  id,
  order,
  dynamic,
  tableThemeClass,
  rowSeparatorClass,
  tableRowSizeClass,
  resizeContent,
  fixedHeader,
  fixedColumn,
  fontSize,
  children,
}) => {
  const style = `
            .div-table-${id} .mdx-table tr:nth-child(odd), 
            .div-table-${id} .mdx-table tr:nth-child(odd) th, 
            .div-table-${id} .mdx-table tr:nth-child(odd) td,
                .div-table-${id} .mdx-table tr:nth-child(even), 
                .div-table-${id} .mdx-table tr:nth-child(even) th, 
                .div-table-${id} .mdx-table tr:nth-child(even) td {
                font-size: ${fontSize}px !important;
            `;
  let innerContent = children;
  if (dynamic) {
    innerContent = (
      <div
        className={`
                ${!resizeContent && fixedHeader ? 'fixedtableHeader' : ''}
                ${!resizeContent && fixedColumn ? 'fixedtableColumn' : ''}`}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      className={`div-table-${id} ${tableThemeClass} ${rowSeparatorClass} ${tableRowSizeClass}`}
      data-table-container={`${order}`}
    >
      <style type="text/css" dangerouslySetInnerHTML={{ __html: style }}></style>

      {innerContent}
    </div>
  );
};

const ContentSubWrapper = ({
  height,
  overflow = 'auto',
  textAlign = '',
  className = '',
  childRenderClassName = '',
  children,
  resizeContent,
  noZoomTarget = false,
  slowElementPlaceholder,
}) => {
  let childRender;
  if (noZoomTarget) {
    childRender = children;
  } else {
    childRender = (
      <div className={`${resizeContent ? 'zoom-target' : 'zoom-target-disable'} ${childRenderClassName}`}>
        {children}
      </div>
    );
  }

  return (
    <div
      className={`item-content-container ${resizeContent ? 'resize-content' : ''} ${className || ''} ${
        slowElementPlaceholder ? 'slowElement' : ''
      }`}
      style={{
        height: `${height}px`,
        overflow: overflow,
        textAlign: textAlign,
      }}
    >
      {slowElementPlaceholder}
      {childRender}
    </div>
  );
};

const LabelRender = ({ height, order, additionalProps }) => {
  return (
    <ContentSubWrapper height={height} className="LabelRender">
      <div className={`item-label label-${order}`} style={{ height: `${height}px` }}>
        <Text key={Utils.Strings.hash(additionalProps)} value={additionalProps.content ?? ''} {...additionalProps} />
      </div>
    </ContentSubWrapper>
  );
};

const IconRender = ({ height, order, additionalProps }) => (
  <ContentSubWrapper height={height} className="IconRender">
    <div className={`item-label-icon label-${order}`} style={{ height: `${height}px` }}>
      <IconText {...additionalProps} />
    </div>
  </ContentSubWrapper>
);

const imageSize = (originalSize, proportionalSize, resizeContent) => {
  let size = '';
  if (originalSize) {
    size = 'imageDashOriginalSize';
  } else if (proportionalSize) {
    size = 'imageDashProportionalSize';
  } else if (resizeContent) {
    size = 'imageDashResizeSize';
  }
  return size;
};

const ImageRender = ({ html, height, order, additionalProps }) => {
  const { originalSize = false, proportionalSize = false, resizeContent = false } = additionalProps;
  const size = imageSize(originalSize, proportionalSize, resizeContent);
  const queryParams = jQuery.param({ content: additionalProps.image });
  const style = { background: `url('${Api.baseUrl()}/upload?${queryParams}')` };
  return (
    <ContentSubWrapper height={height} className="ImageRender">
      <div className={`image-${order}`} style={{ height: `${height}px` }}>
        <div style={style} className={`fill-w fill-h ${size}`}></div>
      </div>
    </ContentSubWrapper>
  );
};

const NewKpiRender = ({
  id,
  path,
  width,
  height,
  filters,
  align,
  dashboardStyle,
  highlight,
  showErrorDialog,
  dashboardPath,
}) => {
  let filter = filters === '' ? [] : filters;
  const updateMdx = useDashboardPageCtx.cached(({ updateMdx }) => updateMdx);
  return (
    <ContentSubWrapper noZoomTarget={true} height={height} className={'NewKpiRender'}>
      <Kpi
        path={path}
        height={height}
        dashboardStyle={dashboardStyle}
        highlight={highlight}
        width={width}
        showErrorDialog={showErrorDialog}
        filters={filter}
        align={align}
        dashboardPath={dashboardPath}
        onResult={(result) => {
          updateMdx(id, result ? { mdx: result?.mdx } : null);
        }}
      />
    </ContentSubWrapper>
  );
};

const BigTableRender = ({ id, path, width, height, filters, dashboardPath, isPresentation }) => {
  const updateMdx = useDashboardPageCtx.cached(({ updateMdx }) => updateMdx);

  return (
    <ContentSubWrapper noZoomTarget={true} height={height} className={'BigTableRender'}>
      <BigTableRenderer
        path={path}
        height={height}
        width={width}
        filters={filters}
        location={'renderablePreload'}
        onDashboard={true}
        dashboardPath={dashboardPath}
        isPresentation={isPresentation}
        onResult={(result) => {
          const mdx = result?.additionalProps?.mdx;
          updateMdx(id, mdx ? { mdx } : null);
        }}
      />
    </ContentSubWrapper>
  );
};

const HtmlRender = ({ height, additionalProps, resizeContent }) => {
  const { editMode } = useContext(DashGridContext);
  return (
    <ContentSubWrapper height={height} resizeContent={resizeContent} className="HtmlRender">
      <div className="dashboard-item-html-component" style={{ textAlign: 'left', height: `${height}px` }}>
        {editMode && <div className="HtmlDragOverlay"></div>}
        <HtmlComponent html={additionalProps.processedContent || additionalProps.content} />
      </div>
    </ContentSubWrapper>
  );
};

const MapRender = ({
  id,
  width,
  height,
  itemProps,
  additionalProps,
  path,
  filters,
  dashItemId,
  mobile,
  isPresentation,
  exportView,
  dashboardPath,
}) => {
  const dashGridCtx = useContext(DashGridContext);
  const updateMdx = useDashboardPageCtx.cached(({ updateMdx }) => updateMdx);

  const onResult = useCallback(
    ({ dataMdx, geoMdx }) => {
      updateMdx(id, { dataMdx, geoMdx });
    },
    [updateMdx]
  );

  const mapStyleOverride = useMemo(() => {
    const override = {};
    if (itemProps.mapCenter) override.center = itemProps.mapCenter;
    if (itemProps.mapZoom) override.zoom = itemProps.mapZoom;
    return override;
  }, [itemProps]);

  return (
    <ContentSubWrapper height={height} className="MapRender">
      <BiSource
        mapPath={path}
        height={height}
        width={width}
        mdxFilter={filters}
        dashItemId={dashItemId}
        mobile={mobile}
        isPresentation={isPresentation}
        exportView={exportView}
        dashboardPath={dashboardPath}
        mapStyleOverride={mapStyleOverride}
        fromCockpit={dashGridCtx.fromCockpit}
        onResult={onResult}
        {...additionalProps}
      />
    </ContentSubWrapper>
  );
};

RenderablePreload = ContextEnhancer(RenderablePreload);
window.__RENDERABLE_PRELOAD_CLEAR_CACHE = () => {
  window.RENDERABLE_PRELOAD_CACHE = {};
  useDashboardPageCtx.getState().clearItemRenderResults();
  useDashboardPageCtx.getState().clearDrillStates();
};
export default RenderablePreload;
