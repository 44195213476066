import "./MapEditor.css";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { FieldArray } from "redux-form";
import BlockUi from 'react-block-ui';

import GeoreferenceEditor from "./GeoreferenceEditor"
import { MODALS } from "components/ui/redux/Actions";
import Button from "components/ui/Button";
import LeafletMap from "components/ui/map/LeafletMap";
import ContextEnhancer from "components/ContextEnhancer";
import {
    clearEntityIds,
    Field,
    renderSelectItemOpts,
    Validations,
    wrapForm
} from "components/ui/FormUtils";
import LegendItems from "components/ui/map/editor/LegendItems";
import TitleItems from "components/ui/map/editor/TitleItems";
import {Tab, TabSet} from "components/ui/TabSet";
import Api from 'components/Api';
import UiMsg from "components/ui/UiMsg";
import EditObjectContainer from "components/ui/edit-object/EditObjectContainer";
import {
    DEFAULT_COLORS,
    FieldType,
    LegendClass,
    LegendType,
    NewMapSource,
    randomColor
} from "components/ui/map/NewMapSource";

import MemberColorList from "components/ui/map/editor/MemberColorList";
import { NewMapRightMenu } from "components/ui/map/editor/NewMapRightMenu";
import NewMapBreadcrumbToolbar from "components/ui/map/editor/NewMapBreadcrumbToolbar";
import ModalInformation from "components/ui/ModalInformation";
import { showQueryError } from "components/ui/map/editor/NewMapUtils";
import DashboardItemInformation from "components/ui/dashboard/components/DashboardItemInformation";

class MapEditor extends Component {

    static propTypes = {
        source: PropTypes.string.isRequired,
        cube: PropTypes.string.isRequired,
        openSaveAs: PropTypes.func,
        openOnEditMode: PropTypes.bool,
        filter: PropTypes.string,
    };

    static defaultProps = {
        formValues: {},
        openOnEditMode: true,
    };

    state = {
        originalValues: {},
        openEditMode: false,
        availableSources: [],
        sourceFields: [],
        legendType: '',
        onAjax: false,
        autoUpdate: true,
        analystMenu: true,
        expandAnalystMenu: false,
        filter: null,
        userCanWrite: true,
        sourcedata: {
            sourceobjectname: '',
            projectid: '',
            address: '',
            zero: false
        },
    };

    constructor(props) {
        super(props);
        this.save = this.props.handleSubmit(async (mapData) => {
            await this.onAjax(async () => {
                try {
                    if (this.savingAs) {
                        mapData = JSON.deepClone(mapData);
                        clearEntityIds(mapData);
                    }
                    const result = await Api.NewMap.saveMap(mapData);
                    if (this.savingAs) {
                        this.savingAs = result;
                    } else {
                        this.props.initialize(result);
                    }
                } catch (e) {
                    console.error(e);
                    UiMsg.ajaxError('Error', e);
                }
            });
        });
        window.MapEditorInstance = this;
        this.runNextStyles = true;
        this.state.analystMenu = this.props.openOnEditMode;
        this.state.openEditMode = this.props.openOnEditMode;
    }

    async componentDidMount() {
        await this.onAjax(this.reloadMap);
        this.toggleAutoUpdate();
    }

    reloadMap = async () => {
        try {
            let {source, id} = this.props;
            if (id !== 0) {
                const map = await Api.NewMap.findOne({id});

                map.information.dataSourceCaption = await Api.Project.cubeCaption({
                    cube: map.query.datasource,
                    projectName: this.props.context.project.name
                });

                this.props.initialize(map);
                source = map.query.datasource;
                const userCanWrite = await Api.NewMap.userCanWriteObject(map.path);
                const printer = await Api.Bng.getPrinterConfiguration(map.path)

                this.setState({
                    userCanWrite,
                    sourcedata: {...this.state.sourcedata, sourceobjectname: source},
                    printer: printer,
                });

                application.utils.waitFor(() => !!this.mapComponent, () => this.mapComponent.setView(map.style.center, map.style.zoom));
            } else {
                this.props.change('mobile', this.props.context.project.objectMobileByDefault);
            }
            const projectId = this.props.context.project.id;
            const result = await Api.Project.sourceFields({projectId, sourceName: source});
            this.setState({
                sourceFields: result.filter(field => field.type !== 'Null'),
                sourcedata: {...this.state.sourcedata, projectid: projectId},
                originalValues: this.props.formValues
            });

            this.props.change('query.datasource', source);
            this.triggerForcedUpdate();
        } catch (e) {
            console.error('Error on reloadMap()', e);
        }
    };

    onAjax = async (fn) => {
        this.setState({onAjax: true});
        try {
            await fn();
        } finally {
            this.setState({onAjax: false});
        }
    };

    triggerUpdate = ({runQuery = false, runStyles = false, query, forced = false}) => {
        this.onAjax(async () => {
            if (this.updateTimer) {
                clearTimeout(this.updateTimer)
            }

            let geoJSON = (this.queryData && this.queryData.geoJSON) || {};
            if (runQuery) {
                geoJSON = await this.fetchGeoJson(query);
            }
            this.queryData = {geoJSON, query: JSON.deepClone(query)};

            const updateFn = () => {
                this.setState({
                    geoJSON,
                });
            };

            if (runQuery || forced) {
                updateFn();
            } else if (runStyles) {
                if (this.runNextStyles) {
                    this.updateTimer = setTimeout(() => {
                        updateFn();
                    }, 2000);
                } else {
                    this.runNextStyles = true;
                }
            }
        });
    };

    updateDataAfterMapProcess = (result) => {
        if (this.updateTimer) {
            clearTimeout(this.updateTimer);
        }

        if(!result.geoJSON.geoMdx || (this.props.id && this.props.id !== this.props.formValues?.id) || !result.members !== null) {
            return;
        }

        const currentMemberColors = this.props.formValues.style.legend.memberColors;
        const memberColors = result.members.map(member => {
            const match = currentMemberColors.find(mc => mc.member === member.member);
            if(match) {
                member.color = match.color ?? member.color;
            }
            return member;
        });

        this.props.change('style.legend.memberColors', memberColors);
        this.runNextStyles = false;
        delete result.members;
        delete result.geoJSON;
        delete result.mapLegend;
        this.setState(result);
    };

    updateSelectedLegends = (updatedLegends) => {
        const formLegend = this.props.formValues.style.legend;

        if (updatedLegends.length === 0 || formLegend.type === FieldType.Measure) {
            return;
        }

        const memberColors = updatedLegends
          .filter(legend => !!legend?.uniqueName)
          .map((legend) => {
              const match = formLegend.memberColors.find(mc => mc.member === legend.uniqueName);
              return {
                  caption: legend.description || legend.caption || legend.uniqueName,
                  color: match?.color || legend?.color || legend?.icon,
                  count: legend.count,
                  member: legend.uniqueName,
                  selected: legend.selected ?? true,
              }
          });

        this.props.change('style.legend.memberColors', memberColors);
    }

    triggerForcedUpdate = () => {
        const {query} = this.props.formValues;
        this.triggerUpdate({
            runQuery: this.queryData ? !_.isEqual(query, this.queryData.query) : true,
            runStyles: true,
            query,
            forced: true
        });
    };


    async fetchGeoJson(query) {
        let geoJSON = LeafletMap.emptyGeoJson();

        if (query && query.datasource && query.geoField) {
            try {
                const obj = {...query};
                if (this.props.filter) {
                    obj.filter = this.props.filter;
                }
                obj.path = `/${_.get(this.props, 'context.project.name', '')}`;
                geoJSON = await Api.NewMap.query(obj);
            } catch (e) {
                console.error(e);
                this.showQueryError((e.message || e.responseText));
            }
        }
        return geoJSON;
    }

    showQueryError = (error) => {
        showQueryError(error, this.props.context.msg);
    };

    fieldIsMeasure(val) {
        return val ?
            _.find(this.state.sourceFields, {value: val}).type === 'Measure'
            : false;
    }

    updatePosition = () => {
        if (this.mapComponent) {
            let mapState = this.mapComponent.readMapState();
            this.props.change('style.zoom', mapState.zoom);
            this.props.change('style.center', mapState.center);
            UiMsg.ok(this.props.context.msg.t('default.position.updated'));
        }
    };

    toggleHeatMap = (data) => {
        this.runNextStyles = true;
        this.props.change('style.heatMap', data);
        this.props.change('query.heatMapField', data.metric || '');
        this.props.change('query.heatMapUseMetric', data.useMetric || false);
    };

    toggleMarkerVisibility = () => {
        this.runNextStyles = true;
        let enabled = !_.get(this.props.formValues, 'style.markerProps.enabled', true);
        this.props.change('style.markerProps.enabled', enabled);
        const msgParams = [this.props.context.msg.t(enabled ? 'enabled.label' : 'disabled.label')];
        UiMsg.ok(this.props.context.msg.t('default.marker.visibility.updated', msgParams));
    };

    toggleMapCaption = () => {
        this.runNextStyles = true;
        let enabled = !_.get(this.props.formValues, 'style.captionProps.enabled', true);
        this.props.change('style.captionProps.enabled', enabled);
        const msgParams = [this.props.context.msg.t(enabled ? 'caption.enabled.label' : 'caption.disabled.label')];
        UiMsg.ok(this.props.context.msg.t('default.mapCaption.visibility.updated', msgParams));
        this.forceUpdate({}, {});
    };

    getEnableHeatMap() {
        return _.get(this.props.formValues, 'style.heatMap.enabled', false);
    }

    legendClassChanged = (e, val) => {
        if (!_.isString(val)) {
            val = e.target.value;
        }

        if (val === LegendClass.Average) {
            this.props.change('style.legend.measure.bands', [
                {color: DEFAULT_COLORS.GREEN, description: this.props.context.msg.t('above.average'), value: 25},
                {color: DEFAULT_COLORS.YELLOW, description: this.props.context.msg.t('within.average'), value: 0},
                {color: DEFAULT_COLORS.RED, description: this.props.context.msg.t('below.average'), value: 25},
            ]);
        } else {
            this.props.change('style.legend.measure.bands', [
                {color: DEFAULT_COLORS.GREEN, description: '', value: 0, operator: '>'}
            ]);
        }
    };

    legendFieldChanged = (e, val) => {
        this.props.change('style.legend.type', this.fieldIsMeasure(val) ? LegendType.Measure : LegendType.Regular);
        this.props.change('style.legend.measure.type', LegendClass.Average);
        this.legendClassChanged(null, LegendClass.Average);
    };

    legendIsMeasure(style = this.props.formValues.style) {
        return style.legend.type === LegendType.Measure;
    }

    toggleAutoUpdate = () => {
        return new Promise(res => {
            this.setState({autoUpdate: !this.state.autoUpdate}, res);
        });
    };

    openGeoreferencedialog = () => {
        this.props.dispatch(MODALS.open(GeoreferenceEditor, {sourcedata: this.state.sourcedata}));
    };

    openMdxView = () => {
        ModalInformation.ShowMessage({text: `
            <h5 style="margin-top: 0">${this.props.context.msg.t('map.mdx.points')}</h5>
            <pre class="text-left MapEditorMdx">
            ${this.state.geoJSON.geoMdx.trim().split('\r\n').join('<br/>')}
            </pre>
            <h5>${this.props.context.msg.t('map.mdx.data')}</h5>
            <pre class="text-left MapEditorMdx">
            ${this.state.geoJSON.dataMdx.trim().split('\r\n').join('<br/>')}
            </pre>
        `})
    };

    executeSaveAs = async (data) => {
        this.props.change('parentFolder', data.folder);
        this.props.change('caption', data.name);
        await this.props.change('information', {description: data.description});
        await new Promise(res => this.setState({}, res));
        try {
            this.savingAs = true;
            await this.save();
            return this.savingAs;
        } finally {
            delete this.savingAs;
        }
    };

    removeMap = () => {
        this.onAjax(async () => {
            try {
                await Api.NewMap.remove({id: this.props.id});
                Api.redirectPersonalPage(this.props.context);
            } catch (e) {
                console.error(e.message, e);
            }
        });
    };

    toggleAnalystMenu = () => {
        this.setState({analystMenu: !this.state.analystMenu});
        this.invalidateMapSize = true;
    };

    closeAnalystMenu = () => {
        this.setState({analystMenu: false});
        this.invalidateMapSize = true;
    };

    toggleAnalystMenuExpand = () => {
        this.setState({expandAnalystMenu: !this.state.expandAnalystMenu});
    };

    toggleMobile = async () => {
        try {
            await Api.NewMap.toggleMobile({id: this.props.formValues.id});
            this.props.change('mobile', !this.props.formValues.mobile);
        } catch (e) {
            console.error(e);
        }
    };

    handleEditObjectChange = (checked) => {
        this.invalidateMapSize = true;
        this.setState({openEditMode: checked, analystMenu: false});
    };

    forceUpdate = (oldQuery, oldStyle) => {
        const {query} = this.props.formValues;
        const runQuery = !_.isEqual(query, oldQuery);
        const runStyles = !_.isEqual(this.props.formValues.style, oldStyle);

        if (runQuery || runStyles) {
            this.triggerUpdate({runQuery, runStyles, query: query});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.mapComponent && this.invalidateMapSize) {
            this.mapComponent.invalidateSize();
            this.invalidateMapSize = undefined;
        }

        if (!this.state.autoUpdate) return;

        this.forceUpdate(prevProps.formValues.query, prevProps.formValues.style);
    }

    render() {
        if (!this.props.initialized) {
            return null;
        }
        const {formValues} = this.props;
        const {sourceFields, legendStats, geoJSON, onAjax} = this.state;
        const {query: queryForMap, style: styleForMap} = formValues;

        let groupedSourceFields = _.groupBy(sourceFields.filter(s => s.type !== FieldType.Georeference && s.type !== FieldType.Null), 'type');
        groupedSourceFields = Object.keys(groupedSourceFields).map(key => {
            return {label: this.props.context.msg.t(key), selectItems: _.sortBy(groupedSourceFields[key], 'label')};
        });
        groupedSourceFields = _.sortBy(groupedSourceFields, 'label');

        const legendIsMeasure = this.legendIsMeasure();

        const isNotMapPersisted = formValues.id === 0;


        const isAtLeastExpert = this.props.context.permissions.isAtLeastExpert();
        const isAdmin = this.props.context.permissions.isAdmin();
        let analystMenu = isAtLeastExpert && this.state.analystMenu;
        let expandAnalystMenu = analystMenu && this.state.expandAnalystMenu;
        const isDirty = !_.isEqual(formValues, this.state.originalValues);
        return (
            <div className="map-editor">
                <form onSubmit={this.save}>
                    <div className="row-fluid">
                        <div className="span3" style={{display: analystMenu ? '' : 'none'}}>
                            <TabSet className={expandAnalystMenu ? 'expanded-analyst-menu' : ''} internal>
                                <Tab label={this.props.context.msg.t('analyst')}>
                                    <div className="row-fluid map-editor-options">

                                        <BlockUi tag="div" blocking={onAjax} className="span12">

                                            <Button icon={`icon-fullscreen`}
                                                    title={this.props.context.msg.t('expand.menu')}
                                                    className={'analyst-expand-btn btn-mini btn-link'}
                                                    onClick={this.toggleAnalystMenuExpand}/>

                                            <div className="conf-container scrollbar-outer" ref={r => j(r).scrollbar()}>
                                                {this.renderQueryPropsFragment(sourceFields)}

                                                {this.renderLegendFragment(groupedSourceFields, legendIsMeasure, legendStats, formValues)}

                                                {this.renderTooltipFragment(groupedSourceFields, formValues)}
                                            </div>
                                            <div>
                                                <Button icon="icon-refresh" className="btn-block nodis"
                                                        disabled={this.state.autoUpdate}
                                                        onClick={this.triggerForcedUpdate}>
                                                    {this.props.context.msg.t('apply')}
                                                </Button>
                                            </div>

                                        </BlockUi>
                                    </div>
                                </Tab>
                            </TabSet>
                        </div>

                        <div className={`${analystMenu ? 'span9' : 'span12'} map-preview-container`}
                             style={{marginLeft: analystMenu ? '' : 0}}>
                            <TabSet
                                className={expandAnalystMenu ? 'overlay' : ''}
                                internal
                                buttons={(() => {
                                    if (!isAtLeastExpert) return [];
                                    return [
                                        <Button onClick={this.updatePosition}
                                                icon="icon-bullseye"
                                                className="btn-editor-map-enable-true"
                                                title={this.props.context.msg.t('mark.position.as.default')}/>,
                                        <Button onClick={() => this.props.openHeatMapConf({
                                            initialValues: {
                                                ..._.get(formValues, 'style.heatMap'),
                                                metric: _.get(formValues, 'query.heatMapField'),
                                                useMetric: _.get(formValues, 'query.heatMapUseMetric'),
                                            },
                                            sourceFields,
                                            onSave: ({data, close}) => {
                                                this.toggleHeatMap(data);
                                                close();
                                            }
                                        })}
                                                icon="icon-fire"
                                                className={`btn-editor-map-enable-${this.getEnableHeatMap()}`}
                                                title={this.props.context.msg.t('configure.heat.map')}/>,
                                        <Button onClick={this.toggleMarkerVisibility}
                                                icon="icon-map-marker"
                                                className={`btn-editor-map-enable-${_.get(this.props.formValues, 'style.markerProps.enabled', true)}`}
                                                title={this.props.context.msg.t('toggle.marker.visibility.default')}/>,
                                        <Button onClick={this.toggleAutoUpdate}
                                                icon="icon-refresh"
                                                className={`btn-editor-map-enable-${this.state.autoUpdate}`}
                                                title={this.props.context.msg.t('toggle.auto.update.title')}/>,
                                        <Button onClick={this.openGeoreferencedialog}
                                                icon="icon-globe"
                                                disabled={!isAdmin || this.state.sourcedata.sourceobjectname === ''}
                                                className="btn-editor-map-enable-true"
                                                title={this.props.context.msg.t('inMemory_verifyGeoreferences')}/>,
                                        <Button onClick={this.openMdxView}
                                                icon="icon-align-justify"
                                                disabled={_.isEmpty(_.get(this.state, 'geoJSON.geoMdx', ''))}
                                                className="btn-editor-map-enable-true"
                                                title={this.props.context.msg.t('see.mdx')}/>,
                                        <Button onClick={this.toggleMapCaption}
                                                icon="format_list_bulleted"
                                                className={`btn-editor-map-enable-${_.get(this.props.formValues, 'style.captionProps.enabled', true)} showSubtitleOnMapButton`}
                                                title={this.props.context.msg.t('toggle.caption.visibility.default')}/>
                                    ]
                                })()}>

                                <Tab label={this.props.context.msg.t('map')}>
                                    <div className="row-fluid">
                                        <div className="span12">
                                            <div className="map-preview item-content-container">
                                                {geoJSON?.error &&
                                                    <DashboardItemInformation
                                                        message={this.props.context.msg.t('dashboard.item.error.message')}
                                                        errorTrace={geoJSON.error}
                                                        showPlaceholder={false}
                                                    />
                                                }
                                                <NewMapSource query={queryForMap}
                                                              style={styleForMap}
                                                              geoJSON={geoJSON}
                                                              onMapRef={ref => this.mapComponent = ref}
                                                              onResultProcessed={this.updateDataAfterMapProcess}
                                                              propsStyle={this.props.formValues.style}
                                                              updateSelectedLegends={this.updateSelectedLegends}/>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </TabSet>
                        </div>
                    </div>
                </form>

                <NewMapBreadcrumbToolbar
                    onAjax={onAjax}
                    formValues={formValues}
                    isNotMapPersisted={isNotMapPersisted}
                    reloadMap={this.reloadMap}
                    source={this.props.source}
                    updateDynamicFilter={this.updateDynamicFilter}
                    sourceFields={this.state.sourceFields}
                    toggleMobile={this.toggleMobile}
                    context={this.props.context}
                />

                {this.state.userCanWrite && this.props.context.permissions.isAtLeastExplorer() &&
                <EditObjectContainer
                    className="floating-edit-object-newmap"
                    checked={this.state.openEditMode}
                    onChange={this.handleEditObjectChange}
                />
                }

                <NewMapRightMenu
                    context={this.props.context}
                    invalid={this.props.invalid}
                    onAjax={onAjax}
                    analystMenu={analystMenu}
                    formValues={formValues}
                    isNotMapPersisted={isNotMapPersisted}
                    isAtLeastExpert={isAtLeastExpert}
                    openOnEditMode={this.state.openEditMode}
                    save={this.save}
                    toggleAnalystMenu={this.toggleAnalystMenu}
                    closeAnalystMenu={this.closeAnalystMenu}
                    executeSaveAs={this.executeSaveAs}
                    removeMap={this.removeMap}
                    dirty={isDirty}
                    printer={this.state.printer}
                />

            </div>
        );

    }

    updateDynamicFilter = async (dynamicFilter) => {
        let autoUpdateToggled = false;
        if (!this.state.autoUpdate) {
            autoUpdateToggled = true;
            await this.toggleAutoUpdate()
        }

        this.props.change('query.timeFilter', dynamicFilter);

        if (autoUpdateToggled) {
            window.requestAnimationFrame(() => {
                this.toggleAutoUpdate()
            });
        }
    };


    renderLegendFragment(groupedSourceFields, legendIsMeasure, legendStats, formValues) {
        return (
            <div>
                <fieldset>
                    <legend>{this.props.context.msg.t('legend')}</legend>
                    <div>
                        <Field name="query.legendField"
                               className={`fill-w`}
                               component="select"
                               onChange={this.legendFieldChanged}>
                            <option value="">{this.props.context.msg.t('without.legend')}</option>
                            {renderSelectItemOpts(groupedSourceFields, true)}
                        </Field>
                    </div>
                </fieldset>
                {!_.isEmpty(formValues.query.legendField) &&
                <fieldset>
                    <legend>{this.props.context.msg.t('classification')}</legend>

                    <div className="legend-container">
                        {legendIsMeasure &&
                        <div className="measure-types">
                            {Object.keys(LegendClass).map((key, idx) => (
                                    <label key={idx}>
                                        <Field name="style.legend.measure.type"
                                               component="input"
                                               type="radio"
                                               onClick={this.legendClassChanged}
                                               value={key}/>
                                        <span className="lbl">
                                            {this.props.context.msg.t(key)}
                                        </span>
                                    </label>
                                )
                            )}
                        </div>
                        }

                        {legendIsMeasure && legendStats &&
                        <div>
                            <ul className="unstyled">
                                <li>
                                    <b>{this.props.context.msg.t('maximum')}</b>
                                    <i>{legendStats.maxFormatted}</i>
                                </li>
                                <li>
                                    <b>{this.props.context.msg.t('average')}</b>
                                    <i>{legendStats.avgFormatted}</i>
                                </li>
                                <li>
                                    <b>{this.props.context.msg.t('minimum')}</b>
                                    <i>{legendStats.minFormatted}</i>
                                </li>
                                <li>
                                    <b>{this.props.context.msg.t('dots')}</b>
                                    <i>{legendStats.countFormatted}</i>
                                </li>
                            </ul>
                        </div>
                        }

                        {!legendIsMeasure &&
                        <MemberColorList name="style.legend.memberColors"
                                         items={formValues.style.legend.memberColors}
                                         change={this.props.change}
                                         randomColor={randomColor}
                        />
                        }

                        {legendIsMeasure &&
                        <FieldArray name="style.legend.measure.bands"
                                    component={LegendItems}
                                    props={({
                                        isAverage: formValues.style.legend.measure.type === LegendClass.Average,
                                        randomColor: randomColor,
                                        valueFormat: legendStats ? legendStats.format : 'INTEGER',
                                        onChange: this.props.change
                                    })}
                        />
                        }
                    </div>

                </fieldset>
                }

            </div>
        );
    }

    renderQueryPropsFragment(sourceFields) {
        return (
            <fieldset>
                <legend>{this.props.context.msg.t('location')}</legend>
                <div>
                    <Field name="query.geoField"
                           component="select"
                           className="fill-w"
                           validate={Validations.required}>
                        <option value="">{this.props.context.msg.t('select.one')}</option>
                        {renderSelectItemOpts(sourceFields.filter(s => s.type === FieldType.Georeference))}
                    </Field>
                </div>
            </fieldset>
        );
    }

    renderTooltipFragment(groupedSourceFields, formValues) {
        const {titleFields = [], datasource} = formValues.query;
        const dimensionInfoMap = {};
        for (const group of groupedSourceFields) {
            for (const item of group.selectItems) {
                dimensionInfoMap[item.value] = item;
            }
        }

        return (
            <fieldset>
                <legend>{this.props.context.msg.t('map.tooltip.config')}</legend>
                <div className="tooltips-container">
                    <FieldArray name="query.titleFields"
                                component={TitleItems}
                                props={({
                                    onChange: _.noop,
                                    options: renderSelectItemOpts(
                                        groupedSourceFields,
                                        true,
                                        i => !titleFields.find(tf => tf.name === i.value)
                                    ),
                                    dimensionInfoMap,
                                    datasource
                                })}/>
                </div>
            </fieldset>
        );
    }
}


export default wrapForm({
    component: ContextEnhancer(MapEditor),
    initialValues: {
        id: 0,
        caption: '',
        parentFolder: '',
        information: {
            dataSourceCaption: '',
            description: '',
            lastDataUpdate: ''
        },
        mobile: false,
        query: {
            datasource: '',
            geoField: '',
            legendField: '',
            titleFields: [],
            heatMapField: '',
            heatMapUseMetric: false,
            timeFilter: {
                dimension: '',
                periodicity: '',
            }
        },
        style: {
            center: {
                lat: 0,
                lng: 0
            },
            zoom: 2,
            legend: {
                type: LegendType.Regular,
                memberColors: [],
                measure: {
                    type: LegendClass.Average,
                    bands: []
                }
            },
            heatMap: {
                enabled: false,
                blur: 15,
                radius: 25,
                minOpacity: 50,
            },
            markerProps: {
                enabled: true,
            },
            captionProps: {
                enabled: true,
            }
        }
    },
    name: 'mapEditorForm'
});