import styles from './AdditionalActivateDialog.module.css';
import ErrorLottie from 'components/bng/securityCheckup/assets/error_lottie.mp4';

import React, { useEffect, useMemo, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useSearchParams } from 'react-router-dom';

import Dialog from 'components/ui/Dialog';
import BngButton, { Type, Variant } from 'components/bng/ui/BngButton';
import useBimContext from 'components/hooks/useBimContext';
import Icon from 'components/ui/common/Icon';
import BngTable from 'components/bng/ui/BngTable';
import { proposalUtils } from 'components/service/bng/AccountApi';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import Utils from 'components/Utils';
import SecurityCheckupDone from 'components/bng/securityCheckup/SecurityCheckupDone';
import useFetchData from 'components/hooks/useFetchData';
import useBimNavigate from 'components/hooks/useBimNavigate';
import { getAppliedItemFromVoucher, Voucher } from 'components/service/bng/MarketplaceApi';
import { RECURRING_TYPES } from 'components/bng/pages/marketplace/MarketplaceCard';
import BngTag from 'components/bng/ui/BngTag';

const buildAdditionalTableCols = ({ context, voucher, getVoucherItemForRow, currentPlan = null }) => {
  return [
    {
      key: 'quantity',
      label: context.msg.t('quantity'),
      render: (row) => {
        return <div>{row.quantity || 1}</div>;
      },
    },
    {
      key: 'additionalName',
      label: context.msg.t('name'),
      render: (row) => {
        const paymentType = context.msg.t(
          row.billingFormat === RECURRING_TYPES.ONCE.key ? 'recurrence.ONCE' : `recurring`
        );
        const paymentMethod = context.msg.t(
          row.billingFormat === RECURRING_TYPES.ONCE.key
            ? row.paymentMethod === 'INSTALLMENTS'
              ? `x${row.installments}`
              : 'x1'
            : row.billingFormat
        );
        const isCurrentPlan = row.pricing.plan && row.id === currentPlan?.id;

        const voucherItem = getVoucherItemForRow(row, voucher);
        const hasDiscount = row.pricing.pricing > row.agreedPricing || voucherItem;

        return (
          <div className={styles.nameWrapper}>
            <span className={styles.itemName}>
              {proposalUtils.translationsForProposalPricing(row).name}
              {isCurrentPlan && ` (${context.msg.t('current')})`}
              {hasDiscount && (
                <BngTag className={`${styles.discountType} ${styles[voucherItem?.type] || ''}`}>
                  {context.msg.t(voucherItem?.type || 'discount')}
                </BngTag>
              )}
            </span>
            {!isCurrentPlan && (
              <div className={styles.nameSmallPrint}>
                <span>{`${context.msg.t('type')}: ${paymentType}`}</span>
                <span>{`${context.msg.t('payment')}: ${paymentMethod}`}</span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      key: 'pricing',
      label: context.msg.t('pricing'),
      render: (row) => {
        const pricing = row.pricing.pricing * row.quantity;
        let agreedPricing = row.agreedPricing;

        const voucherItem = getVoucherItemForRow(row, voucher);
        const hasDiscount = pricing > agreedPricing || voucherItem;
        const isBonified = voucherItem?.type === 'BONUS';

        if (voucherItem && !isBonified) {
          agreedPricing = agreedPricing + voucherItem.applyOnValue(agreedPricing);
        }
        const isCurrentPlan = row.pricing.plan && row.id === currentPlan?.id;

        return (
          <div className={styles.pricingRow}>
            {!isCurrentPlan && hasDiscount && (
              <span className={`${styles.pricingDiscount} ${isBonified ? styles.bonus : ''}`}>
                {isBonified
                  ? `${context.msg.t(voucherItem.duration)} ${context.msg.t('price.free')}`
                  : proposalUtils.getPricingLabel({
                      pricing,
                      currency: row.pricing.currency,
                    })}
              </span>
            )}
            <span className={`${isCurrentPlan ? styles.cutPricing : ''}`}>
              {proposalUtils.getPricingLabel({
                pricing: agreedPricing.toFixed(2),
                currency: row.pricing.currency,
              })}
            </span>
          </div>
        );
      },
    },
  ];
};

export function ProposalComponent({
  resolvedStatus = null,
  afterResolveCallback = _.noop,
  loadingCallback = _.noop,
  proposalRequest = {},
  detailsAlwaysOpen = false,
  enableAutoResolve = false,
  inDialog = false,
  origin = null,
  currentPlan = null,
}) {
  const context = useBimContext();
  const [detailsOpen, setDetailsOpen] = useState(detailsAlwaysOpen);
  const [requestMessageExpanded, setRequestMessageExpanded] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useBimNavigate();
  const { data: isMaster } = useFetchData(async () => {
    const account = await Api.Account.findAccount(proposalRequest.buyer.bimAccountId);
    return account.accountMasterId === context.user.id;
  }, []);

  const rows = useMemo(() => {
    const proposalItems = proposalRequest.additionalPricings.slice();
    proposalItems.push(...proposalRequest.servicePricings);
    if (proposalRequest.planPricings?.length > 0 && currentPlan) {
      proposalItems.push(currentPlan);
    }
    proposalItems.push(...proposalRequest.planPricings);
    return proposalItems.filter((p) => p.status !== 'DISABLED');
  }, [currentPlan]);

  const resolveProposal = async (accepted) => {
    loadingCallback(true);
    try {
      const status = accepted ? proposalUtils.ACTIVATION_TYPES.ACCEPTED : proposalUtils.ACTIVATION_TYPES.REJECTED;
      await Api.Account.resolveProposal({
        accountId: context.accountId,
        proposalId: proposalRequest.id,
        status,
        projectId: context.project.id,
        origin: origin,
      });
      UiMsg.ok(context.msg.t(`proposal.${accepted ? 'accept' : 'reject'}.success`));
      afterResolveCallback(status);
    } catch (e) {
      navigate('/errors/500');
      console.error('Error on function acceptProposal()', e);
      UiMsg.error(context.msg.t('accept.request.error'));
    } finally {
      loadingCallback(false);
    }
  };

  const getVoucherItemForRow = (row, voucher) => {
    if (!voucher) return null;
    const type = row.pricing.additional
      ? 'additionalPricing'
      : row.pricing.plan
      ? 'planPricing'
      : row.pricing.service
      ? 'servicePricing'
      : '';
    return voucher.itemsApplied.find((item) => item.salesType === type && item[item.salesType].id === row.pricing.id);
  };

  useEffect(() => {
    if (!enableAutoResolve || !!resolvedStatus) return;
    (async () => {
      if (searchParams.get('accept') === 'true') {
        await resolveProposal(true);
      } else if (searchParams.get('reject') === 'true' && isMaster) {
        await resolveProposal(false);
      }
    })();
  }, [isMaster]);

  const springStyles = useSpring({
    opacity: detailsOpen ? 1 : 0,
    transform: detailsOpen ? 'translateY(0px)' : 'translateY(-40px)',
    height: detailsOpen ? 100 : 0,
    overflow: 'hidden',
  });

  const voucher = useMemo(() => proposalRequest.appliedVoucher && new Voucher(proposalRequest.appliedVoucher), [
    proposalRequest,
  ]);

  if (voucher) {
    voucher.itemsApplied = [];
    rows.forEach((row) => {
      const pricingType = proposalUtils.getProposalPricingType(row);
      voucher.itemsApplied.push(
        ...getAppliedItemFromVoucher(voucher, pricingType, {
          calculatedPricing: row.calculatedPricing,
          pricingId: row.pricing.id,
          name: proposalUtils.translationsForProposalPricing(row).name,
        })
      );
    });
  }

  const currencySymbol = proposalRequest.contract.currency.symbol;
  const requestProps = proposalRequest.props.requestProps;
  const recurrentRows = useMemo(() => rows.filter((row) => row.billingFormat !== 'ONCE'), [rows]);
  const oncePaymentRows = useMemo(() => rows.filter((row) => row.billingFormat === 'ONCE'), [rows]);

  const [recurringSubtotal, oncePaymentSubtotal, discounts] = useMemo(() => {
    const getPricingForItem = (pricing, voucher) => {
      const voucherItem = getVoucherItemForRow(pricing, voucher);
      let calculatedPricing = pricing.calculatedPricing;
      if (voucherItem && voucherItem.type !== 'BONUS') {
        calculatedPricing = calculatedPricing + voucherItem.applyOnValue(calculatedPricing);
      }
      return calculatedPricing;
    };

    const recurringSubtotal = recurrentRows.reduce((acc, pricing) => {
      let calculatedPricing = getPricingForItem(pricing, voucher);
      if (pricing.pricing.plan && currentPlan?.id === pricing.id) {
        calculatedPricing = calculatedPricing * -1;
      }
      return acc + calculatedPricing;
    }, 0);
    const oncePaymentSubtotal = oncePaymentRows.reduce((acc, pricing) => acc + getPricingForItem(pricing, voucher), 0);
    const total = Math.max(oncePaymentSubtotal + recurringSubtotal, 0);
    const subtotal = rows.reduce((acc, pricing) => {
      let calculatedPricing = pricing.pricing.pricing * pricing.quantity;
      if (pricing.pricing.plan && currentPlan?.id === pricing.id) {
        calculatedPricing = calculatedPricing * -1;
      }
      return acc + calculatedPricing;
    }, 0);

    const discounts = subtotal - total;
    return [recurringSubtotal, oncePaymentSubtotal, discounts];
  }, [currentPlan]);

  const cols = useMemo(
    () =>
      buildAdditionalTableCols({
        context,
        voucher,
        getVoucherItemForRow,
        currentPlan,
      }),
    [currentPlan]
  );

  if (resolvedStatus) {
    return (
      <SecurityCheckupDone
        completedSteps={rows.map((row) => proposalUtils.translationsForProposalPricing(row).name)}
        title={
          resolvedStatus === proposalUtils.ACTIVATION_TYPES.REJECTED ? context.msg.t('proposal.refused.title') : null
        }
        description={context.msg.t(
          resolvedStatus === proposalUtils.ACTIVATION_TYPES.REJECTED ? 'proposal.refused.desc' : 'proposal.done.desc'
        )}
        animation={resolvedStatus === proposalUtils.ACTIVATION_TYPES.REJECTED ? ErrorLottie : null}
        showCompletedSteps={false}
      />
    );
  }

  return (
    <div className="ProposalComponent">
      <div className={`${styles.contentWrapper} ${inDialog ? styles.inDialog : ''}`}>
        <div className={styles.dialogScrollWrapper}>
          <div className={styles.detailsWrapper}>
            {!detailsAlwaysOpen && (
              <a
                onClick={() => {
                  setDetailsOpen(!detailsOpen);
                }}
                className={styles.detailsBtn}
              >
                <Icon icon={detailsOpen ? 'arrow_drop_up' : 'arrow_drop_down'} />
                {context.msg.t('see.details')}
              </a>
            )}
            <animated.div className={styles.hiddenDetails} style={springStyles}>
              <b>{context.msg.t('requested.by')}:</b>
              <div className={styles.requestProps}>
                <div>
                  <span>{context.msg.t('name')}:</span>
                  <b>{requestProps.userName}</b>
                </div>
                <div>
                  <span>{context.msg.t('email')}:</span>
                  <b>{requestProps.userEmail}</b>
                </div>
                <div>
                  <span>{context.msg.t('role')}:</span>
                  <b>{requestProps.role}</b>
                </div>
                <div>
                  <span>{context.msg.t('date')}:</span>
                  <b>{Utils.Date.formatDateTime(requestProps.date)}</b>
                </div>
              </div>
              <b className={styles.idProp}>ID: {proposalRequest.id}</b>
            </animated.div>
          </div>
          {recurrentRows.length > 0 && (
            <div className={`${styles.tableWrapper}`}>
              <BngTable rows={recurrentRows} cols={cols} className={styles.recurringTable} hideHeader={true} />
            </div>
          )}
          {oncePaymentRows.length > 0 && (
            <div className={`${styles.tableWrapper}`}>
              <BngTable rows={oncePaymentRows} cols={cols} className={styles.oncePaymentTable} hideHeader={true} />
            </div>
          )}
        </div>
        <div className={styles.subtotalWrapper}>
          {recurrentRows.length > 0 && (
            <div>
              <span>{context.msg.t('recurring.total')}</span>
              <span className={styles.pricingField}>
                {currencySymbol} {recurringSubtotal.toFixed(2)}
              </span>
            </div>
          )}
          {oncePaymentRows.length > 0 && (
            <div>
              <span>{context.msg.t('oncePayment.total')}</span>
              <span className={styles.pricingField}>
                {currencySymbol} {oncePaymentSubtotal.toFixed(2)}
              </span>
            </div>
          )}
          {(discounts !== 0 || voucher) && (
            <div>
              <span>{context.msg.t('discounts')}</span>
              <span className={styles.pricingField}>
                -{currencySymbol} {discounts.toFixed(2)}
              </span>
            </div>
          )}
        </div>
      </div>
      {proposalRequest.requestMessage && (
        <div className={`${styles.proposalMessageWrapper} ${requestMessageExpanded ? '' : styles.messageHidden}`}>
          <strong>{context.msg.t('obs')}:</strong>
          <span className={`${styles.proposalMessage}`}>{proposalRequest.requestMessage}</span>
          {proposalRequest.requestMessage?.length > 68 && (
            <>
              {requestMessageExpanded && <br />}
              <a className={styles.showMore} onClick={() => setRequestMessageExpanded(!requestMessageExpanded)}>
                {context.msg.t(requestMessageExpanded ? 'show.less' : 'show.more')}
              </a>
            </>
          )}
        </div>
      )}
      <div className={styles.buttonWrappers}>
        {isMaster && (
          <BngButton
            className={`${styles.additionalButtons}`}
            onClick={() => resolveProposal(false)}
            variant={Variant.default}
            type={Type.danger}
          >
            {context.msg.t('reject')}
          </BngButton>
        )}
        <BngButton
          className={`${styles.additionalButtons} ${styles.acceptRequestButton}`}
          onClick={() => resolveProposal(true)}
          type={Type.success}
        >
          {context.msg.t('enable')}
        </BngButton>
      </div>
    </div>
  );
}

export default function AdditionalActivateDialog({ closeModal = _.noop, proposalRequest = {} }) {
  const context = useBimContext();
  const [loading, setLoading] = useState(false);
  const [resolved, setResolved] = useState(null);
  const { data: currentPlan, isLoading: currentPlanLoading } = useFetchData(async () => {
    if (proposalRequest.planPricings?.length > 0) {
      return await Api.Account.fetchActivePlan(proposalRequest.buyer.bimAccountId);
    }
  });

  return (
    <Dialog
      className={`${styles.AdditionalActivateDialog} AdditionalActivateDialog`}
      onClose={closeModal}
      loading={loading || currentPlanLoading}
      titleComponent={() => {
        if (!!resolved) return;
        return (
          <div className={styles.AdditionalActivateDialogHeader}>
            <Icon icon={'new_releases'} className={styles.headerIcon} />
            <div className={styles.titleWrapper}>
              <span className={styles.activateTitle}>{context.msg.t('attention')}</span>
              <span className={styles.newProposalMsg}>{context.msg.t('new.proposal')}</span>
              <span className={styles.titleSmallPrint}>
                {context.msg.t('this.proposals.expires', [proposalRequest.daysToExpire])}
              </span>
            </div>
          </div>
        );
      }}
    >
      <ProposalComponent
        afterResolveCallback={setResolved}
        resolvedStatus={resolved}
        loadingCallback={setLoading}
        proposalRequest={proposalRequest}
        inDialog={true}
        origin={proposalUtils.PROPOSAL_ORIGIN.BIM_ADD_ADDITIONAL_DIALOG_REQUEST}
        currentPlan={currentPlan}
      />
    </Dialog>
  );
}
