import './Icon.css';

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Utils from 'components/Utils'

function IconInt({ icon, type = null, className = '', outlined = false, ...props }, ref) {
  const iconData = useMemo(() => {
    if (!type) {
      if (!!icon) {
        type = Icon.parseType(icon);
      } else {
        type = Icon.MATERIAL;
      }
    }

    const classNames = [];
    const attrs = {};
    let child = null;
    switch (type) {
      case Icon.FONT_AWESOME:
        const isFa4 = _.startsWith(icon, 'fa-');
        let iconExp = isFa4
            ? `fa ${icon}`
            : `icon-${icon.replace('icon-', '')}`;
        classNames.push(iconExp);
        break;
      case Icon.BIM:
        classNames.push(`icon-bim-${icon.replace('icon-bim-', '')}`);
        break;
      default:
        classNames.push('notranslate');
        classNames.push(`material-icons${outlined ? '-outlined' : ''}`);
        attrs.translate = 'no';
        attrs['aria-hidden'] = 'true';
        child = icon;
        break;
    }
    return {
      iconClassName: classNames.join(' '),
      additionalAttributes: attrs,
      childText: child,
    };
  }, [icon, type, outlined]);

  return (
      <i
          className={`Icon ${iconData.iconClassName} ${className}`}
          {...iconData.additionalAttributes}
          {...props}
          ref={ref}
      >
        {iconData.childText}
      </i>
  );
}

const Icon = React.forwardRef(IconInt);

Icon.MATERIAL = 0;
Icon.FONT_AWESOME = 1;
Icon.BIM = 2;

Icon.propTypes = {
  icon: PropTypes.any,
  type: PropTypes.number,
  className: PropTypes.string,
  outlined: PropTypes.bool,
};

Icon.parseType = (icon) => {
  if (
    _.startsWith(icon, 'fa-') ||
    _.startsWith(icon, 'fa fa-') ||
    _.startsWith(icon, 'icon-')
  ) {
    return Icon.FONT_AWESOME;
  } else if (_.startsWith(icon, 'icon-bim')) {
    return Icon.BIM;
  } else {
    return Icon.MATERIAL;
  }
};

Icon.iconIdentifierForPath = (path) => {
  let extension = path.split('.');
  extension = extension[extension.length - 1];
  let icon = '';
  switch (extension) {
    case 'dashboard': {
      icon = Utils.Object.getObjectIcon('dashboard');
      break;
    }
    case 'analysis': {
      icon = Utils.Object.getObjectIcon('analysis', 'showChart');
      break;
    }
    case 'newmap': {
      icon = Utils.Object.getObjectIcon('newmap');
      break;
    }
    case 'kpi': {
      icon = Utils.Object.getObjectIcon('kpi');
      break;
    }
    case 'pdf': {
      icon = 'icon-book';
      break;
    }
    case 'cockpit': {
      icon = Utils.Object.getObjectIcon('cockpit');
      break;
    }
    case 'bigtable': {
      icon = Utils.Object.getObjectIcon('bigtable');
      break;
    }
  }
  return icon;
};

Icon.iconForPath = (path) => {
  const icon = Icon.iconIdentifierForPath(path);
  return <Icon type={Icon.FONT_AWESOME} icon={icon} />;
};

export default Icon;
