import React from 'react';

import BigTableApp from 'components/bng/pages/bigTable/BigTableApp';
import LoadingCenter from 'components/ui/loading/LoadingCenter';
import ContextEnhancer from 'components/ContextEnhancer';
import Api from 'components/Api';
import DashboardItemInformation from 'components/ui/dashboard/components/DashboardItemInformation';
import { ObjectErrorPopup } from 'components/ui/dashboard/components/ObjectErrorPopup';
import FilterBar from 'components/filter/FilterBar';
import FilterService from 'components/filter/FilterService';

class BigTable extends React.Component {

    static defaultProps = {
        id: null,
        createMode: false,
        bigTable: null,
        filters: null,
        viewOnly: false,
        onLoadData: _.noop
    }

    state = {
        loading: true,
        bigTable: null,
        hasError: false,
        filters: _.cloneDeep(this.props.filters),
        errorTrace: '',
    };

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.error(`BigTable component catch an error`, error, errorInfo);
    }

    async componentDidMount() {
        await this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.props.filters, prevProps.filters)) {
            this.setState({
                filters: _.cloneDeep(this.props.filters)
            }, async () => {
                await this.load();
            });
        }
    }

    async load() {
        try {
            let bigTable = this.props.bigTable;
            if (this.props.id) {
                bigTable = await Api.BigTable.findById(this.props.id);
            }
            this.setState({
                bigTable,
            });
        } catch (e) {
            console.error('Error on BigTable.load()', { e });
            this.setState({errorTrace: e});
        } finally {
            this.setState({loading: false});
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="fill-w fill-h">
                    <DashboardItemInformation
                      message={this.props.context.msg.t('BigTable.pageLoadError')}
                      errorTrace={this.state.errorTrace}
                      showPlaceholder={false}
                      showErrorDialog={() => ObjectErrorPopup({ message: this.state.errorTrace })}
                    />
                </div>
            );
        }

        if (this.state.loading) {
            return (
                <div className="fill-w fill-h">
                    <LoadingCenter/>
                </div>
            );
        }

        if (this.state.bigTable) {
            const filters = this.state.filters;
            const withFilters = filters?.length > 0;
            return (
              <>
                {withFilters && (
                  <FilterBar
                    alwaysShowFilterBar={true}
                    filters={filters}
                    onChangeListener={async (filters,
                                             force,
                                             appendAndReplace,
                                             changedFilters,
                                             additionalProps) => {
                        if (!additionalProps.clearFilters) {
                          return;
                        }

                        const newFilters = filters.filter(
                          (f) => !changedFilters.some((changedFilter) => changedFilter.id === f.id)
                        );

                        await new Promise((res) => {
                          this.setState(
                            {
                              filters: newFilters,
                            },
                            res
                          );
                        });

                        await this.load();
                    }}
                    noDropdown={true}
                    type={'OBJECT'}
                    isPublisher={false}
                  />
                )}
                <BigTableApp
                  reload={() => this.load()}
                  createMode={this.props.createMode}
                  filters={FilterService.createFilterParam(filters, true).filter}
                  viewOnly={this.props.viewOnly}
                  bigTable={this.state.bigTable}
                  onLoadData={this.props.onLoadData}
                  withFilters={withFilters}
                  onClearFilter={({ id }) => {
                    this.setState({
                      filters: filters.filter((f) => f.id !== id),
                    });
                  }}
                />
              </>
            );
        } else {
            return <BigTableApp/>;
        }
    }
}

export default ContextEnhancer(BigTable);
