import './BngTag.css';
import React, { useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import Icon from 'components/ui/common/Icon';
import BngButton from 'components/bng/ui/BngButton';

export default function BngTag({
  className = '',
  description = null,
  onClose,
  icon = null,
  textEllipsis = false,
  children,
  iconProps = {},
  maxExpandedWidth,
  ...props
}) {
  const [hover, setHover] = useState(false);

  const collapsedTagStyles = useSpring({
    maxWidth: !hover ? 18 : maxExpandedWidth,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  });

  return (
    <animated.span
      className={`BngTag ${className} flex-center-items gap-1 ${
        maxExpandedWidth ? `collapsableTag ${hover ? 'hovering' : ''}` : ''
      } }`}
      onPointerEnter={() => setHover(true)}
      onPointerLeave={() => setHover(false)}
      style={maxExpandedWidth ? collapsedTagStyles : {}}
      {...props}
    >
      {icon && <Icon icon={icon} {...iconProps} />}
      <div className={'tagBody'}>
        {description && <span className={`description ${textEllipsis ? 'ellipsis' : ''}`}>{description}</span>}
        {children}
        {onClose && <BngButton icon={'close'} className={`BngTagCloseButton`} hover={false} onClick={onClose} />}
      </div>
    </animated.span>
  );
}

export function BetaTag() {
  return <BngTag description={'Beta'} className={'beta'} />;
}
