import styles from './AccessTimeRestrictionLogsDialog.module.css';

import React, { useMemo, useState } from 'react';
import { Field } from 'formik';

import BngLogsDialog from 'components/bng/logs/BngLogsDialog';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import Utils from 'components/Utils';
import useBimContext from 'components/hooks/useBimContext';
import FilterOpts from 'components/bng/pages/common/filter/FilterOpts';
import FilterDropdown from 'components/bng/pages/common/filter/FilterDropdown';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngSearch from 'components/bng/ui/BngSearch';
import Avatar from 'components/ui/Avatar';
import useFetchData from 'components/hooks/useFetchData';
import useTranslation from 'components/hooks/useTranslation';
import bngYup from 'components/bng/form/yup/BngYup';
import { BngField } from 'components/bng/form/BngField';

const LogsFilterSchema = bngYup((yup) => {
  return yup.object({
    user: yup.number().nullable().default(null),
    startDate: yup.date().nullable().default(''),
    endDate: yup.date().nullable().default(''),
    platform: yup.string().nullable().default(''),
    action: yup.string().nullable().default(''),
  });
});

export default function AccessTimeRestrictionLogsDialog({ closeModal = _.noop, availableMembers = [] }) {
  const context = useBimContext();
  const { t } = useTranslation();

  const [filters, setFilters] = useState({ searchTerm: '', filterButton: {} });
  const { data: restrictionLogs = [], isLoading, reload } = useFetchData(async () => {
    try {
      return await Api.AccessTimeRestriction.findAllLogs(context.project.id);
    } catch (e) {
      UiMsg.ajaxError(null, e);
      throw e;
    }
  });

  const filteredRows = useMemo(() => {
    return filterLogRows(restrictionLogs, filters, availableMembers);
  }, [restrictionLogs, filters]);

  const tableColumns = useMemo(() => {
    return [
      {
        label: t('user'),
        render: (row) => {
          const user = findUserById(availableMembers, row.userId);
          return (
            <div className={styles.avatarAndName}>
              <Avatar userId={row.userId} className={styles.userAvatar} />
              <label>{user.name}</label>
            </div>
          );
        },
      },
      {
        label: t('action'),
        render: (row) => {
          return <label>{t(`action.${row.action}`)}</label>;
        },
      },
      {
        label: t('platform.PLATFORM'),
        render: (row) => {
          return <label>{t(`platform.${row.platform}`)}</label>;
        },
      },
      {
        label: t('date'),
        render: (row) => {
          const [datePart, ...timePart] = Utils.Date.formatDateTime(row.date).split(' ');
          return <div>{`${datePart} ${t('atTime')} ${timePart.join(' ')}`}</div>;
        },
      },
    ];
  }, []);

  return (
    <BngLogsDialog
      className="AccessTimeRestrictionLogsDialog"
      closeModal={closeModal}
      title={t('logs')}
      isEmpty={_.isEmpty(restrictionLogs)}
      loading={isLoading}
      rows={filteredRows}
      columns={tableColumns}
      headerButtons={
        <React.Fragment>
          <BngSearch
            className={`${styles.logsSearch}`}
            value={filters.searchTerm}
            onChange={(val) => {
              setFilters({ ...filters, searchTerm: val });
            }}
            title={t('search.object.name')}
            alwaysOpen
            inline
          />
          <LogsFilterDropdown
            alwaysOpen={false}
            users={availableMembers.filter((member) => member.type === 'USER')}
            onChange={(values) => setFilters({ ...filters, filterButton: values })}
            initialValues={filters.filterButton}
          />
          <BngIconButton icon={'refresh'} onClick={reload} />
        </React.Fragment>
      }
    />
  );
}

export const filterLogRows = (selectedRows, filters, availableMembers, userInItem = false) => {
  const {
    searchTerm,
    filterButton: { platform, action, user, startDate, endDate },
  } = filters;

  const updatedRows = selectedRows.map((item) => {
    const user = userInItem ? item.user : findUserById(availableMembers, item.userId);
    return {
      ...item,
      name: Utils.Users.displayName(user),
    };
  });

  if (!_.isEmpty(searchTerm)) {
    selectedRows = updatedRows.filter((row) => {
      return (
        Utils.Strings.includesIgnoreCase(row.id, searchTerm) || Utils.Strings.includesIgnoreCase(row.name, searchTerm)
      );
    });
  }

  if (!_.isEmpty(platform)) {
    selectedRows = selectedRows.filter((row) => {
      return row.platform.toLowerCase() === platform.toLowerCase();
    });
  }

  if (!_.isEmpty(action)) {
    selectedRows = selectedRows.filter((row) => {
      return row.action.toLowerCase() === action.toLowerCase();
    });
  }

  if (user) {
    selectedRows = selectedRows.filter((row) => {
      return row.user.id === user;
    });
  }

  const adjustDate = (val) => {
    if (!val || _.isDate(val)) {
      return val;
    }

    return new Date(`${val.split('T')[0]}T00:00:00`);
  };

  const parsedStartDate = adjustDate(startDate);
  const parsedEndDate = adjustDate(endDate);
  if (parsedStartDate || parsedEndDate) {
    selectedRows = selectedRows.filter((row) => {
      const rowDate = adjustDate(row.date);
      return (!parsedStartDate || rowDate >= parsedStartDate) && (!parsedEndDate || rowDate <= parsedEndDate);
    });
  }

  return selectedRows.slice(0, 20);
};

function findUserById(data, userId) {
  return data.find((item) => item.type === 'USER' && item.userId === userId);
}

export function LogsFilterDropdown({
  className = '',
  onChange = _.noop,
  users,
  initialValues = {},
  showActions = true,
}) {
  const { t } = useTranslation();

  const usersOpts = useMemo(() => {
    const uniqueUsers = users.reduce((array, user) => {
      if (!array.some((u) => u.id === user.id)) {
        array.push(user);
      }
      return array;
    }, []);

    return FilterOpts({
      options: uniqueUsers.map((user) => ({
        value: user.userId || user.id,
        label: Utils.Users.displayName(user),
      })),
    });
  }, []);

  const platformOpts = useMemo(
    () =>
      FilterOpts({
        options: [
          { label: t('platform.MOBILE'), value: 'MOBILE' },
          { label: t('platform.PLATFORM'), value: 'PLATFORM' },
          {
            label: t('platform.PUBLISHER'),
            value: 'PUBLISHER',
          },
          {
            label: t('platform.PRESENTATION'),
            value: 'PRESENTATION',
          },
          { label: t('platform.API'), value: 'API' },
          { label: t('platform.SCHEDULING'), value: 'SCHEDULING' },
        ],
      }),
    []
  );

  const actionOpts = useMemo(
    () =>
      FilterOpts({
        options: [{ label: t('action.ACCESS_ATTEMPT'), value: 'ACCESS_ATTEMPT' }],
      }),
    []
  );

  const initialFormValues = useMemo(() => _.merge({}, LogsFilterSchema.default(), initialValues), [initialValues]);

  const fields = [
    {
      name: 'user',
      label: t('user'),
      options: usersOpts,
    },
    {
      render: () => {
        return (
          <>
            <Field
              name="startDate"
              component={BngField}
              label={t('initial.date')}
              type="date"
              className={styles.dateField}
            />
            <Field
              name="endDate"
              component={BngField}
              label={t('final.date')}
              type="date"
              className={styles.dateField}
            />
          </>
        );
      },
    },
    {
      name: 'platform',
      label: t('platform.PLATFORM'),
      options: platformOpts,
    },
  ];

  if (showActions) {
    fields.push({
      name: 'action',
      label: t('action'),
      options: actionOpts,
    });
  }

  return (
    <FilterDropdown
      className={`LogsFilterDropdown ${className}`}
      fields={fields}
      initialFormValues={initialFormValues}
      dropdownSchema={LogsFilterSchema}
      onChange={onChange}
      overDialog={true}
    />
  );
}
