import './BigTable.css';

import React from 'react';
import PropTypes from 'prop-types';

import ContextEnhancer from 'components/ContextEnhancer';
import UiMsg from 'components/ui/UiMsg';
import LoadingCenter from 'components/ui/loading/LoadingCenter';

import AGTableWrapper from 'components/bng/pages/bigTable/AGTableWrapper';
import BigTableRightMenu from 'components/bng/pages/bigTable/BigTableRightMenu';
import EditObjectContainer from 'components/ui/edit-object/EditObjectContainer';
import { themes } from 'components/bng/pages/bigTable/themes';
import BigTableBreadcrumbToolbar from 'components/bng/pages/bigTable/BigTableBreadcrumbToolbar';
import DataTab from 'components/bng/pages/bigTable/tabs/DataTab';
import LayoutTab from 'components/bng/pages/bigTable/tabs/LayoutTab';
import ColumnsTab from 'components/bng/pages/bigTable/tabs/ColumnsTab';

import { generateCols, generateRows, processQueryResult } from 'components/bng/pages/bigTable/functions';
import BetaLabelContainer from 'components/bng/pages/bigTable/components/BetaLabelContainer';
import BigTableAnalysisHelp from 'components/bng/pages/bigTable/BigTableAnalysisHelp';
import ServerErrorPage from 'components/bng/pages/errors/ServerErrorPage';
import Api from 'components/Api';
import FilterService from 'components/filter/FilterService';
import DashboardItemInformation from 'components/ui/dashboard/components/DashboardItemInformation';
import bimEventBus from 'BimEventBus';
import { imageFrameStyles, imageSizes } from 'components/bng/pages/bigTable/constants';
import { ObjectErrorPopup } from 'components/ui/dashboard/components/ObjectErrorPopup';

const BigTableApp = ContextEnhancer(
  class BigTableAppInner extends React.PureComponent {
    static propTypes = {
      createMode: PropTypes.bool,
      bigTable: PropTypes.object,
      reload: PropTypes.func,
      context: PropTypes.any,
      filters: PropTypes.array,
      viewOnly: PropTypes.bool,
      onLoadData: PropTypes.func,
      onClearFilter: PropTypes.func,
      withFilters: PropTypes.bool,
    };

    static defaultProps = {
      createMode: false,
      bigTable: null,
      reload: _.noop,
      filters: [],
      viewOnly: false,
      onLoadData: _.noop,
      onClearFilter: _.noop,
      withFilters: false,
    };

    state = {
      id: null,
      path: '',
      parentFolder: '',
      name: '',
      description: '',
      mobile: false,

      sources: [],
      data: {},
      hasError: false,
      error: null,
      loading: true,

      editing: j?.QueryString?.openOnEditMode === 'true' || false,
      menuOpen: false,
      reset: false,
      openAccordion: null,
      readyToDraw: false,

      structureFields: [],
      measureColumns: [],

      rows: [],
      columns: [],
      calculatedColumns: [],
      userColumnsDef: [],
      defaultColumns: [],
      dataColumns: [],
      gridConfig: {
        pagination: 'auto',
        pageSize: '100',
        sizeToFit: true,
        headerFilters: true,

        borderOptions: 'none',
        borderColor: '#FFFFFF',
        borderWidth: 1,

        generalFontSize: 'small',
        fontSize: '11',
        rowHeight: '20',
        backgroundColor: '#ffffff',
        stripped: true,
        strippedColor: '#f2f2f2',

        showHeader: true,
        wrapHeaderText: false,
        headerHeight: '20',
        headerFontSize: '12',
        headerBackgroundColor: '#e8e8e8',
        headerFontColor: '#5a5a5a',

        selectedBackgroundColor: '#5a5a5a',
        selectedTextColor: '#ffffff',

        textSlice: true,

        highlightPinnedColumns: true,
        pinnedColumnBackgroundColor: '#FFFFFFFF',
        pinnedColumnStrippedBackgroundColor: '#FFFFFFFF',
      },

      themeConfig: {
        theme: 'default',
        useDefaultTheme: false,
      },

      titleConfig: {
        title: '',
        showTitle: false,
        titleColor: '#000000',
        titleAlign: 'left',
        titleFormat: [],
        titleFontSize: '20',

        description: '',
        showDescription: false,
        descriptionColor: '#000000',
        descriptionAlign: 'left',
        descriptionFormat: [],
        descriptionFontSize: '12',
      },

      totalizerConfig: {
        fontSize: '12',
      },

      sortModel: [],
      filterModel: {},
      initialSortModelApplied: true,

      sourceFields: [],
      datasourceConfig: {
        cube: '',
        sourceFields: [],
        customQuery: false,
        query: '',
        hideEmptyRowColumns: true,
      },
      datasourceName: '',
      dataFormSubmitted: !this.props.createMode,
      columnApi: null,
      disableExportToPdf: false,
      invalidPeriodicity: undefined,
      errorTrace: '',
      projectFilters: [],
      mdx: '',
      queryResult: {},
    };

    async componentDidMount() {
      await this.load();
    }

    async componentDidUpdate(prevProps) {
      if (this.props.bigTable !== prevProps.bigTable) {
        await this.load();
      }
    }

    asyncSetState = async (state = {}) => {
      await new Promise((res) => this.setState(state, res));
    };

    hasNull = (object) => {
      for (let member in object) {
        if (object[member] === null) return true;
        return false;
      }
    };

    async reload() {
      await this.asyncSetState({ reset: false });
      await this.props.reload();
    }

    async load() {
      this.setState({ loading: true });
      try {
        await this.asyncSetState({
          columns: this.props.bigTable.config.columns,
          calculatedColumns: this.props.bigTable.config.calculatedColumns,
          userColumnsDef: this.props.bigTable.config.columns,
          gridConfig: this.hasNull(this.props.bigTable.config.gridConfig)
            ? this.state.gridConfig
            : this.props.bigTable.config.gridConfig,
          sortModel: this.props.bigTable.config.sortModel,
          filterModel: this.props.bigTable.config.filterModel,
          themeConfig: this.props.bigTable.config.themeConfig,
          titleConfig: this.props.bigTable.config.titleConfig,
          totalizerConfig: this.props.bigTable.config.totalizerConfig,
        });

        if (this.props.createMode) {
          await this.asyncSetState({
            datasourceConfig: this.props.bigTable.datasourceConfig,
            datasourceName: this.props.bigTable.datasourceName,
            editing: true,
          });
          await this.setOpenAccordion('BigTableDataMenuItem');
        } else {
          await this.asyncSetState({
            ...this.props.bigTable,
          });
          await this.loadData();
        }

        await this.loadFieldData(this.props.context.project.name, this.props.bigTable.datasourceConfig.cube);
        if (!this.props.viewOnly) {
          const projectFilters = await Api.MdxGlobalFilter.findAll(this.props.context.project.id, true);
          this.setState({
            projectFilters,
          });
        }
        await this.asyncSetState({ readyToDraw: true });

        let pageContent = j('#page-content');
        pageContent.css('overflow', 'unset');

        if (this.state.reset === false) {
          await this.asyncSetState({ reset: true });
        }
      } catch (e) {
        console.error('Error on BigTableApp.load()', {e});
        this.setState({errorTrace: e})
      } finally {
        this.setState({ loading: false });
      }
    }

    async loadFieldData(projectName, cube) {
      const fieldsInfo = await Api.BigTable.findFieldsInfo(projectName, cube);
      this.setState({
        sourceFields: fieldsInfo.filter((f) => f.visible),
      });
    }

    setOpenAccordion = async (key) => {
      let c = null;

      switch (key) {
        case 'BigTableLayoutMenuItem':
          c = LayoutTab;
          break;
        case 'BigTableDataMenuItem':
          c = DataTab;
          break;
        case 'BigTableColumnsMenuItem':
          c = ColumnsTab;
          break;
      }

      await this.setState({
        openAccordion: {
          component: c,
          key: key,
        },
      });
    };

    isDirty() {
      const defaultSelectedMembers = this.props.bigTable.datasourceConfig.sourceFields
        .filter((sf) => sf.visible)
        .flatMap((item) => item.selectedMembers.map((sm) => sm.label));
      const modifiedSelectedMembers = this.state.datasourceConfig.sourceFields
        .filter((sf) => sf.visible)
        .flatMap((item) => item.selectedMembers.map((sm) => sm.label));
      if (!_.isEqual(defaultSelectedMembers, modifiedSelectedMembers)) {
        return true;
      }

      for (const prop of ['columns', 'gridConfig', 'filterModel', 'themeConfig', 'titleConfig', 'totalizerConfig']) {
        if (!_.isEqual(this.state[prop], this.props.bigTable.config[prop])) {
          return true;
        }
      }

      return !_.isEqual(
        this.state.sortModel.map((sm) => `${sm.colId}-${sm.sort}`),
        this.props.bigTable.config.sortModel.map((sm) => `${sm.colId}-${sm.sort}`)
      );
    }

    async loadData() {
      this.setState({ loading: true, errorTrace: '' });

      try {
        const queryReq = {
          ...this.state.datasourceConfig,
          columnConfigs: this.state.columns,
          path: this.props.bigTable?.path || '',
        };
        const queryResult = await Api.BigTable.executeQuery(queryReq, null, this.props.filters);
        const { invalidPeriodicity, mdx } = queryResult.additionalProps;
        if (invalidPeriodicity) {
          UiMsg.warn(
            `${this.props.context.msg.t('attention')}!`,
            this.props.context.msg.t('invalid.date.filter.alert', [this.props.context.msg.t(invalidPeriodicity)])
          );
        }

        const res = queryResult.result;

        const { columns, rows, measureColumns } = processQueryResult({
          columns: this.state.columns,
          queryResult: res,
          sortModel: this.state.sortModel,
        });

        const disableExportToPdf = columns.length * rows.length > this.props.context.bigTableAppConfig.nodeExportLimit;
        this.setState({
          queryResult: res,
          dataColumns: columns,
          measureColumns,
          rows,
          disableExportToPdf,
          invalidPeriodicity,
          mdx,
        });

        const onLoadProps = {
          disableExportToPdf,
          columns: this.state.columns,
          mdx,
        };

        this.props.onLoadData(onLoadProps);

        bimEventBus.emit('BigTableApp:onLoadData', {
          bigTable: this.props.bigTable,
          ...onLoadProps,
        });
      } catch (err) {
        console.error('Error on Mdx query execution', err);
        if (err.isAxiosError) {
          const errorData = err.response.data;
          this.setState({ errorTrace: errorData.message });
        } else {
          UiMsg.ajaxError(this.props.context.msg.t('BigTableApp.MdxQueryError'), err);
        }
      } finally {
        this.setState({ loading: false });
      }
    }

    onDataFormSubmit = async (queryResult, fields, dataConfig) => {
      const { invalidPeriodicity, mdx } = queryResult.additionalProps;
      if (invalidPeriodicity) {
        UiMsg.warn(
          `${this.props.context.msg.t('attention')}!`,
          this.props.context.msg.t('invalid.date.filter.alert', [this.props.context.msg.t(invalidPeriodicity)])
        );
      }

      const data = queryResult.result;

      if (this.state.datasourceConfig.cube !== dataConfig.cube) {
        this.setState({ columns: [] });
      }

      const cols = data ? generateCols(data.data) : [];

      const normalizedData = {
        columns: cols,
        defaultColumns: cols,
        rows: data ? generateRows(data.data) : [],
      };

      await this.updateData(normalizedData, fields);

      const filteredColumns = this.state.columns.filter((col) => {
        if (col.columnType === 'TimeDimension') {
          return (
            cols.some((c) => c.field.split('.')[0].slice(1) === col.key) || cols.some((c) => c.field === `[${col.key}]`)
          );
        } else {
          return (
            cols.some((c) => c.field === `[${col.key}]`) ||
            this.state.calculatedColumns.some((cc) => cc.key === col.key)
          );
        }
      });

      const processedData =
        this.state.dataFormSubmitted &&
        processQueryResult({
          columns: filteredColumns,
          queryResult: data,
          sortModel: this.state.sortModel,
        });

      const columnsFromState = this.state.columns.filter((stateColumn) =>
        (processedData?.columns ?? normalizedData.columns).some(
          (column) => column.field.replace(/\[|\]/g, '').split('.')[0] === stateColumn.key.split('.')[0]
        )
      );

      await this.asyncSetState({
        columns: columnsFromState,
        ...(processedData?.columns ? { dataColumns: processedData?.columns } : {}),
        datasourceConfig: dataConfig,
        datasourceName: dataConfig.datasource,
        dataFormSubmitted: true,
        sortModel: this.updateSortModel(),
        queryResult: data,
        ...(processedData?.rows ? { rows: processedData?.rows } : {}),
        invalidPeriodicity,
        mdx,
      });
    };

    updateSortModel = (data) => {
      let tempSortModel = _.cloneDeep(!!data ? data : this.state.sortModel);
      let validColumnState = true;
      if (this.state.columnApi) {
        validColumnState = _.isEmpty(this.state.columnApi.getColumnState());
      }
      let tempColumnState = _.cloneDeep(
        validColumnState ? this.state.sortModel : this.state.columnApi.getColumnState()
      );

      if (tempSortModel.length === 0) {
        return tempColumnState;
      }

      // Obs Ciço: coloquei um _.uniqBy nessas listas por que esbarrei em um problema aonde estava duplicando as colunas
      // até virar um numero gigante e travar o navegador. Infelizmente não identifiquei a causa raiz, então fica ai
      // esse meaw /\_/\
      tempSortModel = _.uniqBy(tempSortModel, (c) => c.colId);
      tempColumnState = _.uniqBy(tempColumnState, (c) => c.colId);
      let temp = [];
      tempSortModel.forEach((sortModelItem) => {
        tempColumnState.forEach((columnStateItem) => {
          if (sortModelItem.colId === columnStateItem.colId) {
            sortModelItem.sort = columnStateItem.sort;
            temp.push(sortModelItem);
          }
        });
      });
      temp = _.uniqBy(temp, (c) => c.colId);
      let difference = _.differenceBy(tempSortModel, temp);
      difference = difference.concat(temp);
      return difference;
    }

    static getDerivedStateFromError(error) {
      return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
      console.error(error, errorInfo);
    }

    findColumn = (columns, column) => {
      if (columns.length === 0) {
        return null;
      }

      return columns.find((c) => c.key === column.field);
    };

    onChangeName = (data) => {
      if (this.state.titleConfig.title === '') {
        this.setState({
          titleConfig: {
            ...this.state.titleConfig,
            title: data.name,
          },
        });
      }

      if (this.state.titleConfig.description === '') {
        this.setState({
          titleConfig: {
            ...this.state.titleConfig,
            description: data.description,
          },
        });
      }
    };

    chooseFieldType = (columnInfo, col) => {
      const type = columnInfo?.type || col.type;
      switch (type) {
        case 'Url':
          return 'Url';
        case 'Regular':
          return 'TEXT';
        case 'Measure':
          return 'NUMERIC';
        case 'TimeDimension':
          return 'DATE';
        default:
          return columnInfo?.fieldType || col.fieldType;
      }
    };

    chooseFormat = (columnInfo, col) => {
      if (columnInfo?.type === 'Url' || col.type === 'Url') {
        return 'url';
      }

      if (columnInfo?.fieldType === 'NUMERIC' || col.fieldType === 'NUMERIC') {
        return 'number';
      }

      return 'text';
    };

    chooseColumnType = (columnInfo, col) => {
      if (columnInfo?.type === 'Url' || col.type === 'Url') {
        return 'Url';
      }
      return columnInfo?.type || col.type; //Measure, Regular ou TimeDimension
    };

    defaultColumnConfig = (col, isCalculatedColumn = false) => {
      const { gridConfig, sourceFields, totalizerConfig } = this.state;
      const columnInfo = sourceFields.find((f) => col.field === f.value);
      const themeConfig = themes.find((t) => t.value === this.state.themeConfig.theme);
      const persistedColumn = this.props.bigTable.config.columns.find((c) => col.field === c.key);
      const stateColumn = this.state.columns.find((c) => col.field === c.key);

      const column = {
        title: col.label || columnInfo?.label,
        key: col.hierarchy !== '' ? col.hierarchy : col.field,
        hierarchy: col.hierarchy,
        fieldType: isCalculatedColumn ? 'NUMERIC' : this.chooseFieldType(columnInfo, col),
        formatString: columnInfo
          ? columnInfo.formatString
          : isCalculatedColumn && persistedColumn
          ? persistedColumn.formatString
          : 'Standard',
        measureAggregator: columnInfo ? columnInfo.measureAggregator : 'SUM',
        columnType: isCalculatedColumn ? 'Measure' : this.chooseColumnType(columnInfo, col),

        width: 200,
        sortable: true,
        filter: true,
        resizable: !gridConfig.sizeToFit,
        align: 'left',
        verticalAlign: 'center',
        type: 'text',
        showColumn: true,
        textFormat: themeConfig ? themeConfig.columnConfig.textFormat : [],
        fontSize: gridConfig.fontSize,
        autoHeight: false,
        customized: false,
        pinned: 'none',
        headerFilters: true,
        textSlice: true,

        suffix: '',
        prefix: '',
        prefixSuffixSpacing: true,
        format: isCalculatedColumn ? persistedColumn?.format ?? 'number' : this.chooseFormat(columnInfo, col),
        decimals: 2,

        indicatorsType: 'none',

        textColorType: 'automatic',
        textColor: themeConfig ? themeConfig.columnConfig.textColor : '#000000',
        textColorValueType: 'perc_max',
        textColorRanges: [
          { value: 0, color: '#FFFFFFFF', valueOption: 'number' },
          { value: 100, color: '#FFFFFFFF', valueOption: 'number' },
        ],
        textColorGradients: [
          { value: 0, color: '#FFFFFFFF', valueOption: 'number' },
          { value: 100, color: '#FFFFFFFF', valueOption: 'number' },
        ],
        textAutomaticThreshold: 0.65,

        cellColorType: 'none',
        cellColorValueType: 'perc_max',
        cellColorRanges: [
          { value: 0, color: '#FFFFFFFF', valueOption: 'number' },
          { value: 100, color: '#FFFFFFFF', valueOption: 'number' },
        ],
        cellColorGradients: [
          { value: 0, color: '#FFFFFFFF', valueOption: 'number' },
          { value: 100, color: '#FFFFFFFF', valueOption: 'number' },
        ],

        cellBarType: 'perc_max',
        cellBarFixedValue: 0,
        cellBarMeasureColumn: '',
        cellBarColorValueType: 'perc',
        cellBarColorType: 'fixed',
        cellBarColor: '#269C59',
        cellBarColorRanges: [
          { value: 0, color: '#FFFFFFFF', valueOption: 'number' },
          { value: 100, color: '#FFFFFFFF', valueOption: 'number' },
        ],
        cellBarColorGradients: [
          { value: 0, color: '#FFFFFFFF', valueOption: 'number' },
          { value: 100, color: '#FFFFFFFF', valueOption: 'number' },
        ],
        totalizerConfig: {
          enabled: false,
          operator: 'SUM',
          fontSize: totalizerConfig.fontSize,
        },
        imageConfig: {
          enabled: false,
          size: 'SMALL',
          height: imageSizes['SMALL'].value,
          frameStyle: 'SQUARE',
          borderRadius: imageFrameStyles['SQUARE'].value,
          border: false,
          borderColor: '#FFFFFF',
          borderWidth: 1,
        },
        clickConfig: {
          action: 'NO_ACTION',
          props: {},
        },
        calculationType: isCalculatedColumn
          ? persistedColumn?.calculationType ?? stateColumn.calculationType
          : undefined,
        calculationProps: isCalculatedColumn
          ? persistedColumn?.calculationProps ?? stateColumn.calculationProps
          : undefined,
      };

      if (columnInfo) {
        if (columnInfo.fieldType === 'DATE') {
          column.format = 'time';
        } else if (columnInfo.fieldType === 'NUMERIC') {
          switch (columnInfo.formatString) {
            case 'Percent': {
              column.format = 'percent';
              column.align = 'right';
              break;
            }
            case 'Currency': {
              column.format = 'currency';
              column.align = 'right';
              break;
            }
            case 'Standard': {
              column.format = 'numeric';
              column.decimals = 0;
              column.align = 'right';
              break;
            }
            case 'Fixed': {
              column.format = 'fixed';
              column.decimals = 2;
              column.align = 'right';
              break;
            }
            case 'Accounting': {
              column.format = 'Accounting';
              column.decimals = 0;
              column.align = 'right';
              break;
            }
            case 'AccountingFixed': {
              column.format = 'Accounting';
              column.decimals = 2;
              column.align = 'right';
              break;
            }
          }
        }
      }

      return column;
    };

    resetGrid = async () => {
      await this.asyncSetState({ reset: false });
      await new Promise((res) => setTimeout(res, 50));
      await this.asyncSetState({ reset: true });
    };

    updateData = async (data, fieldData) => {
      let newOrder = [];
      if (this.state.columns.length > 0) {
        this.state.columns.forEach((col) => {
          fieldData.forEach((c) => {
            if (c.field === col.key) newOrder.push(c);
          });
          if (col.calculationType) newOrder.push(col);
        });
        let differenceList = _.difference(fieldData, newOrder);
        if (differenceList.length > 0) {
          differenceList = _.concat(differenceList, newOrder);
          newOrder = differenceList;
        }
      } else {
        newOrder = fieldData;
      }

      let configCols = newOrder
        .filter((f) => (f.selected && f.visible) || f.calculationType)
        .filter((f) => (f.type === 'Measure' && f.axis === 'COLUMN') || f.axis === 'ROW' || f.calculationType);

      let columnsConfig = [];
      configCols.forEach((col) => {
        if (col.calculationType) {
          columnsConfig.push(col);
          return;
        }
        let stateCol = this.findColumn(this.state.columns, col);
        if (stateCol) {
          columnsConfig.push(stateCol);
          return;
        }

        const column = this.defaultColumnConfig(col, !!col.calculationType);
        columnsConfig.push(column);
      });

      let measureColumns = columnsConfig
        .filter((f) => f.fieldType === 'NUMERIC')
        .map((f) => ({ label: f.title, value: f.key }));

      let temp = [];
      if (this.props.bigTable.config.columns.length > 0) {
        const differentItems = _.difference(this.state.userColumnsDef, columnsConfig);
        this.props.bigTable.config.columns.forEach((st) => {
          const tempFind =
            differentItems.find((di) => di.key === st.key) || columnsConfig.find((cc) => cc.key === st.key);
          if (tempFind) temp.push(tempFind);
        });
        temp.push(..._.difference(columnsConfig, temp));
      } else {
        const differentItems = _.difference(columnsConfig, this.state.columns);
        if (this.state.columns.length > 0 && differentItems.length > 0) {
          columnsConfig.forEach((cc) => {
            const tempFind = this.state.columns.find((st) => st.key === cc.key);
            if (tempFind) temp.push(cc);
          });
          temp.push(...differentItems);
          if (this.state.userColumnsDef.length > 0) {
            const justAnotherTemp = [];
            temp.forEach((t) => justAnotherTemp.push(this.state.userColumnsDef.find((ucd) => ucd.key === t.key) || t));
            temp = justAnotherTemp;
          }
        } else {
          temp.push(...columnsConfig);
        }
      }
      temp = _.uniqBy(temp, (it) => {
        if (it.key.includes('.')) {
          const parts = it.key.split('.');
          return parts[0];
        } else {
          return it.key;
        }
      });

      await this.asyncSetState({
        measureColumns: measureColumns,
        columns: temp,
        defaultColumns: temp,
        dataColumns: data.columns,
        rows: data.rows,
        sourceFields: fieldData,
      });

      await this.resetGrid();
    };

    onColumnChange = async (index, values) => {
      if (_.isNil(index)) {
        return;
      }

      const { gridConfig } = this.state;
      let columns = [].concat(this.state.columns);
      const tempCurrentColumn = _.cloneDeep(this.props.bigTable.config.columns[index]);
      const tempNewValues = values;
      if (tempCurrentColumn) {
        delete tempCurrentColumn['customized'];
      }
      delete tempNewValues['customized'];
      columns[index] = {
        ...this.state.columns[index],
        ...values,
        resizable: !gridConfig.sizeToFit,
        customized: !_.isEqual(tempCurrentColumn, tempNewValues),
      };

      let normalizedData = {};
      if (this.state.queryResult.data) {
        normalizedData = processQueryResult({
          columns,
          queryResult: this.state.queryResult,
          sortModel: this.state.sortModel,
        });
      }

      await this.asyncSetState({
        columns,
        userColumnsDef: columns,
        dataColumns: normalizedData.columns ?? [],
        rows: normalizedData.rows ?? [],
      });

      await this.updateRuntimeData();
      await this.resetGrid();
    };

    onColumnsOrderChange = (orderChange) => {
      let columns = [].concat(this.state.columns);
      columns.splice(orderChange.fromIdx, 1);
      columns.splice(orderChange.toIdx, 0, this.state.columns[orderChange.fromIdx]);

      let sortModel = [];
      const temp = this.updateSortModel();
      columns.forEach((col) => {
        let sm = temp.find((sm) => sm.colId === `[${col.key}]`);
        if (sm != null) sortModel.push(sm);
      });

      this.setState({ columns, sortModel });
      this.resetGrid();
    };

    toggleColumn = (index) => {
      let columns = [].concat(this.state.columns);
      columns[index].showColumn = !columns[index].showColumn;
      this.setState({ columns });
    };

    onResetColumn = (index, isCalculatedColumn = false) => {
      const columns = this.state.columns.slice();
      const column = columns[index];

      columns[index] = this.defaultColumnConfig(
        {
          field: column.key,
          hierarchy: column.hierarchy ?? '',
          label: column.label || column.title,
        },
        isCalculatedColumn
      );

      this.setState({ columns, userColumnsDef: columns });

      this.loadData();
    };

    onGridConfigChange = (data) => {
      const { gridConfig } = this.state;
      let reset = false;

      let columns = [];
      if (
        this.props.bigTable.config.gridConfig.sizeToFit !== data.sizeToFit ||
        this.props.bigTable.config.gridConfig.fontSize !== data.fontSize
      ) {
        columns = this.state.columns.map((column) => {
          return {
            ...column,
            fontSize: column.customized ? column.fontSize : data.fontSize,
            resizable: !data.sizeToFit,
          };
        });
      } else {
        columns = this.state.columns;
      }

      if (
        gridConfig.rowHeight !== data.rowHeight ||
        this.props.bigTable.config.gridConfig.sizeToFit !== data.sizeToFit
      ) {
        reset = true;
      }

      this.setState({
        gridConfig: {
          ...this.state.gridConfig,
          ...data,
        },
        columns,
      });

      if (reset) {
        this.resetGrid();
      }
    };

    onTotalizerConfigChange = (data) => {
      this.setState({
        totalizerConfig: data,
      });

      this.resetGrid();
    };

    onTitleConfigChange = (data) => {
      this.setState({
        titleConfig: data,
      });
    };

    onSortChanged = async (data) => {
      if (_.isEqual(this.state.sortModel, data)) {
        return;
      }

      const sortModel = this.updateSortModel(data);

      const isSorting = data.find((c) => c.sort !== null);

      if (isSorting) {
        const { columns, rows } = processQueryResult({
          columns: this.state.columns,
          queryResult: this.state.queryResult,
          sortModel: sortModel,
        });

        this.setState({
          dataColumns: columns,
          rows,
          sortModel,
        });

        await this.updateRuntimeData();
      }
    };

    onFilterChanged = async (data) => {
      this.setState({
        filterModel: data,
      });
      await this.updateRuntimeData();
    };

    updateRuntimeData = async () => {
      if (this.props.viewOnly) {
        const { bigTableDto, filter } = this.buildRuntimeData();
        await Api.BigTable.updateBigTableRuntimeData(bigTableDto, filter);
      }
    };

    onColumnResize = (changedColumns) => {
      const columns = this.state.columns.slice();

      let widthChanged = false;

      for (const changedColumn of changedColumns) {
        const { colId, width } = changedColumn;
        const colMatch = columns.find((c) => colId === `[${c.key}]`);
        if (colMatch && colMatch.width !== width) {
          colMatch.width = width;
          widthChanged = true;
        }
      }

      if (widthChanged) {
        this.setState({ columns });
      }
    };

    onChangeTheme = (theme, changeDefault = true) => {
      if (changeDefault) {
        theme.useDefaultTheme = false;
      }

      let themeConfig = themes.find((t) => t.value === theme.theme);
      if (!themeConfig) {
        return;
      }

      if (!theme.custom && themeConfig) {
        let newGridConfig = {
          ...this.state.gridConfig,
          ...themeConfig.gridConfig,
        };

        let columnsClone = this.state.columns.map((col) => {
          return {
            ...col,
            ...themeConfig.columnConfig,
          };
        });

        this.setState({
          gridConfig: newGridConfig,
          columns: columnsClone,
          themeConfig: theme,
        });
      } else {
        this.setState({
          themeConfig: theme,
        });
      }
    };

    buildConfigObject = () => {
      return {
        gridConfig: this.state.gridConfig,
        totalizerConfig: this.state.totalizerConfig,
        titleConfig: this.state.titleConfig,
        themeConfig: this.state.themeConfig,
        columns: this.state.columns,
        calculatedColumns: this.state.calculatedColumns,
        sortModel: this.updateSortModel(),
        filterModel: this.state.filterModel,
      };
    };

    getColumnApi = (data) => {
      this.setState({ columnApi: data });
    };

    buildRuntimeData = () => {
      const bigTableDto = {
        id: this.props.bigTable.id,
        parentFolder: this.state.parentFolder,
        name: this.state.name,
        description: this.state.description,
        config: this.buildConfigObject(),
        datasourceConfig: this.state.datasourceConfig,
        datasourceName: this.state.datasourceName,
      };
      const { filter } = FilterService.createFilterParam(this.props.filters, true);
      return { bigTableDto, filter };
    };

    render() {
      const { hasError, loading, editing, disableExportToPdf, gridConfig } = this.state;

      if (hasError) {
        return (
          <div className="BngBigTable__errorPanel">
            <ServerErrorPage />
          </div>
        );
      }

      if (loading) {
        return (
          <div className="fill-w fill-h">
            <LoadingCenter />
          </div>
        );
      }

      const disableActionButtons = !this.state.dataFormSubmitted;
      const showAdaHelp = !this.state.path && disableActionButtons;
      const configObject = this.buildConfigObject();

      return (
        <div className={`BngBigTable__App ${this.props.withFilters ? 'withFilters' : ''}`}>
          {!this.props.viewOnly && (
            <>
              <BetaLabelContainer />

              {this.props.context.permissions.isAtLeastExplorer() && (
                <EditObjectContainer
                  checked={editing}
                  toggleMenu={false}
                  onChange={() => this.setState({ editing: !editing })}
                  disabled={disableActionButtons}
                />
              )}

              <BigTableBreadcrumbToolbar
                id={this.state.id}
                persisted={!this.state.id}
                path={this.state.path}
                caption={this.state.name}
                mobile={this.state.mobile}
                dataSourceCaption={this.state.datasourceName}
                onReload={() => this.reload()}
                disabled={!_.isFinite(this.state.id) || disableActionButtons}
                filters={this.props.filters}
                disableExportToPdf={disableExportToPdf}
                onExportToPdf={async ({ defaultHandler }) => {
                  try {
                    const { bigTableDto, filter } = this.buildRuntimeData();
                    await Api.BigTable.updateBigTableRuntimeData(bigTableDto, filter);
                    await defaultHandler();
                  } catch (e) {
                    console.error(e);
                    UiMsg.ajaxError(null, e);
                  }
                }}
                onExportToCsv={async (event) => {
                  event.preventDefault();

                  try {
                    const { bigTableDto, filter } = this.buildRuntimeData();
                    await Api.BigTable.exportRuntimeBigTableToCsv(bigTableDto, filter);
                  } catch (e) {
                    console.error(e);
                    UiMsg.ajaxError(null, e);
                  }
                }}
                exportState={{
                  mdx: this.state.mdx,
                  columns: this.state.columns,
                }}
              />
            </>
          )}

          <div className="BngBigTable__mainContainer">
            <div className="BngBigTable__mainPanel">
              {this.state.errorTrace ? (
                <DashboardItemInformation
                  message={this.props.context.msg.t('dashboard.item.error.message')}
                  errorTrace={this.state.errorTrace}
                  showPlaceholder={false}
                  showErrorDialog={() => ObjectErrorPopup({ message: this.state.errorTrace })}
                />
              ) : _.isEmpty(this.state.rows) ? (
                <DashboardItemInformation
                  path={this.props.bigTable?.path ?? 'newbt.bigtable'}
                  showErrorDialog={this.showErrorDialog}
                />
              ) : (
                <AGTableWrapper
                  id={this.state.id ? '' + this.state.id : 'edit'}
                  name={this.state.name}
                  description={this.state.description}
                  gridConfig={gridConfig}
                  totalizerConfig={this.state.totalizerConfig}
                  titleConfig={this.state.titleConfig}
                  dataColumns={this.state.dataColumns}
                  columns={this.state.columns}
                  rows={this.state.rows}
                  sortModel={this.state.sortModel}
                  filterModel={this.props.bigTable.config.filterModel}
                  editing={this.state.editing}
                  sizeToFit={gridConfig.sizeToFit}
                  reset={this.state.reset}
                  readyToDraw={this.state.readyToDraw}
                  onColumnResize={this.onColumnResize}
                  onSortChanged={this.onSortChanged}
                  onFilterChanged={this.onFilterChanged}
                  getColumnApi={this.getColumnApi}
                  bigTablePath={this.props.bigTable.path}
                />
              )}

              {showAdaHelp && <BigTableAnalysisHelp />}
            </div>

            {!this.props.viewOnly && (
              <>
                {this.state.menuOpen && <div className="BngBigTable__spacer"></div>}

                {this.state.editing && (
                  <div className="BngBigTable__rightMenu">
                    <BigTableRightMenu
                      id={this.props.bigTable.id}
                      path={this.state.path}
                      folder={this.state.parentFolder}
                      name={this.state.name}
                      description={this.state.description}
                      config={configObject}
                      datasourceConfig={this.state.datasourceConfig}
                      datasourceName={this.state.datasourceName}
                      onChangeName={this.onChangeName}
                      dirty={this.isDirty()}
                      onSave={() => this.reload()}
                      onMenuOpenChange={(open) => this.setState({ menuOpen: open })}
                      openAccordion={this.state.openAccordion}
                      themeConfig={this.state.themeConfig}
                      gridConfig={gridConfig}
                      totalizerConfig={this.state.totalizerConfig}
                      titleConfig={this.state.titleConfig}
                      columns={this.state.columns}
                      calculatedColumns={this.state.calculatedColumns}
                      measureColumns={this.state.measureColumns}
                      onChangeTheme={this.onChangeTheme}
                      onGridConfigChange={this.onGridConfigChange}
                      onTotalizerConfigChange={this.onTotalizerConfigChange}
                      onTitleConfigChange={this.onTitleConfigChange}
                      onColumnChange={this.onColumnChange}
                      onColumnsOrderChange={this.onColumnsOrderChange}
                      buildDefaultColumnConfig={(col) => this.defaultColumnConfig(col)}
                      onColumnSave={async ({ columns, calculatedColumns, measureColumns }) => {
                        this.setState({
                          columns,
                          calculatedColumns: calculatedColumns ?? this.state.calculatedColumns,
                          measureColumns: measureColumns ?? this.state.measureColumns,
                        });
                      }}
                      loadData={async () => await this.loadData()}
                      onResetColumn={this.onResetColumn}
                      setOpenAccordion={this.setOpenAccordion}
                      toggleColumn={this.toggleColumn}
                      onDataFormSubmit={this.onDataFormSubmit}
                      dataFormSubmitted={this.state.dataFormSubmitted}
                      updateSortModel={this.updateSortModel}
                      printer={this.state.printer}
                      filters={this.props.filters}
                      projectFilters={this.state.projectFilters}
                      onClearFilter={this.props.onClearFilter}
                      onDatasourceConfigChange={(data) => {
                        this.setState({
                          datasourceConfig: {
                            ...data,
                          },
                        });
                      }}
                    />
                  </div>
                )}
              </>
            )}

            {showAdaHelp && <div className="backgroundBlocker"></div>}
          </div>
        </div>
      );
    }
  }
);

export default BigTableApp;
