import './NewFolderDialog.css';

import React from 'react';
import PropTypes from 'prop-types';

import { FastField, Formik } from 'formik';

import BngForm from 'components/bng/form/BngForm';
import Dialog from 'components/ui/Dialog';
import ContextEnhancer from 'components/ContextEnhancer';
import BngField from 'components/bng/form/BngField';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import bngYup from 'components/bng/form/yup/BngYup';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import BngInputIconSelector from 'components/bng/form/BngInputIconSelector';
import BimEventBus from 'BimEventBus';

const NewFolderSchema = bngYup((yup) => {
  return yup.object().shape({
    name: yup.string().max(64).trim().required().default('').validFolderName(),
    icon: yup.string().max(64).trim().required().default(''),
  });
});

class NewFolderDialogInt extends React.Component {
  static propTypes = {
    parentFolder: PropTypes.string.isRequired,
    onCreateFolder: PropTypes.func,
  };

  static defaultProps = {
    parentFolder: '',
    onCreateFolder: _.noop,
  };

  state = {};

  initialValues = NewFolderSchema.default();

  save = async (values, actions) => {
    try {
      const projectId = this.props.context.project.id;
      const folderData = {
        projectId,
        parentFolder: this.props.parentFolder,
        path: `${this.props.parentFolder}/${values.name.trim()}`,
        ...values,
        name: values.name.trim(),
      };
      await Api.Project.createFolder(folderData);
      BimEventBus.emit(Api.Project.FOLDER_CREATED_EVENT);
      await Api.updateJsf();
      await this.props.onCreateFolder(folderData);
      this.props.closeModal();
    } catch (e) {
      if (_.get(e, 'response.status', 0) === 409) {
        console.warn(e);
        actions.setFieldError('name', this.props.context.msg.t('folder.already.exists'));
      } else {
        console.error(e);
        UiMsg.error(null, e);
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  showIconField = () => {
    const { context, parentFolder } = this.props;
    const projectFolder = `/${context.project.name}`;
    return parentFolder === projectFolder;
  };

  render() {
    const { context, closeModal } = this.props;

    return (
      <Formik
        initialValues={this.initialValues}
        validationSchema={NewFolderSchema}
        onSubmit={this.save}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(formikProps) => {
          if (formikProps.values.icon === '') {
            formikProps.setFieldValue('icon', 'folder');
          }

          return (
            <Dialog
              className="NewFolderDialog"
              title={context.msg.t('managefiles_addpopup_title')}
              newDialogLayout={true}
              onClose={closeModal}
              loading={formikProps.isSubmitting}
              verticalCenter={true}
            >
              <BngForm>
                <Dialog.Body>
                  <div className="FieldAndIconContainer">
                    <div>
                      <FastField
                        component={BngField}
                        label={context.msg.t('managefiles_addpopup_label_enterFolderName')}
                        name="name"
                        autoFocus
                        required
                      />
                    </div>
                    {this.showIconField() && (
                      <div>
                        <FastField
                          component={BngField}
                          inputComponent={BngInputIconSelector}
                          label={context.msg.t('icon')}
                          name="icon"
                          showErrors={false}
                          round
                          required
                        />
                      </div>
                    )}
                  </div>
                </Dialog.Body>
                <Dialog.Footer>
                  <DefaultDialogActions {...this.props} okLabel={'create'} />
                </Dialog.Footer>
              </BngForm>
            </Dialog>
          );
        }}
      </Formik>
    );
  }
}

export const NewFolderDialog = ContextEnhancer(NewFolderDialogInt);

export default NewFolderDialog;
