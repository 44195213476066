import './ObjectSearch.css';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';
import BngButton from 'components/bng/ui/BngButton';
import BngClickOutsideOverlay from 'components/bng/ui/BngClickOutsideOverlay';
import useTranslation from 'components/hooks/useTranslation';
import UiMsg from 'components/ui/UiMsg';
import { useEventListener } from 'components/hooks/useEventListener';

function splitFolder(t = _.noop, path = '') {
  if (!path) {
    return;
  }

  let split = path.split('/');
  split = split.slice(2, split.length - 1);
  if (split.length > 0 && /^\d+$/.test(split[0])) {
    split[0] = t('personal.folder');
  }
  return split;
}

function folderName(t = _.noop, path = '') {
  return '/' + splitFolder(t, path).join('/');
}

function logoSize() {
  const logo = document.getElementById('logo-project');
  return logo?.getBoundingClientRect().width || 180;
}

function isVisible(element) {
  return !!(element && (element.offsetWidth || element.offsetHeight || element.getClientRects().length));
}

function calculateSearchSize() {
  let size = logoSize() + 82;

  const reducedMenuButton = document.querySelector('.navbar-inner .reducedRightMenuButton');
  const navItems = document.querySelectorAll('.navbar-inner li.nav-bar-button');
  if (reducedMenuButton && isVisible(reducedMenuButton)) {
    size += 50;
  } else if (navItems.length > 0) {
    navItems.forEach((item) => {
      size += item.offsetWidth;
    });
  } else {
    size += 480;
  }

  return window.innerWidth - size;
}

export default function ObjectSearch({
  items = [],
  onToggle = () => {},
  searching = false,
  fetchMenuItems = () => {},
  className = '',
}) {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedButtonFilters, setSelectedButtonFilters] = useState([]);
  const [searchInputWidth, setSearchInputWidth] = useState(0);

  const inputRef = useRef(null);

  useEffect(() => {
    if (_.isEmpty(items)) {
      fetchMenuItems();
    }
  }, []);

  useEffect(() => {
    const newWidth = calculateSearchSize();
    setSearchInputWidth(newWidth);
  }, [searching]);

  const filteredItems = useMemo(() => {
    const search = searchTerm.toLowerCase();
    return items.filter((item) => {
      const matchesSearchTerm = item.displayName.toLowerCase().includes(search);
      const matchesFilter =
        selectedButtonFilters.length === 0 || selectedButtonFilters.some((filter) => item.path.endsWith(filter));
      return matchesSearchTerm && matchesFilter;
    });
  }, [searchTerm, selectedButtonFilters, items]);

  const buttonFilterOpts = useMemo(
    () => [
      { label: t('.analysis'), value: '.analysis', icon: 'bar_chart' },
      { label: t('.dashboard'), value: '.dashboard', icon: 'space_dashboard' },
      { label: t('.kpi'), value: '.kpi', icon: 'speed' },
      { label: t('.bigtable'), value: '.bigtable', icon: 'view_list' },
      { label: t('.newmap'), value: '.newmap', icon: 'place' },
    ],
    []
  );

  const handleToggle = () => {
    onToggle();
    setSearchTerm('');
    setSelectedButtonFilters([]);
    inputRef.current?.focus();
  };

  return (
    <li className={`nav-search-new-item ${className}`}>
      {searching && <BngClickOutsideOverlay onClick={handleToggle} />}
      <div>
        <div className={`nav-search-new ${searching ? 'expanded' : ''}`}>
          <div
            className={`nav-search-input-box ${searching ? '' : 'hidden-bottom'}`}
            style={{ width: `${searchInputWidth}px` }}
          >
            <Icon icon="search" className="nav-search-button-on-input" onClick={onToggle} />
            <input
              type="text"
              ref={inputRef}
              placeholder={t('header.search')}
              onChange={(e) => {
                setSearchTerm(e.target.value ?? '');
              }}
              value={searchTerm}
              className="nav-search-input-new"
            />
            <div
              className="nav-search-button-on-input-right-box"
              onClick={async () => {
                try {
                  await Api.Bng.accessObjectSearch();
                  const redirectUrl = Api.buildUrl('/objects-map.iface');
                  window.location.replace(redirectUrl);
                } catch (e) {
                  console.error(e);
                  UiMsg.ajaxError(null, e);
                }
              }}
            >
              <span className="nav-search-advanced-link">{t('advanced.search')}</span>
            </div>
          </div>
          <Icon icon={searching ? 'close' : 'search'} className="nav-search-button" onClick={handleToggle} />
        </div>
        <div className={`nav-search-input-new-line ${searching ? '' : 'hidden-bottom'}`}></div>
        {searching && searchTerm && (
          <div className={`SearchResultDiv`} style={{ width: `${searchInputWidth}px` }}>
            <div className={`objectsButtonsWrapper`}>
              {buttonFilterOpts.map((type, idx) => (
                <BngButton
                  key={idx}
                  className={`object-type-buttons ${selectedButtonFilters.includes(type.value) ? 'is-selected' : ''}`}
                  icon={type.icon}
                  onClick={() => {
                    const filterValue = type.value;
                    setSelectedButtonFilters((prevFilters) => {
                      const newFilters = prevFilters.includes(filterValue)
                        ? prevFilters.filter((filter) => filter !== filterValue)
                        : [...prevFilters, filterValue];
                      return newFilters;
                    });
                  }}
                >
                  {type.label}
                </BngButton>
              ))}
            </div>
            {filteredItems.length > 0 ? (
              <ul className="search-results">
                {filteredItems.map((item, idx) => (
                  <li
                    key={idx}
                    className="search-result-item"
                    onClick={() => {
                      const url = Api.buildUrl('/load.iface', {
                        content: item.path,
                      });
                      window.location.replace(url);
                    }}
                  >
                    <div className="search-item-icon">
                      <Icon icon={item.icon} />
                    </div>
                    <div className="search-item-details">
                      <div className="search-item-name">{item.displayName}</div>
                      <div className="search-item-folder">{folderName(t, item.path)}</div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div id="search-not-found">{t('results.search.not.found')}</div>
            )}
          </div>
        )}
      </div>
    </li>
  );
}
