import { useRef } from 'react';
import useInterval from 'components/hooks/useInterval';
import Api from 'components/Api';

const checkSession = async () => {
  try {
    await Api.User.me();
  } catch (ignored) {}
};

export default function useSessionMonitor() {
  const $promise = useRef();

  useInterval(() => {
    if ($promise.current) {
      return;
    }

    $promise.current = checkSession().finally(() => {
      $promise.current = null;
    });
  }, 30 * 1000);
}
