import styles from './UsersPage.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import Api from 'components/Api';
import BngIconButton from 'components/bng/ui/BngIconButton';
import Utils from 'components/Utils';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';
import BngAvatar from 'components/bng/ui/BngAvatar';
import UserGroupListDialog from 'components/bng/pages/admin/users/UserGroupListDialog';
import { MODALS } from 'components/ui/redux/Actions';
import BngDropdown from 'components/bng/ui/BngDropdown';
import BngDropdownTags from 'components/bng/ui/BngDropdownTags';
import ProfileDialog from 'components/ui/profile/ProfileDialog';
import OpConfirmation from 'components/ui/OpConfirmation';
import MobileUserPermission from 'components/ui/profile/MobileUserPermission';
import bngYup from 'components/bng/form/yup/BngYup';
import InviteUsersDialog from 'components/bng/pages/admin/users/InviteUsersDialog';
import { BimachineBadge } from 'components/ui/profile/ProfileCard';
import ActivityDialog from 'components/bng/pages/admin/activity/ActivityDialog';
import CreateUserDialog from 'components/bng/pages/admin/users/CreateUserDialog';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import FilterOpts from 'components/bng/pages/common/filter/FilterOpts';
import FilterDropdown from 'components/bng/pages/common/filter/FilterDropdown';
import CrudPageLayout from 'components/bng/pages/common/layout/CrudPageLayout';
import ChangeRoleDropdown from 'components/bng/pages/admin/users/ChangeRoleDropdown';
import AddAdditionalDialog, { ADDITIONALS } from 'components/bng/accounts/additionals/AddAdditionalDialog';
import LdapUserDialog from 'components/bng/pages/admin/users/LdapUsersDialog';
import BngTag from 'components/bng/ui/BngTag';

export default function UsersPage() {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [loading, setLoading] = useState(false);
  const [tableSortMode, setTableSortMode] = useState({ displayName: 'ASC' });
  const [users, setUsers] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [filters, setFilters] = useState({ searchTerm: '', filterButton: {} });
  const [selectedRow, setSelectedRow] = useState();
  const [userMetrics, setUserMetrics] = useState({});

  const fetchData = async () => {
    setLoading(true);
    try {
      const fetchedUsers = await Api.Project.findAllUsers(context.project.id);
      setUsers(fetchedUsers);

      const groups = await Api.UserGroup.findAllUserGroups(context.project.id);
      groups.sort((o1, o2) => Utils.Strings.compareIgnoreCase(o1.name, o2.name));
      setUserGroups(groups);

      Api.Account.fetchResourceUsage(context.accountId, ADDITIONALS.USERS.key)
        .then((fetchedMetric) =>
          setUserMetrics({
            ...fetchedMetric,
            remainingSpaces: fetchedMetric.available - fetchedMetric.usage,
          }),
        )
        .catch((e) => console.error('Error while fetching Structure usage', e));
    } catch (e) {
      console.error('Error on UsersPage.fetchData()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const currentUserIsMaster = users.find((projectUser) => projectUser.user.id === context.user.id)?.isMaster ?? false;
  const filteredUsers = filterUsers(filters, users, tableSortMode, userGroups);

  const tableColumns = useMemo(
    () =>
      buildTableColumns({
        dispatch,
        context,
        fetchData,
        userGroups,
        currentUserIsMaster,
        setSelectedRow,
        setLoading,
      }),
    [userGroups, currentUserIsMaster],
  );

  const checkGroupFilter = () => {
    const filterGroupId = _.parseInt(filters.filterButton.groups);
    const filteredGroup = userGroups.find((ug) => ug.id === filterGroupId);
    if (!filteredGroup) {
      setFilters({ searchTerm: filters.searchTerm, filterButton: { ...filters.filterButton, groups: null } });
    }
  };

  const headerButtons = useMemo(() => {
    return () => {
      return (
        <div className="flex-center-items">
          <UserFilterDropdown
            onChange={(values) => setFilters({ ...filters, filterButton: values })}
            initialValues={filters.filterButton}
            userGroups={userGroups}
          />
          <BngIconButton
            icon="rule"
            onClick={() => {
              dispatch(
                MODALS.open(MobileUserPermission, {
                  projectId: context.project.id,
                }),
              );
            }}
            disabled={loading}
            title={context.msg.t('configure.user.project.functionalities')}
          />
          <BngIconButton
            icon="rss_feed"
            onClick={() => {
              dispatch(
                MODALS.open(ActivityDialog, {
                  projectId: context.project.id,
                  users: users,
                }),
              );
            }}
            disabled={loading}
            title={context.msg.t('see.activities')}
          />
        </div>
      );
    };
  }, [userGroups, filters, loading]);

  return (
    <div className="UsersPage">
      <CrudPageLayout
        fetchData={fetchData}
        filters={filters}
        tableRows={filteredUsers}
        loading={loading}
        emptyAlertMessage={context.msg.t('no.users.found.for.filter')}
        pageTitle={context.msg.t('user.and.groups')}
        tableSortMode={tableSortMode}
        setFilters={setFilters}
        tableColumns={tableColumns}
        selectedRows={selectedRow}
        headerButtons={headerButtons}
        setTableSortMode={setTableSortMode}
        pageButton={() => <FloatActionButtonDropdown fetchData={fetchData} checkGroupFilter={checkGroupFilter} />}
        titleComponent={() => {
          return (
            <div className={styles.customHeaderAlerts}>
              {context.accountEnabledForBilling && userMetrics.remainingSpaces < 2 && (
                <BngTag
                  icon={'info'}
                  description={context.msg.t(
                    userMetrics.remainingSpaces === 1 ? 'user.space.one.remaining' : 'user.space.none.remaining',
                  )}
                  onClick={() => {
                    dispatch(
                      MODALS.open(AddAdditionalDialog, {
                        featureKey: ADDITIONALS.USERS.key,
                        isConsumptionAdditional: true,
                        onActivate: () => window.location.reload(),
                      }),
                    );
                  }}
                />
              )}
            </div>
          );
        }}
      />
    </div>
  );
}

const filterUsers = (filters, projectUsers, tableSortMode, userGroups) => {
  const {
    searchTerm,
    filterButton: { groups, role, status },
  } = filters;

  if (searchTerm) {
    const search = searchTerm.toLowerCase();
    projectUsers = projectUsers.filter(({ user }) =>
      `${user.displayName || ''}${user.email}`.toLowerCase().includes(search),
    );
  }

  if (groups) {
    const groupId = _.parseInt(groups);
    const group = userGroups.find((ug) => ug.id === groupId);
    if (group) {
      projectUsers = projectUsers.filter(({ user }) => group.members.some((m) => m.id === user.id));
    }
  }

  if (role) {
    projectUsers = projectUsers.filter(
      (user) => (user.role.name === role && !user.isMaster) || (role === 'Master' && user.isMaster),
    );
  }

  if (status) {
    projectUsers = projectUsers.filter((user) => user.userStatus === status);
  }

  Object.entries(tableSortMode).forEach(([prop, direction]) => {
    if (direction === 'NONE') return;

    projectUsers = _.orderBy(
      projectUsers,
      [
        (projectUser) => {
          let value = projectUser[prop] || projectUser.user[prop];
          if (prop === 'displayName' && !value) {
            value = projectUser.email || projectUser.user.email;
          }

          switch (prop) {
            case 'role': {
              return projectUser.isMaster ? -500 : -value.weight;
            }
            case 'status': {
              return !projectUser.user.enabled;
            }
          }
          return _.isString(value) ? value.toLowerCase().normalize('NFD') : value;
        },
      ],
      [direction.toLowerCase()],
    );
  });

  return projectUsers;
};

const FloatActionButtonDropdown = ({ fetchData, checkGroupFilter }) => {
  const { msg, project, ldapConfig, accountId } = useBimContext();
  const dispatch = useReduxDispatch();

  const [open, setOpen] = useState(false);
  const boundaryElement = useMemo(() => document.getElementById('page-content'), []);

  const userResourceCheck = async (ifActiveFn = _.noop) => {
    const shouldAddResource = await Api.Account.shouldAddResource(accountId, ADDITIONALS.USERS.key);
    if (shouldAddResource) {
      dispatch(
        MODALS.open(AddAdditionalDialog, {
          featureKey: ADDITIONALS.USERS.key,
          isConsumptionAdditional: true,
        }),
      );
    } else {
      await ifActiveFn();
    }
  };

  return (
    <BngDropdown
      boundariesElement={boundaryElement}
      popperOpts={{
        placement: 'bottom-start',
      }}
      popperClassName={styles.FloatActionButtonDropdown}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      customButton={({ openDropdown }) => {
        return <BngIconButton icon={'add'} className={`info ${open ? 'Open' : ''}`} onClick={openDropdown} />;
      }}
      customOptions={({ closeDropdown }) => {
        return (
          <div className={`${styles.buttonsContainer} ${open ? styles.showButtons : ''}`}>
            <div>
              <BngIconButton
                id="UsersPage:invite-users-button"
                icon="person_add"
                className={`success`}
                onClick={async () => {
                  dispatch(
                    MODALS.open(InviteUsersDialog, {
                      beforeClose: fetchData,
                      accountId,
                    }),
                  );
                  closeDropdown();
                }}
              />
              <label htmlFor="UsersPage:invite-users-button">{msg.t('invite_user')}</label>
            </div>
            <div>
              <BngIconButton
                id="UsersPage:create-users-button"
                icon="add_circle"
                className={`success`}
                onClick={async () => {
                  await userResourceCheck(() => {
                    dispatch(MODALS.open(CreateUserDialog, { beforeClose: fetchData }));
                  });
                  closeDropdown();
                }}
              />
              <label htmlFor="UsersPage:create-users-button">{msg.t('create_user')}</label>
            </div>
            <div>
              <BngIconButton
                id="UsersPage:see-groups-button"
                icon="groups"
                className={`warning`}
                onClick={() => {
                  dispatch(
                    MODALS.open(UserGroupListDialog, {
                      dispatch,
                      beforeClose: async () => {
                        await fetchData();
                        await checkGroupFilter();
                      },
                    }),
                  );
                  closeDropdown();
                }}
              />
              <label htmlFor="UsersPage:see-groups-button">{msg.t('see.groups')}</label>
            </div>
            {ldapConfig.enabled ? (
              <div>
                <BngIconButton
                  id="UsersPage:ldap-button"
                  icon="person_add"
                  className={`success`}
                  onClick={async () => {
                    await userResourceCheck(() => {
                      dispatch(
                        MODALS.open(LdapUserDialog, {
                          beforeClose: async () => {
                            await fetchData();
                            await checkGroupFilter();
                          },
                        }),
                      );
                    });
                    closeDropdown();
                  }}
                />
                <label htmlFor="UsersPage:ldap-button">{'LDAP'}</label>
              </div>
            ) : undefined}
          </div>
        );
      }}
    />
  );
};

const buildTableColumns = ({
  dispatch,
  context,
  fetchData,
  userGroups,
  currentUserIsMaster,
  setSelectedRow,
  setLoading,
}) => {
  const openUserProfile = (userId) => {
    dispatch(
      MODALS.open(ProfileDialog, {
        userId,
        onSave: () => fetchData(),
      }),
    );
  };

  const isCurrentUser = (row) => row.user.id === context.user.id;

  const rowUserIsAdmin = (row) => row.role.name === Utils.Project.roles.Administrator.name;
  // In case Master user is not in Administrator role, he can change his own role to be Admin
  const isMasterNotAdmin = (row) => isCurrentUser(row) && row.isMaster && !rowUserIsAdmin(row);

  // BIMachine users can change persmission of other BIMachine users, except for the Master and themselves
  const isBimUser = (row) =>
    Utils.Project.isBimachineEmail(context.user.email) &&
    Utils.Project.isBimachineEmail(row.user.email) &&
    !isCurrentUser(row) &&
    !row.isMaster;

  const getRoles = (row) => {
    let roles = Utils.Project.getRoles();

    // Only master or superUser user can apply Administrator role
    if (!currentUserIsMaster && !context.user.superUser && !isBimUser) {
      const adminRole = roles.shift();
      if (isMasterNotAdmin(row)) {
        roles = [adminRole];
      }
    }
    return roles;
  };

  // Master cannot be deleted, master can delete admins and admins can only delete people in lower levels,
  // same applies for sending a new password
  const canAlterLowerRoles = (row) =>
    (!row.isMaster && (currentUserIsMaster || context.user.superUser || !rowUserIsAdmin(row))) || isBimUser(row);

  return [
    {
      key: 'displayName',
      label: context.msg.t('name'),
      sortable: true,
      render: (row) => {
        const canShowProfile = row.userStatus !== 'pending';
        return (
          <div
            className={`${styles.userNameWrapper}`}
            onClick={() => (canShowProfile ? openUserProfile(row.user.id) : _.noop)}
            style={canShowProfile ? { cursor: 'pointer' } : {}}
          >
            <BngAvatar userId={row.user.id} />
            <span className={styles.userName}>{row.user.displayName || row.user.email}</span>
            <BimachineBadge email={row.user.email} />
          </div>
        );
      },
    },
    {
      key: 'email',
      label: context.msg.t('email'),
      sortable: true,
      render: (row) => {
        return (
          <div>
            <span className="fileName">{row.user.email}</span>
          </div>
        );
      },
    },
    {
      key: 'status',
      label: context.msg.t('status'),
      sortable: true,
      render: (row) => {
        return (
          <div>
            <span className={`${styles.fileName} ${styles[row.userStatus]}`}>{context.msg.t(row.userStatus)}</span>
          </div>
        );
      },
    },
    {
      key: 'role',
      label: context.msg.t('user.type'),
      sortable: true,
      render: (row, idx) => {
        return (
          <ChangeRoleDropdown
            userId={row.user.id}
            currentRole={row.role}
            roles={getRoles(row)}
            fetchData={fetchData}
            popperClassName={styles.rolesDropdownOverlay}
            setLoading={setLoading}
            onClose={() => {
              setSelectedRow();
            }}
            customButton={({ openDropdown }) => {
              const canChangeRole = canAlterLowerRoles(row);
              return (
                <div
                  className={`${styles.roleWrapper} ${canChangeRole ? styles.clickable : styles.notClickable}`}
                  onClick={
                    canChangeRole
                      ? (e) => {
                          if (canChangeRole) {
                            setSelectedRow(idx);
                            openDropdown(e);
                          }
                        }
                      : undefined
                  }
                >
                  <Icon icon={row.isMaster ? 'star' : row.role.icon} />
                  <span>{context.msg.t(row.isMaster ? 'master' : row.role.name)}</span>
                </div>
              );
            }}
            popperOpts={{ placement: 'left' }}
          />
        );
      },
    },
    {
      key: 'actions',
      label: context.msg.t('actions'),
      size: 70,
      render: (row, idx) => {
        return (
          <RenderActions
            row={row}
            fetchData={fetchData}
            openUserProfile={openUserProfile}
            context={context}
            userGroups={userGroups}
            canAlterLowerRoles={canAlterLowerRoles(row)}
            currentUserIsMaster={currentUserIsMaster}
            roles={getRoles(row)}
            setSelectedRow={setSelectedRow}
            idx={idx}
            setLoading={setLoading}
          />
        );
      },
    },
  ];
};

const RenderActions = ({
  row,
  fetchData,
  openUserProfile,
  context,
  userGroups,
  canAlterLowerRoles,
  currentUserIsMaster,
  roles,
  setSelectedRow,
  idx,
  setLoading,
}) => {
  const [groupOpts, setGroupOpts] = useState([]);

  const fetchGroups = async () => {
    try {
      const mappedGroups = userGroups.map((group) => {
        const selected = group.users.some((u) => u.id === row.user.id);
        return {
          value: `${group.id}`,
          label: group.name,
          group,
          selected: selected,
          render: (isSelected) => (
            <span
              className={styles.groupName}
              style={isSelected ? { color: '#005dff' } : { color: '#7f7f7f' }}
              title={group.name}
            >
              {group.name}
            </span>
          ),
        };
      });

      setGroupOpts(_.orderBy(mappedGroups, ['selected'], 'desc'));
    } catch (e) {
      console.error('Error on RenderActions.fetchGroups', e);
      UiMsg.error(null, e);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, [userGroups]);

  const addToGroup = async (projectId, userId, selectedGroups) => {
    try {
      const groupsToAdd = selectedGroups
        .filter((selectedGroup) => !selectedGroup.selected)
        .map((selectedGroup) => selectedGroup.group.id);

      const groupsToRemove = [];
      groupOpts.forEach((group) => {
        if (group.selected && !selectedGroups.some((g) => g.group.id === group.group.id)) {
          groupsToRemove.push(group.group.id);
        }
      });

      if (_.isEmpty(groupsToAdd) && _.isEmpty(groupsToRemove)) {
        return;
      }

      await Api.UserGroup.addUserToGroup(projectId, userId, groupsToAdd, groupsToRemove);
      await fetchData();
    } catch (e) {
      console.error('Error on RenderActions.addToGroup', { projectId, userId, selectedGroups }, e);
      UiMsg.error(null, e);
    }
  };

  const createUserGroup = async (groupName, user, projectId) => {
    try {
      let values = {
        name: groupName,
        users: [user],
        projectId: projectId,
      };
      await Api.UserGroup.saveUserGroup(values);
      await fetchData();
    } catch (e) {
      console.error(e);
      if (e.response?.status === 409) {
        UiMsg.error(context.msg.t('groups.exists'));
      } else {
        UiMsg.error(context.msg.t('group.save.error'), e);
      }
    }
  };

  const resendUserInvite = async (userId) => {
    try {
      await Api.Project.resendUserInvite(context.project.id, userId);
      await fetchData();
      UiMsg.ok(context.msg.t('project.invitation.sent'));
    } catch (e) {
      console.error('Error on RenderActions.resendUserInvite', { projectId: context.project.id, userId }, e);
      UiMsg.error(context.msg.t('project.invitation.error'));
    }
  };

  const changePassword = async (email) => {
    try {
      await Api.User.changeUserPassword(email);
      await fetchData();
      UiMsg.ok(context.msg.t('users.password.recovery.request.sent.to.email'));
    } catch (e) {
      console.error('Error on RenderActions.changePassword', { email }, e);
      UiMsg.error(context.msg.t('error_sending_new_password'));
    }
  };

  const deleteUser = async (projectId, userId) => {
    try {
      await Api.Project.removeAccess(projectId, userId);
      await fetchData();
      UiMsg.ok(context.msg.t('remove_success', context.msg.t('user')));
    } catch (e) {
      console.error('Error on RenderActions.deleteUser', { projectId, userId }, e);
      UiMsg.error(context.msg.t('remove_error', context.msg.t('user')), e);
    }
  };

  const clearUserReferencesForMasterProjects = async (userId) => {
    try {
      await Api.Account.clearUserReferences(userId);
      await fetchData();
      UiMsg.ok(context.msg.t('clear.user.references.success'));
    } catch (e) {
      console.error('Error on RenderActions.removeUserReferences', { userId }, e);
      UiMsg.error(context.msg.t('remove.references.on.master.projects.error'), e);
    }
  };

  return (
    <div className={styles.actionsWrapper}>
      <BngDropdownTags
        beforeOpen={async () => {
          setSelectedRow(idx);
          await fetchGroups();
        }}
        onApply={async (selectedGroups) => {
          await addToGroup(context.project.id, row.user.id, selectedGroups || []);
        }}
        onClose={setSelectedRow}
        items={groupOpts}
        multipleSelect={true}
        emptyLabel={context.msg.t('group.not.found')}
        createLabel={'create.group'}
        confirmButton
        customButton={({ openDropdown }) => (
          <BngIconButton icon="group_add" onClick={openDropdown} title={context.msg.t('add.to.group')} />
        )}
        onCreate={async (groupName) => {
          await createUserGroup(groupName, row, context.project.id);
        }}
      />

      <BngDropdown
        className={styles.actionsButton}
        popperOpts={{ placement: 'bottom-end' }}
        popperClassName={styles.actionsDropdownPopper}
        onOpen={() => setSelectedRow(idx)}
        onClose={() => setSelectedRow()}
        customOptions={({ closeDropdown }) => {
          const isUserPending = row.userStatus === 'pending';

          return (
            <div className={styles.actionButtonWrapper}>
              {!isUserPending && (
                <div
                  onClick={() => {
                    openUserProfile(row.user.id);
                  }}
                  className={styles.actionItem}
                >
                  <Icon icon="person" />
                  <span>{context.msg.t('profile')}</span>
                </div>
              )}
              {isUserPending && (
                <div
                  onClick={async () => {
                    await resendUserInvite(row.user.id);
                  }}
                  className={styles.actionItem}
                >
                  <Icon icon="email" />
                  <span>{context.msg.t('resend_invite')}</span>
                </div>
              )}
              {row.user.enabled && canAlterLowerRoles && (
                <div
                  onClick={async () => {
                    await changePassword(row.user.email);
                    closeDropdown();
                  }}
                  className={styles.actionItem}
                >
                  <Icon icon="vpn_key" />
                  <span>{context.msg.t('resend.user.password')}</span>
                </div>
              )}

              {canAlterLowerRoles && (
                <ChangeRoleDropdown
                  roles={roles}
                  userId={row.user.id}
                  currentRole={row.role}
                  fetchData={fetchData}
                  afterChange={closeDropdown}
                  setLoading={setLoading}
                  customButton={({ openDropdown }) => {
                    return (
                      <div
                        onClick={(e) => openDropdown(e)}
                        className={`${styles.actionItem} ${styles.userLevelWrapper}`}
                      >
                        <Icon icon="manage_accounts" />
                        <span>{context.msg.t('change.user.type')}</span>
                        <Icon icon="chevron_right" style={{ marginLeft: 'auto' }} />
                      </div>
                    );
                  }}
                  popperOpts={{ placement: 'left' }}
                />
              )}

              {canAlterLowerRoles && (
                <>
                  <hr />
                  <div
                    onClick={() => {
                      OpConfirmation({
                        title: row.user.displayName || row.user.email,
                        message: context.msg.t('confirm.delete.user'),
                        onConfirm: async () => {
                          await deleteUser(context.project.id, row.user.id);
                          closeDropdown();
                        },
                        msg: context.msg,
                      });
                    }}
                  >
                    <Icon icon="delete" />
                    <span>{context.msg.t('remove.user.from.project')}</span>
                  </div>
                </>
              )}

              {currentUserIsMaster && canAlterLowerRoles && (
                <>
                  <div
                    onClick={async () => {
                      setLoading(true);
                      try {
                        const projectsForMaster = (await Api.Account.findUserReferences(row.user.id))
                          .map((p) => p.displayName)
                          .join('<br/>');
                        OpConfirmation({
                          title: row.user.displayName || row.user.email,
                          html: context.msg.t('remove.references.on.master.projects.confirm', projectsForMaster),
                          onConfirm: async () => {
                            await clearUserReferencesForMasterProjects(row.user.id);
                            closeDropdown();
                          },
                          msg: context.msg,
                        });
                      } catch (e) {
                        console.error(`Error on findUserReferences() for userId=[${row.user.id}]`, e);
                      } finally {
                        setLoading(false);
                      }
                    }}
                  >
                    <Icon icon="clean_hands" />
                    <span>{context.msg.t('remove.references.on.master.projects')}</span>
                  </div>
                </>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

const UserFilterDropdownSchema = bngYup((yup) => {
  return yup.object().shape({
    groups: yup.string().nullable().default(''),
    status: yup.string().nullable().default(''),
    role: yup.string().nullable().default(''),
  });
});

const UserFilterDropdown = ({ onChange = _.noop, initialValues = {}, userGroups = [] }) => {
  const { msg } = useBimContext();

  const statusOpts = _.orderBy(
    FilterOpts({
      options: ['active', 'pending', 'disabled'],
      hasIcon: true,
      customIcon: (obj) => <div className={`${styles.statusColor} ${styles[obj]}`} />,
    }),
  );

  const roleOpts = FilterOpts({
    options: Utils.Project.getRolesWithMaster(),
    hasIcon: true,
  });

  const userGroupOpts = FilterOpts({
    options: userGroups.sort((o1, o2) => Utils.Strings.compareIgnoreCase(o1.name, o2.name)),
    useIdAsValue: true,
    forceTitle: true,
  });

  const initialFormValues = useMemo(
    () => _.merge({}, UserFilterDropdownSchema.default(), initialValues),
    [initialValues],
  );

  const filterFields = [
    {
      name: 'groups',
      label: msg.t('groups.title'),
      options: userGroupOpts,
    },
    {
      name: 'status',
      options: statusOpts,
    },
    {
      name: 'role',
      label: msg.t('user.type'),
      options: roleOpts,
    },
  ];

  return (
    <FilterDropdown
      fields={filterFields}
      dropdownSchema={UserFilterDropdownSchema}
      initialFormValues={initialFormValues}
      onChange={onChange}
    />
  );
};
