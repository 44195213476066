import styles from './AdaAiButton.module.css';

import React, { useEffect } from 'react';
import BngIconButton from 'components/bng/ui/BngIconButton';
import useTranslation from 'components/hooks/useTranslation';
import Api from 'components/Api';
import useAdaAiChatStore from 'components/bng/adaAi/useAdaAiChatStore';

export default function AdaAiButton({ item, path, filterProvider, assistantKey }) {
  const { t } = useTranslation();
  const $adaAiChatStore = useAdaAiChatStore();

  useEffect(() => {
    $adaAiChatStore.notifyPathChange(path);
  }, [path]);

  return (
    <div className={`AdaAiButton ${styles.AdaAiButton}`}>
      <BngIconButton
        id="ada-ai-button"
        icon={<img src={Api.buildUrl('/resources/images/ada-ai/ada-ai-icon.png')} alt="Ada AI" />}
        className={`AdaAi ${styles.AdaAi}`}
        text={t('sql.ada.ia.query.assistant.button')}
        onClick={() => {
          const chatData = {
            assistantKey,
            dashboardPath: path,
            itemPath: item?.path || '',
            dashboardFilters: filterProvider() || [],
          };

          $adaAiChatStore.openChat(chatData);
        }}
      />
    </div>
  );
}
