import styles from './ProposalPage.module.css';

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ProposalComponent } from 'components/bng/accounts/additionals/AdditionalActivateDialog';
import Api from 'components/Api';
import useFetchData from 'components/hooks/useFetchData';
import UiBlocker from 'components/bng/ui/UiBlocker';
import UiMsg from 'components/ui/UiMsg';
import useTranslation from 'components/hooks/useTranslation';
import BngButton from 'components/bng/ui/BngButton';
import useBimNavigate from 'components/hooks/useBimNavigate';
import { proposalUtils } from 'components/service/bng/AccountApi';

export default function ProposalPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useBimNavigate();
  const [loading, setLoading] = useState(false);
  const [resolved, setResolved] = useState(false);

  const redirectToPersonalPage = () => {
    window.location.replace(Api.buildUrl('/spr/bng/personal-page'));
  };

  const {data: proposalOpts, proposalLoading} = useFetchData(async () => {
    try {
      const proposalId = searchParams.get('proposalId');
      const proposal = await Api.Account.fetchProposal(proposalId);

      if (proposal.status !== 'WAITING') {
        UiMsg.warn(t('proposal.already.resolved'));
        redirectToPersonalPage();
      }

      const currentPlan = await Api.Account.fetchActivePlan(proposal.buyer.bimAccountId);
      return {proposal, currentPlan};
    } catch (e) {
      if (e.response.status === 403) {
        navigate('/errors/403');
      } else {
        console.error('Error on fetch oldest waiting proposal', e);
        UiMsg.ajaxError(t('fetch.proposals.error'), e);
      }
    }
  }, [searchParams]);

  return (
    <div className={`ProposalPage ${styles.proposalPage}`}>
      <div className={styles.proposalWrapper}>
        <UiBlocker
          block={loading || proposalLoading}
          renderChildrenWhileBlocking={false}
          className={styles.proposalPageLoader}
        >
          {proposalOpts?.proposal && (
            <>
              <div className={styles.proposalHeader}>
                <img src={`${Api.baseUrl()}/resources/images/logo-bim-black-gray.png`} />
                {!resolved && <span>{t('proposal.email.header', proposalOpts.proposal.buyer.companyName)}</span>}
                <span className={styles.titleSmallPrint}>
                  {t('this.proposals.expires', [proposalOpts.proposal.daysToExpire])}
                </span>
              </div>
              <hr />
              <ProposalComponent
                loadingCallback={setLoading}
                proposalRequest={proposalOpts.proposal}
                detailsAlwaysOpen={true}
                afterResolveCallback={setResolved}
                resolvedStatus={resolved}
                enableAutoResolve={true}
                origin={proposalUtils.PROPOSAL_ORIGIN.EMAIL}
                currentPlan={proposalOpts.currentPlan}
              />
              {!!resolved && (
                <div className={'flex-center-items jc-center mt-2'}>
                  <BngButton onClick={redirectToPersonalPage}>{t('back.to.project')}</BngButton>
                </div>
              )}
            </>
          )}
        </UiBlocker>
      </div>
    </div>
  );
}
