import styles from './MarketplaceItemPage.module.css';

import React, { useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { UiBlocker } from 'components/bng/ui/UiBlocker';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import Icon from 'components/ui/common/Icon';
import UiMsg from 'components/ui/UiMsg';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import BngVideoModal from 'components/bng/ui/BngVideoModal';
import AddonStars from 'components/ui/navbar/addons/AddonStars';
import { proposalUtils } from 'components/service/bng/AccountApi';
import { MODALS } from 'components/ui/redux/Actions';
import { CheckoutDialog } from 'components/bng/pages/marketplace/Checkout';
import BngNumberCounter from 'components/bng/form/BngNumberCounter';
import { guessGroupKey, mapCardItemToSelectedItem } from 'components/bng/pages/marketplace/MarketplaceLayout';
import Utils from 'components/Utils';
import BngIconButton from 'components/bng/ui/BngIconButton';
import useTranslation from 'components/hooks/useTranslation';
import BngAddonActivatedHelp from 'components/ui/navbar/BngAddonActivatedHelp';
import BngButton, { Variant } from 'components/bng/ui/BngButton';
import useBimNavigate from 'components/hooks/useBimNavigate';
import BngTag from 'components/bng/ui/BngTag';
import { localStore, SESSION_DATA } from 'components/Storage';
import { RECURRING_TYPES } from './MarketplaceCard';
import { ADDITIONALS } from 'components/bng/accounts/additionals/AddAdditionalDialog';

function HeaderBlock({
  className = '',
  media,
  loading,
  activateWithoutRequest,
  scrollReference,
  isEnabled,
  isPlan,
  enabledButtonAction = null,
  enabledButtonLabel = '',
  marketplaceItem,
  translations,
  blockImages,
}) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const [showVideo, setShowVideo] = useState(false);

  const scrollToPrice = () => {
    scrollReference.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const enableActions = enabledButtonAction && isEnabled;
  const pageTranslations = proposalUtils.findTranslation(translations);

  const title = pageTranslations?.title || context.msg.t(marketplaceItem.name);
  const desc =
    pageTranslations?.description ||
    proposalUtils.findTranslation(marketplaceItem.marketplaceInfo.translatedProps).desc;
  const imageFile = blockImages.find((img) => img.key === media);
  const video = pageTranslations?.video || null;
  const imageUrl = Api.Marketplace.marketplaceImageUrl(imageFile.path);
  const soonTag = marketplaceItem.marketplaceInfo.soonTag;

  return (
    <div
      className={`HeaderBlock ${styles.MarketplaceItemHeader} ${styles.Block} Addon-${marketplaceItem.key} ${className}`}
    >
      <div className={styles.titleWrapper}>
        <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
        <p dangerouslySetInnerHTML={{ __html: desc }}></p>
        <div className={styles.headerButtonWrapper}>
          <BngButton
            onClick={scrollToPrice}
            className={`${styles.primaryButton} ${styles.buttonStyle}`}
            style={{ color: `${marketplaceItem.marketplaceInfo.color}` }}
          >
            {context.msg.t(
              soonTag
                ? 'demand'
                : isEnabled
                ? isPlan
                  ? 'change.plan'
                  : 'enabled'
                : activateWithoutRequest
                ? 'activate.addon'
                : 'request.activation'
            )}
          </BngButton>
          {(video || enableActions) && (
            <BngButton
              loading={loading}
              onClick={() => {
                enableActions ? enabledButtonAction({ context, dispatch }) : setShowVideo(true);
              }}
              className={`${styles.secondaryButton} ${styles.buttonStyle}`}
            >
              {enableActions ? context.msg.t(enabledButtonLabel) : `${context.msg.t('see.video')} (1 min)`}
            </BngButton>
          )}
        </div>
      </div>
      <div className={styles.headingImgWrapper}>
        <img
          src={imageUrl || `${Api.baseUrl()}/resources/images/addons/addons_page_ada_left.svg`}
          className={styles.headingImg}
        />
      </div>
      {showVideo && (
        <BngVideoModal
          className={styles.headingVideoWrapper}
          videoUrl={video}
          onClose={() => {
            setShowVideo(false);
          }}
          fullHeight
        />
      )}
    </div>
  );
}

function MultiInfoBlock({ infoCards, marketplaceItem, translations, blockImages }) {
  const context = useBimContext();
  const [expandedInfoCard, setExpandedInfoCard] = useState(0);
  const pageTranslations = proposalUtils.findTranslation(translations);

  const changeExpandedCard = (idx) => {
    setExpandedInfoCard(idx);
  };

  return (
    <div className={`MultiInfoBlock ${styles.MultiInfoBlock} ${styles.Block}`}>
      <div className={styles.textContent}>
        <h5 style={{ color: marketplaceItem.marketplaceInfo.color }}>{pageTranslations.title}</h5>
      </div>
      <div className={styles.infoContent}>
        <div className={styles.selectInfo}>
          {infoCards.map((addonInfo, idx) => {
            const isExpanded = expandedInfoCard === idx;
            return (
              <InfoContentCard
                key={`${idx}`}
                expanded={isExpanded}
                onClick={() => changeExpandedCard(idx)}
                {...addonInfo}
              />
            );
          })}
        </div>
        <div className={styles.infoMedia}>
          {infoCards.map((addonInfo, idx) => {
            const visible = expandedInfoCard === idx;
            const imageFile = blockImages.find((img) => img.key === addonInfo.media);
            if (!imageFile) {
              return;
            }
            const imageUrl = Api.Marketplace.marketplaceImageUrl(imageFile.path, context.billingConf.url);
            return (
              <img
                key={`${idx}-image`}
                className={`${styles.infoCardImg} ${visible ? styles.visibleInfoCardImg : ''} `}
                src={imageUrl}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function InfoContentCard({ icon, translations, expanded = false, onClick = _.noop }) {
  const cardTranslations = proposalUtils.findTranslation(translations);

  return (
    <div
      className={`InfoContentCard ${styles.InfoContentCard} ${expanded ? styles.expandedCard : ''}`}
      onClick={onClick}
    >
      <div className={styles.infoTitleWrapper}>
        <Icon icon={icon} className={`${styles.contentCardIcon} `} />
        <p className={`${styles.contentCardTitle}`}>{cardTranslations.title}</p>
      </div>
      <p className={`${styles.contentCardDesc} ${expanded ? styles.descExpanded : ''}`}>
        {cardTranslations.description}
      </p>
    </div>
  );
}

function PlanPriceBlock({
  marketplaceItem,
  translations,
  scrollReference,
  isEnabled,
  requestActivation,
  requestItemToSupport,
  marketplaceItems,
}) {
  const context = useBimContext();
  const [showAda, setShowAda] = useState(false);

  const pageTranslations = proposalUtils.findTranslation(translations);
  const pricings = marketplaceItem.pricings.sort((a, b) => a.pricing - b.pricing);
  const title = pageTranslations?.title || context.msg.t(marketplaceItem.name);
  const extraInfo = pageTranslations?.extraInfo;
  const video = pageTranslations.video;
  const videoTitle = pageTranslations.videoTitle;

  const closeAda = () => {
    setShowAda(false);
    window.location.reload();
  };

  const activateItem = async (itemKey, billingPricingId, pricing) => {
    requestActivation(marketplaceItem, billingPricingId, pricing, 1, () => setShowAda(true));
  };

  return (
    <div className={`${styles.PlanPriceBlock} ${styles.Block}`}>
      <div className={styles.priceHeader} ref={scrollReference}>
        <div className={styles.priceTextContent}>
          <h5 style={{ color: marketplaceItem.marketplaceInfo.color }}>{title}</h5>
        </div>
      </div>
      <div className={styles.plansWrapper}>
        {pricings.map((price, idx) => {
          const selectedPlan = marketplaceItems.activeAddons.find(
            (billingAddon) => billingAddon.pricing?.planKey === price.planKey
          );
          const planSelected = isEnabled && selectedPlan;
          return (
            <PriceCard
              key={`${idx}-price-card-${price.planKey}`}
              idx={idx}
              selected={planSelected}
              activateItem={activateItem}
              marketplaceItem={marketplaceItem}
              isEnabled={isEnabled}
              extraInfo={extraInfo}
              pricingId={price.id}
              soonTag={marketplaceItem.marketplaceInfo.soonTag}
              requestItemToSupport={requestItemToSupport}
              {...price}
            />
          );
        })}
      </div>
      {showAda && (
        <>
          <BngAddonActivatedHelp
            addonKey={marketplaceItem.key}
            helpVideoUrl={video}
            videoTitle={videoTitle}
            closeAda={closeAda}
            showHelpVideo={!!video}
            addonName={proposalUtils.findTranslation(marketplaceItem.props.translations).name}
            knowledgeBaseLink={proposalUtils.findTranslation(marketplaceItem.marketplaceInfo.translatedProps).link}
            container={document.getElementById('wrapper')}
          />
        </>
      )}
    </div>
  );
}

const COLORS = ['#33b577', '#faa133', '#337dff', '#ed6962'];

function PriceCard({
  idx,
  planKey,
  planValue,
  pricing,
  extraInfo,
  selected = false,
  activateItem,
  marketplaceItem,
  pricingId,
  soonTag = false,
  requestItemToSupport = _.noop,
  translations = {},
  activateWithoutRequest,
}) {
  const context = useBimContext();
  const isConsultant = Utils.Users.isConsultant(context.user);
  const { selectedItems, selectCard } = useOutletContext();
  const isInCart = selectedItems.find((i) => i.id === marketplaceItem.id && i.pricing.id === pricingId);
  const description = proposalUtils.findTranslation(translations)?.description;

  extraInfo = extraInfo.replaceAll('{0}', planValue);
  const showCartButton = !selected && isConsultant && !soonTag;
  const isViewUser = !isConsultant && context.permissions.isViewer() && !activateWithoutRequest;

  return (
    <div className={styles.PriceCard} style={{ background: COLORS[Math.ceil(idx % 4)] }}>
      <div className={styles.priceName}>
        <span className={styles.priceNameText}>{planKey}</span>
      </div>
      <div className={styles.priceContent}>
        {description && <div className={styles.planInfo}>{description}</div>}
        {!soonTag && (
          <>
            {!isViewUser && (
              <div className={styles.priceValueWrapper} style={{ color: COLORS[Math.ceil(idx % 4)] }}>
                <div className={styles.pricePrefix}>R$</div>
                <span className={styles.mainPrice}>{pricing}</span>
                <div className={styles.priceSufix}>00</div>
              </div>
            )}
            {extraInfo && <div className={styles.planInfo}>{extraInfo}</div>}
          </>
        )}
        <div className={`${styles.selectButtonWrapper}`}>
          <BngButton
            className={`${styles.selectButton} ${selected ? styles.selectedButton : ''}`}
            onClick={async () => {
              if (soonTag) {
                requestItemToSupport(marketplaceItem, planKey);
              } else {
                if (selected) return;
                return await activateItem(marketplaceItem.id, pricingId, pricing);
              }
            }}
          >
            {context.msg.t(soonTag ? 'demand' : selected ? 'active' : 'select')}
          </BngButton>
          {showCartButton && (
            <BngButton
              className={`${styles.selectButton} ${styles.addToCartBtn} ${isInCart ? styles.inCart : ''}`}
              onClick={() =>
                selectCard({
                  cardItem: marketplaceItem,
                  pricingId,
                })
              }
            >
              <Icon icon={isInCart ? 'remove_shopping_cart' : 'add_shopping_cart'} />
            </BngButton>
          )}
        </div>
      </div>
    </div>
  );
}

function RecurrencePlanPriceBlock({
  marketplaceItem,
  isEnabled,
  requestActivation,
  activateWithoutRequest,
  scrollReference,
  translations,
}) {
  const context = useBimContext();
  const { t } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState(null);

  const pageTranslations = proposalUtils.findTranslation(translations);
  const title = pageTranslations?.title || context.msg.t(marketplaceItem.name);
  const valueLabel = pageTranslations?.valueLabel || t('value');
  const valueSuffix = pageTranslations?.valueSuffix || '';

  const groupedPricesByPlan = marketplaceItem.pricings.reduce((grouped, pricing) => {
    (grouped[pricing.planKey] = grouped[pricing.planKey] || []).push(pricing);
    return grouped;
  }, {});
  const groupKeys = Object.keys(groupedPricesByPlan).sort((a, b) => {
    const valueA = groupedPricesByPlan[a][0].planValue || 0;
    const valueB = groupedPricesByPlan[b][0].planValue || 0;
    return valueA - valueB;
  });
  const { selectedItems, selectCard } = useOutletContext();
  const isConsultant = Utils.Users.isConsultant(context.user);
  const soonTag = marketplaceItem.marketplaceInfo.soonTag;

  const showCartButton = isConsultant && !soonTag;
  const isInCart = selectedItems.find((i) => i.id === marketplaceItem.id && selectedPlan?.id === i.pricing.id);

  return (
    <div className={`${styles.RecurrencePlanPriceBlock} ${styles.Block}`}>
      <div className={styles.priceHeader} ref={scrollReference}>
        <div className={styles.priceTextContent}>
          <h5 style={{ color: marketplaceItem.marketplaceInfo.color }}>{title}</h5>
        </div>
      </div>
      <table className={styles.recurrencePlanTable}>
        <thead>
          <tr>
            <th scope="col" className={styles.planInfoColumns}>
              <div>
                <span>{t('plans')}</span>
                <span className={styles.valueLabel}>{valueLabel}</span>
              </div>
            </th>
            {groupKeys.map((planKey, idx) => (
              <th scope="col" key={`${idx}-${planKey}`} className={styles.planColumn}>
                <div style={{ background: COLORS[Math.ceil(idx % 4)] }}>
                  <span>{planKey}</span>
                  <span>
                    {groupedPricesByPlan[planKey][0].planValue}
                    {valueSuffix}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.values(RECURRING_TYPES).map((type, index) => {
            const pricingsForRecurringType = [];

            for (const [key, value] of Object.entries(groupedPricesByPlan)) {
              const pricingForRecurringType = value.find((pricing) => pricing.recurringType === type.key);
              if (pricingForRecurringType) {
                pricingsForRecurringType.push({ planKey: key, value: pricingForRecurringType });
              }
            }

            if (pricingsForRecurringType.length > 0) {
              return (
                <tr key={`${index}-${type.key}`}>
                  <td className={`${styles.planInfoColumns}`}>
                    <div>{t(type.key)}</div>
                  </td>
                  {pricingsForRecurringType
                    .sort((a, b) => {
                      const indexOfPlanA = groupKeys.indexOf(a.planKey);
                      const indexOfPlanB = groupKeys.indexOf(b.planKey);
                      return indexOfPlanA - indexOfPlanB;
                    })
                    .map((pricing, idx) => {
                      return (
                        <td className={styles.planColumn} key={`${idx}-pricing-${type.key}`}>
                          <div style={{ background: COLORS[Math.ceil(idx % 4)] }} className={styles.pricingWrapper}>
                            <BngButton
                              className={`${styles.selectPlanButton} ${
                                selectedPlan?.id === pricing.value.id ? styles.selectedPlan : ''
                              }`}
                              onClick={() =>
                                selectedPlan?.id === pricing.value.id
                                  ? setSelectedPlan(null)
                                  : setSelectedPlan(pricing.value)
                              }
                            >
                              {proposalUtils.getPricingLabel(pricing.value)}
                            </BngButton>
                          </div>
                        </td>
                      );
                    })}
                </tr>
              );
            }
          })}
        </tbody>
      </table>

      <div className={styles.buttonsWrapper}>
        <BngButton
          disabled={!selectedPlan}
          onClick={async () => {
            if (soonTag) {
              requestItemToSupport(marketplaceItem, selectedPlan.planKey);
            } else {
              await requestActivation(marketplaceItem, selectedPlan.id, selectedPlan.pricing, 1, () =>
                window.location.reload()
              );
            }
          }}
        >
          <span>
            {context.msg.t(
              soonTag
                ? 'demand'
                : isEnabled
                ? 'change.plan'
                : activateWithoutRequest
                ? 'activate.addon'
                : 'request.activation'
            )}
          </span>
          <Icon icon={'shopping_cart'} />
        </BngButton>

        {showCartButton && (
          <BngButton
            disabled={!selectedPlan}
            variant={Variant.outline}
            onClick={() => {
              selectCard({
                cardItem: marketplaceItem,
                pricingId: selectedPlan.id,
              });
              if (isInCart) {
                setSelectedPlan(null);
              }
            }}
          >
            <span>{t(isInCart ? 'remove.from.cart' : 'add.to.cart')}</span>
            <Icon icon={isInCart ? 'remove_shopping_cart' : 'add_shopping_cart'} />
          </BngButton>
        )}
      </div>
    </div>
  );
}

function LinkInfoBlock({ media, marketplaceItem, style, translations, blockImages }) {
  const context = useBimContext();
  const pageTranslations = proposalUtils.findTranslation(translations);

  const title = pageTranslations.title;
  const desc = pageTranslations.description;
  const link =
    pageTranslations?.link || proposalUtils.findTranslation(marketplaceItem.marketplaceInfo.translatedProps)?.link;
  const buttonLbl = pageTranslations?.buttonLbl || context.msg.t('learn.how');
  const imageFile = blockImages.find((img) => img.key === media);
  const imageUrl = Api.Marketplace.marketplaceImageUrl(imageFile.path, context.billingConf.url);

  return (
    <div className={`${styles.LinkInfoBlock} ${styles.Block}`} style={style}>
      <img src={imageUrl} className={styles.linkInfoImg} />
      <div className={styles.linkContent}>
        <h5 style={{ color: `${marketplaceItem.marketplaceInfo.color}` }}>{title}</h5>
        <p dangerouslySetInnerHTML={{ __html: desc }}></p>
        {link && buttonLbl && (
          <a href={link} className={styles.buttonLink} target="_blank">
            <BngButton
              className={`${styles.buttonStyle} ${styles.linkInfoButton}`}
              style={{
                color: `${marketplaceItem.marketplaceInfo.color}`,
                borderColor: `${marketplaceItem.marketplaceInfo.color}`,
              }}
            >
              {buttonLbl}
            </BngButton>
          </a>
        )}
      </div>
    </div>
  );
}

function FaqBlock({ questions, marketplaceItem }) {
  const context = useBimContext();
  return (
    <div className={`${styles.FaqBlock} ${styles.Block}`}>
      <h5 style={{ color: marketplaceItem.marketplaceInfo.color }}>{context.msg.t('faq')}</h5>
      <div className={styles.questionsWrapper}>
        {questions.map((question, idx) => {
          const questionTranslations = proposalUtils.findTranslation(question.translations);
          return (
            <div className={styles.questionCard} key={`${idx}-question`}>
              <p className={styles.questionTitle}>{questionTranslations.question}</p>
              <p className={styles.questionAnswer} dangerouslySetInnerHTML={{ __html: questionTranslations.answer }} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

function SinglePriceBlock({
  marketplaceItem,
  requestActivation,
  isEnabled,
  scrollReference,
  selectQuantity = false,
  translations,
  activateWithoutRequest,
  requestItemToSupport,
}) {
  const context = useBimContext();
  const { selectedItems, selectCard } = useOutletContext();
  const [showAda, setShowAda] = useState(false);

  const [quantity, setQuantity] = useState(1);
  const pageTranslations = proposalUtils.findTranslation(translations);
  const getMostRecentPricing = (pricings) => {
    return pricings.filter((p) => p.active).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
  };

  const isInCart = selectedItems.find((i) => i.id === marketplaceItem.id);
  const isConsultant = Utils.Users.isConsultant(context.user);
  const soonTag = marketplaceItem.marketplaceInfo.soonTag;

  const pricing = getMostRecentPricing(marketplaceItem.pricings);
  const unitPricing = marketplaceItem.feature
    ? (pricing.pricing / marketplaceItem.featureValue.value).toFixed(2)
    : null;

  const activateItem = async () => {
    if (isEnabled) return;
    const billingPricingId = pricing.id;
    requestActivation(marketplaceItem, billingPricingId, pricing.pricing, quantity, () => window.location.reload());
  };

  const title = pageTranslations.title;
  let description = pageTranslations.description;
  const regex = new RegExp('\\{' + 0 + '\\}', 'g');
  description = description.replace(regex, `${pricing.currency.symbol} ${unitPricing}`);

  const pricingSmallPrint = pageTranslations.smallPrint;
  const buttonLbl =
    pageTranslations?.buttonLbl || context.msg.t(activateWithoutRequest ? 'activate.addon' : 'request.activation');
  const video = pageTranslations.video;
  const videoTitle = pageTranslations.videoTitle;

  const showCartButton = !isEnabled && isConsultant && !soonTag;
  const isViewUser = !isConsultant && context.permissions.isViewer() && !activateWithoutRequest;
  const showDescription = !(isViewUser && Object.keys(ADDITIONALS).includes(marketplaceItem.feature?.classification));

  const getDecimalPart = (num) => {
    const decimalStr = Number.isInteger(num) ? 0 : num.toString().split('.')[1];
    return Number(decimalStr).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  };

  const closeAda = () => {
    setShowAda(false);
    window.location.reload();
  };

  return (
    <div className={`${styles.SinglePriceBlock} ${styles.Block}`} ref={scrollReference}>
      <div className={styles.singlePriceContentWrapper}>
        {soonTag && <BngTag description={context.msg.t('coming.soon')} className={styles.soonTag} />}
        <h5 style={{ color: `${marketplaceItem.marketplaceInfo.color}` }}>{title}</h5>
        {!soonTag && (
          <>
            {!isViewUser && (
              <>
                <div className={styles.priceValueWrapper} style={{ color: marketplaceItem.marketplaceInfo.color }}>
                  <div className={styles.pricePrefix}>R$</div>
                  <span className={styles.mainPrice}>{Math.floor(pricing.pricing)}</span>
                  <div>
                    <div className={styles.priceSufix}>{getDecimalPart(pricing.pricing)}</div>
                    {marketplaceItem.pricings[0].recurringType !== 'ONCE' && <div>{context.msg.t('byMonth')}</div>}
                  </div>
                </div>
                {pricingSmallPrint && (
                  <span className={styles.priceSmallPrint} dangerouslySetInnerHTML={{ __html: pricingSmallPrint }} />
                )}
              </>
            )}

            {showDescription && <span dangerouslySetInnerHTML={{ __html: description }} />}
          </>
        )}
        <div className={styles.contractButtonWrapper}>
          {selectQuantity && !soonTag && (
            <BngNumberCounter value={quantity} onChange={(event) => setQuantity(event.target.value)} />
          )}
          <BngButton
            className={`${styles.buttonStyle} ${styles.singlePriceActivate} ${isEnabled ? styles.enabledButton : ''}`}
            style={{ backgroundColor: `${marketplaceItem.marketplaceInfo.color}` }}
            onClick={soonTag ? () => requestItemToSupport(marketplaceItem, null) : activateItem}
            title={isEnabled ? context.msg.t('marketplace.item.alreadyEnabled') : ''}
          >
            {soonTag ? context.msg.t('demand') : isEnabled ? context.msg.t('enabled') : buttonLbl}
          </BngButton>
          {showCartButton && (
            <BngButton
              className={`${styles.buttonStyle} ${styles.singlePriceActivate} ${styles.addToCartBtn} ${
                isInCart ? styles.inCart : ''
              }`}
              onClick={() =>
                selectCard({
                  cardItem: marketplaceItem,
                  quantity,
                  canSelectQuantity: selectQuantity,
                })
              }
            >
              <Icon icon={isInCart ? 'remove_shopping_cart' : 'add_shopping_cart'} />
            </BngButton>
          )}
        </div>
      </div>
      <div className={styles.addonStarsWrapper}>
        <AddonStars fill={marketplaceItem.marketplaceInfo.color} />
        <Icon
          icon={marketplaceItem.marketplaceInfo.icon}
          className={styles.pricingIcon}
          style={{ color: marketplaceItem.marketplaceInfo.color }}
        />
      </div>
      {showAda && (
        <>
          <BngAddonActivatedHelp
            addonKey={marketplaceItem.key}
            helpVideoUrl={video}
            videoTitle={videoTitle}
            closeAda={closeAda}
            showHelpVideo={!!video}
            addonName={proposalUtils.findTranslation(marketplaceItem.props.translations).name}
            knowledgeBaseLink={proposalUtils.findTranslation(marketplaceItem.marketplaceInfo.translatedProps).link}
            container={document.getElementById('wrapper')}
          />
        </>
      )}
    </div>
  );
}

function LinedMediaBlock({ mediaArray = [], marketplaceItem, translations, blockImages }) {
  const context = useBimContext();
  const pageTranslations = proposalUtils.findTranslation(translations);

  const title = pageTranslations.title;
  const description = pageTranslations.description;

  return (
    <div className={`${styles.Block} ${styles.LinedMediaBlock}`}>
      <h5 style={{ color: marketplaceItem.marketplaceInfo.color }}>{title}</h5>
      <div className={styles.linedMediaWrapper}>
        {mediaArray.map((media, idx) => {
          const imageFile = blockImages.find((img) => img.key === media.media);
          const imageUrl = Api.Marketplace.marketplaceImageUrl(imageFile.path, context.billingConf.url);
          const mediaTranslations = proposalUtils.findTranslation(media.translations);
          return (
            <div className={styles.linedMediaContent} key={idx}>
              <span className={styles.linedMediaTitle}>{context.msg.t(mediaTranslations.title)}</span>
              <img src={imageUrl} className={styles.linedMediaImg} />
            </div>
          );
        })}
      </div>
      <p dangerouslySetInnerHTML={{ __html: context.msg.t(description) }}></p>
    </div>
  );
}

const BLOCK_TYPES = {
  HEADER: HeaderBlock,
  MULTI_INFO: MultiInfoBlock,
  PLAN_PRICE: PlanPriceBlock,
  LINK_INFO: LinkInfoBlock,
  FAQ: FaqBlock,
  SINGLE_PRICE: SinglePriceBlock,
  LINED_MEDIA: LinedMediaBlock,
  RECURRENCE_PLAN_PRICE: RecurrencePlanPriceBlock,
};

export function MarketplaceItemTab({ account, marketplaceItems, isMasterOfCurrentAccount, activateWithoutRequest }) {
  const { t } = useTranslation();
  const navigate = useBimNavigate();

  const backAction = () => {
    navigate(-1);
  };

  return (
    <div className={`MarketplaceItemTab ${styles.MarketplaceItemTab}`}>
      <BngIconButton
        icon={'arrow_back'}
        text={t('back_button')}
        onClick={backAction}
        className={`marketplaceItemBackButton ${styles.marketplaceItemBackButton}`}
      />
      <MarketplaceItemPage
        fullPage={false}
        account={account}
        onBack={backAction}
        marketplaceItems={marketplaceItems}
        isMasterOfCurrentAccount={isMasterOfCurrentAccount}
        activateWithoutRequest={activateWithoutRequest}
      />
    </div>
  );
}

const buildSupportRequestKey = (key = '') => {
  return `MarketplaceItemPage:SupportRequest:${key}`;
};

export default function MarketplaceItemPage({
  salesObjId = null,
  salesObjType = null,
  fullPage = true,
  account,
  onBack = _.noop,
  marketplaceItems,
  isMasterOfCurrentAccount,
  activateWithoutRequest,
}) {
  const dispatch = useReduxDispatch();
  const context = useBimContext();
  const navigate = useBimNavigate();
  const [loading, setLoading] = useState(true);
  const [marketplaceItem, setMarketplaceItem] = useState({});
  const [isEnabled, setIsEnabled] = useState(false);

  const $scrollRef = useRef();

  const findAddonInfo = async (currentObjId, type) => {
    try {
      setLoading(true);
      const selectedItem = await Api.Marketplace.fetchMarketplaceInfo(account.id, currentObjId, type);

      if (!selectedItem.marketplaceInfo?.pageContent || selectedItem.marketplaceInfo.pageContent.length === 0) {
        pageNotAvailable();
      }

      const canSelectQuantity = proposalUtils.canSelectQuantityInMarketplace(selectedItem.marketplaceInfo);

      const addonEnabled = marketplaceItems.activeAddons?.some(
        (billingAddon) =>
          !!selectedItem.feature?.key && billingAddon.feature.key === selectedItem.feature.key && !canSelectQuantity
      );

      setMarketplaceItem(selectedItem);
      setIsEnabled(addonEnabled);
    } catch (e) {
      console.error('Error on findAddonInfo()', e);
      UiMsg.ajaxError(context.msg.t('addon.fetch.error'), e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const currentObjId = salesObjId || new URLSearchParams(window.location.search).get('itemId');
    const currentObjType = salesObjType || new URLSearchParams(window.location.search).get('type');

    if (!currentObjId || !currentObjType) {
      navigate('/marketplace');
    } else {
      findAddonInfo(parseInt(currentObjId), currentObjType);
    }
  }, []);

  const requestActivation = async (salesItem, billingPricingId, agreedPricing, quantity, afterSubmit = _.noop) => {
    setLoading(true);
    const errorHandler = (e) => {
      console.error('Error on function requestActivation()', { salesItemKey: salesItem.key }, e);
      UiMsg.error(context.msg.t('marketplace.request.error'));
    };
    try {
      if (activateWithoutRequest) {
        const canSelectQuantity = proposalUtils.canSelectQuantityInMarketplace(salesItem.marketplaceInfo);
        dispatch(
          MODALS.open(CheckoutDialog, {
            items: [
              mapCardItemToSelectedItem(
                salesItem,
                guessGroupKey(salesItem),
                quantity,
                billingPricingId,
                canSelectQuantity
              ),
            ],
            account,
            afterSubmit,
            currentPlan: marketplaceItems.activePlanForAccount,
            isMasterOfCurrentAccount,
          })
        );
      } else {
        const currentObjType = salesObjType || new URLSearchParams(window.location.search).get('type');
        await Api.Marketplace.changeItemStatus(salesItem.key, currentObjType, context.project.id, false, null, {
          ...marketplaceItem,
          billingPricingId,
          agreedPricing,
          quantity,
        });
        UiMsg.ok(context.msg.t('marketplace.item.requested.successfully'));
      }
    } catch (e) {
      errorHandler(e);
    } finally {
      setLoading(false);
    }
  };

  const requestItemToSupport = async (marketplaceItem, planKey) => {
    try {
      setLoading(true);
      const sessionData = localStore.get(SESSION_DATA) || {};
      const alreadyChecked = sessionData[buildSupportRequestKey(marketplaceItem.key)] ?? false;

      if (alreadyChecked) {
        UiMsg.warn(context.msg.t('marketplace.support.already.requested'));
        return;
      }

      const title = `Solicitação de ${marketplaceItem.name} através do Marketplace`;
      await Api.Support.newTicketForMarketplace({
        title,
        items: [marketplaceItem],
        user: context.user,
        project: context.project,
        account,
        planKey,
      });
      UiMsg.ok(context.msg.t('marketplace.item.support.request.success'));

      sessionData[buildSupportRequestKey(marketplaceItem.key)] = true;
      localStore.put(SESSION_DATA, sessionData);
    } catch (e) {
      console.error('Error while trying to send ticket', e);
      UiMsg.ajaxError(context.msg.t('marketplace.request.error'), e);
    } finally {
      setLoading(false);
    }
  };

  const pageNotAvailable = () => {
    UiMsg.warn(context.msg.t('marketplace.page.not.available'));
    onBack();
  };

  return (
    <UiBlocker
      block={loading}
      renderChildrenWhileBlocking={false}
      className={`MarketplaceItemPage ${styles.MarketplaceItemBlocker} ${fullPage ? styles.fullPage : ''} ${
        styles.MarketplaceItemBlockWrapper
      } ${marketplaceItem.key}`}
    >
      <div className={styles.MarketplaceBlocksContainer}>
        {marketplaceItem.marketplaceInfo?.pageContent &&
          marketplaceItem.marketplaceInfo.pageContent
            .sort((a, b) => a.index - b.index)
            .map((block, idx) => {
              const blockType = block.type;
              const BlockType = BLOCK_TYPES[blockType];
              const { className = '', ...props } = block.props ?? {};

              return (
                <BlockType
                  key={`${idx}-${blockType}`}
                  className={`BLOCK_${blockType} ${className}`}
                  loading={loading}
                  setLoading={setLoading}
                  scrollReference={$scrollRef}
                  marketplaceItem={marketplaceItem}
                  isEnabled={isEnabled}
                  requestActivation={requestActivation}
                  blockImages={block.marketplaceBlockImages}
                  activateWithoutRequest={activateWithoutRequest}
                  requestItemToSupport={requestItemToSupport}
                  marketplaceItems={marketplaceItems}
                  {...props}
                />
              );
            })}
      </div>
      <div className={styles.marketplaceItemPageFooter}>
        <img src={`${Api.baseUrl()}/resources/images/logo.png`} alt="BIM Logo" />
      </div>
    </UiBlocker>
  );
}
