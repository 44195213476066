class ValNode {
  constructor(node) {
    this.id = node.id;
    this.caption = node.caption;
  }

  toString() {
    return this.caption;
  }
}

function processNode(node) {
  if (node.children.length > 0) {
    let rows = [];

    node.children.forEach((child) => {
      rows.push(...processNode(child));
    });

    return rows.map((row) => {
      let res = { ...row };
      res[node.id.slice(0, node.id.indexOf('].[') + 1)] = new ValNode(node);
      return res;
    });
  } else {
    let res = {};
    res[node.id.slice(0, node.id.indexOf('].[') + 1)] = new ValNode(node);
    return [res];
  }
}

function processColumnNode(node) {
  let nodeId = node.id.slice(node.id.indexOf('].[') + 2);

  if (node.children.length > 0) {
    let columns = [];

    node.children.forEach((child) => {
      let children = processColumnNode(child);
      columns.push({ ...children, parentField: nodeId });
    });

    return {
      field: nodeId,
      headerName: node.caption,
      children: columns,
    };
  } else {
    return {
      field: nodeId,
      headerName: node.caption,
      type: node.type,
      calculationType: node.calculationType,
      calculationProps: node.calculationProps,
    };
  }
}

function generateMeasureCols(data) {
  let columns = [];

  data.nodes.forEach((node) => {
    columns.push(processColumnNode(node));
  });

  return columns;
}

export function generateCols(data) {
  let columns = [];

  data.rows.depthInfo.hierarchies.forEach((col) => {
    columns.push({
      field: col.id,
      headerName: col.caption,
    });
  });

  columns.push(...generateMeasureCols(data.columns));

  return columns;
}

const calculateAccumulatedValues = (rows = [], calculatedCols = []) => {
  calculatedCols.forEach((col) => {
    const measureColumn = `[${col.calculationProps.measure}]`;
    let accumulatedValue = 0;
    rows.forEach((row) => {
      accumulatedValue += row[measureColumn] || 0;
      row[col.field] = accumulatedValue;
    });
  });
  return rows;
};

const calculateParticipation = (rows = [], calculatedCols = []) => {
  calculatedCols.forEach((col) => {
    const measureColumn = `[${col.calculationProps.measure}]`;

    const colTotal = rows.reduce((sum, row) => {
      return sum + (row[measureColumn] || 0);
    }, 0);

    rows.forEach((row) => {
      const value = row[measureColumn] || 0;
      row[col.field] = colTotal !== 0 ? value / colTotal : 0;
    });
  });
  return rows;
};

const calculateAccumulatedParticipation = (rows, calculatedCols) => {
  calculatedCols.forEach((col) => {
    const measureColumn = `[${col.calculationProps.measure}]`;
    const colTotal = rows.reduce((sum, row) => sum + (row[measureColumn] || 0), 0);
    let accumulatedValue = 0;
    rows.forEach((row) => {
      accumulatedValue += row[measureColumn] || 0;
      row[col.field] = colTotal !== 0 ? accumulatedValue / colTotal : 0;
    });
  });
  return rows;
};

const calculateRanking = (rows = [], calculatedCols = [], isAscending = false) => {
  const sortedRows = rows.slice();

  calculatedCols.forEach((col) => {
    const measureColumn = `[${col.calculationProps.measure}]`;

    sortedRows.sort((a, b) => {
      const aValue = a[measureColumn] || 0;
      const bValue = b[measureColumn] || 0;
      return isAscending ? aValue - bValue : bValue - aValue;
    });

    sortedRows.forEach((row, index) => {
      row[col.field] = index + 1;
    });
  });

  return rows;
};

const sortRowsByColumn = ({ rows = [], sortColId = '', isAscending = true }) => {
  if (!sortColId) {
    return rows;
  }

  return rows.sort((a, b) => {
    const valueA = a[sortColId] || 0;
    const valueB = b[sortColId] || 0;

    if (isAscending) {
      return valueA - valueB;
    } else {
      return valueB - valueA;
    }
  });
};

export const CALCULATION_TYPE_PROCESSORS = {
  ASCENDING_RANKING: {
    transform: ({ rows, columns }) => calculateRanking(rows, columns, true),
  },
  DESCENDING_RANKING: {
    transform: ({ rows, columns }) => calculateRanking(rows, columns, false),
  },
  TOTAL_SHARE: {
    transform: ({ rows, columns }) => calculateParticipation(rows, columns),
  },
  ACCUMULATED: {
    transform: ({ rows, columns }) => calculateAccumulatedValues(rows, columns),
    computeAfterSort: true,
  },
  ACCUMULATED_TOTAL_SHARE: {
    transform: ({ rows, columns }) => calculateAccumulatedParticipation(rows, columns),
    computeAfterSort: true,
  },
};

export function generateRows(data) {
  const mcols = generateMeasureCols(data.columns);
  const measureCols = [];
  mcols.forEach((mc) => {
    if (mc.children) {
      measureCols.push(...mc.children);
    } else {
      measureCols.push(mc);
    }
  });

  const membersRows = [];
  data.rows.nodes.forEach((node) => {
    membersRows.push(...processNode(node));
  });

  const cells = data.cells;

  let rows = [];
  let calculatedColumnsQuantity = 0;
  for (let i = 0; i < membersRows.length; i++) {
    const measureRows = {};
    for (let j = 0; j < measureCols.length; j++) {
      const cellIndex = i * measureCols.length + j - calculatedColumnsQuantity + '';
      const mcol = measureCols[j % measureCols.length];
      const field = mcol.parentField ? mcol.parentField + '_' + mcol.field : mcol.field;
      if (!mcol.calculationType) {
        measureRows[field] = cells[cellIndex] ? cells[cellIndex].value : null;
      } else {
        measureRows[field] = 0.0;
        calculatedColumnsQuantity = calculatedColumnsQuantity + 1;
      }
    }

    rows.push({
      ...measureRows,
      ...membersRows[i],
    });
  }

  const groupedCalcColumns = Object.entries(
    measureCols.reduce((acc, column) => {
      if (column.calculationType) {
        if (!acc[column.calculationType]) {
          acc[column.calculationType] = [];
        }
        acc[column.calculationType].push(column);
      }
      return acc;
    }, {})
  )
    .filter(([calculationType]) => {
      return !!CALCULATION_TYPE_PROCESSORS[calculationType];
    })
    .map(([calculationType, columns]) => {
      return {
        processorData: CALCULATION_TYPE_PROCESSORS[calculationType],
        columns,
      };
    });

  // Processa colunas calculadas 'normais'
  for (const groupedColumns of groupedCalcColumns) {
    if (groupedColumns.processorData.computeAfterSort) {
      continue;
    }

    rows = groupedColumns.processorData.transform({ rows, columns: groupedColumns.columns });
  }

  const isAscending = data.appliedSort?.sort === 'asc';
  rows = sortRowsByColumn({ rows, sortColId: data.appliedSort?.colId, isAscending });

  // Processa colunas calculadas que dependem de ordenação
  for (const groupedColumns of groupedCalcColumns) {
    if (!groupedColumns.processorData.computeAfterSort) {
      continue;
    }

    rows = groupedColumns.processorData.transform({ rows, columns: groupedColumns.columns });
  }

  rows.forEach((row, index) => {
    row.key = index;
  });

  return rows;
}

export const valueFormatter = (params) => {
  return formatValue(params.colDef.customProps.columnType, params.data[params.colDef.field]);
};

const formatValue = (columnType, data) => {
  switch (columnType) {
    case 'number': {
      return parseFloat(data).toFixed(3);
    }
    case 'currency': {
      return 'R$ ' + parseFloat(data).toFixed(2);
    }
    case 'percentual': {
      return parseFloat(data).toFixed(2) + ' %';
    }
    default: {
      return data;
    }
  }
};

export const valueGetter = (params) => {
  return params.data[params.colDef.field];
};

const CurrencyType = {
  currency: 'BRL',
  Currency: 'BRL',
  CurrencyDollar: 'USD',
  CurrencyEuro: 'EUR',
  AccountingDollar: 'USD',
  AccountingEuro: 'EUR',
  AccountingReal: 'BRL',
};

export const numberFormatter = (decimals, format, colId) => {
  const localeOpts = {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  };

  const currency = CurrencyType[format];
  if (currency) {
    Object.assign(localeOpts, {
      style: 'currency',
      currency,
    });
  }
  const isPercentFormat = format === 'percent';
  const isAccountingFormat = format.startsWith('Accounting');
  const isTime = ['Hour', 'Minutes', 'time'].includes(format);

  return (params) => {
    let value = parseFloat(params.value);
    if (isNaN(value)) {
      return params.value;
    }

    if (isAccountingFormat) {
      let formattedValue = Math.abs(value).toLocaleString('pt-BR', localeOpts);
      if (value < 0) {
        return `(${formattedValue})`;
      }
      return formattedValue;
    } else if (isPercentFormat) {
      value *= 100;
      if (params.colDef) {
        params.colDef.custom.suffix = ' %';
      }
    } else if (isTime) {
      const hourFormatter = buildHourValueFormatter();
      return hourFormatter({ value });
    }

    return value.toLocaleString('pt-BR', localeOpts);
  };
};

export const buildHourValueFormatter = _.memoize(() => {
  const minuteInHours = 1 / 60;
  return (params) => {
    const tempValue = params.value;
    const hour = Math.floor(tempValue);
    let decimalPart = tempValue - hour;
    decimalPart = minuteInHours * Math.round(decimalPart / minuteInHours);
    let minute = Math.floor(decimalPart * 60) + '';
    if (minute.length < 2) {
      minute = '0' + minute;
    }
    return `${hour}:${minute}`;
  };
});

export const defaultFormatter = (colId) => {
  return (params) => {
    return params.value;
  };
};

function getRegex1Value(a) {
  let b = a.slice(0, a.indexOf(' '));
  if (b.length < 2) {
    b = '0' + b;
  }
  return a.slice(-4) + '/' + b;
}

function getRegex2Value(a) {
  let b = monthToNumber(a.slice(0, a.indexOf('/')));
  return a.slice(-4) + '/' + b;
}

function getRegex3Value(a) {
  return a.split('/').reverse().join('-');
}

function monthToNumber(month) {
  switch (month) {
    case 'JAN':
      return '01';
    case 'FEV':
      return '02';
    case 'MAR':
      return '03';
    case 'ABR':
      return '04';
    case 'MAI':
      return '05';
    case 'JUN':
      return '06';
    case 'JUL':
      return '07';
    case 'AGO':
      return '08';
    case 'SET':
      return '09';
    case 'OUT':
      return '10';
    case 'NOV':
      return '11';
    case 'DEZ':
      return '12';
    default:
      return null;
  }
}

function fullMonthToNumber(month) {
  switch (month) {
    case 'JANEIRO':
      return '01';
    case 'FEVEREIRO':
      return '02';
    case 'MARÇO':
      return '03';
    case 'ABRIL':
      return '04';
    case 'MAIO':
      return '05';
    case 'JUNHO':
      return '06';
    case 'JULHO':
      return '07';
    case 'AGOSTO':
      return '08';
    case 'SETEMBRO':
      return '09';
    case 'OUTUBRO':
      return '10';
    case 'NOVEMBRO':
      return '11';
    case 'DEZEMBRO':
      return '12';
    default:
      return null;
  }
}

export function dateComparator(valueA, valueB, nodeA, nodeB, isInverted) {
  let regex1 = /[0-9]+\s((TRIMESTRE)|(SEMESTRE)|(SEMANA))\/[0-9]{4}/;
  let regex2 = /[A-Z]{3}\/[0-9]{4}/;
  let regex3 = /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/;

  let func = null;

  if (valueA.match(regex1) && valueB.match(regex1)) {
    func = getRegex1Value;
  } else if (valueA.match(regex2) && valueB.match(regex2)) {
    func = getRegex2Value;
  } else if (valueA.match(regex3) && valueB.match(regex3)) {
    func = getRegex3Value;
  } else if (fullMonthToNumber(valueA) && fullMonthToNumber(valueB)) {
    func = fullMonthToNumber;
  }

  if (func != null) {
    return func(valueA).localeCompare(func(valueB));
  }

  return valueA - valueB;
}

export const quantile = (arr, q) => {
  const sorted = arr;
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

export const buildCssRules = (props) => {
  const rules = [];

  rules.push(`.BngBigTable-${props.id} .ag-row {background-color: ${props.gridConfig.backgroundColor};}`);

  if (props.gridConfig.highlightPinnedColumns) {
    rules.push(
      `.BngBigTable-${props.id} .ag-pinned-left-cols-container .ag-row {background-color: ${props.gridConfig.pinnedColumnBackgroundColor};}`
    );
    rules.push(
      `.BngBigTable-${props.id} .ag-pinned-right-cols-container .ag-row {background-color: ${props.gridConfig.pinnedColumnBackgroundColor};}`
    );
  }

  if (props.gridConfig.stripped === true) {
    rules.push(`.BngBigTable-${props.id} .ag-row-odd {background-color: ${props.gridConfig.strippedColor};}`);
    if (props.gridConfig.highlightPinnedColumns) {
      rules.push(
        `.BngBigTable-${props.id} .ag-pinned-left-cols-container .ag-row-odd {background-color: ${props.gridConfig.pinnedColumnStrippedBackgroundColor};}`
      );
      rules.push(
        `.BngBigTable-${props.id} .ag-pinned-right-cols-container .ag-row-odd {background-color: ${props.gridConfig.pinnedColumnStrippedBackgroundColor};}`
      );
    }
  } else {
    rules.push(`.BngBigTable-${props.id} .ag-row-odd {background-color: ${props.gridConfig.backgroundColor};}`);
    if (props.gridConfig.highlightPinnedColumns) {
      rules.push(
        `.BngBigTable-${props.id} .ag-pinned-left-cols-container .ag-row-odd {background-color: ${props.gridConfig.pinnedColumnBackgroundColor};}`
      );
      rules.push(
        `.BngBigTable-${props.id} .ag-pinned-right-cols-container .ag-row-odd {background-color: ${props.gridConfig.pinnedColumnBackgroundColor};}`
      );
    }
  }

  rules.push(`.BngBigTable-${props.id} .ag-header-icon {color: ${props.gridConfig.headerFontColor};}`);
  rules.push(
    `.BngBigTable-${props.id} .ag-header-cell-text {color: ${props.gridConfig.headerFontColor}; font-size: ${props.gridConfig.headerFontSize}px;}`
  );
  rules.push(
    `.BngBigTable-${props.id} .ag-header-group-text {color: ${props.gridConfig.headerFontColor}; font-size: ${props.gridConfig.headerFontSize}px;}`
  );
  rules.push(
    `.BngBigTable-${props.id} .ag-header-viewport {background-color: ${props.gridConfig.headerBackgroundColor};}`
  );
  rules.push(
    `.BngBigTable-${props.id} .ag-pinned-left-header {background-color: ${props.gridConfig.headerBackgroundColor};}`
  );
  rules.push(
    `.BngBigTable-${props.id} .ag-pinned-right-header {background-color: ${props.gridConfig.headerBackgroundColor};}`
  );
  rules.push(`.BngBigTable-${props.id} .ag-row:not(.ag-row-first) {border-top-style: none;}`);

  //rules.push(`.BngBigTable-${props.id} .ag-row-hover {background-color: ${props.gridConfig.hoverBackgroundColor} !important;}`);
  rules.push(
    `.BngBigTable-${props.id} .ag-row-selected {background-color: ${props.gridConfig.selectedBackgroundColor} !important;}`
  );
  rules.push(
    `.BngBigTable-${props.id} .ag-row-selected .ag-react-container {color: ${props.gridConfig.selectedFontColor} !important;}`
  );

  rules.push(
    `.BngBigTable-${props.id} .ag-cell.ag-cell-value {line-height: ${
      _.parseInt(props.gridConfig.fontSize) + 2 + 'px'
    } !important;}`
  );
  rules.push(`.BngBigTable-${props.id} .ag-row .ag-react-container {height: 100% !important;}`);

  if (props.gridConfig.borderOptions === 'all' || props.gridConfig.borderOptions === 'vertical') {
    rules.push(
      `.BngBigTable-${props.id} [aria-colindex="1"][role="gridcell"] .ag-react-container {border-left: ${props.gridConfig.borderWidth}px solid ${props.gridConfig.borderColor};}`
    );
    rules.push(
      `.BngBigTable-${props.id} .ag-cell-first-right-pinned .ag-react-container {border-left: ${props.gridConfig.borderWidth}px solid ${props.gridConfig.borderColor};}`
    );
  }

  if (props.gridConfig.borderOptions === 'outer') {
    rules.push(
      `.BngBigTable-${props.id} .ag-root-wrapper {border: ${props.gridConfig.borderWidth}px solid ${props.gridConfig.borderColor};}`
    );
  } else {
    rules.push(`.BngBigTable-${props.id} .ag-root-wrapper {border: none;}`);
  }

  rules.push(`.BngBigTable-${props.id} .ag-root-wrapper {background: transparent}`);

  return rules;
};

export const iconForDimensionType = (type = '') => {
  switch (type) {
    case 'NUMERIC':
    case 'Measure': {
      return '123';
    }
    case 'DATE':
    case 'TimeDimension': {
      return 'event';
    }
    case 'URL':
    case 'Url':
    case 'url': {
      return 'link';
    }
    case 'IMAGE_URL':
    case 'image_url': {
      return 'image';
    }
    default: {
      return 'abc';
    }
  }
};

const convertNumber = (value) => {
  if (!Number.isFinite(value)) {
    return Number.MIN_SAFE_INTEGER;
  }
  return parseFloat(value);
};

export function numberComparator(valueA, valueB) {
  return convertNumber(valueA) - convertNumber(valueB);
}

export function processQueryResult({ columns = [], queryResult = {}, sortModel = [] }) {
  queryResult = _.cloneDeep(queryResult);

  columns
    .filter((f) => f.columnType === 'Measure' && !!f.calculationType)
    .forEach((f) =>
      queryResult.data.columns.nodes.push({
        label: f.title,
        value: f.key,
        type: f.type,
        calculationType: f.calculationType,
        calculationProps: f.calculationProps,
        measureColumn: f.measureColumn,
        id: `[${f.columnType}].[${f.key}]`,
        caption: f.title,
        children: [],
      })
    );

  queryResult.data.appliedSort = sortModel.find((col) => !!col.sort);

  return {
    columns: queryResult ? generateCols(queryResult.data) : [],
    rows: queryResult ? generateRows(queryResult.data) : [],
    measureColumns: queryResult.data.columns.nodes.map((node) => {
      return {
        value: node.id.split('.').at(-1).slice(1, -1),
        label: node.caption,
      };
    }),
  };
}
