import { create } from 'zustand';
import UUID from 'bng/utils/UUID';
import { DIALOG_MODE } from 'components/ui/Dialog';

const isTheSameChat = (chatA, chatB) => {
  if (!chatA || !chatB) return false;

  return (
    chatA.assistantKey === chatB.assistantKey &&
    chatA.dashboardPath === chatB.dashboardPath &&
    chatA.itemPath === chatB.itemPath
  );
};

const useAdaAiChatStore = create((set, get) => ({
  chats: [],
  currentObjectPath: null,

  setDialogMode: (chatData, mode) => {
    set((state) => ({
      chats: state.chats.map((chat) =>
        isTheSameChat(chat, chatData) ? { ...chat, dialogMode: mode, currentObject: mode !== 'minimized' } : chat
      ),
    }));
  },

  openChat: (chatData) => {
    set((state) => {
      if (state.isChatOpen(chatData)) {
        if (state.isChatMinimized(chatData)) {
          state = state._getRestoredChatState(chatData);
        }
        return { chats: state.chats };
      }

      chatData = {
        ...chatData,
      };

      if (!chatData.id) {
        chatData.id = `AdaAiChat-${UUID.generate()}`;
      }

      return {
        chats: [...state.chats, chatData],
      };
    });
    if (chatData.messageLogPreview) {
      get().updateChat(chatData);
    }
    get().setDialogMode(chatData, '');
  },

  closeChat: (chatData) => {
    set((state) => {
      const closeChat = state.chats.filter((chat) => !isTheSameChat(chat, chatData));

      return {
        chats: closeChat,
      };
    });
  },

  restoreChat: (chatData) => {
    set((state) => state._getRestoredChatState(chatData));
    get().setDialogMode(chatData, '');
  },

  _getRestoredChatState: (chatData) => {
    return {
      chats: get().chats.map((chat) => (isTheSameChat(chat, chatData) ? { ...chat, currentObject: true } : chat)),
    };
  },

  updateChat: (chatData) => {
    set((state) => ({
      chats: state.chats.map((chat) => (isTheSameChat(chat, chatData) ? { ...chat, ...chatData } : chat)),
    }));
  },

  notifyPathChange: (dashboardPath) => {
    set({
      currentObjectPath: dashboardPath,
    });
  },

  isChatOpen: (chatData) => get().chats.some((chat) => isTheSameChat(chat, chatData)),

  isChatMinimized: (chatData) => {
    const chat = get().chats.find((c) => isTheSameChat(c, chatData));
    return chat ? chat.dialogMode === DIALOG_MODE.MINIMIZED : false;
  },

  clearMessageLogPreview: (chatData) => {
    get().updateChat({
      ...chatData,
      messageLogPreview: null,
    });
  },
}));

export default useAdaAiChatStore;
