import { Axios } from 'commonUtils';

class AppCacheApi {
  clearAppCache() {
    return Axios.post(`/spr/cache/clearApp`);
  }
  clearOlapCache() {
    return Axios.post(`/spr/cache/clearOlap`);
  }
}

export default AppCacheApi;
