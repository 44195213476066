import styles from './CreateIpRestrictionDialog.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import { FastField, Field, Formik } from 'formik';
import Dialog from 'components/ui/Dialog';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import useTranslation from 'components/hooks/useTranslation';
import BngForm from 'components/bng/form/BngForm';
import BngYup from 'components/bng/form/yup/BngYup';
import BngField from 'components/bng/form/BngField';
import BngListField from 'components/bng/form/BngListField';
import BngUserItemSelect from 'components/bng/form/itemSelect/BngUserItemSelect';
import UiMsg from 'components/ui/UiMsg';
import {
  buildSortedMemberOptions,
  fetchMembersForOptions,
} from 'components/bng/exportScheduling/BngExportRecipientsTab';
import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';
import BngSwitch from 'components/bng/form/BngSwitch';
import { buildRuleOpts } from 'components/bng/pages/project/management/tabs/AccessTimeRestrictionTab';

const IpRestrictionRuleSchema = BngYup((yup) =>
  yup.object({
    id: yup.number().default(0),
    active: yup.boolean().default(true),
    props: yup
      .object({
        countries: yup.array(yup.string().required().default('')),
        restrictedIps: yup.array(yup.string().required()).min(1).required(),
      })
      .default({ countries: [], restrictedIps: [] }),
    allowedUsers: yup.array(yup.object()).default([]),
    deniedUsers: yup.array(yup.object({})).min(1).default([]),
    exceptionUsersEnabled: yup.boolean().default(false),
  })
);

export default function CreateIpRestrictionDialog({ closeModal = _.noop, id = null, afterSubmit = _.noop }) {
  const { t } = useTranslation();
  const context = useBimContext();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(IpRestrictionRuleSchema.default());
  const [memberOpts, setMemberOpts] = useState([]);
  const [countriesOpts, setCountriesOpts] = useState([]);

  const ruleOpts = useMemo(() => {
    return buildRuleOpts(t);
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await Promise.allSettled([fetchMemberOpts(), fetchCountriesOpts()]);
        if (id) {
          await fetchIpRestriction();
        }
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const buildMemberForInput = (restrictionMember) => {
    const isUser = restrictionMember.user;
    const isRule = restrictionMember.rule;
    const member = isUser
      ? restrictionMember.user
      : isRule
      ? ruleOpts.find((r) => r.id === restrictionMember.rule)
      : restrictionMember.userGroup;
    return {
      id: `${isUser ? 'USER-' : isRule ? '' : 'GROUP-'}${member.id}`,
      name: member.displayName || member.userName || member.caption || member.name,
      type: isUser ? 'USER' : isRule ? 'RULE' : 'GROUP',
      userId: isUser ? member.id : null,
      groupId: !isUser && !isRule ? member.id : null,
      email: member.email,
      icon: member.icon,
      restriction: restrictionMember.type,
    };
  };

  const fetchIpRestriction = async () => {
    try {
      const fetchedRestriction = await Api.IpRestrictionRule.fetchOne(id);
      const deniedUsers = fetchedRestriction.restrictedUsers
        .filter((restrictedUser) => restrictedUser.type === 'DENY')
        .map((restrictedUser) => {
          return buildMemberForInput(restrictedUser);
        });
      const allowedUsers = fetchedRestriction.restrictedUsers
        .filter((restrictedUser) => restrictedUser.type === 'ALLOW')
        .map((exceptionUser) => {
          return buildMemberForInput(exceptionUser);
        });
      setInitialValues({
        ...IpRestrictionRuleSchema.default(),
        ...fetchedRestriction,
        deniedUsers,
        allowedUsers,
        exceptionUsersEnabled: allowedUsers.length > 0,
      });
    } catch (e) {
      console.error('Error on function fetchIpRestriction()', e);
      UiMsg.error(t('ip.restriction.fetch.error'));
    }
  };

  const fetchMemberOpts = async () => {
    try {
      const fetchedMembers = await fetchMembersForOptions({
        projectId: context.project.id,
        msg: context.msg,
      });
      ruleOpts
        .slice()
        .reverse()
        .forEach((option) => {
          fetchedMembers.unshift(option);
        });
      setMemberOpts(
        fetchedMembers.filter((member) => member.type !== 'USER' || member.userId !== context.accountMasterId)
      );
    } catch (e) {
      console.error('Error on function fetchMemberOpts()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  const fetchCountriesOpts = async () => {
    try {
      const fetchedCountries = await Api.IpRestrictionRule.fetchCountries();
      setCountriesOpts(
        Object.entries(fetchedCountries)
          .sort(([key1, value1], [key2, value2]) => {
            return value1 > value2;
          })
          .map(([key, value]) => {
            return {
              value: key,
              label: value,
            };
          })
      );
    } catch (e) {
      console.error('Error on function fetchCountriesOpts()', e);
      UiMsg.ajaxError(t('error.fetching.countries'), e);
    }
  };

  return (
    <Formik
      onSubmit={async (values) => {
        setLoading(true);
        try {
          const projectId = context.project.id;
          let restrictedUsers = values.deniedUsers;
          if (values.exceptionUsersEnabled) {
            restrictedUsers = restrictedUsers.concat(values.allowedUsers);
          }

          restrictedUsers = restrictedUsers.map((member) => {
            return {
              id: member.restrictedUserId ?? 0,
              user:
                member.type === 'USER'
                  ? {
                      id: member.userId,
                    }
                  : null,
              userGroup:
                member.type === 'GROUP'
                  ? {
                      id: member.groupId,
                    }
                  : null,
              rule: member.type === 'RULE' ? member.id : null,
              type: member.restriction,
            };
          });

          await Api.IpRestrictionRule.save({
            ...values,
            props: {
              ...values.props,
            },
            restrictedUsers,
            project: {
              id: projectId,
            },
          });
          UiMsg.ok(t('ip.restriction.save.success'));
          await afterSubmit();
          closeModal();
        } catch (e) {
          console.error('Error on function saveIpRestrictionRule()', e);
          UiMsg.error(t('ip.restriction.save.error'));
        } finally {
          setLoading(false);
        }
      }}
      initialValues={initialValues}
      validationSchema={IpRestrictionRuleSchema}
      enableReinitialize
    >
      {({ values, submitForm }) => {
        return (
          <BngForm>
            <Dialog
              className={`CreateIpRestrictionDialog ${styles.CreateIpRestrictionDialog} large`}
              onClose={closeModal}
              title={t(id ? 'edit.restriction' : 'new.restriction')}
              newDialogLayout={true}
              loading={loading}
            >
              <Dialog.Body>
                <div className={styles.ipRegionWrapper}>
                  <BngListField
                    className={styles.restrictionListField}
                    items={values.props.restrictedIps}
                    label={t('liberation.ips')}
                    name={'props.restrictedIps'}
                    onNewItem={() => {
                      return '';
                    }}
                    btnLabel={t('add.ip')}
                    itemRender={({ name }) => {
                      return (
                        <FastField
                          name={`${name}`}
                          placeholder={'255.255.255.255'}
                          component={BngField}
                          withLabel={false}
                          rootClassName={styles.textField}
                        />
                      );
                    }}
                  />
                  <BngListField
                    className={styles.restrictionListField}
                    items={values.props.countries}
                    label={t('country.region')}
                    name={'props.countries'}
                    onNewItem={() => {
                      return '';
                    }}
                    btnLabel={t('add.country.region')}
                    itemRender={({ name }) => {
                      return (
                        <FastField
                          name={`${name}`}
                          component={BngField}
                          withLabel={false}
                          rootClassName={styles.textField}
                          inputComponent={BngSelectSearch}
                          options={countriesOpts}
                        />
                      );
                    }}
                  />
                </div>
                <Field
                  name={`deniedUsers`}
                  label={t('restricted.users')}
                  component={BngField}
                  inputComponent={BngUserItemSelect}
                  buttonLabel={t('click.here.to.select')}
                  options={buildSortedMemberOptions({
                    members: memberOpts.map((member) => {
                      return { ...member, restriction: 'DENY' };
                    }),
                    isSharingMobile: false,
                    sendTo: values.deniedUsers,
                    msg: context.msg,
                    removeGroups: false,
                  })}
                />
                <Field
                  component={BngField}
                  name="exceptionUsersEnabled"
                  inputComponent={BngSwitch}
                  asProps={{ label: t('excluded.members.enabled') }}
                  withLabel={false}
                />
                {values.exceptionUsersEnabled && (
                  <Field
                    name={`allowedUsers`}
                    component={BngField}
                    withLabel={false}
                    inputComponent={BngUserItemSelect}
                    buttonLabel={t('click.here.to.select')}
                    options={buildSortedMemberOptions({
                      members: memberOpts.map((member) => {
                        return { ...member, restriction: 'ALLOW' };
                      }),
                      isSharingMobile: false,
                      sendTo: values.allowedUsers,
                      msg: context.msg,
                      removeGroups: false,
                    })}
                  />
                )}
              </Dialog.Body>
              <Dialog.Footer>
                <DefaultDialogActions onClickSaveButton={submitForm} closeModal={closeModal} />
              </Dialog.Footer>
            </Dialog>
          </BngForm>
        );
      }}
    </Formik>
  );
}
