import stepsCommonsStyles from './StepsCommonStyles.module.css';

import React, { useEffect, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';

import { BngField } from 'components/bng/form/BngField';
import { arrayToOptionsI18n, BngSelect } from 'components/bng/form/BngSelect';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';
import BngForm from 'components/bng/form/BngForm';

export const ProjectType = ['Template', 'Production', 'Homologation', 'Demo'];
export const ProjectLanguage = ['pt_BR', 'en_US', 'es_ES', 'pt_PT'];

export default function ProjectStep({ replication = false, accountOpts = [] }) {
  const { msg } = useBimContext();
  const { values, setFieldValue } = useFormikContext();
  const projectTypes = useMemo(() => {
    const opts = ProjectType.slice();
    const accountOpt = values.accountId ? accountOpts.find((opt) => opt.value === parseInt(values.accountId)) : null;
    if (!accountOpt || !accountOpt.account.partner) {
      opts.shift();
    }
    return arrayToOptionsI18n({ msg }, opts);
  }, [values.accountId]);
  const projectLanguages = useMemo(() => arrayToOptionsI18n({ msg }, ProjectLanguage), []);

  useEffect(() => {
    setFieldValue('url', `https://${values.name}.bimachine.com`, false);
  }, [values.name]);

  return (
    <BngForm className={`${stepsCommonsStyles.ContentContainerStyle}`}>
      <div className="row-fluid">
        <div className="span6">
          <Field name="name" component={BngField} maxLength={20} required={true} />
        </div>
        <div className="span6">
          <Field name="displayName" label={msg.t('caption')} required={true} component={BngField} maxLength={60} />
        </div>
      </div>

      <div className="row-fluid">
        <div className="span6">
          <Field
            label={msg.t('url')}
            name="url"
            component={BngField}
            readOnly={true}
            disabled={true}
            showErrors={false}
          />
        </div>
        {!replication && (
          <div className="span6">
            <Field
              label={BngField.LABEL_EMPTY}
              name="domainId"
              component={BngField}
              inputComponent={BngSelect}
              readOnly={true}
              disabled={true}
              options={[]}
            />
          </div>
        )}
      </div>

      <Field name="description" component={BngField} />

      <div className="row-fluid">
        <div className="span6">
          <Field
            name="accountId"
            label={msg.t('account')}
            component={BngField}
            inputComponent={BngSelectSearch}
            options={accountOpts}
            required={true}
          />
        </div>
        <div className="span6">
          <Field
            name="projectType"
            label={msg.t('type')}
            component={BngField}
            inputComponent={BngSelectSearch}
            showSearchBar={false}
            options={projectTypes}
            required={true}
          />
        </div>
      </div>
      <div className="row-fluid">
        <div className="span6">
          <Field
            name="language"
            label={msg.t('language')}
            component={BngField}
            inputComponent={BngSelectSearch}
            showSearchBar={false}
            options={projectLanguages}
            required={true}
          />
        </div>
      </div>

      <div className="row-fluid">
        <div className="span6">
          <Field
            label=""
            name="availableOnMobile"
            component={BngField}
            title={msg.t('project.general.mobile.hint')}
            inputComponent={BngCheckbox}
            asProps={{ label: msg.t('avaliable.on.mobile') }}
          />

          <Field
            label=""
            name="objectMobileByDefault"
            component={BngField}
            title={msg.t('mobile.default.state.hint')}
            inputComponent={BngCheckbox}
            asProps={{ label: msg.t('mobile.default.state') }}
          />

          <Field
            label=""
            name="limitSupport"
            component={BngField}
            title={msg.t('project.general.limit.support', Api.baseUrl())}
            inputComponent={BngCheckbox}
            asProps={{ label: msg.t('limit.support.to.admins.only') }}
          />
        </div>
      </div>
    </BngForm>
  );
}
