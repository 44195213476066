import 'script-loader!legacy/vendor/jquery.easy-pie-chart-2.1.6-custom.js';
import 'script-loader!peity/jquery.peity';

import React from 'react';
import PropTypes from 'prop-types';
import { RightFragment } from 'components/ui/kpi/RightFragment';
import { Growth } from 'components/ui/kpi/Growth';
import { KpiBands } from 'components/ui/kpi/KpiBands';
import { KpiTarget } from 'components/ui/kpi/KpiTarget';
import Api from 'components/Api';
import { KpiBandsSquare } from 'components/ui/kpi/KpiBandsSquare';
import Utils from 'components/Utils';
import KpiValueFormatter from 'components/ui/kpi/KpiValueFormatter';

export const resizeEl = (el = null, modifiers) => {
    if (!el) return;
    let sourceModifiers = {};
    for (let modifier of Object.keys(modifiers)) {
        const {value, refSize, multiplier, minValue, maxValue} = modifiers[modifier];
        let _val = 0;
        if (!!value) {
            _val = value;
        } else {
            const desireVal = refSize * multiplier;
            if (!!minValue && desireVal < minValue) {
                _val = `${minValue}px`
            } else if (!!maxValue && desireVal > maxValue) {
                _val = `${maxValue}px`;
            } else {
                _val = `${desireVal}px`;
            }
        }
        sourceModifiers[modifier] = _val;
    }
    Object.assign(el.style, sourceModifiers);
};

const isAbsoluteValue = (extractedFormat) => {
    return extractedFormat
        && extractedFormat.absoluteValue
        && extractedFormat.prefix === '('
        && extractedFormat.suffix === ')';
}

class BaseKpi extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        label: PropTypes.string,
        target: PropTypes.number,
        targetLabel: PropTypes.string,
        preffix: PropTypes.string,
        suffix: PropTypes.string,
        value: PropTypes.number,
        image: PropTypes.string,
        color: PropTypes.string,
        percentage: PropTypes.number,
        model: PropTypes.string,
        useBands: PropTypes.bool,
        imageType: PropTypes.string,
        kpiId: PropTypes.number,
        bandsInformations: PropTypes.array,
        growthViewModel: PropTypes.object,
        targetIsPercent: PropTypes.bool,
        type: PropTypes.string,
        measureAsTarget: PropTypes.bool,
        growthInfoRender: PropTypes.bool,
        viewBands: PropTypes.bool,
        minValue: PropTypes.number,
        percent: PropTypes.bool,
        invert: PropTypes.bool,
        errorInformation: PropTypes.object,
        useDecimals: PropTypes.bool,
        decimalCases: PropTypes.number,
        useDecimalsIndicator: PropTypes.bool,
        decimalCasesIndicator: PropTypes.number,
        colorTarget: PropTypes.string,
        showNegativeVariationOption: PropTypes.bool,
        percentValueVariation: PropTypes.bool,
        textAlign: PropTypes.string,
        align: PropTypes.string,
        div: PropTypes.any,
        shouldChangeColor: PropTypes.bool,
        summarizeValues: PropTypes.bool,
        formatValue: PropTypes.string,
        extractedFormat: PropTypes.shape({
            prefix: PropTypes.string,
            suffix: PropTypes.string,
            value: PropTypes.string,
            absoluteValue: PropTypes.bool
        })
    };

    static defaultProps = {
        model: 'DEFAULT',
        title: '',
        label: '',
        target: 0,
        targetLabel: '',
        preffix: '',
        suffix: '',
        value: 0,
        image: '',
        color: 'rgb(22, 167, 101)',
        colorTarget: '',
        percentage: 0,
        useBands: false,
        imageType: '',
        kpiId: 0,
        bandsInformations: [],
        growthViewModel: {},
        targetIsPercent: false,
        type: 'ASCENDING',
        measureAsTarget: false,
        growthInfoRender: false,
        viewBands: true,
        minValue: 0,
        percent: false,
        invert: false,
        errorInformation: {},
        useDecimals: false,
        decimalCases: 0,
        useDecimalsIndicator: false,
        decimalCasesIndicator: 0,
        showNegativeVariationOption: false,
        percentValueVariation: false,
        textAlign: '',
        align: 'center',
        div: undefined,
        shouldChangeColor: false,
        summarizeValues: false,
        formatValue: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            target: props.targetIsPercent && !props.measureAsTarget ? props.target * 100 : props.target,
            bandsInformations: props.bandsInformations,
            percentage: props.percentage,
            color: props.color,
            kpiId: props.kpiId + '_' + Utils.randomId(),
        };
    }

    formatValue = ({
                       context,
                       useDecimals,
                       decimalCases,
                       percent,
                       summarizeValues,
                       value,
                       indicatorPercentage = false,
                       absoluteValue = false
                   }) => {
        const userLocale = Api.findUserLocale().replace('_', '-');
        return KpiValueFormatter.formatValue({
            locale: userLocale,
            context,
            useDecimals,
            summarizeValues,
            percent,
            decimalCases,
            value,
            indicatorPercentage,
            absoluteValue
        });
    };

    formatValueProps = () => {
        const {
            context,
            useDecimals,
            decimalCases,
            decimalCasesIndicator,
            useDecimalsIndicator,
            percent,
            summarizeValues
        } = this.props;

        let percentValue = decimalCases;
        let decimals = useDecimals;

        if (percent && useDecimalsIndicator) {
            percentValue = decimalCasesIndicator;
            decimals = useDecimalsIndicator;
        }
        const formatProps = {
            context,
            useDecimals: decimals,
            decimalCases: percentValue,
            percent,
            summarizeValues,
            absoluteValue: isAbsoluteValue(this.props.extractedFormat),
        };

        const formatPropsBands = {context, useDecimals, decimalCases, percent, summarizeValues};

        const formatPropsPercent = {
            context,
            useDecimals: useDecimalsIndicator,
            decimalCases: decimalCasesIndicator,
            percent,
            summarizeValues
        };
        const formatValue = this.props.formatValue !== '' ? this.props.formatValue : this.formatValue({
            ...formatProps,
            value: this.state.value,
            indicatorPercentage: percent
        });
        const titleFormattedValue = this.formatValue({
            context,
            percent,
            decimalCases,
            useDecimals,
            value: this.props.value,
            indicatorPercentage: percent
        });

        const formatTarget = this.formatValue({
            ...formatProps,
            value: this.state.target,
            indicatorPercentage: percent || this.props.targetIsPercent
        });
        const formatPercentage = this.formatValue({
            ...formatPropsPercent,
            value: this.state.percentage,
            indicatorPercentage: true
        });
        let formatBands = this.state.bandsInformations;
        formatBands.map(bandInfo => {
            bandInfo.formattedValue = this.formatValue({...formatPropsBands, value: bandInfo.valueNotFormated});
        });

        this.setState({
            titleFormattedValue,
            value: formatValue,
            target: formatTarget,
            bandsInformations: formatBands,
            percentage: formatPercentage,
        });
    };

    formatTargetColor = () => {
        const { colorTarget, target, value, useBands, targetIsPercent, type } = this.props;

        const adjustedTarget = targetIsPercent ? target * 100 : target;
        let color = this.state.color;

        const targetCondition = type === 'ASCENDING' ? value >= adjustedTarget : value <= adjustedTarget;

        if (colorTarget && !useBands && adjustedTarget && targetCondition) {
            color = this.props.colorTarget;
        }
        this.setState({color})
    };

    handleKPIUpdate = async () => {
        const resizeProps = {
            width: this.props.width,
            height: this.props.height
        };
        await this.resizeObject(resizeProps);
        this.initComponent(resizeProps);
    };

    async componentDidMount() {
        this.formatValueProps();
        this.formatTargetColor();
        await this.handleKPIUpdate();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        await this.handleKPIUpdate();
        if (!_.isEqual(prevProps, this.props)) {
            this.setState({
                value: this.props.value,
                target: this.props.target,
                bandsInformations: this.props.bandsInformations,
                percentage: this.props.percentage,
                color: this.props.color,
            }, () => {
                this.formatValueProps();
                this.formatTargetColor();
            });
        }
    }

    initComponent = ({width, height}) => {
        _.noop();
    };

    customResizeKpi = ({width, height, checkScroll = true}) => {
        _.noop();
    };

    static keepProportion = ({width, height, model}) => {
        let size = {width, height};

        if (['PERCENT', 'SQUARE', 'ONLY_VALUE', 'SIMPLE_VALUE', 'PROGRESS_BAR'].filter(e => model.includes(e)).length > 0) {
            return size;
        }

        const ratio = 1.7;
        const minValue = Math.min(width, height);

        if (minValue === height) {
            size.width = height * ratio;
        } else {
            size.height = width * (1 / ratio);
        }

        if (width < size.width) {
            size.width = width;
            size.height = width * (1 / ratio);
        }

        if (height < size.height) {
            size.height = height;
            size.width = height * ratio;
        }

        return size;
    };

    resizeObject = async ({width, height, checkScroll = true}) => {
        if (this.props.div) {
            const notSquare = !this.props.model.includes('SQUARE');
            const sizes = BaseKpi.keepProportion({width, height, model: this.props.model});

            this.customResizeKpi({...sizes, checkScroll});

            if (checkScroll && this.props.width < this.props.div.scrollWidth && notSquare) {
                let x = (this.props.width / this.props.div.scrollWidth) * 0.9;
                height = height * (x);
                await this.resizeObject({
                    width: sizes.width,
                    height,
                    checkScroll: false
                });
            }
        }
    };

    useRightFragment = ({renderTable = false, container = null}) => {
        const {value, ...otherProps} = this.props;

        if (!container) return null;

        otherProps.color = this.state.color;

        const rightFragmentValues = {
            ...otherProps,
            value: this.state.value,
            renderTable,
            container: container,
            titleFormattedValue: this.state.titleFormattedValue,
        };

        return (
            <RightFragment {...rightFragmentValues}/>
        )
    };

    useGrowth = () => {
        const growthValues = {
            growthViewModel: this.props.growthViewModel,
            div: this.props.div,
            kpiId: this.props.kpiId,
        };
        if (this.props.growthInfoRender) {
            return (
                <Growth {...growthValues}/>
            )
        }
    };

    showBands = () => {
        const {useBands, viewBands} = this.props;
        return useBands && viewBands;
    }

    showTarget = () => {
        const {useBands, viewBands, target} = this.props;
        return !useBands && viewBands && target !== 0;
    }

    showAddInfo = () => {
        return this.showBands() || this.showTarget();
    }

    useBands = ({container}) => {
        const {kpiId, div, model, targetLabel, measureAsTarget, width, height, align} = this.props;
        const {bandsInformations} = this.state;

        if (!container) return null;

        if (this.showBands()) {

            let band1 = bandsInformations[1].formattedValue;
            let band2 = bandsInformations[2].formattedValue;

            if (measureAsTarget) {
                band1 = bandsInformations[2].formattedValue;
                band2 = bandsInformations[3].formattedValue;
            }

            const kpiBand = {
                band1Color: bandsInformations[1].bandColor,
                band2Color: bandsInformations[2].bandColor,
                band3Color: bandsInformations[3].bandColor,
                band2MinValue: band1,
                band2MaxValue: band2,
                kpiId,
                div,
                model,
                container: container,
                width: width,
                height: height,
                align
            };


            if (model === 'SQUARE' || model === 'SQUAREPERCENT') {
                return (
                    <KpiBandsSquare {...kpiBand}/>
                )

            } else {
                return (
                    <KpiBands {...kpiBand}/>
                )
            }


        } else if (this.showTarget()) {
            return (
                <KpiTarget kpiId={kpiId}
                           div={div}
                           targetLabel={targetLabel}
                           target={this.state.target}
                           container={container}
                           width={width}
                           height={height}
                           align={align}
                           model={model}
                />
            )
        }
    }

}

export default BaseKpi;