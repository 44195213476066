import React from 'react';
import MktDefaultTab from 'components/bng/pages/marketplace/tabs/MktDefaultTab';
import GroupKeys from 'components/bng/pages/marketplace/tabs/GroupKeys';

const EDUCATIONAL_GROUPS = [
  {
    title: 'marketplaceTab.educational',
    desc: 'educational.desc',
    key: GroupKeys.EDUCATIONAL,
  },
];

export default function MktEducationalTab({ ...props }) {
  return <MktDefaultTab className={'MktEducationalTab'} groupsToRender={EDUCATIONAL_GROUPS} {...props} />;
}
