// css imported on CommonCssImports.js
import React, { useEffect, useRef, useState } from 'react';
import Popper from 'components/bng/ui/BngPopper';
import BngClickOutsideOverlay from 'components/bng/ui/BngClickOutsideOverlay';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import Icon from 'components/ui/common/Icon';
import ReactDOM from 'react-dom';

export function BngDropdown({
  className = '',
  popperClassName = '',
  popperStyle = {},
  icon = 'more_vert',
  container = document.body,
  closeOnSelect = true,
  options = [],
  onOpen = _.noop,
  onClose = _.noop,
  customButton = null,
  customOptions = null,
  boundariesElement = null,
  popperOpts = {},
  popperFlipBehaviour = 'flip',
  title = '',
  overDialog = false,
  disabled = false,
  btnIconProps = {},
  useContainerForPopper = true,
  keepOpen = false,
  useArrow = false,
  arrowClassName = '',
  offset = [0, 0],
  visible = undefined,
  startOpened = false,
}) {
  const [popperRef, setPopperRef] = useState(null);
  const $root = useRef();

  useEffect(() => {
    if (!startOpened) {
      return;
    }
    openDropdown({ currentTarget: $root.current });
  }, [$root.current]);

  if (!boundariesElement && useContainerForPopper) {
    boundariesElement = container;
  }

  const openDropdown = async (event) => {
    if (!!event && !!event.persist) event.persist();
    const target = event.currentTarget || event.target;
    await setPopperRef(target);
    await onOpen(event);
  };

  const closeDropdown = (event) => {
    setPopperRef(null);
    onClose(event);
  };

  const overDialogClass = overDialog ? 'BngDropdown-OVERDIALOG' : '';
  const popperIsOpen = _.isUndefined(visible) ? !_.isEmpty(popperRef) : visible;
  const hideStyle = _.isNil(popperRef) && keepOpen ? { display: 'none' } : {};
  const popperPosition = popperRef?.getBoundingClientRect();

  return (
    <div className={`BngDropdown ${className}`} title={title} ref={$root}>
      {!customButton && <BngIconButton icon={icon} onClick={openDropdown} disabled={disabled} {...btnIconProps} />}
      {customButton && customButton({ openDropdown, closeDropdown })}

      <Popper
        className={`bng-dropdown-parent ${popperClassName} ${overDialogClass}`}
        container={useContainerForPopper ? container : null}
        style={{ ...popperStyle, ...hideStyle }}
        open={keepOpen || popperIsOpen}
        anchorEl={popperRef}
        popperOptions={{
          modifiers: {
            preventOverflow: {
              boundariesElement: boundariesElement,
            },
            flips: {
              behavior: popperFlipBehaviour,
            },
            offset: {
              offset: `${offset[0]},${offset[1]}`,
            },
          },
        }}
        {...popperOpts}
      >
        <>
          <BngClickOutsideOverlay
            onClick={closeDropdown}
            container={container}
            className={`${popperClassName ? `${popperClassName}Overlay` : ''} ${
              overDialogClass ? `${overDialogClass}Overlay` : ''
            }`}
            style={hideStyle}
          />

          {useArrow &&
            (keepOpen || popperIsOpen) &&
            ReactDOM.createPortal(
              <div
                className={`BngDropdownPopperArrow ${arrowClassName}`}
                style={{
                  left: (popperPosition?.left ?? 0) + ((popperPosition?.width ?? 0) / 2 - 8) + offset[0],
                  top: (popperPosition?.top ?? 0) + ((popperPosition?.height ?? 0) - 4) + offset[1],
                  ...hideStyle,
                }}
              />,
              container,
            )}

          {customOptions && customOptions({ closeDropdown, isOpen: popperIsOpen })}

          {!customOptions && (
            <ul className="bng-dropdown unstyled">
              {options.map((opt, idx) => {
                if (Array.isArray(opt)) {
                  if (opt.length === 1) {
                    opt = opt[0];
                  } else {
                    return (
                      <OptionGroup
                        key={opt.key || idx}
                        group={opt}
                        closeDropdown={closeDropdown}
                        closeOnSelect={opt.closeOnSelect ?? closeOnSelect}
                      />
                    );
                  }
                }

                if (opt.isSeparator) {
                  return <BngDropdownSeparator className={opt.className} key={opt.key || idx} title={opt.label} />;
                } else {
                  return (
                    <Option
                      key={opt.key || idx}
                      opt={opt}
                      closeDropdown={closeDropdown}
                      closeOnSelect={opt.closeOnSelect ?? closeOnSelect}
                    />
                  );
                }
              })}
            </ul>
          )}
        </>
      </Popper>
    </div>
  );
}

const Option = ({ opt, closeDropdown, closeOnSelect, className = '' }) => {
  if (opt.hasOwnProperty('visible') && !opt.visible) return null;

  return (
    <li
      onClick={
        opt.disabled
          ? undefined
          : (event) => {
              const fn = opt.onClick || _.noop;
              fn(event, { closeDropdown });
              if (closeOnSelect) {
                closeDropdown();
              }
            }
      }
      title={opt.title}
      className={`${opt.className || ''} ${className} ${opt.disabled ? 'disabled' : ''}`}
    >
      {opt.hasOwnProperty('render') && opt.render({ closeDropdown })}
      {!opt.hasOwnProperty('render') && (
        <React.Fragment>
          <Icon icon={opt.icon} />
          <span>{opt.label}</span>
        </React.Fragment>
      )}
    </li>
  );
};

const OptionGroup = ({ group, closeDropdown, closeOnSelect }) => {
  return group.map((opt, idx) => {
    const isFirst = idx === 0;
    const isLast = !isFirst && group.length - 1 === idx;
    return (
      <Option
        key={opt.key || idx}
        opt={opt}
        closeDropdown={closeDropdown}
        closeOnSelect={opt.closeOnSelect ?? closeOnSelect}
        className={`GroupOption ${isFirst ? 'GroupFirst' : ''} ${isLast ? 'GroupLast' : ''} ${
          !isLast ? 'box-shadow-none' : ''
        }`}
      />
    );
  });
};

export const BngDropdownSeparator = ({ title, className = '' }) => {
  return (
    <li className={`BngDropdownSeparator ${className}`}>
      {title && <span>{title}</span>}
      <hr style={{ width: '100%' }} />
    </li>
  );
};

export default BngDropdown;
