import "./GeoreferenceEditor.css";

import React from "react";
import PropTypes from 'prop-types';

import ContextEnhancer from "components/ContextEnhancer";
import UiMsg from "components/ui/UiMsg";
import Api from "components/Api";
import Button from "components/ui/Button";
import Dialog from "components/ui/Dialog";
import Checkbox from "components/ui/Checkbox";
import PaginateTable from "components/ui/common/PaginateTable";
import GeoreferenceItem from "./GeoreferenceItem";
import {UiBlocker} from "components/bng/ui/UiBlocker";

export let GeoReferenceObject = {
    sourceobjectname: '',
    projectid: '',
    georeferencex: [],
    georeferencey: [],
    address: [],
    oldaddress: []

};

export const defaultGeoReferenceObject = {
    sourceobjectname: '',
    projectid: '',
    georeferencex: [],
    georeferencey: [],
    address: [],
    oldaddress: []

};
export const addressGeo = {
    address: ''
};

export const idRow = {
    id: '',
}

export const savegeo = {
    adress: [],
    geox: [],
    geoy: []
}

class GeoreferenceEditor extends React.Component {

    static propTypes = {
        closeModal: PropTypes.func,
        value: PropTypes.array,
        items: PropTypes.array
    };

    static defaultProps = {
        value: [],
        items: []
    };

    state = {
        maxElements: 10,
        resetPagination: false,
        geos: [],
        value: false,
        lockUI: false,
        totalItens: 0,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.enableFields();
        this.enableButtonGeo();
    }

    async updateGeoreferenceList(updateItemsNumber = false, page, resetPagination = false, maxElements = this.state.maxElements) {
        const {sourcedata} = this.props;
        const sourceObject = sourcedata;
        sourceObject.limit = maxElements;
        sourceObject.offset = (page - 1) * maxElements;

        this.setState({geos: [], resetPagination: resetPagination});

        try {
            const geos = await Api.NewMap.getAddress(sourceObject);
            let total = updateItemsNumber ? await Api.NewMap.countAddress(sourceObject) : this.state.totalItens;

            this.setState({geos: geos, totalItens: total, resetPagination: false});

            this.enableFields();
            this.enableButtonGeo();
        } catch (e) {
            console.error('Error while trying to fetch georeference', e.responseJSON);
            return {error: e};
        }
    }

    handleChangeNumberPerPage = async (perPage, pageNumber) => {
        this.setState({maxElements: perPage});
        return await this.updateGeoreferenceList(true, pageNumber, true, perPage);
    };

    handlePagination = async (pageNumber, firstLoad) => {
        return await this.updateGeoreferenceList(firstLoad, pageNumber, false);
    };

    save = async (shouldCloseDialog = true) => {
        this.setState({lockUI: true});
        GeoReferenceObject.sourceobjectname = this.props.sourcedata.sourceobjectname;
        GeoReferenceObject.projectid = this.props.sourcedata.projectid;

        j('#geo-table tbody tr').each(function () {
            const col = j(this).children();
            if (col[1].childNodes[0].defaultValue !== col[1].childNodes[0].value || col[1].childNodes[2].value !== col[1].childNodes[2].defaultValue) {
                GeoReferenceObject.address.push(col[0].childNodes[0].value);
                GeoReferenceObject.oldaddress.push(col[0].childNodes[0].value);
                GeoReferenceObject.georeferencex.push(col[1].childNodes[0].value);
                GeoReferenceObject.georeferencey.push(col[1].childNodes[2].value);
            }
        });

        try {
            let result = false;

            if (!!GeoReferenceObject.address || !!GeoReferenceObject.projectid) {
                result = await Api.NewMap.updateAddress(GeoReferenceObject);
            }

            if (result && shouldCloseDialog) {
                UiMsg.ok(this.props.context.msg.t('edit.georeference.save.sucess'));
                this.props.closeModal();
            } else if (result && !shouldCloseDialog) {
                GeoReferenceObject = {...defaultGeoReferenceObject}
            } else if (!result) {
                UiMsg.error(this.props.context.msg.t('edit.georeference.save.error'));
            }

        } catch (e) {
            console.error(e)
        } finally {
            this.setState({lockUI: false});
        }

    };

    enableFields = () => {
        j('.btn-warning').on("click", function () {
            var id = j(this).closest('tr').attr("id");
            var tr = document.getElementById(id);
            if (tr.childNodes[1].childNodes[0].disabled === false) {
                tr.childNodes[1].childNodes[0].disabled = true;
                tr.childNodes[1].childNodes[2].disabled = true;
            } else {
                tr.childNodes[1].childNodes[0].disabled = false;
                tr.childNodes[1].childNodes[2].disabled = false;
            }
        });
    };

    handleSearchChange = (event) => {
        this.props.sourcedata.address = event.target.value;
    };

    handleNullGeoChange = (event) => {
        this.props.sourcedata.zero = event.target.checked;
        this.setState({value: event.target.checked});
        this.updateGeoreferenceList(true, 1, true);
    };

    executeSearch = () => {
        this.updateGeoreferenceList(true, 1, true);
    };

    //This is one of the ugliest things I have every seen... TODO: Rewrite the whole thing
    enableButtonGeo = () => {
        j('.btn-table-api-geo').on("click", function () {
            var id = j(this).closest('tr').attr("id");
            var tr = document.getElementById(id);
            var name = tr.childNodes[0].childNodes[0].value;
            addvalue(name, id);
        });

        function addvalue(name, id) {
            addressGeo.address = name;
            idRow.id = id;
        }
    };

    executeSearchGeo = async (address = []) => {
        try {
            this.setState({lockUI: true})
            const params = {
                address: address[0],
                ignoreCache: true
            }
            const result = await Api.NewMap.searchAddress(params);
            const resultArr = result.split(',');

            GeoReferenceObject.address = [...GeoReferenceObject.address, ...address];
            GeoReferenceObject.oldaddress = [...GeoReferenceObject.oldaddress, ...address];
            GeoReferenceObject.georeferencex = [...GeoReferenceObject.georeferencex, resultArr[0]]
            GeoReferenceObject.georeferencey = [...GeoReferenceObject.georeferencey, resultArr[1]]

            if (!!result) {
                const geos = this.state.geos.map(geo => {
                    const geoAddress = Object.keys(geo)[0];
                    if (geoAddress === address[0]) {
                        return {
                            [geoAddress]: result,
                        };
                    }
                    return geo;
                })

                this.setState({geos: []})
                this.setState({geos});
                await this.save(false);
            } else {
                UiMsg.warn(this.props.context.msg.t('edit.georeference.not.found'));
            }
        } catch (e) {
            console.error('error on execute geo API fetch: ', e);
        }
    }

    createTableItem = (geo, i) => {
        Object.entries(geo);
        const itemvalue = geo[Object.keys(geo)];
        const value = itemvalue.split(',');
        const itemName = Object.keys(geo);
        const re = new RegExp((/([A-Z])\w+/)).test(itemName.toString());

        return (
            <GeoreferenceItem key={itemName[0]} i={i}
                              valueX={value[0]} valueY={value[1]}
                              itemName={itemName}
                              re={re}
                              executeSearchGeo={() => this.executeSearchGeo(itemName)}
                              onSave={() => this.save(false)}
            />
        );
    };

    render() {
        return (
            <Dialog open={this.props.open} className="large dash-icon-component"
                    title={this.props.context.msg.t('edit.georeference')}
                    onClose={this.props.closeModal}>
                <div className="georef-search-container">
                    <div className="georef-address">
                        <label>{this.props.context.msg.t('address')}</label>
                    </div>

                    <input name="search" type="text" className="georef-search" onChange={this.handleSearchChange}/>

                    <div className="georef-search-button">
                        <Button style={{height: 25, marginTop: 2}} type="submit" onClick={this.executeSearch}
                                className="btn btn-primary iceCmdBtn btn-mini btn-table btn-search" title=""
                                icon="icon-search"/>
                    </div>

                    <Checkbox style={{marginLeft: 506, marginTop: -36, maxWidth: 283}}
                              name="mobile"
                              value={{
                                  value: this.state.value,
                                  label: this.props.context.msg.t('show.georeference.zeroes')
                              }}
                              selected={this.state.value}
                              onChange={this.handleNullGeoChange}
                    />
                </div>
                <UiBlocker block={this.state.lockUI}>
                <PaginateTable handleChangePage={this.handlePagination}
                               handleChangeNumberPerPage={this.handleChangeNumberPerPage} initialPass={10} height={370}
                               totalItens={this.state.totalItens} externalChange={this.state.resetPagination}
                               className={"geo-paginate-table-wrapper"} paginationClassName={"geo-pagination-container"}>
                    <table id="geo-table" className="iceDatTbl crud-table crud-grid-form">
                        <thead>
                        <tr>
                            <th>{this.props.context.msg.t('address')}</th>
                            <th>{this.props.context.msg.t('inMemory_georeference')}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.geos.map(this.createTableItem)}
                        </tbody>
                    </table>
                </PaginateTable>
                </UiBlocker>

                <Button style={{right: -705, marginTop: 10, height: 32, width: 94}} type="submit"
                        label={this.props.context.msg.t('save')} className="btn btn-primary-geo-save"
                        loading={this.state.lockUI} title="Salvar" icon="icon-save"
                        onClick={this.props.closeModal}>
                    {this.props.context.msg.t('save')}
                </Button>
            </Dialog>
        );
    }
}


export default ContextEnhancer(GeoreferenceEditor);