import { Axios, buildUrlWithAuth } from 'commonUtils';
import FilterService from 'components/filter/FilterService';
import Api from 'components/Api';
import Utils from 'components/Utils';

class BigTableModel {
    constructor(data) {
        Object.assign(this, data);
    }
}

export default class BigTableApi {
    async findById(id) {
        const {data} = await Axios.get(`/spr/bigTable/${id}`);
        return new BigTableModel(data);
    }

    async findFieldsInfo(projectName, cube) {
        const {data} = await Axios.get(`/spr/bigTable/info`, {
            params: {
                projectName,
                inMemory: cube
            }
        });
        return data;
    }

    async executeQuery(datasourceConfig, cancelSource, filters) {
        const opts = {};
        if (cancelSource) {
            opts.cancelToken = cancelSource.token;
        }

        // Remove filters without selections
        filters = FilterService.createFilterParam(filters, true)
          .filter
          .filter(f => f.members?.length > 0);

        if (!_.isEmpty(filters)) {
            opts.params = {...FilterService.createFilterParam(filters)};
        }

        const {data} = await Axios.post(`/spr/bigTable/executeQuery`, datasourceConfig, opts);
        return data;
    }

    async executeByPath(path, filters = [], publisherContent = undefined) {
        if(typeof filters === "string") {
            filters = JSON.parse(filters);
        }
        const {data} = await Axios.post(`/spr/bigTable/execute`, {filters}, {
            params: {
                path,
                publisherContent: publisherContent ? publisherContent : undefined,
            }
        });
        return this.processExecutionData(data);
    }

    async save(bigTableData) {
        await Axios.post('/spr/bigTable/save', bigTableData);
    }

    async saveAs(bigTableData) {
        const {data} = await Axios.post('/spr/bigTable/save-as', bigTableData)
        return data;
    }

    async remove(id) {
        await Axios.delete(`/spr/bigTable/remove/${id}`);
    }

    async toggleMobile(id) {
        const {data} = await Axios.post(`/spr/bigTable/${id}/toggle-mobile`);
        return data;
    }

    async filterMembersInfo(params = {}, hierarchies = false) {
        const url = `/spr/bigTableFilters/${hierarchies ? 'hierarchies' : 'members'}`;
        const {data} = await Axios.get(url, {
            params
        });
        return data;
    }

    async exportRuntimeBigTableToCsv(bigTable, filters) {
        const {data} = await Axios.post(`/spr/bigTable/exportCsv/runtime`, {bigTable, filters});
        await this.openCsvFromLink(data);
    }

    async updateBigTableRuntimeData(bigTable, filters) {
        const {data} = await Axios.post(`/spr/bigTable/updateRuntimeData`, {bigTable, filters});
        return data;
    }

    generateExportToCsvLink(path, params) {
        return buildUrlWithAuth(`/spr/bigTable/exportCsv?${Api.params({path, ...params})}`);
    }

    async generateExportToCsvLinkFromDashboard(path, filters, bigtableFilterModel, bigtableSortModel) {
        if (_.isEmpty(bigtableSortModel)) {
            bigtableSortModel = [];
        } else {
            bigtableSortModel = _.isArray(bigtableSortModel) ? bigtableSortModel : [bigtableSortModel];
        }

        const reqBody = {
            filters,
            bigtableFilterModel,
            bigtableSortModel
        };
        const {data} = await Axios.post(`/spr/bigTable/exportCsv/dashboard`, reqBody, {
            params: {
                path
            }
        });
        await this.openCsvFromLink(data);
    }

    exportObjectUrl({fullUrl = false, ...props}) {
        Api.checkContent(props);
        const baseUrl = Api.buildBaseUrl(fullUrl);
        return `${baseUrl}/spr/bigTable/exportPdf/dashboard?${j.param(props)}`;
    }

    async openCsvFromLink(exportLink, useHref = false) {
        const blob = new Blob([exportLink], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        if(!useHref) {
            link.setAttribute("href", url);
        } else {
            link.setAttribute("download", `bigtable-${Date.now()}.csv`);
        }
        link.style.visibility = 'hidden';
        link.click();
    }

    processExecutionData(data) {
        data.bigTableDTO = new BigTableModel(data.bigTableDTO);
        return data;
    }

    exportCsvFromDash = async (path, filters, bigtableFilterModel, bigtableSortModel) => {
        const {filter} = FilterService.createFilterParam(filters, true);
        await this.generateExportToCsvLinkFromDashboard(path, filter, bigtableFilterModel, bigtableSortModel);
    }

    exportCsvFromObject = async () => {
        const {data} = await Axios.post(`/spr/bigTable/exportCsv/object`);
        await this.openCsvFromLink(data, true);
    }

    exportPdfFromDash = async (path, filters, item, bigtableFilterModel, bigtableSortModel) => {
        const filterRef = await FilterService.createFilterRef(filters);
        const mdxRef = Utils.Object.isAnalysis(item.path) ? await Api.Dash.findItemMdxRef(item.id) : undefined;
        const exportUrl = this.exportObjectUrl({
            filterRef,
            mdxRef,
            content: path,
            bigtableFilterModel: JSON.stringify(bigtableFilterModel),
            bigtableSortModel: JSON.stringify(bigtableSortModel)
        });
        window.open(exportUrl, '_blank');
    }

}
