import styles from './IpRestrictionDetailsDialog.module.css';

import { useMemo } from 'react';
import Dialog from 'components/ui/Dialog';
import useTranslation from 'components/hooks/useTranslation';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Utils from 'components/Utils';
import BngTag from 'components/bng/ui/BngTag';
import Icon from 'components/ui/common/Icon';
import useBimContext from 'components/hooks/useBimContext';
import useFetchData from 'components/hooks/useFetchData';
import { buildRuleOpts } from 'components/bng/pages/project/management/tabs/AccessTimeRestrictionTab';

function UserList({ title, restrictedUsers = [], ruleOpts }) {
  return (
    <div className={styles.userList}>
      <div className={styles.userListHeader}>
        <BngTag className={styles.userListTag}>{restrictedUsers.length}</BngTag>
        <span>{title}</span>
      </div>
      {restrictedUsers.map((restrictedUser) => {
        const member =
          restrictedUser.user ?? restrictedUser.userGroup ?? ruleOpts.find((r) => r.id === restrictedUser.rule);

        return (
          <div
            className={styles.userListItem}
            key={`restricted-${restrictedUser.user ? 'USER' : 'GROUP'}-${member.id}`}
          >
            <Icon icon={restrictedUser.user ? 'person' : restrictedUser.rule ? member.icon : 'group'} />
            <span>{member.displayName || member.userName || member.caption || member.name}</span>
          </div>
        );
      })}
    </div>
  );
}

export default function IpRestrictionDetailsDialog({ closeModal = _.noop, restrictionId }) {
  const { t } = useTranslation();
  const context = useBimContext();
  const ruleOpts = useMemo(() => buildRuleOpts(t), []);

  const { data: ipRestriction, isLoading: loading } = useFetchData(async () => {
    try {
      return await Api.IpRestrictionRule.fetchOne(restrictionId);
    } catch (e) {
      UiMsg.error(t('ip.restriction.fetch.error'));
      throw e;
    }
  }, []);

  const languageTag = context.user.preferredLanguage.split('_')[0];
  const regionNames = new Intl.DisplayNames([languageTag], { type: 'region' });
  return (
    <Dialog
      className={`IpRestrictionDetailsDialog ${styles.IpRestrictionDetailsDialog}`}
      title={t('addon.ip.restriction.name')}
      onClose={closeModal}
      loading={loading}
    >
      <section className={styles.updateInfoSection}>
        <div className={styles.updateMember}>
          <span>
            {`${t('modifiedBy')}: `}
            <strong>{`${ipRestriction?.updatedBy.displayName || ipRestriction?.updatedBy.caption}`}</strong>
          </span>
          <span>{Utils.Date.formatDateTime(ipRestriction?.updatedAt)}</span>
        </div>
        <div className={styles.updateMember}>
          <span>
            {`${t('createdBy')}: `}
            <strong>{`${ipRestriction?.createdBy.displayName || ipRestriction?.createdBy.caption}`}</strong>
          </span>
          <span>{Utils.Date.formatDateTime(ipRestriction?.createdAt)}</span>
        </div>
      </section>

      <section className={styles.extraInfoSection}>
        <span className={styles.listItem}>
          {t('ip.restriction.restricted.ips')}:<strong>{ipRestriction?.props.restrictedIps.join('; ')}</strong>
        </span>
        <span className={styles.listItem}>
          {t('status')}:<strong>{ipRestriction?.active ? t('active') : t('inactive')}</strong>
        </span>
        {ipRestriction?.props.countries.values?.length > 0 && (
          <span className={styles.listItem}>
            {t('ip.restriction.restricted.country.region')}:
            <strong>
              {ipRestriction?.props.countries.values.map((country) => regionNames.of(country)).join(', ')}
            </strong>
          </span>
        )}
      </section>

      <section className={styles.usersAndGroupsSection}>
        <UserList
          title={t('user.and.groups')}
          ruleOpts={ruleOpts}
          restrictedUsers={ipRestriction?.restrictedUsers.filter((ru) => ru.type === 'DENY')}
        />
        <UserList
          title={t('exception')}
          ruleOpts={ruleOpts}
          restrictedUsers={ipRestriction?.restrictedUsers.filter((ru) => ru.type === 'ALLOW')}
        />
      </section>
      <span>
        {t('ip.restriction.master.info')}
        <a href={t('addon.ip.restriction.link')} target={'_blank'}>
          {t('know.more')}
        </a>
      </span>
    </Dialog>
  );
}
