import FilterService from 'components/filter/FilterService';
import { Axios, buildUrl } from 'commonUtils';

class MetadataApi {
  buildMetadataUrl = async ({ filters, content, itemId, queryResultLimit, ...params }) => {
    const filterRef = await FilterService.createFilterRef(filters);

    ['bigtableFilterModel', 'bigtableSortModel'].forEach((key) => {
      if (params[key]) {
        params[key] = encodeURIComponent(JSON.stringify(params[key]));
      }
    });

    return buildUrl(`/spr/metadata`, {
      content,
      filterRef,
      itemId,
      queryResultLimit,
      ...params,
    });
  };

  exportMetadata = async (props = {}) => {
    const url = await this.buildMetadataUrl(props);
    return Axios.getData(url);
  };
}

export default MetadataApi;
