import './DimensionListWithFilters.css';

import React, { useEffect, useMemo, useState } from 'react';
import { Field, FieldArray } from 'formik';

import { BngField } from 'components/bng/form/BngField';
import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import BngRadio from 'components/bng/form/BngRadio';
import Button from 'components/ui/Button';
import Badge from 'components/ui/Badge';
import Icon from 'components/ui/common/Icon';
import { BngSelect } from 'components/bng/form/BngSelect';
import BngInputSpinner from 'components/bng/form/BngInputSpinner';
import BngClickOutsideOverlay from 'components/bng/ui/BngClickOutsideOverlay';
import BngSwitch from 'components/bng/form/BngSwitch';

export const buildDimensionOpts = ({ msg, sourceFields, props }) => {
  const result = _.cloneDeep(
    sourceFields.filter((field) => {
      return field.visible && (field.type === 'Regular' || field.type === 'TimeDimension');
    })
  );
  if (props?.showFilterOpts ?? true) {
    result
      .filter((f) => f.type === 'TimeDimension')
      .forEach((field) => {
        field.children = [
          {
            value: `${field.value}.(Completo).[Ano]`,
            label: msg.t('year'),
            previewLabel: `${field.label} - ${msg.t('year')}`,
          },
          {
            value: `${field.value}.(Semestre)`,
            label: msg.t('schema.time.semester'),
            previewLabel: `${field.label} - ${msg.t('schema.time.semester')}`,
          },
          {
            value: `${field.value}.(Trimestre)`,
            label: msg.t('schema.time.quarter'),
            previewLabel: `${field.label} - ${msg.t('schema.time.quarter')}`,
          },
          {
            value: `${field.value}.(Completo).[Mes]`,
            label: msg.t('schema.time.month'),
            previewLabel: `${field.label} - ${msg.t('schema.time.month')}`,
          },
          {
            value: `${field.value}.(Semana)`,
            label: msg.t('schema.time.week'),
            previewLabel: `${field.label} - ${msg.t('schema.time.week')}`,
          },
          {
            value: `${field.value}.(Completo).[Dia]`,
            label: msg.t('schema.time.day'),
            previewLabel: `${field.label} - ${msg.t('schema.time.day')}`,
          },
        ];
      });
  }
  return _.orderBy(result, 'label');
};

const extractDimensionName = (dim) => {
  if (dim) {
    const idx = dim.indexOf('.');
    if (idx !== -1) {
      dim = dim.slice(0, idx);
    }
  }
  return dim;
};

const LEVEL_LIMIT = 5;

export default {
  defaultVal() {
    return [
      { dimension: '', filterId: '' },
      { dimension: '', filterId: '' },
      { dimension: '', filterId: '' },
    ];
  },
  render({ context: { msg }, name, sourceFields, formikProps, globalFilters, props }) {
    props = _.merge({ showFilterOpts: true, showOrderOpts: true }, props);
    const dimensionOpts = useMemo(() => {
      return buildDimensionOpts({
        msg,
        sourceFields,
        props,
      });
    }, [sourceFields]);

    const sortMeasureOpts = useMemo(() => {
      if (!props.measuresField) {
        return null;
      }

      let selectedMeasures = _.get(formikProps.values, `params.${props.measuresField}`, []);
      if (!Array.isArray(selectedMeasures)) {
        selectedMeasures = [selectedMeasures];
      }

      return selectedMeasures
        .map((measureName) => {
          return sourceFields.find((field) => {
            return field.type === 'Measure' && field.value === measureName;
          });
        })
        .filter(Boolean);
    }, [sourceFields, props.measuresField, formikProps.values]);

    return (
      <FieldArray name={name}>
        {(arrayHelpers) => {
          const dimensions = _.get(formikProps.values, name) || [];
          const disableAddButton = dimensions.length >= dimensionOpts.length || dimensions.length >= LEVEL_LIMIT;
          const { datasource, cube } = formikProps.values;
          return (
            <div className="DimensionListWithFiltersContainer">
              {dimensions.map((val, idx) => {
                const fieldPrefix = `${name}[${idx}]`;
                const processedDimensionOpts = dimensionOpts.map((opt) => {
                  let isCurrentOption =
                    val.dimension &&
                    (opt.value === val.dimension ||
                      (opt.children?.some((childOpt) => childOpt.value === val.dimension) ?? false));
                  if (!isCurrentOption) {
                    const isDisabled = dimensions.some((d) => d.dimension === opt.value);

                    if (isDisabled) {
                      opt = { ...opt };
                      opt.disabled = isDisabled;
                      if (opt.children) {
                        opt.children = opt.children.map((c) => ({ ...c, disabled: true }));
                      }
                    } else {
                      opt.children?.map((childOpt) => {
                        childOpt.disabled = dimensions.some((d) => d.dimension === childOpt.value);
                        return childOpt;
                      });
                    }
                  }
                  return opt;
                });

                const findFiltersForDim = (dim) => {
                  if (!dim) return [];
                  dim = extractDimensionName(dim);
                  return globalFilters.filter((globalFilter) =>
                    globalFilter.mdxFilters.some(
                      (mf) =>
                        mf.datasource === datasource &&
                        mf.cube === cube &&
                        (mf.dimension === dim || mf.dimension === `BIMF${dim}`)
                    )
                  );
                };

                const filtersForDim = findFiltersForDim(val.dimension);
                const disableFilterButton = filtersForDim.length === 0;
                const opts = [
                  [
                    {
                      className: 'DimensionListWithFiltersButtonDropdown',
                      disabled: disableFilterButton,
                      render: () => (
                        <BngDropdown
                          className="w-100"
                          key={`selectedFilterId-${val.filterId ?? ''}`}
                          title={msg.t(
                            filtersForDim.length === 0
                              ? 'level.no.related.filter.title'
                              : !val.filterId && filtersForDim.length > 1
                              ? 'level.multiple.filters.alert'
                              : 'filters'
                          )}
                          popperClassName="DimensionListWithFiltersPopper BngDropdownCheckboxPopper"
                          customButton={({ openDropdown }) => {
                            return (
                              <div
                                className={`DimensionListWithFiltersButtonWrapper ${
                                  disableFilterButton ? 'disabled' : ''
                                }`}
                                onClick={disableFilterButton ? null : openDropdown}
                              >
                                <Icon
                                  className={`DimensionListWithFiltersButton Icon Filter ${
                                    val.filterId ? 'Selected' : ''
                                  }`}
                                  icon="icon-bim-filter"
                                />
                                <label className={'DimensionListWithFiltersButton Label'}>{msg.t('filters')}</label>
                                {!val.filterId && filtersForDim.length > 1 && (
                                  <Badge value={`${filtersForDim.length}`} color="badge-warning" />
                                )}
                              </div>
                            );
                          }}
                          disabled={disableFilterButton}
                          customOptions={({ closeDropdown }) => {
                            const [filterVal, setFilterVal] = useState(val.filterId || '');

                            useEffect(() => {
                              setFilterVal(val.filterId);
                            }, [val.filterId]);

                            const applyFilter = () => {
                              formikProps.setFieldValue(`${fieldPrefix}.filterId`, filterVal);
                              closeDropdown();
                            };

                            return (
                              <div>
                                <div className="text-center fw-500">{`${msg.t('select.filter.to.be.applied')}:`}</div>
                                <div className="FiltersContainer mt-1">
                                  <BngRadio
                                    value={''}
                                    label={msg.t('none')}
                                    field={{ value: filterVal }}
                                    form={{ setFieldValue: () => setFilterVal('') }}
                                  />
                                  {filtersForDim.map((f) => {
                                    return (
                                      <BngRadio
                                        key={f.id}
                                        value={f.id}
                                        label={f.displayName || f.name}
                                        field={{ value: filterVal }}
                                        form={{ setFieldValue: () => setFilterVal(f.id) }}
                                      />
                                    );
                                  })}
                                </div>
                                <div className="mt-2">
                                  <Button className={`bng-button save btn-small w-100`} onClick={applyFilter}>
                                    {msg.t('apply')}
                                  </Button>
                                </div>
                                <BngClickOutsideOverlay
                                  onClick={applyFilter}
                                  className="DimensionListWithFiltersPopper"
                                />
                              </div>
                            );
                          }}
                        />
                      ),
                      visible: props?.showFilterOpts ?? true,
                    },
                    {
                      className: 'DimensionListWithFiltersButtonDropdown',
                      render: () => (
                        <BngDropdown
                          className="w-100"
                          popperClassName="DimensionListWithFiltersPopper BngDropdownCheckboxPopper"
                          customButton={({ openDropdown }) => {
                            return (
                              <div className={'DimensionListWithFiltersButtonWrapper'} onClick={openDropdown}>
                                <Icon
                                  className={`DimensionListWithFiltersButton Icon ${
                                    val.sortOrder || val.sortQuantity ? 'Selected' : ''
                                  }`}
                                  icon="sort_by_alpha"
                                />
                                <label className={'DimensionListWithFiltersButton Label'}>
                                  {msg.t('sort_members')}
                                </label>
                              </div>
                            );
                          }}
                          customOptions={({ closeDropdown }) => {
                            const sortOptions = [
                              { value: 'NONE', label: msg.t('none') },
                              { value: 'ASC', label: msg.t('ascending') },
                              { value: 'DESC', label: msg.t('descending') },
                            ];

                            const [sortOrder, setSortOrder] = useState(val.sortOrder || 'NONE');
                            const [sortQuantity, setSortQuantity] = useState(val.sortQuantity || 0);
                            const [enableSortQuantity, setEnableSortQuantity] = useState(
                              val.enableSortQuantity || false
                            );
                            const [sortMeasure, setSortMeasure] = useState();

                            useEffect(() => {
                              setSortOrder(val.sortOrder);
                              setSortQuantity(val.sortQuantity);
                              setEnableSortQuantity(val.enableSortQuantity);
                              setSortMeasure(val.sortMeasure);
                            }, [val.sortOrder, val.sortQuantity, val.enableSortQuantity, val.sortMeasure]);

                            const applySort = () => {
                              formikProps.setFieldValue(`${fieldPrefix}.sortOrder`, sortOrder);
                              formikProps.setFieldValue(`${fieldPrefix}.sortQuantity`, sortQuantity);
                              formikProps.setFieldValue(`${fieldPrefix}.enableSortQuantity`, enableSortQuantity);
                              formikProps.setFieldValue(`${fieldPrefix}.sortMeasure`, sortMeasure);
                              closeDropdown();
                            };

                            const disableSortQuantity = _.isEmpty(sortOrder) || sortOrder === 'NONE';
                            return (
                              <div>
                                <div className="fw-500 pb-2">{`${msg.t('sort_members')}:`}</div>
                                <BngSelect
                                  options={sortOptions}
                                  value={sortOrder}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setSortOrder(newValue);
                                    setSortQuantity(
                                      newValue === 'NONE' || _.isEmpty(newValue)
                                        ? 0
                                        : enableSortQuantity && sortQuantity === 0
                                        ? 1
                                        : sortQuantity
                                    );
                                  }}
                                />
                                {sortMeasureOpts && (
                                  <>
                                    <div className="fw-500 pb-2">{`${msg.t('Measure')}:`}</div>

                                    <BngSelect
                                      options={sortMeasureOpts}
                                      value={sortMeasure}
                                      emptyOption={msg.t('DEFAULT')}
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        setSortMeasure(newValue);
                                      }}
                                    />
                                  </>
                                )}
                                <div className="fw-500 pb-2">
                                  {`${msg.t('limit_members')}:`}
                                  <BngSwitch
                                    checked={enableSortQuantity}
                                    onChange={(e) => {
                                      setEnableSortQuantity(e.target.checked);
                                      setSortQuantity(
                                        sortOrder === 'NONE' || _.isEmpty(sortOrder)
                                          ? 0
                                          : e.target.checked && (sortQuantity === 0 || _.isEmpty(sortQuantity))
                                          ? 1
                                          : 0
                                      );
                                    }}
                                  />
                                </div>
                                <BngInputSpinner
                                  value={sortQuantity}
                                  onChange={(e) => setSortQuantity(e)}
                                  min={1}
                                  max={100}
                                  step={1}
                                  disabled={!enableSortQuantity || disableSortQuantity}
                                  disableAddRemoveButton={!enableSortQuantity || disableSortQuantity}
                                  allowNegative={false}
                                />
                                <div className="mt-3">
                                  <Button className={`bng-button save btn-small w-100`} onClick={applySort}>
                                    {msg.t('apply')}
                                  </Button>
                                </div>
                                <BngClickOutsideOverlay
                                  onClick={applySort}
                                  className="DimensionListWithFiltersPopper"
                                />
                              </div>
                            );
                          }}
                        />
                      ),
                      visible: props.showOrderOpts ?? true,
                    },
                  ],
                  [
                    {
                      className: 'DimensionListWithFiltersButtonDropdown',
                      onClick: () => arrayHelpers.remove(idx),
                      disabled: dimensions.length === 1,
                      closeOnSelect: true,
                      render: () => (
                        <BngDropdown
                          customButton={() => {
                            return (
                              <div
                                className={`DimensionListDeleteButtonWrapper ${
                                  dimensions.length === 1 ? 'disabled' : ''
                                }`}
                              >
                                <Icon className={`DimensionListDeleteButton Icon`} icon="delete" />
                                <label className={'DimensionListDeleteButton Label'}>{msg.t('delete')}</label>
                              </div>
                            );
                          }}
                        />
                      ),
                    },
                  ],
                ];

                return (
                  <div key={fieldPrefix} className="DimensionListWithFilters">
                    <Field
                      name={`${fieldPrefix}.dimension`}
                      label={
                        idx === 0
                          ? `${msg.t('level')} 1 ${msg.t('visualization')}`
                          : `${msg.t('level')} ${idx + 1} ${msg.t('detailing')}`
                      }
                      component={BngField}
                      inputComponent={BngSelectSearch}
                      options={processedDimensionOpts}
                      rootClassName="Dimension"
                      form={{
                        ...formikProps,
                        setFieldValue: (name, value) => {
                          let maintainFilterId = false;
                          // Caso esteja trocando somente a hierarquia dentro da dimensão de tempo não é necessário
                          // zerar o filterId
                          if (value?.includes('.[') && val.dimension?.includes('.[')) {
                            maintainFilterId = value.split('.')[0] === val.dimension.split('.')[0];
                          }

                          formikProps.setFieldValue(name, value);

                          if (!maintainFilterId) {
                            let filterId = '';

                            const filters = findFiltersForDim(value);
                            if (filters.length === 1) {
                              filterId = filters[0].id;
                            }
                            formikProps.setFieldValue(`${fieldPrefix}.filterId`, filterId);
                          }
                        },
                      }}
                      groupedOpts
                    />
                    <div className="no-wrap">
                      <div className={`FilterButtonContainer ${filtersForDim.length > 0 ? 'filterIconGlow' : ''}`}>
                        <BngDropdown
                          closeOnSelect={false}
                          options={opts}
                          className="FilterButtonContainer"
                          popperClassName="DimensionListWithFiltersPopper BngDropdownPopper"
                        />
                        {filtersForDim.length > 0 && !val.filterId && <div className="filterBlink" />}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="d-flex">
                <Button
                  className={'bng-button fix save flex-grow-1 Action AddButton AddLevelButton'}
                  onClick={() =>
                    arrayHelpers.push({
                      dimension: '',
                      filterId: '',
                    })
                  }
                  disabled={disableAddButton}
                  title={disableAddButton ? msg.t('level.limit.reached.title', [LEVEL_LIMIT]) : undefined}
                >
                  {msg.t('add.level')}
                </Button>
              </div>
            </div>
          );
        }}
      </FieldArray>
    );
  },
};
