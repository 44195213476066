import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import { useFormikContext } from 'formik';

import { MODALS } from 'components/ui/redux/Actions';
import { RightMenuItem } from 'components/ui/right-menu/RightMenu';
import SaveAsDialogContainer from 'components/ui/common/SaveAsDialogContainer';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { useEventListener } from 'components/hooks/useEventListener';

export default function SaveMenuItem({
  isPersisted = true,
  save = _.noop,
  openSaveAs = _.noop,
  saveAs = _.noop,
  clearAccordion = _.noop,
  dirtyCheckFunction,
  ...props
}) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const [isDirty, setDirty] = useState(props.dirty ?? false);
  const { submitForm, values, initialValues, resetForm } = useFormikContext();

  useEffect(() => {
    window.__OBJECT_RELOAD = ({ dirty, reset = true, reloadFilters = false }) => {
      setDirty(dirty);
      if (reset) {
        resetForm();
        if (_.isFunction(window.__FILTERS_FORM_RESET)) {
          window.__FILTERS_FORM_RESET(reloadFilters);
        }
      }
    };
    return () => delete window.__OBJECT_RELOAD;
  }, []);

  useEffect(() => {
    window.__OBJECT_DIRTY = isDirty || dirtyCheckFunction?.();
  }, [isDirty, dirtyCheckFunction?.()]);

  useEventListener('beforeunload', (event) => {
    if (window.__OBJECT_DIRTY && !window.__IGNORE_DIRTY_OBJECT) {
      event.preventDefault();
      event.returnValue = ''; // Gecko, Trident, Chrome 34+
      return ''; // Gecko, WebKit, Chrome <34
    }
  });

  useEventListener('visibilitychange', () => {
    if (!(application.ice.isLoaded() || !window.__OBJECT_DIRTY)) {
      return;
    }

    delete window.__OBJECT_DIRTY;
    window.__JSF_DISPOSE_CALLBACK?.();
  });

  useEffect(() => {
    setDirty(props.dirty);
  }, [props.dirty]);

  useEffect(() => {
    if (dirtyCheckFunction) {
      return;
    }
    setDirty(!_.isEqual(values, initialValues) || props.dirty);
  }, [values, initialValues]);

  const handleSave = async () => {
    if (!isPersisted) {
      dispatch(
        MODALS.open(SaveAsDialogContainer, {
          onSubmit: saveAs,
          ...props,
        })
      );
      return;
    }
    await save({ submitForm, values });
    clearAccordion();
  };

  let showDirtyAlert = isDirty;
  if (dirtyCheckFunction) {
    showDirtyAlert = dirtyCheckFunction();
  }

  return (
    <RightMenuItem
      {...props}
      onClick={handleSave}
      title={context.msg.t('save')}
      icon="save"
      className={`SaveMenuItem ${showDirtyAlert ? 'right-menu-icon-glow' : ''}`}
    >
      {showDirtyAlert && <div className="blink" title={context.msg.t('dashboard.pending.changes')} />}
    </RightMenuItem>
  );
}

SaveMenuItem.propTypes = {
  path: PropType.string,
  isPersisted: PropType.bool,
  dirty: PropType.bool,
  save: PropType.func,
  saveAs: PropType.func,
  clearAccordion: PropType.func,
  openSaveAs: PropType.func,
  generalConfigRef: PropType.any,
  dirtyCheckFunction: PropType.func,
};
